import React, { useEffect, useState } from "react";
import ServiceDynamicForm from "./ServiceDynamicForm";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { useParams } from "react-router-dom";
import FilledServiceForms from "./FilledServiceForms";
import { Card, Col, Row } from "react-bootstrap";
import ServiceFormsUpdate from "./ServiceFormsUpdate";

const ServiceFormsMainPage = () => {
  const accessToken = sessionStorage.getItem("accessToken");
  const LoginUserType = sessionStorage.getItem("LoginUserType");
  const ParamsData = useParams();

  const [PreTreatmentFormTab, setPreTreatmentFormTab] = useState(false);
  const [PostTreatmentFormTab, setPostTreatmentFormTab] = useState(false);
  const [ConcentFormTab, setConcentFormTab] = useState(true);
  const [TreatmentFormTab, setTreatmentFormTab] = useState(false);
  const [TreatmentFormTabTwo, setTreatmentFormTabTwo] = useState(false);

  const handlePreTreatmentFormTab = () => {
    setPreTreatmentFormTab(true);
    setPostTreatmentFormTab(false);
    setConcentFormTab(false);
    setTreatmentFormTab(false);
    setTreatmentFormTabTwo(false);
  };
  const handlePostTreatmentFormTab = () => {
    setPreTreatmentFormTab(false);
    setPostTreatmentFormTab(true);
    setConcentFormTab(false);
    setTreatmentFormTab(false);
    setTreatmentFormTabTwo(false);
  };
  const handleConcentFormTab = () => {
    setPreTreatmentFormTab(false);
    setPostTreatmentFormTab(false);
    setConcentFormTab(true);
    setTreatmentFormTab(false);
    setTreatmentFormTabTwo(false);
  };
  const handlTreatmentFormTab = () => {
    setPreTreatmentFormTab(false);
    setPostTreatmentFormTab(false);
    setConcentFormTab(false);
    setTreatmentFormTab(true);
    setTreatmentFormTabTwo(false);
  };
  const handlTreatmentFormTabTwo = () => {
    setPreTreatmentFormTab(false);
    setPostTreatmentFormTab(false);
    setConcentFormTab(false);
    setTreatmentFormTab(false);
    setTreatmentFormTabTwo(true);
  };

  const [PreTreatmentFormData, setPreTreatmentFormData] = useState(null);
  const [PostTreatmentFormData, setPostTreatmentFormData] = useState(null);
  const [ConcentFormData, setConcentFormData] = useState(null);
  const [TreatmentFormData, setTreatmentFormData] = useState(null);
  const [TreatmentFormDataTwo, setTreatmentFormDataTwo] = useState(null);
  const [FilledPreTreatmentFormData, setFilledPreTreatmentFormData] =
    useState(null);
  const [FilledPostTreatmentFormData, setFilledPostTreatmentFormData] =
    useState(null);
  const [FilledConcentFormData, setFilledConcentFormData] = useState(null);
  const [FilledTreatmentFormData, setFilledTreatmentFormData] = useState(null);
  const [FilledTreatmentFormDataTwo, setFilledTreatmentFormDataTwo] = useState(null);

  const [apiTrigger, setApiTrigger] = useState(false);

  const handleChildSubmit = (success) => {
    if (success) {
      setApiTrigger(!apiTrigger);
    }
  };

  const [CompleteFormData, setCompleteFormData] = useState(null);
  const [MasterServiceNoData, setMasterServiceNoData] = useState(null);
  const LoginPatientUUID = sessionStorage.getItem("LoginPatientUUID");

  const [concentFormVerifiedbyProvider,setconcentFormVerifiedbyProvider]=useState(null)
  // console.log("concentFormVerifiedbyProvider",concentFormVerifiedbyProvider)
  useEffect(() => {
    const fetchPatientForms = async () => {
      try {
        let endpoint = `/apis/default/api/patient/${ParamsData.pid}/serviceForms/${ParamsData.formname}/appointment/${ParamsData.eid}`;

        if (LoginUserType == "Patient") {
          endpoint = `/apis/default/portal/patient/${ParamsData.pid}/serviceForms/${ParamsData.formname}/appointment/${ParamsData.eid}`;
        }

        const patientFormsResponse = await ApiServices.get(
          endpoint,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );
        setCompleteFormData(patientFormsResponse.data["0"]);
        setconcentFormVerifiedbyProvider(patientFormsResponse.data["0"].is_consent_provider_reviewed);
        const PreTreatmentJson =
          patientFormsResponse.data["0"].pre_treatment_json;
        const PostTreatmentJson =
          patientFormsResponse.data["0"].post_treatment_json;
        const ConcentFormJson =
          patientFormsResponse.data["0"].consent_treatment_json;
        const TreatmentFormJson = patientFormsResponse.data["0"].treatment_json;
        const TreatmentFormJsonTwo = patientFormsResponse.data["0"].treatment_2_json;

        const FilledPreTreatmentJson =
          patientFormsResponse.data["0"].filled_pretreatment_form_data;
        const FilledPostTreatmentJson =
          patientFormsResponse.data["0"].filled_posttreatment_form_data;
        const FilledConcentFormJson =
          patientFormsResponse.data["0"].filled_consent_form_data;
        const FilledTreatmentFormJson =
          patientFormsResponse.data["0"].filled_treatment_form_data;
        const FilledTreatmentFormJsonTwo =
          patientFormsResponse.data["0"].filled_treatment_form_data_2;
        try {
          const PreTreatmentJsondataArray = JSON.parse(PreTreatmentJson);
          setPreTreatmentFormData(PreTreatmentJsondataArray);
          const PostTreatmentJsondataArray = JSON.parse(PostTreatmentJson);
          setPostTreatmentFormData(PostTreatmentJsondataArray);
          const ConcentFormJsondataArray = JSON.parse(ConcentFormJson);
          setConcentFormData(ConcentFormJsondataArray);
          const TreatmentFormJsondataArray = JSON.parse(TreatmentFormJson);
          setTreatmentFormData(TreatmentFormJsondataArray);
          const TreatmentFormJsondataArrayTwo = JSON.parse(TreatmentFormJsonTwo);
          setTreatmentFormDataTwo(TreatmentFormJsondataArrayTwo);

          const FilledPreTreatmentJsondataArray = JSON.parse(
            FilledPreTreatmentJson
          );
          setFilledPreTreatmentFormData(FilledPreTreatmentJsondataArray);
          const FilledPostTreatmentJsondataArray = JSON.parse(
            FilledPostTreatmentJson
          );
          setFilledPostTreatmentFormData(FilledPostTreatmentJsondataArray);
          const FilledConcentFormJsondataArray = JSON.parse(
            FilledConcentFormJson
          );
          setFilledConcentFormData(FilledConcentFormJsondataArray);
          const FilledTreatmentFormJsondataArray = JSON.parse(
            FilledTreatmentFormJson
          );
          setFilledTreatmentFormData(FilledTreatmentFormJsondataArray);
          const FilledTreatmentFormJsondataArrayTwo = JSON.parse(
            FilledTreatmentFormJsonTwo
          );
          setFilledTreatmentFormDataTwo(FilledTreatmentFormJsondataArrayTwo);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPatientForms();

    const MasterServiceNoData = async () => {
      try {
        let endpoint = `/apis/default/api/masterServices/${ParamsData.formname}`;

        if (LoginUserType == "Patient") {
          endpoint = `/apis/default/portal/masterServices/${ParamsData.formname}`;
        }
        const MasterServiceNo = await ApiServices.get(
          endpoint,
          // `/apis/default/api/masterServices/${ParamsData.formname}`,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );

        setMasterServiceNoData(MasterServiceNo.data["0"]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    MasterServiceNoData();
  }, [apiTrigger]);

  const [patientData, setpatientData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `/apis/default/api/patient/${ParamsData.uuid}`;

        if (LoginUserType == "Patient") {
          endpoint = `/apis/default/portal/patient/${LoginPatientUUID}`;
        }

        const patientResponse = await ApiServices.get(
          endpoint,
          // `/apis/default/api/patient/${ParamsData.uuid}`,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );

        const patientdata = patientResponse.data.data;
        setpatientData(patientdata);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [ParamsData]);

  const [AppointmentData, setAppointmentData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `/apis/default/api/appointment/${ParamsData.eid}`;

        if (LoginUserType == "Patient") {
          endpoint = `/apis/default/portal/appointment/${ParamsData.eid}`;
        }
        const AppointmentDataResponse = await ApiServices.get(
          endpoint,
          // `/apis/default/api/appointment/${ParamsData.eid}`,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );
        const AppointmentData = AppointmentDataResponse.data["0"];
        setAppointmentData(AppointmentData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [ParamsData]);


  return (
    <div style={{ position: "relative" , overflow: "hidden"}}>
      <div style={{ display: "flex", justifyContent: "space-around",position: "fixed",
            top: 60,
            left: 0,
            right: 0,
            background: "rgb(241, 245, 248)",
            zIndex: 1, 
            padding: "10px", 
            maxWidth: "100%"
            }}>
        {MasterServiceNoData && MasterServiceNoData.has_pre_treatment == 1 && (
          <button
            onClick={handlePreTreatmentFormTab}
            className={
              PreTreatmentFormTab
                ? "appointment-type-btn-active"
                : "appointment-type-btn-inactive"
            }
          >
            Pre Treatment Form
          </button>
        )}
        {MasterServiceNoData &&
          MasterServiceNoData.has_consent_treatment == 1 && (
            <button
              onClick={handleConcentFormTab}
              className={
                ConcentFormTab
                  ? "appointment-type-btn-active"
                  : "appointment-type-btn-inactive"
              }
            >
              Consent Form Tab
            </button>
          )}

        {LoginUserType != "Patient" && (
          <>
            {MasterServiceNoData &&
              MasterServiceNoData.has_treatment_form == 1 && (
                <button
                  onClick={handlTreatmentFormTab}
                  className={
                    TreatmentFormTab
                      ? "appointment-type-btn-active"
                      : "appointment-type-btn-inactive"
                  }
                >
                  Treatment Form
                </button>
              )}
          </>
        )}

        {LoginUserType != "Patient" && (
          <>
            {MasterServiceNoData &&
              MasterServiceNoData.has_treatment_form_2 == 1 && (
                <button
                  onClick={handlTreatmentFormTabTwo}
                  className={
                    TreatmentFormTabTwo
                      ? "appointment-type-btn-active"
                      : "appointment-type-btn-inactive"
                  }
                >
                  Treatment Form 2
                </button>
              )}
          </>
        )}

        {MasterServiceNoData && MasterServiceNoData.has_post_treatment == 1 && (

          <>
          {LoginUserType == 'Patient'
          ?
          <>
          { CompleteFormData&&CompleteFormData.show_post_treatment == 1 &&
          <button
            onClick={handlePostTreatmentFormTab}
            className={
              PostTreatmentFormTab
                ? "appointment-type-btn-active"
                : "appointment-type-btn-inactive"
            }
          >
            Post Treatment Form
          </button>}</>
          :
          <button
            onClick={handlePostTreatmentFormTab}
            className={
              PostTreatmentFormTab
                ? "appointment-type-btn-active"
                : "appointment-type-btn-inactive"
            }
          >
            Post Treatment Form
          </button>
          }
              </>
        )}
      </div>

      <div style={{ display: "flex", justifyContent: "space-around",position: "fixed",
            top: 100,
            left: 0,
            right: 0,
            background: "rgb(241, 245, 248)",
            zIndex: 1, 
            padding: "10px", 
            maxWidth: "100%"
            }}>
        <Card>
          <Card.Body>
            <div style={{fontSize:"12px"}}>
              <Row>
                <Col sm={3}>
                  <b>Patient Name : </b> {patientData && patientData.fname}{" "}
                  {patientData && patientData.lname}
                </Col>
                <Col sm={3}>
                  <b>Gender : </b> {patientData && patientData.sex}
                </Col>
                <Col sm={3}>
                  <b>Date of Birth : </b> {patientData && patientData.DOB}
                </Col>
                <Col sm={3}>
                  <b>Contact Number : </b>{" "}
                  {patientData && patientData.phone_contact}
                </Col>
                <Col sm={3}>
                  <b>Email ID : </b> {patientData && patientData.email}
                </Col>
                <Col sm={6}>
                  <b>Address : </b> {patientData && patientData.street}{" "}
                  {patientData && patientData.city}{" "}
                  {patientData && patientData.state}{" "}
                  {patientData && patientData.country_code}
                </Col>
                <Col sm={6}>
                  <b>Appointment Type : </b>{" "}
                  {AppointmentData && AppointmentData.appointment_type}
                </Col>
                <Col sm={6}>
                  <b style={{ color: "red" }}>Note : </b>{" "}
                  {AppointmentData && AppointmentData.appointment_note}
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>
      </div>

      <div style={{marginTop:"145px"}}>
        {MasterServiceNoData && MasterServiceNoData.has_pre_treatment == 1 && (
          <>
            {PreTreatmentFormTab && (
              <div>
                {FilledPreTreatmentFormData == null ? (
                  <>
                    <ServiceDynamicForm
                      DynamicFormData={PreTreatmentFormData}
                      CompleteFormData={CompleteFormData}
                      FormName="filled_pretreatment_form_data"
                      onChildSubmit={handleChildSubmit}
                    />
                  </>
                ) : (
                  <ServiceFormsUpdate
                    AvailableForms={PreTreatmentFormData}
                    FilledArrayForm={FilledPreTreatmentFormData}
                    DynamicFormData={PreTreatmentFormData}
                      CompleteFormData={CompleteFormData}
                      FormName="filled_pretreatment_form_data"
                      onChildSubmit={handleChildSubmit}
                  />
                )}
              </div>
            )}
          </>
        )}
        {MasterServiceNoData && MasterServiceNoData.has_post_treatment == 1 && (
          <>
            {PostTreatmentFormTab && (
              <div>
                {FilledPostTreatmentFormData == null ? (
                  <>
                    <ServiceDynamicForm
                      DynamicFormData={PostTreatmentFormData}
                      CompleteFormData={CompleteFormData}
                      FormName="filled_posttreatment_form_data"
                      onChildSubmit={handleChildSubmit}
                    />
                  </>
                ) : (
                  <ServiceFormsUpdate
                    AvailableForms={PostTreatmentFormData}
                    FilledArrayForm={FilledPostTreatmentFormData}
                    DynamicFormData={PostTreatmentFormData}
                      CompleteFormData={CompleteFormData}
                      FormName="filled_posttreatment_form_data"
                      onChildSubmit={handleChildSubmit}
                  />
                )}
              </div>
            )}
          </>
        )}
        {MasterServiceNoData &&
          MasterServiceNoData.has_consent_treatment == 1 && (
            <div>
              {ConcentFormTab && (
                <div>
                  {FilledConcentFormData == null ? (
                    <>
                      <ServiceDynamicForm
                        DynamicFormData={ConcentFormData}
                        CompleteFormData={CompleteFormData}
                        FormName="filled_consent_form_data"
                        onChildSubmit={handleChildSubmit}
                      />
                    </>
                  ) : (
                    <div>
                    <ServiceFormsUpdate
                      AvailableForms={ConcentFormData}
                      FilledArrayForm={FilledConcentFormData}
                      DynamicFormData={ConcentFormData}
                        CompleteFormData={CompleteFormData}
                        concentFormVerifiedbyProvider={concentFormVerifiedbyProvider}
                        FormName="filled_consent_form_data"
                        onChildSubmit={handleChildSubmit}
                    />
                    {/* <FilledServiceForms
                      AvailableForms={ConcentFormData}
                      FilledArrayForm={FilledConcentFormData}
                    /> */}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        {MasterServiceNoData && MasterServiceNoData.has_treatment_form == 1 && (
          <div>
            {TreatmentFormTab && (
              <div>
                {FilledTreatmentFormData == null ? (
                  <ServiceDynamicForm
                    DynamicFormData={TreatmentFormData}
                    CompleteFormData={CompleteFormData}
                    FormName="filled_treatment_form_data"
                    onChildSubmit={handleChildSubmit}
                  />
                ) : (
                  <ServiceFormsUpdate
                    AvailableForms={TreatmentFormData}
                    FilledArrayForm={FilledTreatmentFormData}
                    DynamicFormData={TreatmentFormData}
                    CompleteFormData={CompleteFormData}
                    FormName="filled_treatment_form_data"
                    onChildSubmit={handleChildSubmit}
                  />
                )}
              </div>
            )}
          </div>
        )}
        {MasterServiceNoData && MasterServiceNoData.has_treatment_form_2 == 1 && (
          <div>
            {TreatmentFormTabTwo && (
              <div>
                {FilledTreatmentFormDataTwo == null ? (
                  <ServiceDynamicForm
                    DynamicFormData={TreatmentFormDataTwo}
                    CompleteFormData={CompleteFormData}
                    FormName="filled_treatment_form_data_2"
                    onChildSubmit={handleChildSubmit}
                  />
                ) : (
                  <ServiceFormsUpdate
                    AvailableForms={TreatmentFormDataTwo}
                    FilledArrayForm={FilledTreatmentFormDataTwo}
                    DynamicFormData={TreatmentFormDataTwo}
                    CompleteFormData={CompleteFormData}
                    FormName="filled_treatment_form_data_2"
                    onChildSubmit={handleChildSubmit}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceFormsMainPage;
