import React from "react";
import "./Loader.css";
import { Bars } from "react-loader-spinner";

const NewLoginLoader = () => {
  return (
    <div><div className="loader-overlay">
    <div className="loader-content">
    <div style={{display:"flex",justifyContent:"center"}}>
      <Bars
        height="100"
        width="200"
      //   color="#4fa94d"
        color="#5D33FC"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
    <h1>Hey there! Just a moment, please. We're setting up your personalized dashboard.</h1>
  </div>
  </div>
  </div>
  )
}

export default NewLoginLoader