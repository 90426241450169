import React, { useEffect, useState } from 'react';
import { Carousel, Col, ProgressBar, Row } from 'react-bootstrap';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ApiServices from '../../GlobalFiles/ApiServices/ApiServices';
import { useMediaQuery } from '@mui/material';

const MedSpaTreatmentCarousel = () => {
  
  const isSmallScreen = useMediaQuery('(max-width: 576px)');
  const itemsPerSlide = isSmallScreen ? 1 : 3;
  
  const [activeIndex, setActiveIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Skin'); 
  const [allItems, setAllItems] = useState([]);
  const [categoryItems, setCategoryItems] = useState([]);

  useEffect(() => {
    ApiServices.get("/api/services/").then((response) => {
      const items = response.data;
      const uniqueCategories = Array.from(new Set(items.map((item) => item.treatment_type)));
      setCategories(uniqueCategories);
      setAllItems(items);
    });
  }, []);

const NewcategoryItems = [...categoryItems,...categoryItems,...categoryItems]
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + " ..."; 
    }
    return text;
  };

  useEffect(() => {
    
    const filteredItems = allItems.filter((item) => item.treatment_type === selectedCategory && item.activestatus && item.is_subtype == false);
    const sortedData = [...filteredItems].sort((a, b) => a.sequence - b.sequence);
    setCategoryItems(sortedData);
    setActiveIndex(0);
    updateProgress(0, filteredItems.length);
  }, [selectedCategory, allItems]);
  

  const handleNext = () => {
    const newIndex = (activeIndex + 1) % categoryItems.length;
    setActiveIndex(newIndex);
    updateProgress(newIndex, categoryItems.length);
  };

  const handlePrev = () => {
    const newIndex = (activeIndex - 1 + categoryItems.length) % categoryItems.length;
    setActiveIndex(newIndex);
    updateProgress(newIndex, categoryItems.length);
  };

  const updateProgress = (newIndex, itemCount) => {
    setProgress(((newIndex + 1) / itemCount) * 100);
  };
  
  return (
    <div className="custom-carousel-container">
      <div className="treatment-category-buttons" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        {categories&&categories?.map((category, index) => (
          <button
            key={index}
            onClick={() => setSelectedCategory(category)}
            className={selectedCategory === category ? 'selected-treatment-category' : 'nonselected-treatment-category'}
          >
            {category}
          </button>
        ))}
      </div>
      <Carousel
        interval={null}
        activeIndex={activeIndex}
        className="custom-carousel"
        slide={false}
        indicators={false}
      >
        {categoryItems&&categoryItems?.map((item, itemIndex) => (
          <Carousel.Item key={itemIndex}>
            <Row className="justify-content-between">
              {NewcategoryItems&&NewcategoryItems?.slice(itemIndex, itemIndex + itemsPerSlide).map((slideItem, slideItemIndex) => (
                <Col sm={4} key={slideItemIndex}>
                  <div className='gradient-overlay'>
                    <img src={slideItem.image} alt={`Slide ${slideItemIndex + 1}`} className='treatment-curousal-image' />
                    <Carousel.Caption className='treatment-carousal-caption'>
                      <h3 className='traetment-caption-title-caurousal'>{slideItem.title}</h3>
                      <p className='traetment-caption-description-caurousal'>
                      {truncateText(slideItem.short_info, 150)}
                      </p>
                      <div style={{ display: "flex", justifyContent: "left" }}>
                      <Link to={`/services/${slideItem.id}`}>
                        <button className='traetment-caption-button-caurousal'>Learn More</button>
                      </Link>
                      </div>
                    </Carousel.Caption>
                  </div>
                </Col>
              ))}
            </Row>
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="medispa-treatment-carousel-controls">
        <ProgressBar now={progress} className={`progress-bar ${progress >= 100 ? 'progress-bar-med-spa-bg-complete' : 'progress-bar-med-spa-bg-remain'}`} />
        <FaArrowLeft onClick={handlePrev} className='med-spa-treat-curousal-navigators' />
        <FaArrowRight onClick={handleNext} className='med-spa-treat-curousal-navigators' />
      </div>
      <div style={{ display: "flex", justifyContent: "right", paddingTop: "10px" }}>
        <Link to="/services">
          <button className='med-spa-custom-button' style={{ minWidth: "150px" }}>
            See all Services
          </button>
        </Link>
      </div>
    </div>
  );
};

export default MedSpaTreatmentCarousel;
