import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button } from 'react-bootstrap';
import Loader from '../Loader/Loader';

const PaymentGateway = ({patientData, amount, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState('');
  const [customerId, setCustomerId] = useState('');
  const[disablebutton,setdisablebutton]=useState(false)
  const[loading,setloading]=useState(false)
  const[errorMessage,seterrorMessage]=useState(null)
  useEffect(() => {
    fetch(
      // 'https://cosmosapi.medispacosmos.com/api/create-customer/'
    `https://medispacosmos.com/riteclinic-openemr/apis/default/api/customer`
    , {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: patientData.email,
        firstName: patientData.fname,
        lastName: patientData.lname,
      }),
    })
    .then((res) => res.json())
    .then((data) => {
      setCustomerId(data.customerId);

      // Fetch the client secret using the customer ID
      return fetch(
        // 'https://cosmosapi.medispacosmos.com/api/create-payment-intent/'
        `https://medispacosmos.com/riteclinic-openemr/apis/default/api/paymentintent`
        , {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount:parseFloat(amount),
          email: patientData.email,
          firstName: patientData.fname,
          lastName: patientData.lname,
          customerId: data.id,
        }),
      });
    })
    .then((res) => res.json())
    .then((data) => {
      setClientSecret(data.client_secret);
    });
  }, [amount]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setloading(true)
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (result.error) {
      setloading(false)
      console.log('Payment Error!',result.error);
      seterrorMessage(result.error.message)
      console.log(result.error.message);
    } else if (result.paymentIntent.status === 'succeeded') {
      setloading(false)
      console.log('Payment succeeded!');
      if (typeof onSuccess === "function") {
        onSuccess();
      }
    }
  };



  return (
    <div>
    {loading && <Loader/>}
    <form onSubmit={handleSubmit}>
      <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '4px', maxWidth: '100%', margin: 'auto', }}>
        <label style={{ fontSize: '16px', marginBottom: '8px', display: 'block' }}>
          {/* Card details: */}
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
        </label>
        <div style={{textAlign:"center",paddingTop:"10px"}}>
        <Button variant='success' type="submit"  disabled={!stripe || disablebutton}>
          Pay $ {amount}
        </Button>
        </div>
        {errorMessage&&
        <div style={{textAlign:"justify",paddingTop:"10px"}}>
       <p style={{color:"red"}}>{errorMessage&&errorMessage}</p>
        </div>
        }

      </div>
    </form>
    </div>
  );
};

export default PaymentGateway;
