import React from "react";
import { Oval } from "react-loader-spinner";

const OvalLoader = () => {
  return (
    <div>
      <Oval
        height={10}
        width={10}
        color="#1c324a"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#1c324a66"
        strokeWidth={10}
        strokeWidthSecondary={10}
      />
    </div>
  );
};

export default OvalLoader;
