import React from 'react'
import "./MedSpaContactUs.css"

import ContactUsSecOne from './Sections/ContactUsSecOne'
import ContactUsSecTwo from './Sections/ContactUsSecTwo'
import ContactUsMap from './Sections/ContactUsMap'
import { Fade } from 'react-reveal'

const MedSpaContactUs = () => {
  return (
    <div>
          
          <div style={{paddingTop:"25px"}}>
        <ContactUsSecTwo/>
        </div>
          <div>
          <Fade bottom>
        <ContactUsMap/>
        </Fade>
        </div>
    </div>
  )
}

export default MedSpaContactUs