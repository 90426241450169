import React, { useEffect, useState } from "react";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { useParams } from "react-router-dom";
import { Card, Col, Row, Form, Button, Modal } from "react-bootstrap";
import { FaWallet } from "react-icons/fa";
import swal from "sweetalert";
import axios from "axios";
import QRCode from "qrcode.react";
import Loader from "../Loader/Loader";
import PaymentGateway from "../CardPayments/PaymentGateway";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const PatientWallet = () => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PROMISE
  );
  const paramsData = useParams();
  const LoginUserType = sessionStorage.getItem("LoginUserType");
  const accessToken = sessionStorage.getItem("accessToken");
  const [walletBalance, setWalletBalance] = useState();
  const [walletTransactions, setwalletTransactions] = useState();
  const [finalAmount, setfinalAmount] = useState(null);
  const [transactiondescription, settransactiondescription] = useState("");
  const [showPaymentMethods, setshowPaymentMethods] = useState(false);
  const [loading, setloading] = useState(false);
  const [fetchBillingDataAgin, setfetchBillingDataAgin] = useState(false);
  const { fname, lname, email } = paramsData;
  const CustomerBillData = { fname, lname, email };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `/apis/default/api/wallet/${paramsData.walletid}`;

        if (LoginUserType === "Patient") {
          endpoint = `/apis/default/portal/wallet/${paramsData.walletid}`;
        }

        const response = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });

        setWalletBalance(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    const fetchTransactions = async () => {
      try {
        let endpoint = `/apis/default/api/walletTransaction/${paramsData.walletid}`;

        if (LoginUserType === "Patient") {
          endpoint = `/apis/default/portal/walletTransaction/${paramsData.walletid}`;
        }

        const response = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });

        setwalletTransactions(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchTransactions();
  }, [paramsData, accessToken, LoginUserType, fetchBillingDataAgin]);

  const handleAddMoney = () => {
    if (showPaymentMethods) {
      setshowPaymentMethods(false);
      setfinalAmount("");
    } else {
      setshowPaymentMethods(true);
    }
    console.log("Amount to add:", finalAmount);
  };

  const [paymentMethod, setPaymentMethod] = useState("");
  const [firstCardAmount, setFirstCardAmount] = useState(0);
  const [cashAmount, setCashAmount] = useState(0);

  const handlePaymentMethodSelect = (method) => {
    setPaymentMethod(method);
  };

  const handleDualCardPayment = () => {
    const secondCardAmount = finalAmount - firstCardAmount;
  };

  const handleCardCashPayment = () => {
    const cardAmount = finalAmount - cashAmount;
  };

  const sendPaymentEmail = async () => {
    setloading(true);
    try {
      let endpoint = `/apis/default/api/walletSendLink`;

      if (LoginUserType === "Patient") {
        endpoint = `/apis/default/portal/walletSendLink`;
      }

      const response = await ApiServices.post(
        endpoint,
        {
          patient_fname: CustomerBillData.fname,
          patient_lname: CustomerBillData.lname,
          patient_email: CustomerBillData.email,
          wallet_id: paramsData.walletid,
          amount: parseFloat(finalAmount),
        },
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      swal({
        title: "Payment Link Send Successful",
        // text: `Pleasen Check ${CustomerBillData.email} for Payment Link`,
        icon: "success",
        button: "OK",
        timer: 3000,
      });
      setloading(false);
      console.log(JSON.stringify(response.data));
    } catch (error) {
      console.error(error);
      setloading(false);
    }
  };

  const paymentFor = "wallet_topup"
  const handleQRCodeClick = () => {
    const paymentPageURL = `/wallet-top-up-qr/${CustomerBillData.fname}/${CustomerBillData.lname}/${CustomerBillData.email}/${finalAmount}/${paramsData.walletid}/${paymentFor}`;
    const finalURL = `${paymentPageURL}`;
    window.open(finalURL, "_blank");
  };

  const cashPaymentReceived = async () => {
    setloading(true);
    try {
      const verifyBillingData = {
        transactionType: "credit",
        amount: finalAmount,
        description: transactiondescription,
        "topup_mode":"Cash"
      };

      let endpoint = `/apis/default/api/wallet/${paramsData.walletid}`;

      if (LoginUserType === "Patient") {
        endpoint = `/apis/default/portal/wallet/${paramsData.walletid}`;
      }

      const response = await ApiServices.put(
        endpoint,
        verifyBillingData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      swal({
        title: `Wallet Credited by Amount $ ${finalAmount} Successfully`,
        icon: "success",
        button: "OK",
        timer: 2000,
      });
    } catch (error) {
      console.error("Error Collecting Cash:", error);
      swal({
        title: `Something Went Wrong`,
        icon: "error",
        button: "OK",
        timer: 2000,
      });
    } finally {
      setloading(false);
      setfetchBillingDataAgin(!fetchBillingDataAgin);
      setshowPaymentMethods(false);
      setfinalAmount(``);
    }
  };

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const handlePaymentModalOpen = () => {
    setShowPaymentModal(true);
  };
      const handlePaymentModalClose = () => {
    setShowPaymentModal(false);
  };

  const handlePaymentModalCloseSuccess = async () => {
    setShowPaymentModal(false);
    try {
      let endpoint = `/apis/default/api/wallet/${paramsData.walletid}`;

      if (LoginUserType === "Patient") {
        endpoint = `/apis/default/portal/wallet/${paramsData.walletid}`;
      }

      const verifyBillingData = {
        transactionType: "Card Payment",
        amount: finalAmount,
        description: transactiondescription,
        "topup_mode":"Card"
      };

      const response = await ApiServices.put(
        endpoint,
        verifyBillingData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      swal({
        title: `Wallet Credited by Amount $ ${finalAmount} Successfully`,
        icon: "success",
        button: "OK",
        timer: 2000,
      });
    } catch (error) {
      console.error("Error Collecting Cash:", error);
      swal({
        title: `Something Went Wrong`,
        icon: "error",
        button: "OK",
        timer: 2000,
      });
    } finally {
      setloading(false);
      setfetchBillingDataAgin(!fetchBillingDataAgin);
      setshowPaymentMethods(false);
      setfinalAmount(``);
    }
  };

  const [voucherId, setVoucherId] = useState('');
  const [showVoucherModal, setshowVoucherModal] = useState(false);
  const handleVoucherModalOpen = () => {
    setshowVoucherModal(true);
  };
      const handleVoucherModalClose = () => {
        setshowVoucherModal(false);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    const payload = {
      voucher_id: voucherId,
      pid: paramsData.pid,
    };

    try {
      let endpoint = `/apis/default/api/redeemVoucher`;

      if (LoginUserType === "Patient") {
        endpoint = `/apis/default/portal/redeemVoucher`;
      }

      const response = await ApiServices.post(endpoint, payload,

        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      if (response.data.walletId && response.data.redeemedAmount) {
        swal({
          title: `Voucher redeemed successfully!`,
          text: `Redeemed Amount: ${response.data.redeemedAmount}`,
          icon: "success",
          button: "OK",
          timer: 2000,
        });
      } else if (response.data.error) {
        swal({
          title: `Error`,
          text: "This Voucher is already Used" ,
          icon: "error",
          button: "OK",
          timer: 2000,
        });
      }  
      setloading(false);
      setfetchBillingDataAgin(!fetchBillingDataAgin);
      setshowVoucherModal(false)
    } catch (error) {
      swal({
        title: `Something Went Wrong!`,
        icon: "error",
        button: "OK",
        timer: 2000,
      });
      setloading(false);
      setfetchBillingDataAgin(!fetchBillingDataAgin);
      setshowVoucherModal(false)
    }
  };



  return (
    <div>
      {loading && <Loader />}
      <Row>
        <Col sm={6} className="mb-2">
          <Card style={{ height: "100%" }}>
            <Card.Body>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                <b>
                  <FaWallet style={{ fontSize: "25px" }} /> Available Wallet
                  Balance
                </b>
                <>${walletBalance && walletBalance.currentBalance}</>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={6} className="mb-2">
          <Card>
            <Card.Body>
              <Row>
                <Col sm={6}>
                  <Form.Group controlId="addMoney">
                    <Form.Control
                      type="number"
                      placeholder="Enter Amount to Add money to wallet"
                      value={finalAmount}
                      onChange={(e) => setfinalAmount(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Button
                    onClick={handleAddMoney}
                    disabled={finalAmount == null}
                    variant={showPaymentMethods ? "danger" : "success"}
                  >
                    {showPaymentMethods
                      ? "Cancel Transaction"
                      : "Add Money to wallet"}
                  </Button>
                </Col>
                <Col sm={3}>
                  <Button
                    onClick={handleVoucherModalOpen}
                    variant="secondary"
                  >
                    Redeem Voucher
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {showPaymentMethods && (
        <div className="mt-3">
          <Card>
            <Card.Body>
              <div>
                <h5>Select Payment Method:</h5>
                <Form>
                  <Row>
                  {LoginUserType !== "Patient" &&
                    <Col sm={2}>
                      <Form.Check
                        type="radio"
                        label="Cash"
                        name="paymentMethod"
                        onChange={() => handlePaymentMethodSelect("cash")}
                      />
                    </Col>
                    }
                    <Col sm={2}>
                      <Form.Check
                        type="radio"
                        label="Single Card"
                        name="paymentMethod"
                        onChange={() =>
                          handlePaymentMethodSelect("single-card")
                        }
                      />
                    </Col>
                    <Col sm={2}>
                      <Form.Check
                        type="radio"
                        label="Dual Card"
                        name="paymentMethod"
                        onChange={() => handlePaymentMethodSelect("dual-card")}
                        disabled
                      />
                    </Col>
                    <Col sm={2}>
                      <Form.Check
                        type="radio"
                        label="Card + Cash"
                        name="paymentMethod"
                        onChange={() => handlePaymentMethodSelect("card-cash")}
                        disabled
                      />
                    </Col>
                    <Col sm={2}>
                      <Form.Check
                        type="radio"
                        label="QR"
                        name="paymentMethod"
                        onChange={() => handlePaymentMethodSelect("qr")}
                      />
                    </Col>
                    <Col sm={2}>
                      <Form.Check
                        type="radio"
                        label="Email Link"
                        name="paymentMethod"
                        onChange={() => handlePaymentMethodSelect("email")}
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="mt-3">
                <Form.Group controlId="transactiondescription">
                  <Form.Control
                    type="text"
                    placeholder="Add Note For this Transaction"
                    value={transactiondescription}
                    onChange={(e) => settransactiondescription(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="mt-3">
                {paymentMethod === "cash" && (
                  <div style={{ marginTop: "20px", textAlign: "center" }}>
                    <Button onClick={cashPaymentReceived}>
                      ${finalAmount} Payment Received in Cash
                    </Button>
                  </div>
                )}
                {paymentMethod === "single-card" && (
                  <div style={{ textAlign: "center" }}>
                    <Button variant="success" onClick={handlePaymentModalOpen}>
                      Proceed to Pay ${finalAmount}
                    </Button>
                  </div>
                )}
                {paymentMethod === "dual-card" && (
                  <div>
                    <Form.Group controlId="firstCardAmount" className="mt-3">
                      <Form.Label>Amount to Pay with First Card:</Form.Label>
                      <Form.Control
                        type="number"
                        value={firstCardAmount}
                        onChange={(e) =>
                          setFirstCardAmount(parseFloat(e.target.value))
                        }
                        max={finalAmount}
                      />
                    </Form.Group>
                    <Button onClick={handleDualCardPayment}>
                      Pay with Dual Card (First Card: ${firstCardAmount})
                    </Button>
                    <Button onClick={handleDualCardPayment}>
                      Pay with Dual Card (Second Card: $
                      {finalAmount - firstCardAmount})
                    </Button>
                  </div>
                )}
                {paymentMethod === "card-cash" && (
                  <div>
                    <Form.Group controlId="cashAmount" className="mt-3">
                      <Form.Label>Amount to Pay in Cash:</Form.Label>
                      <Form.Control
                        type="number"
                        value={cashAmount}
                        onChange={(e) =>
                          setCashAmount(parseFloat(e.target.value))
                        }
                        max={finalAmount}
                      />
                    </Form.Group>
                    <Button onClick={handleCardCashPayment}>
                      Pay with Card + Cash (Cash: ${cashAmount})
                    </Button>
                    <Button onClick={handleCardCashPayment}>
                      Pay with Card + Cash (Card: ${finalAmount - cashAmount})
                    </Button>
                  </div>
                )}
                {paymentMethod === "email" && (
                  <div style={{ marginTop: "20px", textAlign: "center" }}>
                    <Button onClick={sendPaymentEmail} variant="success">
                      Send Payment Link to Email
                    </Button>
                  </div>
                )}

                {paymentMethod === "qr" && (
                  <div style={{ marginTop: "20px", textAlign: "center" }}>
                    <h3>Scan QR Code to Pay</h3>
                    <QRCode
                      value={`https://medispacosmos.com/cosmos-emr/wallet-top-up-qr/${CustomerBillData.fname}/${CustomerBillData.lname}/${CustomerBillData.email}/${finalAmount}/${paramsData.walletid}/${paymentFor}`}
                      onClick={handleQRCodeClick}
                    />
                    <p>Final Amount: {finalAmount}</p>
                  </div>
                )}
              </div>
            </Card.Body>
          </Card>
        </div>
      )}

      <div className="mt-3">
        <h3>Wallet Transaction History</h3>
        <table className="billing-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Type</th>
              <th>Note</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {walletTransactions &&
              walletTransactions?.map((index) => (
                <tr>
                  <td>{index.transaction_id}</td>
                  <td>{index.transaction_date}</td>
                  <td>{index.transaction_type}</td>
                  <td>{index.description}</td>
                  <td>{index.amount}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <Modal
        show={showPaymentModal}
        onHide={handlePaymentModalClose}
        centered
        className="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Card Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showPaymentModal && (
            <Elements stripe={stripePromise}>
              <PaymentGateway
                patientData={CustomerBillData}
                amount={finalAmount}
                onSuccess={handlePaymentModalCloseSuccess}
              />
            </Elements>
          )}
        </Modal.Body>
      </Modal>


      <Modal
        show={showVoucherModal}
        onHide={handleVoucherModalClose}
        centered
        className="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Redeem Voucher</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <Row className="justify-content-md-center">
        <Col md="6">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="voucherId">
              <Form.Label>Voucher ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Voucher ID"
                value={voucherId}
                onChange={(e) => setVoucherId(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100 mt-3">
              Redeem
            </Button>
          </Form>
          
        </Col>
      </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PatientWallet;
