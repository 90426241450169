// import React, { useEffect, useState } from "react";
// import { Table, Button } from "react-bootstrap";
// import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
// import { Link, useNavigate, useParams } from "react-router-dom";

// const PatientDashboardAppointments = ({patientData}) => {
//   const paramsData = useParams()
//   const [AllAppointmentData, setAllAppointmentData] = useState([]);
//   const [AppointmentFormsData, setAppointmentFormsData] = useState([]);
//   const accessToken = sessionStorage.getItem("accessToken");
//   const LoginPatientID = sessionStorage.getItem("LoginPatientID");
//   const LoginUserType = sessionStorage.getItem("LoginUserType");

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         let endpoint = `/apis/default/api/patient/${paramsData.pid}/appointment`;

//         if (LoginUserType === "Patient") {
//           endpoint = `/apis/default/portal/patient/${LoginPatientID}/appointment`;
//         }

//         const appointmentDataResponse = await ApiServices.get(endpoint, {
//           Authorization: `Bearer ${accessToken}`,
//         });
//         if (appointmentDataResponse.data === "Patient does not have any appointment data.") {
//           setAllAppointmentData([]);
//         } else {
//           // Set AllAppointmentData to the received data
//           setAllAppointmentData(appointmentDataResponse.data || []);
//         }
//         // setAllAppointmentData(appointmentDataResponse.data || []);
//       } catch (error) {
//         console.error("Error fetching appointment data:", error);
//       }
//     };

//     fetchData();
//   }, [LoginPatientID, LoginUserType]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         let endpoint = `/apis/default/api/getFormsPatient/${paramsData.pid}`;

//         if (LoginUserType === "Patient") {
//           endpoint = `/apis/default/portal/getFormsPatient/${LoginPatientID}`;
//         }
//         const pendingFormsResponse = await ApiServices.get(endpoint, {
//           Authorization: `Bearer ${accessToken}`,
//         });
//         // setAppointmentFormsData(pendingFormsResponse.data || []);
//         if (pendingFormsResponse.data === "Forms data not available for this patient.") {
//           setAppointmentFormsData([]);
//         } else {
//           // Set AllAppointmentData to the received data
//           setAppointmentFormsData(pendingFormsResponse.data || []);
//         }
//       } catch (error) {
//         console.error("Error fetching forms data:", error);
//       }
//     };
//     fetchData();
//   }, [LoginPatientID, LoginUserType]);

//   const [AppointmentcombinedData, setAppointmentcombinedData] = useState([]);
//   const [openIndices, setOpenIndices] = useState([]);

// console.log("AppointmentcombinedData",AppointmentcombinedData)
//   useEffect(() => { 
//     const combinedData = AllAppointmentData&&AllAppointmentData?.map((appointment) => {
//       const matchingForms = AppointmentFormsData&&AppointmentFormsData?.filter(
//         (form) => form.eid === appointment.pc_eid
//       );
//       return {
//         pc_eid: appointment.pc_eid,
//         appointmentData: appointment,
//         formsData: matchingForms,
//       };
//     });

//     combinedData&&combinedData?.sort(
//       (a, b) =>
//         new Date(b.appointmentData.pc_eventDate) -
//         new Date(a.appointmentData.pc_eventDate)
//     );

//     setAppointmentcombinedData(combinedData);
//   }, [AllAppointmentData, AppointmentFormsData]);
  

//   const toggleCollapse = (index) => {
//     if (openIndices.includes(index)) {
//       setOpenIndices(openIndices.filter((i) => i !== index));
//     } else {
//       setOpenIndices([...openIndices, index]);
//     }
//   };

//   const navigate =useNavigate()

//   const handleFormData = (formData) => {
//     LoginUserType !== "Patient" ?
//     navigate(`/admin/service-form/${formData.eid}/${formData.pid}/${formData.form_id}/${paramsData.uuid}`)
//     :
//     navigate(`/patient-dashboard/service-form/${formData.eid}/${formData.pid}/${formData.form_id}`)
//   };

//   return (
//     <div className="table-responsive">
//     {AppointmentcombinedData &&
//       <Table striped bordered hover>
//         <thead>
//           <tr>
//             <th></th>
//             <th>ID</th>
//             <th>Date</th>
//             <th>Time</th>
//             <th>Reason</th>
//             <th>Status</th>
//             <th>Bill</th>
//           </tr>
//         </thead>
//         <tbody>
//           {AppointmentcombinedData &&
//             AppointmentcombinedData.map((appointment, index) => (
//               <React.Fragment key={appointment.pc_eid}>
//                 <tr>
//                   <td
//                     onClick={() => toggleCollapse(index)}
//                     style={{
//                       cursor: "pointer",
//                       textAlign: "center",
//                     }}
//                   >
//                     {openIndices.includes(index) ? "-" : "+"}
//                   </td>
//                   {appointment.appointmentData && (
//                     <>
//                       <td>{appointment.appointmentData.pc_eid}</td>
//                       <td>{appointment.appointmentData.pc_eventDate}</td>
//                       <td>{appointment.appointmentData.pc_startTime}</td>
//                       <td>{appointment.appointmentData.appointment_type}</td>
//                       <td style={{ textAlign: "center" }}>
//                         {appointment.appointmentData.pc_apptstatus ===
//                         "APPR" ? (
//                           <Button variant="success">Approved</Button>
//                         ) : (
//                           <Button variant="warning">Pending</Button>
//                         )}
//                       </td>
//                       <td>
//                         {/* {appointment.appointmentData.verified_receptionist ===
//                           1 &&  */}
//                           <Link to={
//                             LoginUserType !== "Patient" ?
//                             `/admin/patient-details-view/${patientData.uuid}/${appointment.appointmentData.pc_eid}/${patientData.pid}`
//                            : `/patient-dashboard/show-bill/${patientData.uuid}/${appointment.appointmentData.pc_eid}/${patientData.pid}`
//                             }>
//                           <Button variant="info">Show</Button>
//                           </Link>
//                           {/* } */}
//                       </td>
//                     </>
//                   )}
//                 </tr>
//                 {(openIndices.includes(index) ||
//                   appointment.formsData.some(
//                     (formData) => formData.is_consent_provider_reviewed === 0
//                   )) && (
//                   <tr>
//                     <td></td>
//                     <td colSpan={6}>
//                       <Table bordered>
//                         <thead>
//                           <tr>
//                             <th>Service</th>
//                             <th>Status</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {appointment.formsData &&
//                             appointment.formsData.map((formData, formIndex) => (
//                               <tr key={formIndex}>
//                                 <td>{formData.service}</td>
//                                 <td>
//                                   {formData.is_consent_provider_reviewed ==
//                                   0 ? (
//                                     <Button variant="danger" onClick={() => handleFormData(formData)}>Pending</Button>
//                                   ) : (
//                                     <Button variant="success" onClick={() => handleFormData(formData)}>Verified</Button>
//                                   )}
//                                 </td>
//                               </tr>
//                             ))}
//                         </tbody>
//                       </Table>
//                     </td>
//                   </tr>
//                 )}
//               </React.Fragment>
//             ))}
//         </tbody>
//       </Table>}
//     </div>
//   );
// };

// export default PatientDashboardAppointments;


import React, { useEffect, useState } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import swal from "sweetalert";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { Link, useNavigate, useParams } from "react-router-dom";

const PatientDashboardAppointments = ({ patientData }) => {
  const paramsData = useParams();
  const [AllAppointmentData, setAllAppointmentData] = useState([]);
  const [AppointmentFormsData, setAppointmentFormsData] = useState([]);
  const [AppointmentcombinedData, setAppointmentcombinedData] = useState([]);
  const [openIndices, setOpenIndices] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const accessToken = sessionStorage.getItem("accessToken");
  const LoginPatientID = sessionStorage.getItem("LoginPatientID");
  const LoginUserType = sessionStorage.getItem("LoginUserType");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `/apis/default/api/patient/${paramsData.pid}/appointment`;

        if (LoginUserType === "Patient") {
          endpoint = `/apis/default/portal/patient/${LoginPatientID}/appointment`;
        }

        const appointmentDataResponse = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });

        setAllAppointmentData(
          appointmentDataResponse.data ===
          "Patient does not have any appointment data."
            ? []
            : appointmentDataResponse.data || []
        );
      } catch (error) {
        console.error("Error fetching appointment data:", error);
      }
    };

    fetchData();
  }, [LoginPatientID, LoginUserType]);

  useEffect(() => {
    const fetchFormsData = async () => {
      try {
        let endpoint = `/apis/default/api/getFormsPatient/${paramsData.pid}`;
        if (LoginUserType === "Patient") {
          endpoint = `/apis/default/portal/getFormsPatient/${LoginPatientID}`;
        }
        const pendingFormsResponse = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });
        setAppointmentFormsData(
          pendingFormsResponse.data ===
          "Forms data not available for this patient."
            ? []
            : pendingFormsResponse.data || []
        );
      } catch (error) {
        console.error("Error fetching forms data:", error);
      }
    };

    fetchFormsData();
  }, [LoginPatientID, LoginUserType]);

  useEffect(() => {
    const combinedData =
      AllAppointmentData &&
      AllAppointmentData.map((appointment) => {
        const matchingForms =
          AppointmentFormsData &&
          AppointmentFormsData.filter(
            (form) => form.eid === appointment.pc_eid
          );
        return {
          pc_eid: appointment.pc_eid,
          appointmentData: appointment,
          formsData: matchingForms,
        };
      });

    combinedData &&
      combinedData.sort(
        (a, b) =>
          new Date(b.appointmentData.pc_eventDate) -
          new Date(a.appointmentData.pc_eventDate)
      );

    setAppointmentcombinedData(combinedData);
  }, [AllAppointmentData, AppointmentFormsData]);

  const toggleCollapse = (index) => {
    setOpenIndices((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const handleFormData = (formData) => {
    navigate(
      LoginUserType !== "Patient"
        ? `/admin/service-form/${formData.eid}/${formData.pid}/${formData.form_id}/${paramsData.uuid}`
        : `/patient-dashboard/service-form/${formData.eid}/${formData.pid}/${formData.form_id}`
    );
  };

  const handleCancelAppointment = async () => {
    try {
      const payload = {
        encounter_id: selectedAppointment.encounter_id,
        pid: selectedAppointment.pid,
        apptstatus: "CANCEL",
      };

      await ApiServices.put(
        "/apis/default/portal/apptStatusUpdate",
        payload,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );

      swal({
        title: "Appointment Cancelled Successfully",
        text: "Your Appointment at Cosmos Medi Spa has been Cancelled.",
        icon: "success",
        button: "OK",
        timer: 2000,
      });

      setShowModal(false);
      setSelectedAppointment(null);

      // Refresh data after cancellation
      setAllAppointmentData((prev) =>
        prev.filter(
          (appointment) =>
            appointment.pc_eid !== selectedAppointment.encounter_id
        )
      );
    } catch (error) {
      swal({
        title: "Error",
        text: "Unable to cancel the appointment. Please try again later.",
        icon: "error",
        button: "OK",
        timer: 2000,
      });
    }
  };

  const openCancelModal = (appointment) => {
    setSelectedAppointment({
      encounter_id: appointment.appointmentData.pc_eid,
      pid: appointment.appointmentData.pid,
    });
    setShowModal(true);
  };

  return (
    <div className="table-responsive">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Date</th>
            <th>Time</th>
            <th>Reason</th>
            <th>Status</th>
            <th>Bill</th>
            <th>Cancel</th>
          </tr>
        </thead>
        <tbody>
          {AppointmentcombinedData &&
            AppointmentcombinedData.map((appointment, index) => (
              <React.Fragment key={appointment.pc_eid}>
                <tr>
                  <td
                    onClick={() => toggleCollapse(index)}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    {openIndices.includes(index) ? "-" : "+"}
                  </td>
                  <td>{appointment.appointmentData.pc_eid}</td>
                  <td>{appointment.appointmentData.pc_eventDate}</td>
                  <td>{appointment.appointmentData.pc_startTime}</td>
                  <td>{appointment.appointmentData.appointment_type}</td>
                  <td style={{ textAlign: "center" }}>
                    {appointment.appointmentData.pc_apptstatus === "APPR" ? (
                      <Button variant="success">Approved</Button>
                    ) : (
                      <Button variant="warning">Pending</Button>
                    )}
                  </td>
                  <td>
                    <Link
                      to={
                        LoginUserType !== "Patient"
                          ? `/admin/patient-details-view/${patientData.uuid}/${appointment.appointmentData.pc_eid}/${patientData.pid}`
                          : `/patient-dashboard/show-bill/${patientData.uuid}/${appointment.appointmentData.pc_eid}/${patientData.pid}`
                      }
                    >
                      <Button variant="info">Show</Button>
                    </Link>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => openCancelModal(appointment)}
                      disabled={
                        new Date(appointment.appointmentData.pc_eventDate) <
                        new Date()
                      }
                    >
                      Cancel
                    </Button>
                  </td>
                </tr>
              </React.Fragment>
            ))}
        </tbody>
      </Table>

      {/* Modal for cancel confirmation */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel this appointment?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={handleCancelAppointment}>
            Confirm Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PatientDashboardAppointments;
