import React from "react";
import { Col, Container, Row } from "react-bootstrap";
// import GirlImage from "../../Assets/hpone.png"
import GirlImage from "../../Assets/komaldhiran.jpg"
import { Link } from "react-router-dom";
import WorkingHours from "../WorkingHours/WorkingHours";

const MedSpaSectionTwo = () => {
  return (
    <div>
      <Container>
        <Row className="align-items-center">
          <Col sm={6} className="order-1 order-sm-1">
            {/* <img
              // src="https://www.viomedspa.com/media/Perfection-is-Our-Obession.jpg"
              src={GirlImage}
              style={{ height: "80%", width: "100%",borderRadius:"20px" }}
              // className="section-image-quadra-shape"
              alt=''
            /> */}
            <WorkingHours/>
          </Col>

          <Col sm={6} className="order-2 order-sm-2" style={{textAlign:"left"}}>
          <h1 class="explore-treartment-tag" style={{textAlign:"left"}}>Industry Experts</h1>
            <h1 className="section-one-head-tag">
              Your Perfection is Our Obsession
            </h1>
            <p className="section-one-para-tag">
              Our team is meticulously trained to ensure a safe, comfortable,
              and luxurious medical spa experience for every one of our clients.
              With a curated beauty and wellness treatment plan tailored to your
              aesthetic goals, our team strives to help you achieve your vision
              of beauty. Learn more about our commitment to delivering
              life-changing results.
            </p>
            <Link to="/services">
            <button className="med-spa-custom-button">Learn More</button>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MedSpaSectionTwo;
