// import React from 'react';
// import { Container, Nav, Navbar } from 'react-bootstrap';
// import { Link, NavLink } from 'react-router-dom';
// import "bootstrap/dist/css/bootstrap.css";
// import BrandLogo from "../../Assets/cosmos-png.png";
// import "./MedSpaNavbar.css";

// const MedSpaNavbar = () => {
//   return (
//     <div className='fixed-navba-styles'>
//       <Navbar expand="lg" variant="light" className="main-web-nav">
//         <Container>
//           <Link to="/">
//             <Navbar.Brand className="me-auto custom-logo" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
//               <img src={BrandLogo} alt='' className='brand-logo-style' />
//             </Navbar.Brand>
//           </Link>
//           <Navbar.Toggle aria-controls="navbar-nav" className="custom-toggle" />
//           <Navbar.Collapse id="navbar-nav" style={{ justifyContent: "end", gap: "20px" }}>
//             <Nav className="ms-auto custom-nav-links">
//               <NavLink to="/" exact className='custom-nav-links-link-tag' activeClassName="active">
//                 Home
//               </NavLink>
//               <NavLink to="/about" className='custom-nav-links-link-tag' activeClassName="active">
//                 About
//               </NavLink>
//               <NavLink to="/services" className='custom-nav-links-link-tag' activeClassName="active">
//                 Services
//               </NavLink>
//               <NavLink to="/cosmos-products" className='custom-nav-links-link-tag' activeClassName="active">
//                 Products
//               </NavLink>
//               <NavLink to="/contactus" className='custom-nav-links-link-tag' activeClassName="active">
//                 Contact
//               </NavLink>
//               <NavLink to="/appointment" className='custom-nav-links-link-tag' activeClassName="active">
//                 Appointment
//               </NavLink>
//             </Nav>
//             <Link to="/signup">
//               <button className='med-spa-custom-button'>Signup Now</button>
//             </Link>
//             <Link to="/patient-login" >
//               <button className='med-spa-custom-button' style={{ backgroundColor: "#e5e7eb", color: "black" }}>
//                 Registered Patient Login
//               </button>
//             </Link>
//           </Navbar.Collapse>
//         </Container>
//       </Navbar>
//     </div>
//   );
// };

// export default MedSpaNavbar;


import React, { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.css";
import BrandLogo from "../../Assets/cosmos-png.png";
import "./MedSpaNavbar.css";

const MedSpaNavbar = () => {
  const [expanded, setExpanded] = useState(false);

  const handleNavLinkClick = () => {
    setExpanded(false); // Close the navbar
  };

  return (
    <div className='fixed-navba-styles'>
      <Navbar expand="lg" variant="light" className="main-web-nav" expanded={expanded}>
        <Container>
          <Link to="/">
            <Navbar.Brand className="me-auto custom-logo" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <img src={BrandLogo} alt='' className='brand-logo-style' />
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle 
            aria-controls="navbar-nav" 
            className="custom-toggle" 
            onClick={() => setExpanded(expanded ? false : true)} 
          />
          <Navbar.Collapse id="navbar-nav" style={{ justifyContent: "end", gap: "20px" }}>
            <Nav className="ms-auto custom-nav-links">
              <NavLink to="/" exact className='custom-nav-links-link-tag' activeClassName="active" onClick={handleNavLinkClick}>
                Home
              </NavLink>
              <NavLink to="/about" className='custom-nav-links-link-tag' activeClassName="active" onClick={handleNavLinkClick}>
                About
              </NavLink>
              <NavLink to="/services" className='custom-nav-links-link-tag' activeClassName="active" onClick={handleNavLinkClick}>
                Services
              </NavLink>
              <NavLink to="/cosmos-products" className='custom-nav-links-link-tag' activeClassName="active" onClick={handleNavLinkClick}>
                Products
              </NavLink>
              <NavLink to="/contactus" className='custom-nav-links-link-tag' activeClassName="active" onClick={handleNavLinkClick}>
                Contact
              </NavLink>
              <NavLink to="/appointment" className='custom-nav-links-link-tag' activeClassName="active" onClick={handleNavLinkClick}>
                Appointment
              </NavLink>
            </Nav>
            <Link to="/signup" onClick={handleNavLinkClick}>
              <button className='med-spa-custom-button'>Signup Now</button>
            </Link>
            <Link to="/patient-login" onClick={handleNavLinkClick}>
              <button className='med-spa-custom-button' style={{ backgroundColor: "#e5e7eb", color: "black" }}>
                Registered Patient Login
              </button>
            </Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default MedSpaNavbar;
