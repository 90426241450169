import React, { useState } from "react";
import axios from "axios";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import EmailOtpVerification from "../OTPVerification/EmailOtpVerification";
import { Fade } from "react-reveal";
import OvalLoader from "../Loader/OvalLoader";
import swal from "sweetalert";
import CalendarViewMultiple from "../CalendarView/CalendarViewMultiple";
import Loader from "../Loader/Loader";
import { Link } from "react-router-dom";

const PatientLogin = ({ onLoadingChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [correctEmail, setcorrectEmail] = useState(false);
  const [patientData, setPatientData] = useState();
  const [successfulLogin, setsuccessfulLogin] = useState(false);
  console.log("patientData", patientData);
  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
    email: "",
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;

    // Validate email as the user types
    const newFormErrors = { ...formErrors };
    newFormErrors.email = !newEmail
      ? "Email is required"
      : !validateEmail(newEmail)
      ? "Invalid email format"
      : "";

    setEmail(newEmail);
    setcorrectEmail(newFormErrors.email === "");
    setFormErrors(newFormErrors);
  };

  const [VerifiedOtp, setVerifiedOtp] = useState(false);
  const [OvalLoading, setOvalLoading] = useState(false);
  const [VerifiedOtpMessage, setVerifiedOtpMessage] = useState("");
  const [ErrorFromOtpVerification, setErrorFromOtpVerification] = useState("");

  const [showOtpModal, setShowOtpModal] = useState(false);
  const openOtpModal = () => {
    sendEmailOTP();
  };

  const handleOtpVerification = (inputOtp) => {
    ApiServices.post("/api/verify_otp/", {
      email: email,
      otp: inputOtp,
    })
      .then((response) => {
        const isOtpVerified = response.data.otp_verification;
        if (isOtpVerified) {
          setShowOtpModal(false);
          setVerifiedOtp(true);
          setErrorFromOtpVerification("OTP Verified Successfullly...!");
          setVerifiedOtpMessage("");
          setOtp(inputOtp);
        } else {
        }
      })
      .catch((error) => {
        console.error("Failed to verify OTP:", error);
        setErrorFromOtpVerification("OTP is incorrect or has expired.");
      });
  };

  const sendEmailOTP = () => {
    setOvalLoading(true);
    ApiServices.post("/api/send_otp/", { email: email })
      .then((response) => {
        setShowOtpModal(true);
        setOvalLoading(false);
      })
      .catch((error) => {
        console.error("Failed to send OTP:", error);
        setOvalLoading(false);
      });
  };

  const validateForm = () => {
    let isValid = true;
    const newFormErrors = { ...formErrors };

    // Validate username
    if (!username) {
      newFormErrors.username = "Username is required";
      isValid = false;
    } else {
      newFormErrors.username = "";
    }

    // Validate password
    if (!password) {
      newFormErrors.password = "Password is required";
      isValid = false;
    } else {
      newFormErrors.password = "";
    }

    // Validate email
    if (!email) {
      newFormErrors.email = "Email is required";
      isValid = false;
    } else if (!validateEmail(email)) {
      newFormErrors.email = "Invalid email format";
      isValid = false;
    } else {
      newFormErrors.email = "";
    }

    setFormErrors(newFormErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    onLoadingChange(true);
    const authPayload = {
      grant_type: "password",
      client_id: "G7H1yJbmR_JzoZV3Xw0wV8LO58pL2hb-YV6KFv-hl94",
      scope: "openid offline_access api:oemr api:port patient/patient.read",
      user_role: "patient",
      username: username,
      password: password,
      email: email,
      otp: otp,
    };

    const authHeaders = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    try {
      const authResponse = await axios.post(
        "https://medispacosmos.com/riteclinic-openemr/oauth2/default/token",
        new URLSearchParams(authPayload).toString(),
        { headers: authHeaders }
      );

      const accessToken = authResponse.data.access_token;

      const apiHeaders = {
        Authorization: `Bearer ${accessToken}`,
      };

      const apiResponse = await axios.get(
        "https://medispacosmos.com/riteclinic-openemr/apis/default/portal/patient",
        { headers: apiHeaders }
      );
      swal({
        title: "Login Successful",
        text: "You have been Successfully Login.",
        icon: "success",
        button: "OK",
        timer: 2000,
      });
      sessionStorage.setItem("Patient_Pid", apiResponse.data["data"].id);
      setPatientData(apiResponse.data["data"]);
      setsuccessfulLogin(true);
      onLoadingChange(false);
    } catch (error) {
      console.error("Error:", error.message);
      onLoadingChange(false);
      swal({
        title: "Login Failed",
        text: "An error occurred during Login.",
        icon: "error",
        button: "OK",
        timer: 3000,
      });
    }
  };

  return (
    <div>
      {successfulLogin ? (
        <CalendarViewMultiple />
      ) : (
        <div>
          <h1 className="wel-come-staff-info" style={{ fontSize: "2.2rem" }}>
            Login here
          </h1>
          <form onSubmit={handleSubmit}>
            <input
              placeholder="Username"
              className="appt-as-guest-input-field"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <div className="error-message">{formErrors.username}</div>
            <br />

            <input
              placeholder="Password"
              className="appt-as-guest-input-field"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="error-message">{formErrors.password}</div>
            <br />

            <input
              placeholder="Email"
              className="appt-as-guest-input-field"
              type="email"
              value={email}
              disabled={VerifiedOtp}
              onChange={handleEmailChange}
            />
            {correctEmail && (
              <div>
                {VerifiedOtp ? (
                  <p
                    className="clear-filters-text"
                    style={{
                      marginTop: "-10px",
                      fontSize: "13px",
                      cursor: "auto",
                      color: "green",
                    }}
                  >
                    Email Verified Successfully
                  </p>
                ) : (
                  <div>
                    {OvalLoading ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "-10px",
                          fontSize: "13px",
                          gap: "5px",
                          cursor: "auto",
                        }}
                      >
                        <p
                          className="clear-filters-text"
                          style={{ cursor: "auto" }}
                        >
                          Processing
                        </p>
                        <OvalLoader />
                      </div>
                    ) : (
                      <Fade bottom collapse>
                        <p
                          className="clear-filters-text"
                          onClick={openOtpModal}
                          style={{
                            marginTop: "-7px",
                            marginBottom: "3px",
                            fontSize: "16px",
                            color: "red",
                            textAlign: "left",
                          }}
                        >
                          Verify Email Address
                        </p>
                      </Fade>
                    )}
                  </div>
                )}
              </div>
            )}
            {formErrors.email && (
              <div className="error-message">{formErrors.email}</div>
            )}
            <br />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button type="submit" className="med-spa-custom-button">
                Login
              </button>
              <Link
                to={`${process.env.REACT_APP_EMR_URL}/riteclinic-openemr/portal/`}
                target="_blank"
              >
                <button class="med-spa-custom-button">Click here to Access PatientPortal</button>
              </Link>
            </div>
          </form>

          <EmailOtpVerification
            show={showOtpModal}
            onClose={() => setShowOtpModal(false)}
            onVerify={handleOtpVerification}
            error={ErrorFromOtpVerification}
            email={email}
          />
        </div>
      )}
      {/* {isLoading && <Loader fname={email}/>} */}
    </div>
  );
};

export default PatientLogin;
