import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";

import AccessToken from "../../GlobalFiles/ApiServices/AccessToken";
import PatientAccessToken from "../../GlobalFiles/ApiServices/PatientAccessToken";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const PatientBillsList = () => {
  const [AppointmentData, setAppointmentData] = useState([]);
  const navigate = useNavigate();
  const accessToken = sessionStorage.getItem("accessToken");
  const LoginUserType = sessionStorage.getItem("LoginUserType");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const PatientData = await PatientAccessToken.fetchData();
        let endpoint = `/apis/default/api/patient/${PatientData.pid}/appointment`;

        if (LoginUserType == "Patient") {
          endpoint = `/apis/default/portal/patient/${PatientData.pid}/appointment`;
        }
        const appointmentDataResponse = await ApiServices.get(
          endpoint,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );
        const filteredAppointmentData = appointmentDataResponse.data.filter(
          (item) => item.verified_receptionist === 1
        );

        setAppointmentData(filteredAppointmentData);
        // setAppointmentData(appointmentDataResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleShowBillClick = (params) => {
    const patientUuid = params.puuid;
    const patienteid = params.pc_eid;
    const patientepid = params.pc_pid;
    navigate(
      `/patient-dashboard/show-bill/${patientUuid}/${patienteid}/${patientepid}`
    );
  };

  const columnDefs = [
    {
      headerName: "Appointment Date",
      field: "pc_eventDate",
      headerClass: "custom-header-of-tables",
      flex: 1,
      minWidth: 150,
      // maxWidth: "220",
    },
    {
      headerName: "Appointment Time",
      field: "pc_startTime",
      headerClass: "custom-header-of-tables",
      // maxWidth: "240",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Show Bill",
      field: "show_bill",
      headerClass: "custom-header-of-tables",
      // maxWidth: "240",
      flex: 1,
      minWidth: 150,
      cellRenderer: (params) => {
        const { data } = params;
        return (
          <Button
            variant="info"
            size="sm"
            onClick={() => handleShowBillClick(data)}
          >
            Show Bill
          </Button>
        );
      },
    },
    {
      headerName: "Bill Status",
      field: "bill_status",
      headerClass: "custom-header-of-tables",
      flex: 1,
      minWidth: 150,
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  return (
    <div>
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <input
          type="text"
          placeholder="All Appointments..."
          className="form-control mb-2"
        />
      </div>
      <div
        className="ag-theme-alpine"
        style={{ width: "100%", overflow: "hidden", height: "430px" }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={AppointmentData}
          defaultColDef={defaultColDef}
        />
      </div>
    </div>
  );
};

export default PatientBillsList;
