import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useNavigate } from "react-router-dom";
import AppointmentPatientList from "../../GlobalFiles/ApiServices/AppointmentPatientList";

const ProviderCalendarAppointments = ({selectedDate}) => {

  const [patientList, setPatientList] = useState([]);
  const accessToken = sessionStorage.getItem("accessToken");
  const fetchPatientList = async () => {
    try {
      const patients = await AppointmentPatientList.fetchData();
      setPatientList(patients);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchPatientList();
  }, []);
  const [searchInput, setSearchInput] = useState("");
  const [filteredPatientList, setFilteredPatientList] = useState([]);
  const getName = (params) => {
    const patient = params.data;
    return `${patient.patient_fname} ${patient.mname ? patient.mname : ""} ${
      patient.patient_lname
    }`;
  };

  useEffect(() => {
    setFilteredPatientList(filterPatientsByDate(patientList));
  }, [patientList,selectedDate]);

  const filterPatientsByDate = (patients) => {
    // const currentDate = selectedDate.toISOString().split("T")[0];
    const year = selectedDate.getFullYear();
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = selectedDate.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    console.log("formattedDate",formattedDate)
    return patients.filter((patient) => patient.pc_eventDate == formattedDate);
  };

  const navigate = useNavigate();
  const handleCellClick = (params) => {
    if (params.colDef.field === "name") {
      const patientUuid = params.data.patient_uuid;
      navigate(`/admin/patient-details/${patientUuid}/`);
    }
  };

  const handleSearchInputChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchInput(inputValue);

    const filteredPatients = filterPatientsByDate(patientList).filter(
      (patient) =>
        patient.fname?.toLowerCase().includes(inputValue) ||
        patient.mname?.toLowerCase().includes(inputValue) ||
        patient.lname?.toLowerCase().includes(inputValue)
    );

    setFilteredPatientList(filteredPatients);
  };

  const handleShowBillClick = (params) => {
    console.log("params of Show Bill",params)
    const patientUuid = params.patient_uuid;
    const patienteid = params.pc_eid;
    const patientepid = params.pc_pid;
    navigate(`/admin/show-bill/${patientUuid}/${patienteid}/${patientepid}`);
  };

  const handleGenerateBillClick = (params) => {
    console.log("params",params)
    const patientUuid = params.patient_uuid;
    const patienteid = params.pc_eid;
    const patientepid = params.pc_pid;
      navigate(`/admin/generate-bill/${patientUuid}/${patienteid}/${patientepid}`);
  };

  const columnDefs = [
    {
      headerName: "Name",
      field: "name",
      valueGetter: getName,
      headerClass: "custom-header-of-tables",
      onCellClicked: handleCellClick,
      minWidth: 250,
      flex: 1,
    },

    {
      headerName: "Email",
      field: "patient_email",
      headerClass: "custom-header-of-tables",
      minWidth: 240,
      flex: 1,
    },
    {
      headerName: "Appointment Time",
      field: "pc_startTime",
      headerClass: "custom-header-of-tables",
      minWidth: 140,
      flex: 1,
    },
    {
      headerName: "Client Type",
      field: "client_type",
      headerClass: "custom-header-of-tables",
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: "Appointment Status",
      field: "pc_apptstatus",
      headerClass: "custom-header-of-tables",
      minWidth: 140,
      flex: 1,
      sortable: false,
      filter: false,
      cellRenderer: (params) => {
        const status = params.data.pc_apptstatus.trim();
        let buttonColor = "";
        let buttonText = "";
        let textCololor = "";

        switch (status) {
          case "-":
            buttonColor = "yellow";
            buttonText = "Pending";
            textCololor = "black";
            break;
          case "^":
            buttonColor = "yellow";
            buttonText = "Pending";
            textCololor = "black";
            break;
          case "APPR":
            buttonColor = "green";
            buttonText = "Approved";
            textCololor = "white"; 
            break;
          case "REJ":
            buttonColor = "red";
            buttonText = "Rejected";
            textCololor = "white";
            break;
          default:
            buttonColor = "gray";
            buttonText = "Unknown";
            textCololor = "white"; 
        }

        return (
          <div
            style={{
              backgroundColor: buttonColor,
              color: textCololor,
              textAlign: "center",
            }}
            // onClick={() => handleButtonClick(params.data)}
          >
            {buttonText}
          </div>
        );
      },
    },
    {
      headerName: "Bill",
      field: "generatebill",
      headerClass: "custom-header-of-tables",
      minWidth: 140,
      flex: 1,
      sortable: false,
      filter: false,
      cellRenderer: (params) => {
        const { data } = params;
        console.log("show bill params",params.data)
        return (
          <div>
            {params.data.generated_bill_provider != 1 ?
          <Button
            variant="info"
            size="sm"
            onClick={() => handleGenerateBillClick(data)}
          >
            Generate Bill
          </Button>
          : 
          <Button
            variant="info"
            size="sm"
            onClick={() => handleShowBillClick(data)}
          >
            Show Bill
          </Button>
          }
          </div>
        );
      },
    },
   
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  return (
    <div style={{ backgroundColor: "#F1F5F8" }}>
      <Row>
        <Col sm={12}>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <input
              type="text"
              placeholder="Search Patient by Name..."
              value={searchInput}
              onChange={handleSearchInputChange}
              className="form-control mb-2"
            />
          </div>
          <div
            className="ag-theme-alpine"
            style={{
              height: "50vh",
              width: "100%",
              // overflow: "hidden"
            }}
          >
            <AgGridReact
              columnDefs={columnDefs}
              //   rowData={patientList}
              rowData={filteredPatientList}
              defaultColDef={defaultColDef}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProviderCalendarAppointments;
