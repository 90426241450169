import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { Col, Row } from "react-bootstrap";
import RecurringAppointmentSlot from "./RecurringAppointmentSlot";

const RecurringAppointmentsCalendar = ({
  PatientToBookAppointment,
  handleModalClose,
  updateAppointmentData
}) => {
  const onSuccess = () => {
    console.log("onSuccess callback triggered");
    handleModalClose();
  };
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState();
  console.log("selectedDate",selectedDate)
  const currentDate = new Date();
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  
  const LoginUserType = sessionStorage.getItem("LoginUserType")
  // const isWeekend = (date) => {
  //   const day = date.getDay();
  //   return day === 0 || day === 1 || day === 2 ;
  // };

  const isWeekend = (date) => {
    const day = date.getDay();
    if (LoginUserType === "Patient") {
      return day === 0 || day === 1 || day === 2 ;
    } else {
      return false;
    }
  };

  const tileDisabled = ({ date }) => {
    return isWeekend(date);
  };

  useEffect(() => {
    // Find the next available date after the current date
    const findNextAvailableDate = () => {
      let nextDate = new Date();
      while (isWeekend(nextDate)) {
        nextDate.setDate(nextDate.getDate() + 1);
      }
      setSelectedDate(nextDate);
    };

    findNextAvailableDate();
  }, []);

  return (
    <div>
      <div className="appointment-calender-top-div">
        <h3
          className="section-one-head-tag"
          style={{
            fontSize: "22px",
            margin: "0px",
            paddingTop: "10px",
            paddingBottom: "10px",
            textAlign: "center",
            width: "100%",
          }}
        >
          Recurring Appointments Calendar
        </h3>
      </div>
      {PatientToBookAppointment ? (
        <Row>
          <Col sm={6}>
            <Calendar
              onChange={handleDateChange}
              value={selectedDate}
              minDate={currentDate}
              tileDisabled={tileDisabled}
            />
          </Col>
          <Col sm={6}>
            <RecurringAppointmentSlot
              selectedDate={selectedDate}
              PatientToBookAppointment={PatientToBookAppointment}
              onSuccess={onSuccess}
              updateAppointmentData={updateAppointmentData}
            />
          </Col>
        </Row>
      ) : (
        <div>
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            minDate={currentDate}
            tileDisabled={tileDisabled}
          />
          <RecurringAppointmentSlot
            selectedDate={selectedDate}
            PatientToBookAppointment={PatientToBookAppointment}
            updateAppointmentData={updateAppointmentData}
            // onSuccess={onSuccess}
          />
        </div>
      )}
    </div>
  );
};

export default RecurringAppointmentsCalendar;
