import React, { useState } from 'react';
import { Button, Modal, Box, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const PatientGroup = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [newGroupName, setNewGroupName] = useState('');

  const patientList = [
    { id: 1, name: 'John Doe' },
    { id: 2, name: 'Jane Doe' },
    // Add more patients as needed
  ];

  const groupList = ['Group 1', 'Group 2', 'Group 3'];

  const handleAddGroupClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  const handleCreateGroup = () => {
    // Implement logic to create a new group (add to your data or API)
    console.log('Creating new group:', newGroupName);
    setModalOpen(false);
  };

  return (
    <div>
      <h2>Patient List</h2>
      <ul>
        {patientList.map((patient) => (
          <li key={patient.id}>{patient.name}</li>
        ))}
      </ul>
      <Button variant="contained" onClick={handleAddGroupClick}>
        Add Group
      </Button>

      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Box sx={{ width: 300, p: 2, bgcolor: 'background.paper' }}>
          <FormControl fullWidth>
            <InputLabel id="group-select-label">Select Group</InputLabel>
            <Select
              labelId="group-select-label"
              id="group-select"
              value={selectedGroup}
              onChange={handleGroupChange}
              label="Select Group"
            >
              {groupList.map((group) => (
                <MenuItem key={group} value={group}>
                  {group}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="New Group Name"
            fullWidth
            margin="normal"
            onChange={(e) => setNewGroupName(e.target.value)}
          />

          <Button variant="contained" onClick={handleCreateGroup}>
            Create New Group
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default PatientGroup;
