/*import $ from 'jquery';
import ReactDOM from 'react-dom';
import 'jquery-ui-sortable';
import React, { Component, createRef } from 'react';
import axios from 'axios';

window.jQuery = $;
require('formBuilder');
require('../../../node_modules/formBuilder/dist/form-render.min.js')

class FormBuilderJs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showExistingForms: true,
      existingForms: [], // Store existing forms fetched from API
      formBuilderDisplay: false, // Track whether to display the form builder
    };
  }

  fb = createRef();

  componentDidMount() {
    this.initializeFormBuilder();
  }

  initializeFormBuilder = () => {
    // Initialize form builder without displaying it initially
    const formBuilder = $(ReactDOM.findDOMNode(this.fb.current)).formBuilder({ display: false });
    this.setState({ formBuilder });

    // Set up event listeners
    document.getElementById('previewButton').addEventListener('click', this.handlePreview);
    document.getElementById('createNewFormButton').addEventListener('click', this.showFormBuilder);
    document.getElementById('showExistingFormsButton').addEventListener('click', this.showExistingForms);

    // Fetch existing forms initially
    this.fetchExistingForms();
  };


  fetchExistingForms = async () => {
    try {
      const authResponse = await axios.post(
        "http://13.40.141.190/digihealthemr/oauth2/default/token",
        {
          client_id: "dmfdSjsrYN4dV-GAeEFL2vE8T0FOwNHFY3Yvl0E55f4",
          grant_type: "password",
          scope:
            "openid offline_access api:oemr api:fhir api:port user/allergy.read user/allergy.write user/appointment.read user/appointment.write user/dental_issue.read user/dental_issue.write user/document.read user/document.write user/drug.read user/encounter.read user/encounter.write user/facility.read user/facility.write user/immunization.read user/insurance.read user/insurance.write user/insurance_company.read user/insurance_company.write user/insurance_type.read user/list.read user/medical_problem.read user/medical_problem.write user/medication.read user/medication.write user/message.write user/patient.read user/patient.write user/practitioner.read user/practitioner.write user/prescription.read user/procedure.read user/soap_note.read user/soap_note.write user/surgery.read user/surgery.write user/transaction.read user/transaction.write user/vital.read user/vital.write user/AllergyIntolerance.read user/CareTeam.read user/Condition.read user/Coverage.read user/Encounter.read user/Immunization.read user/Location.read user/Medication.read user/MedicationRequest.read user/Observation.read user/Organization.read user/Organization.write user/Patient.read user/Patient.write user/Practitioner.read user/Practitioner.write user/PractitionerRole.read user/Procedure.read user/formbuilder.write user/formNames.read",
          user_role: "users",
          username: "admin@admin.com",
          password: "admin@admin.com",
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const { access_token } = authResponse.data;
      sessionStorage.setItem("access_token", access_token);
      const response = await axios.get(
        "http://13.40.141.190/digihealthemr/apis/default/api/formNames",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      this.setState({ existingForms: response.data[0] });
    } catch (error) {
      console.error('Error fetching existing forms:', error.message);
    }
  };

  showExistingForms = () => {
    // Fetch existing forms from API when the button is clicked
    this.fetchExistingForms();
    this.setState({ showExistingForms: true, formBuilderDisplay: false });
  };
  showFormBuilder = () => {
    // Display the form builder when the "Create New Form" button is clicked
    this.setState({ showExistingForms: false, formBuilderDisplay: true });
  };
  
  renderExistingForms = () => {
    const { existingForms } = this.state;

    return (
      <div>
        <select>
        {existingForms.map((form, index) => (
          <option key={form.form_id} value={form.form_id}>
            {form.form_name}
          </option>
        ))}
        </select>
      </div>
    );
  };

  render() {
    const { showExistingForms, formBuilderDisplay } = this.state;
    return (
      <div style={{ backgroundColor: 'white', padding: '10px' }}>
        <h2>Create your own form</h2>
        <div className="form-buttons">
          <button id="previewButton" onClick={this.handlePreview}>
            Preview Form
          </button>
          <button id="submit">Save</button>
          <button onClick={this.handleClose}>Close</button>

          <button id="showExistingFormsButton" onClick={this.showExistingForms}>
            Show Existing Forms
          </button>
          {showExistingForms && (
            <button id="createNewFormButton" onClick={this.showFormBuilder}>
              Create New Form
            </button>
          )}
        </div>
        <div>
          {formBuilderDisplay ? (
            <div id="fb-editor" ref={this.fb} />
          ) : (
            <div id="fb-editor" ref={this.fb} style={{ display: 'none' }} />
          )}
          <div id="fb-render"></div>
          {showExistingForms && this.renderExistingForms()}
        </div>
      </div>
    );
  }
}

export default FormBuilderJs;*/

import $ from 'jquery';
import ReactDOM from 'react-dom';
import 'jquery-ui-sortable';
import React, { Component, createRef, useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, TextField} from '@mui/material';

window.jQuery = $;
require('formBuilder');
require('../../../node_modules/formBuilder/dist/form-render.min.js');

const FormBuilderJs = () => {
  const [isExistingFormsVisible, setIsExistingFormsVisible] = useState(true);
  const [existingForms, setExistingForms] = useState([]);
  const [formBuilderDisplay, setFormBuilderDisplay] = useState(false);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [sendFormDialogOpen, setSendFormDialogOpen] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [formName, setFormName] = useState("");
  const [rewardPoints, setRewardPoints] = useState(0);
  const [formFrequency, setFormFrequency] = useState(0);
  const [deadline, setDeadline] = useState(0);
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [formBuilderJson, setFormBuilderJson] = useState("");

  const fb = createRef();

  useEffect(() => {
    initializeFormBuilder();
    fetchExistingForms();
    fetchPatientList();
  }, []);

  const initializeFormBuilder = () => {
    const formBuilder = $(ReactDOM.findDOMNode(fb.current)).formBuilder();
    setFormBuilderDisplay({ formBuilder });
    //formBuilderInstance = $(ReactDOM.findDOMNode(fb.current)).formBuilder({ display: false });
    //setFormBuilderDisplay({ formBuilder: formBuilderInstance });

    document.getElementById('previewButton').addEventListener('click', handlePreview);
    document.getElementById('createNewFormButton').addEventListener('click', showFormBuilder);
    document.getElementById('showExistingFormsButton').addEventListener('click', showExistingForms);
    document.getElementById('submit').addEventListener('click', () => handleSave(formBuilder));
  };

  const handleSave = (formBuilder) => {
    //alert(JSON.stringify(formBuilder.formData));
    //const jsonData = formBuilder.actions.getData('json', true);
    //console.log('JSON Data:', jsonData);
    if (formBuilder && formBuilder.formData) {
      //alert(JSON.stringify(formBuilder.formData));
      setSaveButtonClicked(true);
      setFormBuilderJson(formBuilder.formData)
    } else {
      console.error('formBuilder or formBuilder.formData is not defined.');
    }
  };

  const fetchPatientList = async () => {
      try {
        const authResponse = await axios.post(
          "http://13.40.141.190/digihealthemr/oauth2/default/token",
          {
            client_id: "dmfdSjsrYN4dV-GAeEFL2vE8T0FOwNHFY3Yvl0E55f4",
            grant_type: "password",
            scope:
              "openid offline_access api:oemr api:fhir api:port user/allergy.read user/allergy.write user/appointment.read user/appointment.write user/dental_issue.read user/dental_issue.write user/document.read user/document.write user/drug.read user/encounter.read user/encounter.write user/facility.read user/facility.write user/immunization.read user/insurance.read user/insurance.write user/insurance_company.read user/insurance_company.write user/insurance_type.read user/list.read user/medical_problem.read user/medical_problem.write user/medication.read user/medication.write user/message.write user/patient.read user/patient.write user/practitioner.read user/practitioner.write user/prescription.read user/procedure.read user/soap_note.read user/soap_note.write user/surgery.read user/surgery.write user/transaction.read user/transaction.write user/vital.read user/vital.write user/AllergyIntolerance.read user/CareTeam.read user/Condition.read user/Coverage.read user/Encounter.read user/Immunization.read user/Location.read user/Medication.read user/MedicationRequest.read user/Observation.read user/Organization.read user/Organization.write user/Patient.read user/Patient.write user/Practitioner.read user/Practitioner.write user/PractitionerRole.read user/Procedure.read user/formbuilder.write user/formNames.read",
            user_role: "users",
            username: "admin@admin.com",
            password: "admin@admin.com",
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
  
        const { access_token } = authResponse.data;
        sessionStorage.setItem("access_token", access_token);
        const accessToken = sessionStorage.getItem("access_token");
        const patientResponse = await axios.get(
            "http://13.40.141.190/digihealthemr/apis/default/api/patient",
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          );
      setPatientList(patientResponse.data.data); 
      console.log(patientResponse.data.data);
    } catch (error) {
      console.error('Error fetching patient list:', error.message);
    }
  };

  const fetchExistingForms = async () => {
    try {
      const authResponse = await axios.post(
        "http://13.40.141.190/digihealthemr/oauth2/default/token",
        {
          client_id: "dmfdSjsrYN4dV-GAeEFL2vE8T0FOwNHFY3Yvl0E55f4",
          grant_type: "password",
          scope:
            "openid offline_access api:oemr api:fhir api:port user/allergy.read user/allergy.write user/appointment.read user/appointment.write user/dental_issue.read user/dental_issue.write user/document.read user/document.write user/drug.read user/encounter.read user/encounter.write user/facility.read user/facility.write user/immunization.read user/insurance.read user/insurance.write user/insurance_company.read user/insurance_company.write user/insurance_type.read user/list.read user/medical_problem.read user/medical_problem.write user/medication.read user/medication.write user/message.write user/patient.read user/patient.write user/practitioner.read user/practitioner.write user/prescription.read user/procedure.read user/soap_note.read user/soap_note.write user/surgery.read user/surgery.write user/transaction.read user/transaction.write user/vital.read user/vital.write user/AllergyIntolerance.read user/CareTeam.read user/Condition.read user/Coverage.read user/Encounter.read user/Immunization.read user/Location.read user/Medication.read user/MedicationRequest.read user/Observation.read user/Organization.read user/Organization.write user/Patient.read user/Patient.write user/Practitioner.read user/Practitioner.write user/PractitionerRole.read user/Procedure.read user/formbuilder.write user/formNames.read",
          user_role: "users",
          username: "admin@admin.com",
          password: "admin@admin.com",
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const { access_token } = authResponse.data;
      sessionStorage.setItem("access_token", access_token);
      
      const response = await axios.get(
        "http://13.40.141.190/digihealthemr/apis/default/api/formNames",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      setExistingForms(response.data);
    } catch (error) {
      console.error('Error fetching existing forms:', error.message);
    }
  };
  const handlePreview = () => {
    // Implement the logic for handling preview
    console.log("Preview clicked");
  };
  
  const handleClose = () => {
    setSaveButtonClicked(false);
  };
  const showExistingForms = () => {
    fetchExistingForms();
    setIsExistingFormsVisible(true);
    setFormBuilderDisplay(false);
  };

  const showFormBuilder = () => {
    setIsExistingFormsVisible(false);
    setFormBuilderDisplay(true);
  };

  const handleSendFormClick = () => {
    setSendFormDialogOpen(true);
  };

  const handleSendFormClose = () => {
    setSendFormDialogOpen(false);
  };

  const handleSendForm = () => {
    // Implement logic to send selected forms to selected patients
    handleSendFormClose();
  };

  const handlePatientCheckboxChange = (patientId) => {
    const isSelected = selectedPatients.includes(patientId);

    if (isSelected) {
      setSelectedPatients((prevSelected) => prevSelected.filter((id) => id !== patientId));
    } else {
      setSelectedPatients((prevSelected) => [...prevSelected, patientId]);
    }
  };

  const handleFormSave = () => {
    console.log('Form Builder Data:', formBuilderJson);
    console.log('Form Name:', formName);
    console.log('Reward Points:', rewardPoints);
    console.log('Form Frequency:', formFrequency);
    console.log('Deadline:', deadline);
    setSaveButtonClicked(false);
  }

  const renderExistingForms = () => {
    return (
      <div>
        <select>
          {existingForms.map((form, index) => (
            <option key={form.form_id} value={form.form_id}>
              {form.form_name}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const renderFormDialog = () => (
    <Dialog open={saveButtonClicked && formBuilderDisplay} onClose={handleClose}>
      <DialogTitle>Form Details</DialogTitle>
      <DialogContent>
        <TextField
          label="Form Name"
          value={formName}
          onChange={(e) => setFormName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Form Reward Points"
          type="number"
          value={rewardPoints}
          onChange={(e) => setRewardPoints(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Form Frequency"
          type="number"
          value={formFrequency}
          onChange={(e) => setFormFrequency(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Deadline for Form Filling"
          type="number"
          value={deadline}
          onChange={(e) => setDeadline(e.target.value)}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleFormSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );

  const renderPatientList = () => {
    return (
      <Dialog open={sendFormDialogOpen} onClose={handleSendFormClose}>
        <DialogTitle>Select Patients</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {patientList.map((patient) => (
                  <TableRow key={patient.id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedPatients.includes(patient.id)}
                        onChange={() => handlePatientCheckboxChange(patient.id)}
                      />
                    </TableCell>
                    <TableCell>{`${patient.fname} ${patient.mname} ${patient.lname}`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendFormClose}>Cancel</Button>
          <Button onClick={handleSendForm} disabled={selectedPatients.length === 0}>
            Send Forms
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  
  return (
    <div style={{ backgroundColor: 'white', padding: '10px' }}>
      <h2>Create your own form</h2>
      <div className="form-buttons">
        <button id="previewButton" onClick={handlePreview}>
          Preview Form
        </button>
        <button id="submit" onClick={handleSave}>
          Save
        </button>
        <button onClick={handleClose}>Close</button>
        <button id="showExistingFormsButton" onClick={showExistingForms}>
          Show Existing Forms
        </button>
        {isExistingFormsVisible && (
          <button id="createNewFormButton" onClick={showFormBuilder}>
            Create New Form
          </button>
        )}
        <button id="sendFormButton" onClick={handleSendFormClick}>
          Send Form
        </button>
      </div>
      <div>
        {showFormBuilder && <div id="fb-editor" ref={fb} />}
        {isExistingFormsVisible && renderExistingForms()}
      </div>
      {renderPatientList()}
      {renderFormDialog()}
    </div>
  );
};

export default FormBuilderJs;

