import ApiServices from "./ApiServices";
import { AES, enc } from "crypto-js";

class AccessToken {
  fetchAccessToken = async () => {
    try {
      let username;
      let password;
      let scope;
      let UserRole;

      const userType = sessionStorage.getItem("LoginUserType");

      if (userType == "Patient") {
        const encryptedEmail = sessionStorage.getItem("encryptedEmail");
        const encryptedPassword = sessionStorage.getItem("encryptedPassword");
        username = AES.decrypt(encryptedEmail,process.env.REACT_APP_USER_ENC_DEC_KEY).toString(enc.Utf8);
        password = AES.decrypt(encryptedPassword,process.env.REACT_APP_USER_ENC_DEC_KEY).toString(enc.Utf8);
        UserRole = "patient";
        scope = process.env.REACT_APP_PATIENT_SCOPES;

      } else {
        username = process.env.REACT_APP_PROVIDER_USERNAME;
        password = process.env.REACT_APP_PROVIDER_PASSWORD;
        UserRole = "users";
        scope = process.env.REACT_APP_PROVIDER_SCOPES;
      }

      const authResponse = await ApiServices.post(
        "/oauth2/default/token",
        {
          client_id: process.env.REACT_APP_CLINTID_ACS_TKN,
          grant_type: "password",
          scope: scope,
          user_role: UserRole,
          username: username,
          password: password,
          email: userType === "Patient" ? username : username,
        },
        {
          "Content-Type": "application/x-www-form-urlencoded",
        }
      );

      return authResponse.data.access_token;
    } catch (error) {
      console.error("Error fetching access token:", error);
      throw error;
    }
  };
}
export default new AccessToken();
