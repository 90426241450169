import React from "react";
import "./Loader.css"; // Import your loader styles
import { Dna, FallingLines } from "react-loader-spinner";

const Loader = ({ fname }) => {
  return (
    <div className="loader-overlay">
      <div className="loader-content">
        {/* <Dna
          visible={true}
          height="200"
          width="180"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        /> */}
        <FallingLines
          color="#1c324a"
          width="200"
          visible={true}
          ariaLabel="falling-lines-loading"
        />
        <h1 className="section-one-head-tag" style={{fontSize:"2rem"}}>Hello { fname ? fname : "User" }...! <br/> Get ready to experience luxury. We're processing your request.</h1>
      </div>
    </div>
  );
};

export default Loader;
