import React, { useEffect, useState } from 'react'
import ApiServices from '../../GlobalFiles/ApiServices/ApiServices';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FiArrowRight } from "react-icons/fi";
import { Fade } from 'react-reveal';
const ArticlesCard = () => {
    const [articlesData, setarticlesData] = useState([]);
    useEffect(() => {
      ApiServices.get("/api/articles/").then((response) => {
        const filteredArticles = response.data.filter((article) => article.activestatus === true);
        setarticlesData(filteredArticles);
        // setarticlesData(response.data);
      });
    }, []);

  return (
    <Container style={{textAlign:"left"}}>

<div style={{paddingBottom:"30px"}}>
      <h1 class="explore-treartment-tag">Stay Informed with Our Latest Articles</h1>  
      <p class="explore-treartment-sub-title-tag">Wellness at Its Best. Our blog is your gateway to uncovering the secrets of holistic well-being and self-improvement.Our articles cover a range of topics, from skincare and beauty tips to health and wellness strategies</p>
      {/* <h1 class="explore-treartment-sub-title-head-tag">Explore Our Wellness Articles</h1> */}
      </div>
        <Row>
        {articlesData &&
            articlesData?.map((index) => (
              <Col md={4} style={{ paddingBottom: "15px" }}>
                <Link to={`/articles/${index.id}`} style={{ textDecoration: "none" }}>
                <Fade bottom>

                
                  <Card className="articles-card-body">
                    <Card.Img
                      variant="top"
                      src={index.image}
                      style={{ height: "270px" }}
                    />
                    <Card.Body>
                      <Card.Title style={{ borderBottom: "1px solid #1c324a" }}>
                        <Row className="d-flex align-items-center">
                          <Col sm={10}>
                            <h1 className="services-card-title">
                              {index.title}
                            </h1>
                          </Col>
                          <Col
                            sm={2}
                            className="w-100 d-flex justify-content-end"
                          >
                            <FiArrowRight />
                          </Col>
                        </Row>
                      </Card.Title>
                      <Card.Text className="serv-ces-card-text">
                        {/* {index.subtitle} */}
                        {index.subtitle.length > 100
    ? `${index.subtitle.substring(0, 400)}...` // Display only the first 100 characters
    : index.subtitle}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  </Fade>
                </Link>
              </Col>
            ))}
        </Row>
    </Container>
  )
}

export default ArticlesCard