import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Loader from '../Loader/Loader';

const SuccessfullPayments = () => {
    const [payments, setPayments] = useState([]);
   const[loading,setloading]=useState(false)
  useEffect(() => {
    const fetchData = async () => {
      setloading(true)
      try {
        const response = await axios.get('https://cosmosapi.medispacosmos.com/api/fetch-payments/');
        const successfulPayments = response.data.payments.filter(payment => payment.status === 'succeeded');
        setPayments(successfulPayments);
        setloading(false)
        // setPayments(response.data.payments);
      } catch (error) {
        console.error('Error fetching payments:', error);
        setloading(false)
      }
    };

    fetchData();
  }, []);
  const getPaymentMode = (payment) => {
    // Example: Extracting payment mode from metadata or other available fields
    if (payment.payment_method_types.includes('card')) {
      return 'Credit Card';
    } else if (payment.payment_method_types.includes('link')) {
      return 'Email Link';
    } else {
      return 'Unknown';
    }
  };
  const [searchTerm, setSearchTerm] = useState('');
  const filteredPayments = payments.filter(payment => {
    const { metadata, receipt_email } = payment;
    if (!metadata) return false; // Skip payments without metadata
    const { firstName, lastName } = metadata;
    const searchTermLowerCase = searchTerm.toLowerCase();
    return (firstName && firstName.toLowerCase().includes(searchTermLowerCase)) ||
           (lastName && lastName.toLowerCase().includes(searchTermLowerCase)) ||
           (receipt_email && receipt_email.toLowerCase().includes(searchTermLowerCase));
});


  return (
    <div>
    {loading && <Loader/>}
         <div>
      <h2>Successful Payments</h2>
      <input
                    type="text"
                    placeholder="Search by First Name, Last Name, or Email"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{width:"100%",marginBottom:"10px",padding:"5px"}}
                />
      <table className="billing-table">
        <thead>
          <tr>
            <th>Payment ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Payment Mode</th>
            {/* <th>Last 4 Digits</th> */}
            <th>Date and Time</th>
          </tr>
        </thead>
        <tbody>
          {filteredPayments&&filteredPayments.map((payment) => (
            <tr key={payment.id}>
              <td>{payment.id}</td>
              <td>{payment.metadata.firstName}</td>
              <td>{payment.metadata.lastName}</td>
              <td>{payment.receipt_email}</td>
              <td>$ {payment.amount / 100} </td>
              <td>{payment.status}</td>
              <td>{getPaymentMode(payment)}</td>
              {/* <td>
                {getPaymentMode(payment) === 'Credit Card'
                  ? payment.payment_method_options.card.last4
                  : 'N/A'}
              </td> */}
              <td>{new Date(payment.created * 1000).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  )
}

export default SuccessfullPayments