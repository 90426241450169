import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ServicesDetailsSectionOne from "../../Components/ServicesDetails/ServicesDetailsSectionOne";
import ServicesDetailsSectionTwo from "../../Components/ServicesDetails/ServicesDetailsSectionTwo";
import ServicesDetailsSectionThree from "../../Components/ServicesDetails/ServicesDetailsSectionThree";
import ServicesDetailsFaq from "../../Components/ServicesDetails/ServicesDetailsFaq";
import ServicesArticles from "../../Components/Articles/ServicesArticles/ServicesArticles";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { Fade } from "react-reveal";
import SubServicesCards from "../../Components/ServicesCards/SubServicesCards";

const MedSpaServiceDetailsPage = () => {
  const { id } = useParams();
  const [serviceDetailsData, setserviceDetailsData] = useState(null);

  useEffect(() => {
    const apiUrl = `/api/services/${id}/`;
    ApiServices.get(apiUrl).then((response) => {
      setserviceDetailsData(response.data);
    });
  }, [id]);

  return (
    <div>
      <div>
        {
          <Fade bottom>
            <ServicesDetailsSectionOne data={serviceDetailsData} />
          </Fade>
        }
      </div>
      <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
            <ServicesDetailsSectionTwo data={serviceDetailsData} />
          </Fade>
        }
      </div>
      <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
            <ServicesDetailsSectionThree data={serviceDetailsData} />
          </Fade>
        }
      </div>
      <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
            <SubServicesCards data={serviceDetailsData} />
          </Fade>
        }
      </div>
      <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
            <ServicesDetailsFaq data={serviceDetailsData} />
          </Fade>
        }
      </div>
      <div style={{ paddingTop: "80px", paddingBottom: "80px" }}>
        {
          <Fade bottom>
            <ServicesArticles />
          </Fade>
        }
      </div>
    </div>
  );
};

export default MedSpaServiceDetailsPage;
