import React, { useState, useEffect } from "react";
import axios from "axios";
import Calendar from "react-calendar";
import { format } from "date-fns";
import "./CalendarViewMultiple.css";
import { Button } from "react-bootstrap";
import AppointmentModal from "./AppointmentModal";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";

const CalendarViewMultiple = ({ fname }) => {
  const [providerAvailability, setProviderAvailability] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const [timeSlots, setTimeSlots] = useState({});
  const [apiResponse, setApiResponse] = useState({ booked_slots: [] });
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedProviderId, setSelectedProviderId] = useState("");
  const [availableDateSet, setAvailableDateSet] = useState(new Set());
  const [showModal, setShowModal] = useState(false);

  const newfname = fname;
  console.log("Booked Slots:", apiResponse.booked_slots);
  console.log("Time Slots:", timeSlots);
  console.log("selectedDate:", selectedDate);

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          //   "http://3.147.76.112/api/appointmentSlots/"
          `${process.env.REACT_APP_SERVERURL}/api/appointmentSlots/`
        );
        setApiResponse(response.data);

        const availabilityObject = {};
        const availableDates = new Set();

        Object.values(response.data).forEach((response) => {
          const providerId = response.pc_providerid;
          const startDate = new Date(response.pc_eventDate);
          const endDate = new Date(response.pc_endDate);

          let currentDate = startDate;

          while (currentDate <= endDate) {
            if ([4, 5, 6].includes(currentDate.getDay())) {
              const dateKey = format(currentDate, "yyyy-MM-dd");

              if (!availabilityObject[dateKey]) {
                availabilityObject[dateKey] = {};
              }

              availabilityObject[dateKey][providerId] = {
                available: currentDate >= startDate && currentDate <= endDate,
                startTime: response.pc_startTime,
                endTime: response.pc_endTime,
              };

              if (
                currentDate >= new Date() &&
                availabilityObject[dateKey][providerId].available
              ) {
                availableDates.add(dateKey);
              }
            }

            currentDate.setDate(currentDate.getDate() + 1);
          }
        });

        setProviderAvailability(availabilityObject);
        setAvailableDateSet(availableDates);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedDate && providerAvailability[selectedDate]) {
      const slots = Object.entries(providerAvailability[selectedDate]).reduce(
        (acc, [providerId, providerData]) => {
          if (providerData.available) {
            const startTime = new Date(`2000-01-01T${providerData.startTime}`);
            const endTime = new Date(`2000-01-01T${providerData.endTime}`);

            const bookedSlots = apiResponse.booked_slots.filter(
              (slot) =>
                slot.pc_providerid === providerId &&
                slot.pc_eventDate === selectedDate
            );

            // ...

            while (startTime < endTime) {
              console.log("Checking Time Slot:", startTime);
              const timeKey = startTime.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              });

              // Skip the time range between 12 PM and 1 PM
              if (
                !(startTime.getHours() === 12 && startTime.getMinutes() === 0)
              ) {
                if (!acc[timeKey]) {
                  acc[timeKey] = {};
                }

                console.log("Before Checking Booked Slots:", acc[timeKey][providerId]);
               // ... (previous code)

// ... (previous code)

acc[timeKey][providerId] = {
  slotAvailable: !bookedSlots.some((bookedSlot) => {
    const bookedSlotDate = bookedSlot.pc_eventDate;
    const bookedStartTime = new Date(`${bookedSlotDate}T${bookedSlot.pc_startTime}`);
    const bookedEndTime = new Date(`${bookedSlotDate}T${bookedSlot.pc_endTime}`);

    const isBooked =
      bookedSlotDate === selectedDate &&
      startTime.getTime() >= bookedStartTime.getTime() &&
      startTime.getTime() < bookedEndTime.getTime();

      console.log(
        "Checking Time Slot:", startTime,
        "Booked Slot:", bookedStartTime, bookedEndTime,
        "Is Booked:", isBooked,
        "Provider ID:", providerId,
        "Slot Date:", bookedSlotDate,
        "Selected Date:", selectedDate,
        "Start Time:", startTime.getTime(),
        "Booked Start Time:", bookedStartTime.getTime(),
        "Booked End Time:", bookedEndTime.getTime()
      );
  

    return isBooked;
  }),
};

// ... (rest of the code)


                console.log("After Checking Booked Slots:", acc[timeKey][providerId]);
              }

              // Increment the time by 60 minutes
              startTime.setMinutes(startTime.getMinutes() + 60);
            }
          }
          return acc;
        },
        {}
      );

      setTimeSlots(slots);
    } else {
      setTimeSlots({});
    }
  }, [selectedDate, providerAvailability, apiResponse]);

  useEffect(() => {
    console.log("Time Slots Updated:", timeSlots);
  }, [timeSlots]);
  

  const handleButtonClick = (time) => {
    const availableProviders = Object.keys(timeSlots[time]).filter(
      (provider) => timeSlots[time][provider].slotAvailable
    );

    if (availableProviders.length > 0) {
      const randomProvider =
        availableProviders[
          Math.floor(Math.random() * availableProviders.length)
        ];

      setSelectedTime(time);
      setSelectedProviderId(randomProvider);
      setShowModal(true);
    }
  };

  const tileClassName = ({ date }) => {
    const currentDate = format(date, "yyyy-MM-dd");
    const isAvailable = availableDateSet.has(currentDate);
    return isAvailable ? "available" : "unavailable";
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [appointmentCategory, setAppointmentCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    pc_catid: "18",
    pc_catname: "Consultation",
  });

  useEffect(() => {
    ApiServices.get(`/api/appointmentCategory/`)
      .then((response) => {
        setAppointmentCategory(response.data);
      })
      .catch((error) => {
        console.error("Error fetching appointment categories:", error);
      });
  }, []);

  const handleCategoryChange = (e) => {
    const selectedOption = appointmentCategory.find(
      (category) => category.pc_catid === e.target.value
    );

    setSelectedCategory({
      pc_catid: selectedOption ? selectedOption.pc_catid : "",
      pc_catname: selectedOption ? selectedOption.pc_catname : "",
    });
  };

  return (
    <div>
      <div>
        <label>Select Appointment Type:</label>
        <select
          className="register-patient-column-input"
          value={selectedCategory.pc_catid}
          onChange={handleCategoryChange}
        >
          <option value="">Select Category</option>
          {appointmentCategory.map((category) => (
            <option key={category.pc_catid} value={category.pc_catid}>
              {category.pc_catname}
            </option>
          ))}
        </select>
      </div>

      <div>
        <Calendar
          tileClassName={tileClassName}
          onChange={(date) => setSelectedDate(format(date, "yyyy-MM-dd"))}
        />
      </div>

      {Object.keys(timeSlots).length > 0 && (
        <div className="appoint-ment-slots-menu">
          {Object.keys(timeSlots).map((time) => (
            <Button
              className="select-time-slot-button"
              key={time}
              disabled={
                !Object.keys(timeSlots[time]).some(
                  (provider) => timeSlots[time][provider].slotAvailable
                )
              }
              onClick={() => handleButtonClick(time)}
            >
              {time}
            </Button>
          ))}
        </div>
      )}

      <AppointmentModal
        show={showModal}
        handleClose={handleCloseModal}
        selectedTime={selectedTime}
        selectedDate={selectedDate}
        selectedProviderId={selectedProviderId}
        newfname={newfname}
        selectedCategory={selectedCategory}
      />
    </div>
  );
};

export default CalendarViewMultiple;
