import React, { useState } from 'react';
import CryptoJS from 'crypto-js';

const EncryDecryption = () => {
  const [decryptedData, setDecryptedData] = useState('');

  const encryptedData = '006MPsl9WegSPvaBwWEqufBcSA1+6ux9IO4NXDnm3r5q4m+zsd1HfhiY7umgYgN8Aiy8jssHz88ctCjSqzawHXMvyZFdRhDevR2JsbFF/Fef7cyvAOit9NRrqZs79tIFrezjRQbnqa8Ugnm/d0RT2Z70u4Glc29X8cSzDTjIr8eTcnm3AdgU7wOOkuo5GcDsLcW0+y47/olOsXO+TFXok0gpgRarYXu4YwlB2VNZ0xGz1rngdAQA4X9YXXktrXmA8Do4p82FdwNAucXtp3DZDkMK2hIGUePQclvTMg7ZWHSE4WyyCrPQZVTMsEwABJrl/qRXnV4bYlvKD4JJUvAkUoQ6k/UyYnL/rscn+HxfOnQGuXyKy+Mg4ouGJiRm4Zxj8h4OjJz6z5crfvs6jvVTivtnBnSQd1uO9ceSGL1xw/npzfcr30IdOjPqiskqGRaIoIu98a8ldpTTlqSIO1UihXC+k7Eqx4iULOyoehzY/XwmJ2ZPWjfS';

  const secretKey = 'your-secret-key';
  
  const decryptData = (encryptedData, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  };

  const handleDecryption = () => {
    try {
      const decryptedText = decryptData(encryptedData, secretKey);
      setDecryptedData(decryptedText);
    } catch (error) {
      console.error('Error decrypting data:', error);
      setDecryptedData('Decryption failed');
    }
  };

  return (
    <div>
      <h1>Encryption/Decryption</h1>
      <button onClick={handleDecryption}>Decrypt Data</button>
      {decryptedData && (
        <div>
          <h2>Decrypted Data:</h2>
          <p>{decryptedData}</p>
        </div>
      )}
    </div>
  );
};

export default EncryDecryption;
