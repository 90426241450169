import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import AccessToken from "../../GlobalFiles/ApiServices/AccessToken";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import PaymentGateway from "../CardPayments/PaymentGateway";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import swal from "sweetalert";
import Loader from "../Loader/Loader";

const UpdateGenerateBill = ({ handleupdateBill, onChildSubmit,BillId }) => {
  const paramsData = useParams();
  const navigate = useNavigate();
  const currentDate = new Date().toISOString().split("T")[0];
  const [loading, setloading] = useState(false);
  const accessToken = sessionStorage.getItem("accessToken");
  const LoginUserID = sessionStorage.getItem("LoginUserID");
  const [doctorServices, setDoctorServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [discounts, setDiscounts] = useState({});
  const [quantities, setQuantities] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedTip, setSelectedTip] = useState(0);
  const [customTip, setCustomTip] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [tipAmount, setTipAmount] = useState(0);
  const [BillSuccess, setBillSuccess] = useState(false);
  const [allProducts, setallProducts] = useState([]);
  const [ShowProducts, setShowProducts] = useState(false);
  const handleShowProducts = () => {
    setShowProducts(!ShowProducts);
  };
  const [AppointmentData, setAppointmentData] = useState();
  const [addsoapnote, setaddsoapnote] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const AppointmentDataResponse = await ApiServices.get(
          `/apis/default/api/appointment/${paramsData.eid}`,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );

        const AppointmentData = AppointmentDataResponse.data["0"];
        setAppointmentData(AppointmentData);
        setaddsoapnote(AppointmentData.soap_note);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [paramsData]);

  useEffect(() => {
    // axios.get(`http://3.147.76.112/api/products/`).then((response) => {
    axios
      .get(`https://cosmosapi.medispacosmos.com/api/products/`)
      .then((response) => {
        setallProducts(response.data);
      });
  }, []);

  useEffect(() => {
    ApiServices.get(`/apis/default/api/allowedServiceDsicount/${LoginUserID}`, {
      Authorization: `Bearer ${accessToken}`,
    }).then((response) => {
      setDoctorServices(response.data);
    });
  }, []);

  const handleServiceSelection = (serviceId) => {
    // console.log("serviceId", serviceId);
    const selectedService = doctorServices.find(
      (doctorService) => doctorService.service_id === serviceId
    );

    if (
      selectedService &&
      !selectedServices.some((service) => service.id === serviceId)
    ) {
      setSelectedServices((prevSelectedServices) => [
        ...prevSelectedServices,
        {
          id: selectedService.service_id,
          title: selectedService.label_on_bill,
          default_price: selectedService.unit_cost,
          discount: selectedService.percent_off,
          serviceName: selectedService.service,
        },
      ]);

      // Set discount and quantity for the selected service
      setDiscounts((prevDiscounts) => ({ ...prevDiscounts, [serviceId]: 0 }));
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [serviceId]: 1,
      }));
    }
  };

  const [selectedProducts, setSelectedProducts] = useState([]);
  const handleProductSelection = (productId) => {
    const selectedProduct = allProducts.find(
      (product) => product.id === productId
    );

    if (selectedProduct) {
      setSelectedProducts((prevSelectedProducts) => [
        ...prevSelectedProducts,
        {
          id: selectedProduct.id,
          title: selectedProduct.title,
          default_price: selectedProduct.price,
          discount: 0,
          quantity: 1,
        },
      ]);
    }
  };

  const handleProductQuantityChange = (productId, newQuantity) => {
    const validatedQuantity = Math.max(1, newQuantity);
    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.map((product) =>
        product.id === productId
          ? { ...product, quantity: validatedQuantity }
          : product
      )
    );
  };

  // Modify the handleProductDiscountChange function
  const handleProductDiscountChange = (productId, newDiscount) => {
    const validatedDiscount = Math.min(Math.max(0, newDiscount), 100);
    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.map((product) =>
        product.id === productId
          ? { ...product, discount: validatedDiscount }
          : product
      )
    );
  };

  const handleProductRemoval = (productId) => {
    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.filter((product) => product.id !== productId)
    );
  };

  const [totalBillAmount, setTotalBillAmount] = useState(0);

  const calculateTotalProductBill = () => {
    return selectedProducts.reduce((total, product) => {
      const discountedPrice =
        product.default_price -
        (product.default_price * product.discount) / 100;
      const productTotalAmount = discountedPrice * product.quantity;
      return total + productTotalAmount;
    }, 0);
  };

  useEffect(() => {
    const calculatedTotalAmount = selectedServices.reduce((total, service) => {
      const discountedPrice =
        service.default_price -
        (service.default_price * discounts[service.id]) / 100;
      const serviceTotalAmount = discountedPrice * quantities[service.id];
      return total + serviceTotalAmount;
    }, 0);

    const totalProductBill = calculateTotalProductBill();
    const totalAmount = calculatedTotalAmount + totalProductBill;

    setTotalBillAmount(totalAmount);
  }, [
    selectedServices,
    discounts,
    quantities,
    handleProductDiscountChange,
    handleProductRemoval,
    handleProductQuantityChange,
  ]);

  const handleDiscountChange = (serviceId, newDiscount) => {
    const selectedService = selectedServices.find(
      (service) => service.id === serviceId
    );

    if (selectedService) {
      const validatedDiscount = Math.min(
        Math.max(0, newDiscount),
        selectedService.discount
      );
      setDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [serviceId]: validatedDiscount,
      }));
    }
  };

  const handleQuantityChange = (serviceId, newQuantity) => {
    const validatedQuantity = Math.max(1, newQuantity);
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [serviceId]: validatedQuantity,
    }));
  };
  const [serviceNotes, setServiceNotes] = useState({});

  const handleServiceNoteChange = (serviceId, note) => {
    setServiceNotes((prevServiceNotes) => ({
      ...prevServiceNotes,
      [serviceId]: note,
    }));
  };
  const handleServiceRemoval = (serviceId) => {
    // Uncheck the checkbox for the removed service
    const checkbox = document.getElementById(`checkbox_${serviceId}`);
    if (checkbox) {
      checkbox.checked = false;
    }

    setSelectedServices((prevSelectedServices) =>
      prevSelectedServices.filter((service) => service.id !== serviceId)
    );
    setDiscounts((prevDiscounts) => {
      const { [serviceId]: removedDiscount, ...restDiscounts } = prevDiscounts;
      return restDiscounts;
    });
    setQuantities((prevQuantities) => {
      const { [serviceId]: removedQuantity, ...restQuantities } =
        prevQuantities;
      return restQuantities;
    });
  };

  useEffect(() => {
    const calculatedTotalAmount = selectedServices.reduce((total, service) => {
      const discountedPrice =
        service.default_price -
        (service.default_price * discounts[service.id]) / 100;
      const serviceTotalAmount = discountedPrice * quantities[service.id];
      return total + serviceTotalAmount;
    }, 0);

    setTotalAmount(calculatedTotalAmount);
  }, [selectedServices, discounts, quantities]);

  useEffect(() => {
    const tipPercentage = selectedTip / 100;
    const tipAmount = tipPercentage * totalAmount;
    setTipAmount(tipAmount + customTip);

    const finalAmountWithTip = totalAmount + tipAmount + customTip;
    setFinalAmount(finalAmountWithTip);
  }, [totalAmount, selectedTip, customTip]);

  // const LoginUserID = sessionStorage.getItem("LoginUserID");

  const handleBillingSubmit = async () => {
    setloading(true);
    const currentDate = new Date().toISOString().split("T")[0];
    // console.log("postData",postData)
    const postData = {
      bill_id: "",
      pid: paramsData.pid,
      eid: paramsData.eid,
      date_of_billing: currentDate,
      services: selectedServices.map((service) => ({
        service_title: service.title,
        service_id: service.id,
        service_quantity: quantities[service.id],
        service_discount: discounts[service.id],
        service_default_price: service.default_price,
        service_note: serviceNotes[service.id],
        service_provider: LoginUserID,
        quantitytodeductfromPackage: "",
        quantityToPay: quantities[service.id],
      })),
      products: selectedProducts.map((product) => ({
        product_id: product.id,
        product_title: product.title,
        product_provider: LoginUserID,
        product_quantity: product.quantity,
        product_discount: product.discount,
        product_default_price: product.default_price,
        quantitytodeductfromPackage: "",
        quantityToPay: product.quantity,
      })),

      modeOfPayment: [
        {
          PartialPaymentAmount: "",
          PartialPaymentMode: "",
        },
        {
          RemainingPaymentAmount: "",
          RemainingPaymentMode: "",
        },
      ],

      tip: 0,
      amount_after_tip: 0,
      is_billed: 0,
      verified_receptionist: 0,
      cash: 0,
      wallet_money: 0,
      credit_card: 0,
      partialPayment: null,
      RemainingPayment: null,
      transactionIdofpartialPayment: "",
      transactionIdofRemainingPayment: "",
      platformFeeforCard: "",
    };

    try {
      const response = await ApiServices.post(
        `/apis/default/api/billingweb/${BillId}`,
        postData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      console.log(response.data);
      setBillSuccess(true);
      setloading(false);
      onChildSubmit(true);
      swal({
        title: "Bill has been Updated successfully",
        // text: "",
        icon: "success",
        button: "OK",
        timer: 2000,
      });

      ApiServices.put(
        `/apis/default/api/patient/${AppointmentData.pc_pid}/appointment/${AppointmentData.pc_eid}`,
        {
          pc_catid: AppointmentData.pc_catid,
          pc_title: AppointmentData.pc_title,
          pc_duration: AppointmentData.pc_duration,
          pc_hometext: AppointmentData.pc_hometext,
          pc_apptstatus: AppointmentData.pc_apptstatus,
          pc_eventDate: AppointmentData.pc_eventDate,
          pc_startTime: AppointmentData.pc_startTime,
          pc_facility: AppointmentData.pc_facility,
          pc_billing_location: AppointmentData.pc_billing_location,
          pc_aid: AppointmentData.pc_aid,
          client_type: AppointmentData.client_type,
          wallet_id: AppointmentData.wallet_id,
          user_type: AppointmentData.user_type,
          is_billed: 0,
          assigned_by: AppointmentData.assigned_by,
          appointment_note: AppointmentData.appointment_note,
          appointment_type: AppointmentData.appointment_type,
          soap_note: addsoapnote,
          verified_receptionist: 0,
          generated_bill_provider: 1,
        },
        {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        }
      );
      setloading(false);
      if (typeof handleupdateBill === "function") {
        handleupdateBill();
      }
      // navigate(
      //   `/admin/show-bill/${paramsData.uuid}/${paramsData.eid}/${paramsData.pid}`
      // );
    } catch (error) {
      console.error(error);
      setloading(false);
      if (error.response && error.response.data) {
        swal({
          title: "Error",
          text: error.response.data.message || "Something went wrong",
          icon: "error",
          button: "OK",
          timer: 2000,
        });
      } else {
        // Display a generic error message
        // onChildSubmit(true)
        setloading(false);
        // swal({
        //   title: "Error",
        //   text: "Something went wrong",
        //   icon: "error",
        //   button: "OK",
        //   timer: 2000,
        // });
      }
    }
  };

  const [SevicesFilledData, setSevicesFilledData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const SevicesFilledDataResponse = await ApiServices.get(
          `/apis/default/api/soapnote/${paramsData.pid}/appointment/${paramsData.eid}`,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );
        setSevicesFilledData(SevicesFilledDataResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [paramsData]);

  const [serviceSearch, setServiceSearch] = useState("");
  const [productSearch, setProductSearch] = useState("");

  // Other state variables and useEffect hooks...

  // Filtered services based on search input
  const filteredServices = doctorServices.filter((service) =>
    service.service.toLowerCase().includes(serviceSearch.toLowerCase())
  );

  // Filtered products based on search input
  const filteredProducts = allProducts.filter((product) =>
    product.title.toLowerCase().includes(productSearch.toLowerCase())
  );

  return (
    <div>
      {loading && <Loader />}
      <div></div>
      <Row>
        <Col sm={12}>
          <div>
            <input
              type="text"
              placeholder="Search services"
              value={serviceSearch}
              onChange={(e) => setServiceSearch(e.target.value)}
              style={{ width: "100%", marginBottom: "10px" }}
            />
            <table className="billing-table">
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Service</th>
                  <th>Sub Service</th>
                  <th>Default Price</th>
                  <th>Discount</th>
                </tr>
              </thead>
              <tbody>
                {filteredServices &&
                  filteredServices.map((doctorService) => (
                    <tr key={doctorService.service_id}>
                      <td>
                        <input
                          id={`checkbox_${doctorService.service_id}`}
                          type="checkbox"
                          onChange={() =>
                            handleServiceSelection(doctorService.service_id)
                          }
                          disabled={BillSuccess}
                        />
                      </td>
                      <td>{doctorService.service}</td>
                      <td>{doctorService.service_subtype}</td>
                      <td>{doctorService.unit_cost}</td>
                      <td>{doctorService.percent_off}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Col>
        <Col className="mt-3">
          <Button onClick={handleShowProducts}>
            {ShowProducts ? "Hide Products" : "Show Products"}
          </Button>
        </Col>
        {ShowProducts && (
          <Col sm={12} className="mt-3">
            <div>
              <input
                type="text"
                placeholder="Search products"
                value={productSearch}
                onChange={(e) => setProductSearch(e.target.value)}
                style={{ width: "100%", marginBottom: "10px" }}
              />
              <table className="billing-table">
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>Title</th>
                    <th>Price</th>
                    {/* <th>Discount</th> */}
                  </tr>
                </thead>
                <tbody>
                  {filteredProducts &&
                    filteredProducts.map((product) => (
                      <tr key={product.id}>
                        <td>
                          <input
                            id={`checkbox_product_${product.id}`}
                            type="checkbox"
                            onChange={() => handleProductSelection(product.id)}
                            disabled={BillSuccess}
                          />
                        </td>
                        <td>{product.title}</td>
                        <td>{product.price}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Col>
        )}
        <Col sm={12} className="mt-3">
          <Card>
            <Card.Body>
              <div className="mt-3">
                <table className="billing-table">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Default Price</th>
                      <th>Quantity</th>
                      <th>Discount Percentage</th>
                      <th>Discounted Price</th>
                      <th>Total Amount</th>
                      <th>Action</th>
                      <th>Note</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedServices.map((selectedService) => {
                      const serviceId = selectedService.id;
                      const discountedPrice =
                        selectedService.default_price -
                        (selectedService.default_price * discounts[serviceId]) /
                          100;
                      const totalAmountForService =
                        discountedPrice * quantities[serviceId];

                      return (
                        <tr key={serviceId}>
                          <td>
                            {selectedService.serviceName != "miscellaneous" ? (
                              selectedService.title
                            ) : (
                              <input
                                type="text"
                                placeholder="Custom Title"
                                value={selectedService.title || ""}
                                onChange={(e) => {
                                  const customTitle = e.target.value;
                                  setSelectedServices(
                                    (prevSelectedServices) => {
                                      const updatedServices =
                                        prevSelectedServices.map((service) =>
                                          service.id === serviceId
                                            ? {
                                                ...service,
                                                customTitle,
                                                title: customTitle,
                                              }
                                            : service
                                        );
                                      return updatedServices;
                                    }
                                  );
                                }}
                                disabled={BillSuccess}
                              />
                            )}
                          </td>
                          <td>
                            {selectedService.serviceName != "miscellaneous" ? (
                              selectedService.default_price
                            ) : (
                              <input
                                type="number"
                                style={{ width: "80px" }}
                                placeholder="Custom Price"
                                value={selectedService.customPrice || ""}
                                onChange={(e) => {
                                  const customPrice = e.target.value;
                                  setSelectedServices(
                                    (prevSelectedServices) => {
                                      const updatedServices =
                                        prevSelectedServices.map((service) =>
                                          service.id === serviceId
                                            ? {
                                                ...service,
                                                customPrice,
                                                default_price: customPrice,
                                              }
                                            : service
                                        );
                                      return updatedServices;
                                    }
                                  );
                                }}
                                disabled={BillSuccess}
                              />
                            )}
                          </td>
                          <td>
                            <Button
                              variant="danger"
                              onClick={() =>
                                handleQuantityChange(
                                  serviceId,
                                  quantities[serviceId] - 1
                                )
                              }
                              disabled={BillSuccess}
                            >
                              -
                            </Button>
                            <span style={{ padding: "10px" }}>
                              {quantities[serviceId]}
                            </span>
                            <Button
                              variant="success"
                              onClick={() =>
                                handleQuantityChange(
                                  serviceId,
                                  quantities[serviceId] + 1
                                )
                              }
                              disabled={BillSuccess}
                            >
                              +
                            </Button>
                          </td>
                          <td>
                            <input
                              type="number"
                              value={discounts[serviceId]}
                              onChange={(e) =>
                                handleDiscountChange(serviceId, e.target.value)
                              }
                              style={{ width: "100px" }}
                              disabled={BillSuccess}
                            />
                          </td>
                          <td>{discountedPrice}</td>

                          <td>{totalAmountForService}</td>

                          <td>
                            <Button
                              variant="danger"
                              onClick={() => handleServiceRemoval(serviceId)}
                              disabled={BillSuccess}
                            >
                              Delete
                            </Button>
                          </td>
                          <td>
                            <textarea
                              type="text"
                              placeholder="Service Note"
                              onChange={(e) =>
                                handleServiceNoteChange(
                                  serviceId,
                                  e.target.value
                                )
                              }
                              disabled={BillSuccess}
                              style={{ width: "100%", minWidth: "320px" }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="mt-3">
                <table className="billing-table">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Default Price</th>
                      <th>Quantity</th>
                      <th>Discount Percentage</th>
                      <th>Discounted Price</th>
                      <th>Total Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedProducts &&
                      selectedProducts.map((selectedProduct) => {
                        const productId = selectedProduct.id;
                        const discountedPrice =
                          selectedProduct.default_price -
                          (selectedProduct.default_price *
                            selectedProduct.discount) /
                            100;
                        const totalAmountForProduct =
                          discountedPrice * selectedProduct.quantity;

                        return (
                          <tr key={productId}>
                            <td>{selectedProduct.title}</td>
                            <td>{selectedProduct.default_price}</td>
                            <td>
                              <Button
                                variant="danger"
                                onClick={() =>
                                  handleProductQuantityChange(
                                    productId,
                                    selectedProduct.quantity - 1
                                  )
                                }
                                disabled={BillSuccess}
                              >
                                -
                              </Button>
                              <span style={{ padding: "10px" }}>
                                {selectedProduct.quantity}
                              </span>
                              <Button
                                variant="success"
                                onClick={() =>
                                  handleProductQuantityChange(
                                    productId,
                                    selectedProduct.quantity + 1
                                  )
                                }
                                disabled={BillSuccess}
                              >
                                +
                              </Button>
                            </td>
                            <td>
                              <input
                                type="number"
                                value={selectedProduct.discount}
                                onChange={(e) =>
                                  handleProductDiscountChange(
                                    productId,
                                    e.target.value
                                  )
                                }
                                style={{ width: "100px" }}
                                disabled={BillSuccess}
                              />
                            </td>
                            <td>{discountedPrice}</td>
                            <td>{totalAmountForProduct}</td>
                            <td>
                              <Button
                                variant="danger"
                                onClick={() => handleProductRemoval(productId)}
                                disabled={BillSuccess}
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>

              <div
                className="charges-info-div"
                style={{ display: "flex", gap: "5px" }}
              >
                <h2>Total Service Charges :</h2>
                <p>$ {totalAmount}</p>
              </div>
              <div
                className="charges-info-div"
                style={{ display: "flex", gap: "5px" }}
              >
                <h2>Total Product Bill :</h2>
                <p>$ {calculateTotalProductBill()}</p>
              </div>

              <div
                className="charges-info-div"
                style={{ display: "flex", gap: "5px" }}
              >
                <h2>Total Bill Amount :</h2>
                <p>$ {totalBillAmount}</p>
              </div>
              <div className="charges-info-div">
                <h2>SOAP Note :</h2>

                <ul>
                  {SevicesFilledData &&
                    SevicesFilledData.map((item, index) => {
                      const existingSoapNoteNew = item.soap_note.soap_note;
                      console.log(
                        "existingSoapNoteNew in Bill",
                        existingSoapNoteNew
                      );
                      return (
                        <ul key={index}>
                          {existingSoapNoteNew &&
                            Object.values(existingSoapNoteNew).map(
                              (note, noteIndex) => (
                                <li key={noteIndex}>
                                  {note.Note} - {note.ProviderID}{" "}
                                  {/* <Button>Edit</Button> */}
                                </li>
                              )
                            )}
                        </ul>
                      );
                    })}
                </ul>

                {/* <textarea
                  placeholder="Add SOAP note..."
                  value={addsoapnote}
                  onChange={(e) => setaddsoapnote(e.target.value)}
                  style={{ width: "100%", padding: "5px" }}
                /> */}
              </div>
              <div
                style={{
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="success"
                  onClick={handleBillingSubmit}
                  disabled={BillSuccess}
                >
                  Submit Billing
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UpdateGenerateBill;
