import React, { useState,useRef ,useEffect} from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Loader from "../Loader/Loader";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import { PiEyeClosedFill } from "react-icons/pi";
import { RxEyeOpen } from "react-icons/rx";
import { AES } from "crypto-js";
import AccessToken from "../../../GlobalFiles/ApiServices/AccessToken";

const PatientDetailsForm = () => {
  const [loading, setloading] = useState(false);
  const [emailtoken, setemailtoken] = useState("");
  const [createCredentialsLink, setcreateCredentialsLink] = useState("");
  const [createCredentialsModal, setcreateCredentialsModal] = useState(false);
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordMismatchError, setPasswordMismatchError] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleVerifyPasswordChange = (e) => {
    setVerifyPassword(e.target.value);
    setPasswordMismatchError("");
  };
  // const [otp, setOtp] = useState("");
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [EmrAccessToken, setEmrAccessToken] = useState("");
  const navigate = useNavigate();

  const OpencreateCredentialsModal = () => {
    setcreateCredentialsModal(true);
  };
  const ClosecreateCredentialsModal = () => {
    setcreateCredentialsModal(false);
  };

  const [formData, setFormData] = useState({
    title: "",
    fname: "",
    mname: "",
    lname: "",
    street: "",
    postal_code: "",
    city: "",
    state: "",
    country_code: "",
    phone_contact: "",
    DOB: "",
    sex: "",
    race: "",
    ethnicity: "",
    email: "",
    token_id: "",
  });

  const [communicationPreferences, setCommunicationPreferences] = useState({
    allow_imm_reg_use: "UNASSIGNED",
    allow_imm_info_share: "UNASSIGNED",
    allow_health_info_ex: "UNASSIGNED",
    allow_patient_portal: "YES",
    hipaa_voice: "YES",
    hipaa_notice: "YES",
    hipaa_message: "UNASSIGNED",
    hipaa_allowsms: "YES",
    hipaa_allowemail: "YES",
    hipaa_mail: "YES",
  });

  const [validationErrors, setValidationErrors] = useState({
    fname: "",
    lname: "",
    sex: "",
    DOB: "",
    email: "",
    phone_contact: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setFormData({ ...formData, [name]: value.toLowerCase() });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    setValidationErrors({ ...validationErrors, [name]: "" });
    if (name === "email") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!value.match(emailRegex)) {
        setValidationErrors({
          ...validationErrors,
          [name]: "Invalid email address",
        });
      } else {
        setValidationErrors({ ...validationErrors, [name]: "" });
      }
    }
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setValidationErrors({ ...validationErrors, password: "" });
  };

  const handleSubmit = async () => {
    const errors = {};
    if (!formData.fname) errors.fname = "First name required.";
    if (!formData.lname) errors.lname = "Last name  required.";
    if (!formData.sex) errors.sex = "Gender is required.";
    if (!formData.DOB) errors.DOB = "Date of birth is required.";
    if (!formData.email) errors.email = "Email is required.";
    if (!formData.phone_contact)
      errors.phone_contact = "Phone Number is required.";
    if (!password) errors.password = "Password is required.";
    // if (!verifyPassword) errors.password = "Password is required.";

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    if (password !== verifyPassword) {
      setPasswordMismatchError("Passwords do not match");
      return;
    } else {
      setPasswordMismatchError("");
    }
    if (!isCheckboxChecked) {
      setValidationErrors({
        ...validationErrors,
        checkbox: "Please accept the terms and conditions.",
      });
      return;
    }

    setloading(true);

    ApiServices.postemr("/apis/default/api/verifyemail", {
      fname: formData.fname,
      mname: formData.mname,
      lname: formData.lname,
      email: formData.email,
      DOB: formData.DOB,
    }).then((response) => {
      let inputotp = response.data.data.OTP;
      ApiServices.getemr(
        `/apis/default/api/verificationtoken/${inputotp}`,
        {}
      ).then((response) => {
        const token_id_holder = response.data.data.token_id_holder;
        setemailtoken(token_id_holder);
        const patientId = response.data.data.patient_id;

        if (patientId) {
          swal({
            title: "Email Already Registered",
            text: "Please log in to your account",
            icon: "warning",
            buttons: {
              confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "btn-primary",
                closeModal: true,
              },
            },
            closeOnClickOutside: false,
            closeOnEsc: false,
          }).then((confirmed) => {
            if (confirmed) {
              navigate("/patient-login");
            }
          });
        } else {
          const makePatientApiRequest = async () => {
            try {
              const accessToken = await AccessToken.fetchAccessToken();
              setEmrAccessToken(accessToken);
              const formDataWithPreferences = {
                ...formData,
                ...communicationPreferences,
              };
              formDataWithPreferences.token_id = emailtoken;

              const response = await ApiServices.postemr(
                "/apis/default/api/patient",
                formDataWithPreferences,
                {
                  Authorization: `Bearer ${accessToken}`,
                }
              );



              const patientPassLinkResponse = await ApiServices.postemr(
                "/apis/default/api/patientpasslink",
                {
                  resetPass: false,
                  email: formData.email,
                  token_id: response.data.data.token_id,
                  pid: response.data.data.pid,
                }
              );
              const link = patientPassLinkResponse.data.data.forward;
              setcreateCredentialsLink(link);

              const WalletCreate = await ApiServices.postemr(
                "/apis/default/api/wallet",
                {
                  initialBalance: 0,
                  pid: response.data.data.pid,
                },
                {
                  Authorization: `Bearer ${accessToken}`,
                }
              );

              const Regitercredentialsresponse = await ApiServices.postemr(
                "/apis/default/api/patientneworresetpass",
                {
                  forward: patientPassLinkResponse.data.data.forward,
                  email: formData.email,
                  login_uname: formData.email,
                  pass_new: password,
                  pass_new_confirm: password,
                },
                {
                  Authorization: `Bearer ${accessToken}`,
                }
              );

              ApiServices.postemr(
                "/apis/default/api/intakeForms",
                {
                  pid: response.data.data.pid,
                },
                {
                  Authorization: `Bearer ${accessToken}`,
                }
              );
              setcreateCredentialsModal(true);
              setloading(false);
            } catch (error) {
              console.error("Error:", error);
              setloading(false);
            }
          };
          makePatientApiRequest();
        }
      });
    });
  };

  const otpInputRefs = useRef([]);
  const handleBackspace = (index, e) => {
    if (e.keyCode === 8 && index > 0 && otp[index] === "") {
      otpInputRefs.current[index - 1].focus();
    }
  };
  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  
    if (value !== "" && index < 5) {
      otpInputRefs.current[index + 1].focus();
    }
  };



  const handleSubmitCredentials = async (e) => {
    // e.preventDefault();
    const enteredOtp = otp.join("");
    try {
      const response = await ApiServices.postemr(
        "/apis/default/api/patientneworresetpassupdate",
        {
          forward: createCredentialsLink,
          onetime_pass: enteredOtp,
          login_uname: formData.email,
          email: formData.email,
          // pass_new: password,
          // pass_new_confirm: password,
        },
        // {
        //   Authorization: `Bearer ${EmrAccessToken}`,
        // }
      );

      if (response.data.data.status === true) {
        try {
          const tokenResponse = await ApiServices.postemr(
            "/oauth2/default/token",
            {
              client_id: "G7H1yJbmR_JzoZV3Xw0wV8LO58pL2hb-YV6KFv-hl94",
              grant_type: "password",
              scope:
                "openid offline_access api:oemr api:port patient/patient.read",
              user_role: "patient",
              username: formData.email,
              password: password,
              email: formData.email,
            },
            {
              "Content-Type": "application/x-www-form-urlencoded",
            }
          );

          const accessToken = tokenResponse.data.access_token;
          sessionStorage.setItem("Patient_Access_Token", accessToken);

          const apiResponse = await ApiServices.getemr(
            "/apis/default/portal/patient",
            {
              Authorization: `Bearer ${accessToken}`,
            }
          );

          // console.log("Login response of Patient", apiResponse.data.data);
          sessionStorage.setItem("LoginUserFname", apiResponse.data.data.fname);
          sessionStorage.setItem("LoginUserLname", apiResponse.data.data.lname);
          const encryptedEmail = AES.encrypt(
            formData.email,
            process.env.REACT_APP_USER_ENC_DEC_KEY
          ).toString();
          const encryptedPassword = AES.encrypt(
            password,
            process.env.REACT_APP_USER_ENC_DEC_KEY
          ).toString();
          sessionStorage.setItem("encryptedEmail", encryptedEmail);
          sessionStorage.setItem("encryptedPassword", encryptedPassword);
          sessionStorage.setItem("LoginUserType", "Patient");
          const adminaccessToken = await AccessToken.fetchAccessToken();
          sessionStorage.setItem("accessToken", adminaccessToken);

          swal({
            title: "Registration and Login Successfully",
            icon: "success",
            button: "OK",
            timer: 2000,
          }).then(() => {
            sessionStorage.setItem("Patient_Pid", apiResponse.data["data"].id);
            navigate("/patient-dashboard");
          });
        } catch (error) {
          console.error("Error:", error.response?.data || error.message);
          swal({
            title: "Error",
            text: "An error occurred while processing your request. Please try again later.",
            icon: "error",
            button: "OK",
          });
        }
      } else {
        swal({
          title: "OTP is Incorrect or Used",
          text: "Please Check Your mailbox for correct OTP.",
          icon: "error",
          button: "OK",
        });
        setOtp(Array(6).fill(""))
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      // Provide feedback to the user about the error
      swal({
        title: "Error",
        text: "An error occurred while processing your request. Please try again later.",
        icon: "error",
        button: "OK",
      });
    }
  };

  useEffect(() => {
    const allDigitsEntered = otp.every(digit => digit !== "");
    if (allDigitsEntered) {
      handleSubmitCredentials();
    }
  }, [otp]);

  return (
    <div>
      {loading && <Loader />}
      <div>
        <Container>
          <Row className="align-items-center p-4">
            <Col sm={5}>
              <h1 class="explore-treartment-tag">Cosmos Med Spa</h1>
              <h1
                class="explore-treartment-sub-title-head-tag-signup"
                style={{ paddingTop: "15px", paddingBottom: "15px" }}
              >
                Your Path to Radiant Health Starts Here...
                <br /> Register Now!
              </h1>
              <p
                className="med-spa-staff-info-p-tag"
                style={{ textAlign: "center" }}
              >
                Start your wellness journey with us. Register to experience the
                art of relaxation and the science of well-being.
              </p>
            </Col>
            <Col sm={7}>
              <Row>
                <Col sm={6}>
                  <select
                    className="register-patient-column-input"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                  >
                    <option>Title</option>
                    <option>Mr.</option>
                    <option>Mrs.</option>
                    <option>Miss.</option>
                  </select>
                </Col>

                <Col sm={6}>
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      placeholder="First Name"
                      className="register-patient-column-input"
                      name="fname"
                      value={formData.fname}
                      onChange={handleInputChange}
                    />
                    <p className="validation-error-message">*</p>
                  </div>
                  <p className="validation-error-message">
                    {validationErrors.fname}
                  </p>
                </Col>

                <Col sm={6}>
                  <input
                    type="text"
                    placeholder="Middle Name"
                    className="register-patient-column-input"
                    name="mname"
                    value={formData.mname}
                    onChange={handleInputChange}
                  />
                </Col>
                <Col sm={6}>
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="register-patient-column-input"
                      name="lname"
                      value={formData.lname}
                      onChange={handleInputChange}
                    />
                    <p className="validation-error-message">*</p>
                  </div>
                  <p className="validation-error-message">
                    {validationErrors.lname}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <p
                    style={{
                      margin: "0px",
                      paddingLeft: "8px",
                      textAlign: "left",
                      color: "grey",
                    }}
                  >
                    Select Your Gender
                  </p>
                  <div style={{ display: "flex" }}>
                    <select
                      className="register-patient-column-input"
                      name="sex"
                      value={formData.sex}
                      onChange={handleInputChange}
                    >
                      <option>Gender</option>
                      <option>Male</option>
                      <option>Female</option>
                      <option>Prefer not to say</option>
                    </select>

                    <p className="validation-error-message">*</p>
                  </div>
                  <p className="validation-error-message">
                    {validationErrors.sex}
                  </p>
                </Col>
                <Col sm={6}>
                  <p
                    style={{
                      margin: "0px",
                      paddingLeft: "8px",
                      textAlign: "left",
                      color: "grey",
                    }}
                  >
                    Select Date of Birth
                  </p>
                  <div style={{ display: "flex" }}>
                    <input
                      type="date"
                      placeholder="Date of Birth"
                      className="register-patient-column-input"
                      name="DOB"
                      value={formData.DOB}
                      onChange={handleInputChange}
                      max={new Date().toISOString().split("T")[0]}
                    />
                    <p className="validation-error-message">*</p>
                  </div>
                  <p className="validation-error-message">
                    {validationErrors.DOB}
                  </p>
                </Col>
                <Col sm={6}>
                  <select className="register-patient-column-input">
                    <option>Marital Status</option>
                    <option>Married</option>
                    <option>Single</option>
                    <option>Prefer not to say</option>
                  </select>
                </Col>
                <Col sm={6}>
                  <select
                    className="register-patient-column-input"
                    name="ethnicity"
                    value={formData.ethnicity}
                    onChange={handleInputChange}
                  >
                    <option>Ethnicity</option>
                    <option>African</option>
                    <option>Asian</option>
                    <option>European</option>
                    <option>Middle Eastern</option>
                    <option>Indigenous </option>
                    <option>Latino/Hispanic</option>
                    <option>Pacific Islander</option>
                    <option>Multiracial/Multiethnic</option>
                    <option>Other Ethnic Groups</option>
                    <option>Prefer not to say</option>
                  </select>
                </Col>
              </Row>

              <Row>
                <Col sm={4} className="mb-2">
                  <textarea
                    className="register-patient-column-input"
                    placeholder="Street"
                    style={{ height: "100%" }}
                    name="street"
                    value={formData.street}
                    onChange={handleInputChange}
                  />
                </Col>
                <Col sm={4}>
                  <input
                    type="text"
                    placeholder="City"
                    className="register-patient-column-input"
                    style={{ marginBottom: "10px" }}
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                  <input
                    type="text"
                    placeholder="State"
                    className="register-patient-column-input"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                  />
                </Col>
                <Col sm={4}>
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      placeholder="Postal Code"
                      className="register-patient-column-input"
                      style={{ marginBottom: "10px" }}
                      name="postal_code"
                      value={formData.postal_code}
                      onChange={handleInputChange}
                    />
                    <p className="validation-error-message">*</p>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={4}>
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      placeholder="Cellphone Number"
                      className="register-patient-column-input"
                      name="phone_contact"
                      value={formData.phone_contact}
                      onChange={handleInputChange}
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onKeyDown={(e) => {
                        if (
                          !(
                            (e.keyCode > 95 && e.keyCode < 106) ||
                            (e.keyCode > 47 && e.keyCode < 58) ||
                            e.keyCode === 8 ||
                            e.keyCode === 9 ||
                            e.keyCode === 46 ||
                            e.keyCode === 37 ||
                            e.keyCode === 39
                          )
                        ) {
                          e.preventDefault();
                        }
                      }}
                      style={{ "-moz-appearance": "textfield" }}
                    />
                    <p className="validation-error-message">*</p>
                  </div>
                  <p className="validation-error-message">
                    {validationErrors.phone_contact}
                  </p>
                </Col>

                <Col sm={8}>
                  <div style={{ display: "flex" }}>
                    <input
                      type="email"
                      placeholder="Email Address"
                      className="register-patient-column-input"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    <p className="validation-error-message">*</p>
                  </div>
                  <p className="validation-error-message">
                    <b>{validationErrors.email}</b>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div style={{ display: "flex" }}>
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      className="register-patient-column-input"
                      name="password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <p className="validation-error-message">*</p>
                    {showPassword ? (
                      <PiEyeClosedFill
                        type="button"
                        onClick={togglePasswordVisibility}
                        style={{ color: "black", fontSize: "25px" }}
                      />
                    ) : (
                      <RxEyeOpen
                        type="button"
                        onClick={togglePasswordVisibility}
                        style={{ color: "black", fontSize: "25px" }}
                      />
                    )}
                  </div>
                  <p className="validation-error-message">
                    {validationErrors.password}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div style={{ display: "flex" }}>
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Verify Password"
                      className="register-patient-column-input"
                      name="verifyPassword"
                      value={verifyPassword}
                      onChange={handleVerifyPasswordChange}
                    />
                    <p className="validation-error-message">*</p>
                    {showPassword ? (
                      <PiEyeClosedFill
                        type="button"
                        onClick={togglePasswordVisibility}
                        style={{ color: "black", fontSize: "25px" }}
                      />
                    ) : (
                      <RxEyeOpen
                        type="button"
                        onClick={togglePasswordVisibility}
                        style={{ color: "black", fontSize: "25px" }}
                      />
                    )}
                  </div>
                  <p className="validation-error-message">
                    {passwordMismatchError}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <input
                      className="custom-checkbox-"
                      type="checkbox"
                      style={{
                        margin: "0px",
                        cursor: "pointer",
                        width: "35px",
                      }}
                      onChange={handleCheckboxChange}
                      required
                    />
                    <p style={{ margin: "0px" }}>
                      By checking this box, I confirm that I have read and agree
                      to the{" "}
                      <Link
                        to="/privacy-policy"
                        style={{ color: "blue", cursor: "pointer" }}
                        target="_blank"
                      >
                        Terms and Conditions
                      </Link>{" "}
                      ,{" "}
                      <Link
                        to="/privacy-policy"
                        style={{ color: "blue", cursor: "pointer" }}
                        target="_blank"
                      >
                        Privacy Policy
                      </Link>{" "}
                      and Financial Policy of Cosmos Medi Spa.
                    </p>
                  </div>
                  <p className="validation-error-message">
                    {validationErrors.checkbox}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col sm={12} style={{ textAlign: "right" }} className="mt-2">
                  <button
                    onClick={handleSubmit}
                    className="med-spa-custom-button"
                  >
                    Submit
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Modal
            show={createCredentialsModal}
            onHide={ClosecreateCredentialsModal}
          >
            <Modal.Header>
              <Modal.Title>
                <p
                  style={{ margin: "0px", fontSize: "2.5rem" }}
                  className="section-one-head-tag"
                >
                  Token Verification
                </p>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{ margin: "0px" }} className="med-spa-staff-info-p-tag">
                Enter the Token sent to your email address : <br />
                {formData.email}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px",
                }}
              >
                {/* <input
                  type="text"
                  className="appt-as-guest-input-field"
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                /> */}

                <div className="login-form-flex-div" style={{border:"none"}}>
                {otp&&otp?.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    min="0"
                    max="9"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!isNaN(value) && value >= 0 && value <= 9) {
                        handleOtpChange(index, value);
                      }
                    }}
                    onKeyDown={(e) => handleBackspace(index, e)}
                    className="login-form-input-field"
                    ref={(el) => (otpInputRefs.current[index] = el)}
                    style={{border:"1px solid rgb(28,50,74)",textAlign:"center",color:"black"}}
                  />
                ))}
              </div>
              </div>
              <div>
                {/* <p
                  style={{ margin: "0px" }}
                  className="med-spa-staff-info-p-tag"
                >
                  Did not receive the Token?
                </p> */}
                <p
                  style={{ margin: "0px" }}
                  className="med-spa-staff-info-p-tag"
                >
                  Check your email inbox, including spam or junk folders.
                </p>
                {/* <p
                  style={{ margin: "0px" }}
                  className="med-spa-staff-info-p-tag"
                >
                  If you still don't receive the OTP, you can
                  <p
                    className="clear-filters-text"
                  >
                    Resend OTP
                  </p>
                </p> */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="med-spa-custom-button"
                style={{
                  backgroundColor: "rgb(229, 231, 235)",
                  color: "black",
                }}
                onClick={ClosecreateCredentialsModal}
              >
                Close
              </button>
              {/* <button
                className="med-spa-custom-button"
                onClick={handleSubmitCredentials}
              >
                Verify Token
              </button> */}
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    </div>
  );
};

export default PatientDetailsForm;
