import React, { useState } from 'react'
import { FiPhoneCall } from 'react-icons/fi';
const MedSpaCallUs = () => {
  const[price,setPrice]=useState('')
  return (
    <div>
      
      <div className='call-now-div-bg'>
      <div className='call-now-div-bg-style'>
        <FiPhoneCall className='call-now-div-logo'/>
      </div>
      </div>
      <div className='give-us-a-call-main-div'>
        <h3>Call Me</h3>
        <p>If you'd like to speak with us, give us a call!</p>
      </div>
      <div className='call-now-button-div'>
        <button className='call-now-button-div-button' style={{color:"white"}}>
        <a href='tel:8128030079' style={{textDecoration:"none",color:"white"}}>
        CALL NOW
        </a>
        </button>
      </div>
    </div>
  )
}

export default MedSpaCallUs