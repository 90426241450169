import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import MedSpaHomePage from "../../Container/MedSpaHomePage/MedSpaHomePage";
import MedSpaNavbar from "../../Components/MedSpaNavbar/MedSpaNavbar";
import MedSpaFooter from "../../Components/MedSpaFooter/MedSpaFooter";
import MedSpaServicesPage from "../../Container/MedSpaServicesPage/MedSpaServicesPage";
import MedSpaServiceDetailsPage from "../../Container/MedSpaServiceDetailsPage/MedSpaServiceDetailsPage";
import MedSpaArticlesPage from "../../Container/MedSpaArticlesPage/MedSpaArticlesPage";
import MedSpaArticlesDetailsPage from "../../Container/MedSpaArticlesDetailsPage/MedSpaArticlesDetailsPage";
import MedSpaSupportScreen from "../../Container/MedSpaSupportScreen/MedSpaSupportScreen";
import MedSpaPatientRegistrationPage from "../../Container/MedSpaPatientRegistrationPage/MedSpaPatientRegistrationPage";
import MedSpaAboutUs from "../../Container/MedSpaAboutUs/MedSpaAboutUs";
import MedSpaContactUs from "../../Container/MedSpaContactUs/MedSpaContactUs";
import MedSpaAppointmentPage from "../../Container/MedSpaAppointmentPage/MedSpaAppointmentPage";
import { Fade } from "react-reveal";
import CalendarViewMultiple from "../../Components/CalendarView/CalendarViewMultiple";
import PatientLogin from "../../Components/PatientLogin/PatientLogin";
import MedSpaPrivacyPolicyPage from "../../Container/MedSpaPrivacyPolicyPage/MedSpaPrivacyPolicyPage";
import MedSpaProductPage from "../../Container/MedSpaProductPage/MedSpaProductPage";
import MedSpaProductDetailsPage from "../../Container/MedSpaProductPage/MedSpaProductDetailsPage";
import MedSpaFianancialPolicyPage from "../../Container/MedSpaPrivacyPolicyPage/MedSpaFianancialPolicyPage";
import "../../GlobalFiles/CSS/Medspastyles.css"

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const WebRoutes = () => {
  return (
    <div>
      <ScrollToTop />
      <MedSpaSupportScreen />
      <MedSpaNavbar />
      <div style={{ paddingTop: "55px" }}>
        <Routes>
          <Route path="/" element=<MedSpaHomePage /> />
          <Route path="/services" element=<MedSpaServicesPage /> />
          <Route path="/services/:id" element=<MedSpaServiceDetailsPage /> />
          <Route path="/articles" element=<MedSpaArticlesPage /> />
          <Route path="/articles/:id" element=<MedSpaArticlesDetailsPage /> />
          <Route path="/signup" element=<MedSpaPatientRegistrationPage /> />
          <Route path="/about" element=<MedSpaAboutUs /> />
          <Route path="/contactus" element=<MedSpaContactUs /> />
          <Route path="/appointment" element=<MedSpaAppointmentPage /> />
          <Route path="/login" element=<PatientLogin /> />
          <Route path="/privacy-policy" element=<MedSpaPrivacyPolicyPage /> />
          <Route path="/financial-policy" element=<MedSpaFianancialPolicyPage/> />
          <Route path="/cosmos-products" element=<MedSpaProductPage /> />
          <Route
            path="/cosmos-products/:id/:title"
            element=<MedSpaProductDetailsPage />
          />

          <Route path="/calendarmultiple" element=<CalendarViewMultiple /> />
        </Routes>
      </div>
      <div>
        {
          <Fade bottom>
            <MedSpaFooter />
          </Fade>
        }
      </div>
    </div>
  );
};

export default WebRoutes;
