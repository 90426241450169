import React from 'react'
import "./MedSpaArticlesPage.css"
import ArticlesCard from './ArticlesCard'

const MedSpaArticlesPage = () => {
  return (
    <div>
      <div style={{paddingTop:"50px", paddingBottom:"30px"}}>
        <ArticlesCard/>
      </div>
    </div>
  )
}

export default MedSpaArticlesPage