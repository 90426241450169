import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import TeamImage from "../../../Assets/teamgroup.jpg"
import EquipmentsImage from "../../../Assets/equipments.jpg"
import ConsultImage from "../../../Assets/dermpat.jpg"
import LuxuriousEnvironment from "../../../Assets/LuxuriousEnvironment.jpg"
import equippedone from "../../../Assets/equippedone.jpg"
import { Link } from 'react-router-dom'
import { Fade } from 'react-reveal'
const ContactUsSecOne = () => {
  return (
    <div>
        <Container style={{textAlign:"left"}}>
       <Fade bottom>

        <div style={{paddingTop:"20px"}}>
        <h1 class="explore-treartment-tag">What Sets Us Apart :</h1>
        </div>
       </Fade>
        <div style={{paddingTop:"50px"}}>
        <Fade bottom>
        <Row className="align-items-center">
          <Col sm={6} className="order-1 order-sm-1">
            <img
              
              src={TeamImage}
              style={{ height: "80%", width: "100%" }}
              className="section-image-quadra-shape"
              alt=''
            />
          </Col>

          <Col sm={6} className="order-2 order-sm-2">
          
            <h1 className="section-one-head-tag">
            Expertise
            </h1>
            <p className="section-one-para-tag">
            Our team consists of seasoned and highly-trained professionals who are deeply committed to delivering exceptional, personalized care. We invest the time to thoroughly understand your unique needs and goals, ensuring your experience is tailored to you.
            </p>
            <Link to="/about">
            <button className="med-spa-custom-button">Learn More</button>
            </Link>
          </Col>
        </Row>
        </Fade>
        </div>

        <div style={{paddingTop:"50px"}}>
        <Fade bottom>
        <Row className="align-items-center">
          <Col sm={6} className="order-2 order-sm-2">
            <img
              
              src={EquipmentsImage}
              style={{ height: "80%", width: "100%" }}
              className="section-image-quadra-shape"
              alt=''
            />
          </Col>

          <Col sm={6} className="order-1 order-sm-1">
          
            <h1 className="section-one-head-tag">
            Cutting-Edge Technology
            </h1>
            <p className="section-one-para-tag">
            We remain at the forefront of the industry, providing access to the latest advancements in medical spa treatments. Our commitment to staying current guarantees you receive the most effective and up-to-date solutions for your beauty and wellness journey.
            </p>
            <Link to="/services">
            <button className="med-spa-custom-button">Learn More</button>
            </Link>
          </Col>
        </Row>
        </Fade>
        </div>

        <div style={{paddingTop:"50px"}}>
        <Fade bottom>
        <Row className="align-items-center">
          <Col sm={6} className="order-1 order-sm-1">
            <img
              
              src={ConsultImage}
              style={{ height: "80%", width: "100%" }}
              className="section-image-quadra-shape"
              alt=''
            />
          </Col>

          <Col sm={6} className="order-2 order-sm-2">
          
            <h1 className="section-one-head-tag">
            Holistic Approach
            </h1>
            <p className="section-one-para-tag">
            We believe in a holistic approach that balances inner and outer beauty. Our services go beyond mere aesthetic enhancements; they're designed to boost not only your appearance but also your confidence and overall well-being. We aim to help you look and feel your best.
            </p>
            <Link to="/about">
            <button className="med-spa-custom-button">Learn More</button>
            </Link>
          </Col>
        </Row>
        </Fade>
        </div>

        <div style={{paddingTop:"50px"}}>
        <Fade bottom>
        <Row className="align-items-center">
          <Col sm={6} className="order-2 order-sm-2">
            <img
              
              src={LuxuriousEnvironment}
              style={{ height: "80%", width: "100%" }}
              className="section-image-quadra-shape"
              alt=''
            />
          </Col>

          <Col sm={6} className="order-1 order-sm-1">
          
            <h1 className="section-one-head-tag">
            Luxurious Environment
            </h1>
            <p className="section-one-para-tag">
            When you step into our spa, you enter a serene and luxurious space that's carefully crafted to provide an escape from the everyday. Our ambiance is designed to immerse you in relaxation and rejuvenation, making your visit an indulgent experience.
            </p>
            <Link to="/services">
            <button className="med-spa-custom-button">Learn More</button>
            </Link>
          </Col>
        </Row>
        </Fade>
        </div>

        <div style={{paddingTop:"50px"}}>
        <Fade bottom>
        <Row className="align-items-center">
          <Col sm={6} className="order-1 order-sm-1">
            <img
              
              src={equippedone}
              style={{ height: "80%", width: "100%" }}
              className="section-image-quadra-shape"
              alt=''
            />
          </Col>

          <Col sm={6} className="order-2 order-sm-2">
          
            <h1 className="section-one-head-tag">
            State-of-the-Art Equipment
            </h1>
            <p className="section-one-para-tag">
            We're fully equipped with the latest, state-of-the-art technologies and tools to ensure the highest quality of care and results in a safe and comfortable environment. Your comfort, safety, and satisfaction are our top priorities.
            </p>
            <Link to="/services">
            <button className="med-spa-custom-button">Learn More</button>
            </Link>
          </Col>
        </Row>
        </Fade>
        </div>
        </Container>
    </div>
  )
}

export default ContactUsSecOne