import React, { useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import EmailLogo from "../../Assets/dashicons_email.svg";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import swal from "sweetalert";
import axios from "axios";
import Loader from "../Loader/Loader";


const ForgotPassword = () => {
  const location = useLocation();
const[loading,setloading]=useState(false)
  const queryParams = new URLSearchParams(location.search);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
  });
  console.log("formData",formData)
  const [errors, setErrors] = useState({});

  const navigate = useNavigate("");
  

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "email") {
  //     setFormData({ ...formData, [name]: value.toLowerCase() });
  //   } else {
  //     setFormData({ ...formData, [name]: value });
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const lowercaseValue = value.toLowerCase();
    setFormData({ ...formData, [name]: lowercaseValue, email: lowercaseValue });
  };
  


  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true)
    try {
      const validationErrors = validateForm(formData);
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
      const response = await ApiServices.post(
      // const response = await axios.post(
        "/apis/default/api/emailforgotpassword",
        formData
      );
      setloading(false)
      console.log("response",response.data)
      if(response.data.data.status == true){
      swal({
        title: "OTP Sent Successfully",
        text: "Please Check Your Mailbox for One Time Password for Generating New Login Credentials",
        icon: "success",
        button: "OK",
        timer: 2000,
      });
      navigate(`/reset-password/${formData.username}`);
    }
    else{
      swal({
        title: "Something Went Wrong",
        text: "Please Check the One Time Password and Link is Valid or Not.",
        icon: "warning",
        button: "OK",
        timer: 2000,
      });
    }
      console.log("API Response:", response.data);
    } catch (error) {
      setloading(false)
      console.error("Error:", error.response?.data || error.message);
    }
  };

  const validateForm = (data) => {
    const errors = {};

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(data.username)) {
      errors.email = "Invalid email address";
    }
    return errors;
  };

  const cardStyle = {
    margin: "auto",
    height: "auto",
    width: "900px",
    background: "#6665DD",
    padding: "20px",
    borderRadius: "50px",
  };


  return (
    <div>
    {loading && <Loader/>}
    <Container
      style={{ display: "flex", justifyContent: "center", height: "100vh" }}
    >
      <Card style={cardStyle}>
        <Card.Body>
          <Row style={{ alignItems: "center" }}>
            <Col sm={6}>
              <div>
                <h1
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "30px",
                  }}
                >
                 Forgot Your Password?
                </h1>
                <p style={{ color: "white", textAlign: "justify" }}>
                  
We understand, forgetting passwords happens to the best of us! Don't worry; we're here to help you regain access to your Cosmos Medi Spa patient portal.
                </p>
                <p style={{ color: "white", textAlign: "justify" }}>
                 
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <div
                style={{
                  paddingBottom: "20px",
                  paddingTop: "10px",
                }}
              >
                <form onSubmit={handleSubmit}>
                  <label
                    style={{
                      color: "white",
                      fontWeight: "600",
                      paddingBottom: "10px",
                    }}
                  >
                    Email :
                  </label>
                  <div className="login-form-flex-div">
                    <img
                      src={EmailLogo}
                      alt=""
                      className="login-form-input-logo"
                    />
                    <input
                      type="email"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                      className="login-form-input-field"
                    />
                  </div>
                  <div style={{ marginTop: "-15px" }}>
                    <span style={{ color: "red" }}>{errors.email}</span>
                  </div>

                
                  <button className="login-form-login-button" type="submit">
                    Send OTP
                  </button>
                </form>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container></div>
  );
};

export default ForgotPassword;
