import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import skintypeone from "../../Assets/skintypeone.webp"
import skintypetwo from "../../Assets/skintypetwo.webp"
import skintypethree from "../../Assets/skintypethree.webp"
import skintypefour from "../../Assets/skintypefour.webp"
import treatableconcernone from "../../Assets/treatableconcernone.webp"
import treatableconcertwo from "../../Assets/treatableconcerntwo.webp"
import treatableconcerthree from "../../Assets/treatableconcernthree.webp"

const ServicesDetailsSectionThree = ({ data }) => {
    const detailsData = data;
    const [skintypelogos,setskintypelogos]=useState()
    const [treatableconcernlogos,settreatableconcernlogos]=useState()
    const [treatablearealogos,settreatablearealogos]=useState()
    const [treatableconcernstext,settreatableconcernstext]=useState()
    const [treatableareastext,settreatableareastext]=useState()
    // const treatable_concerns_names = detailsData&&detailsData?.treatable_concerns_text.map(area => area.name).join(', ');
    // const treatable_area_names = detailsData&&detailsData?.treatable_areas_text.map(area => area.name).join(', ');
    
    useEffect(()=>{
        if(data){
            setskintypelogos(detailsData.skin_type_logo)
            settreatableconcernlogos(detailsData.treatable_concerns)
            settreatablearealogos(detailsData.treatable_areas)
            settreatableconcernstext(detailsData.treatable_concerns_text)
            settreatableareastext(detailsData.treatable_areas_text)
        }
    },[data])
   

  return (
    <div>
        <Container style={{textAlign:"left"}}>
        <h1 class="section-one-head-tag" style={{textAlign:"center"}}>{detailsData&&detailsData?.title}</h1>
        <p className="section-one-para-tag" style={{textAlign:"center",paddingTop:"20px",paddingBottom:"20px"}}>
             {detailsData&&detailsData?.subtitle}
            </p>
            <Row className="section-three-class-border-">
                <Col sm={4} className='section-three-middle-col-border'>
                    <Row className='flex-of-image-logo'>
                    <img alt='' src={skintypeone} className='section-three-card-inside-logos'/>
                    <img alt='' src={skintypetwo} className='section-three-card-inside-logos'/>
                    <img alt='' src={skintypethree} className='section-three-card-inside-logos'/>
                    <img alt='' src={skintypefour} className='section-three-card-inside-logos'/>

                        {/* {skintypelogos&&skintypelogos?.map((index)=>(
                            <img alt='' src={index.image} className='section-three-card-inside-logos' title={index.name}/>
                        ))} */}
                    </Row>
                    <h1 className='section-three-class-header'>Skin Type</h1>
                    <p className='section-three-class-para'>{detailsData&&detailsData?.skintype}</p>
                </Col>
                <Col sm={4} className='section-three-middle-col-border'>
                <Row className='flex-of-image-logo'>
                <img alt='' src={treatableconcernone} className='section-three-card-inside-logos'/>
                <img alt='' src={treatableconcertwo} className='section-three-card-inside-logos'/>
                <img alt='' src={treatableconcerthree} className='section-three-card-inside-logos'/>

                        {/* {treatableconcernlogos&&treatableconcernlogos?.map((index)=>(
                            <img alt='' src={index.image} className='section-three-card-inside-logos' title={index.name}/>
                        ))} */}
                    </Row>
                <h1 className='section-three-class-header'>Treatable Concerns</h1>
                    <p className='section-three-class-para'>{treatableconcernstext}</p>
                </Col>
                <Col sm={4} className='section-three-middle-col-border'>
                <Row className='flex-of-image-logo'>
                            <img alt='' src={treatableconcernone} className='section-three-card-inside-logos'/>
                        {/* {treatablearealogos&&treatablearealogos?.map((index)=>(
                            <img alt='' src={index.image} className='section-three-card-inside-logos' title={index.name}/>
                        ))} */}
                    </Row>
                <h1 className='section-three-class-header'>Treatable Areas</h1>

                    <p className='section-three-class-para'>{treatableareastext}</p>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default ServicesDetailsSectionThree