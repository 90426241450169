import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import Loader from "../Loader/Loader";
import swal from "sweetalert";
import FilledIntakeForm from "./FilledIntakeForm";
import { useParams } from "react-router-dom";

const IntakeUnfilledForm = ({
  formTitle,
  formJson,
  formName,
  FilledIntakeFormsJsonData,
  onChildSubmit,
}) => {
  const accessToken = sessionStorage.getItem("accessToken");
  const LoginPatientID = sessionStorage.getItem("LoginPatientID");
  
  const paramsData = useParams();
  const LoginUserType = sessionStorage.getItem("LoginUserType");

  const [loading, setloading] = useState(false);
  const [AvailableForms, setAvailableForms] = useState();
  useEffect(() => {
    const AvailableFormsJsondataArray = JSON.parse(formJson);
    setAvailableForms(AvailableFormsJsondataArray);
  }, [formJson]);

  const [formValues, setFormValues] = useState([]);
 
  const handleInputChange = (fieldName, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  const canvasRefs = useRef({});
  const drawingRefs = useRef({});

  const startDrawing = ({ nativeEvent }, fieldName) => {
    const canvas = canvasRefs.current[fieldName];
    if (canvas) {
      const { offsetX, offsetY } = nativeEvent;
      const ctx = canvas.getContext('2d');
      ctx.beginPath();
      ctx.moveTo(offsetX, offsetY);
      drawingRefs.current[fieldName] = true;
    }
  };

  const draw = ({ nativeEvent }, fieldName) => {
    if (!drawingRefs.current[fieldName]) return;
    const canvas = canvasRefs.current[fieldName];
    if (canvas) {
      const { offsetX, offsetY } = nativeEvent;
      const ctx = canvas.getContext('2d');
      ctx.lineTo(offsetX, offsetY);
      ctx.stroke();
    }
  };

  const endDrawing = (fieldName) => {
    drawingRefs.current[fieldName] = false;
    const canvas = canvasRefs.current[fieldName];
    if (canvas) {
      const imageData = canvas.toDataURL();
      setFormValues((prevValues) => ({
        ...prevValues,
        [fieldName]: imageData,
      }));
    }
  };

  const getFieldComponent = (field) => {
    switch (field.type) {
      case "header":
        return (
          <h4 key={field.label}>
            {field.label && /<\/?[a-z][\s\S]*>/i.test(field.label) ? (
              <span
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{ __html: field.label }}
              />
            ) : (
              field.label
            )}
          </h4>
        );
      case "text":
        return (
          <label key={field.label} style={{ width: "100%" }}>
            {/* {field.label} */}
            {field.label && /<\/?[a-z][\s\S]*>/i.test(field.label) ? (
              <span
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{ __html: field.label }}
              />
            ) : (
              field.label
            )}
            <input
              type={field.subtype || "text"}
              className={field.className}
              name={field.name}
              required={field.required}
              value={formValues[field.name] || ""}
              onChange={(e) => handleInputChange(field.name, e.target.value)}
            />
          </label>
        );
      case "textarea":
        return (
          <label key={field.label} style={{ width: "100%" }}>
            {field.label && /<\/?[a-z][\s\S]*>/i.test(field.label) ? (
              <span
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{ __html: field.label }}
              />
            ) : (
              field.label
            )}
            <textarea
              className={field.className}
              name={field.name}
              required={field.required}
              // value={BaSicData[field.name] || ''}
              value={formValues[field.name] || ""}
              onChange={(e) => handleInputChange(field.name, e.target.value)}
            />
          </label>
        );

      case "checkbox-group":
        return (
          <label key={field.label} style={{ width: "100%" }}>
            {field.label && /<\/?[a-z][\s\S]*>/i.test(field.label) ? (
              <span
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{ __html: field.label }}
              />
            ) : (
              field.label
            )}
            <input
              type="checkbox"
              className={field.className}
              name={field.name}
              required={field.required}
              checked={formValues[field.name] || false}
              onChange={(e) => handleInputChange(field.name, e.target.checked)}
            />
          </label>
        );

      case "radio-group":
        return (
          <div key={field.label}>
            {field.label && /<\/?[a-z][\s\S]*>/i.test(field.label) ? (
              <span
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{ __html: field.label }}
              />
            ) : (
              field.label
            )}
            {field.values.map((option, index) => (
              <label key={index} style={{ width: "100%" }}>
                <input
                  type="radio"
                  name={field.name}
                  value={option.value}
                  // checked={option.selected}
                  checked={formValues[field.name] === option.value}
                  onChange={(e) =>
                    handleInputChange(field.name, e.target.value)
                  }
                />
                {option.label}
              </label>
            ))}
          </div>
        );
      case "paragraph":
        if (field.subtype === "p") {
          return (
            <div key={field.label}>
              {/* {field.label && (
                    <p style={{ width: "100%" }}>
                      {field.label}
                    </p>
                  )} */}

              {field.label && /<\/?[a-z][\s\S]*>/i.test(field.label) ? (
                <span
                  style={{ width: "100%" }}
                  dangerouslySetInnerHTML={{ __html: field.label }}
                />
              ) : (
                field.label
              )}
            </div>
          );
        }

      case "canvas":
        return (
          <div>
             <canvas
                ref={(el) => (canvasRefs.current[field.name] = el)}
                onMouseDown={(e) => startDrawing(e, field.name)}
                onMouseMove={(e) => draw(e, field.name)}
                onMouseUp={() => endDrawing(field.name)}
                onMouseOut={() => endDrawing(field.name)}
                width={300}
                height={100}
                style={{ border: "1px solid #000" }}
              />
              <b>{field.name}</b>
              <img
                src={formValues[field.name]}
                alt="Captured Signature"
                style={{ maxWidth: "100%", maxHeight: "300px" }}
              />
          </div>
        );



      case "date":
        return (
          <label key={field.label} style={{ width: "100%" }}>
            {field.label && (
              <p style={{ marginBottom: "5px" }}>
                {field.label.replace(/<br>/g, "")}
              </p>
            )}
            <input
              type={field.subtype || "text"}
              className={field.className || "form-control"}
              name={field.name}
              required={field.required || false}
              value={formValues[field.name] || ""}
              onChange={(e) => handleInputChange(field.name, e.target.value)}
            />
          </label>
        );
      default:
        return null;
    }
  };

  const getLabelFromAvailableForms = (name) => {
    const fieldInfo = AvailableForms[name];
    return fieldInfo ? fieldInfo.label : name;
  };

  const Patient_Access_Token = sessionStorage.getItem("Patient_Access_Token");
  const [PatientData, setPatientData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      
      try {
        const response = await ApiServices.get("/apis/default/portal/patient", {
          Authorization: `Bearer ${Patient_Access_Token}`,
        });

        const data = response.data.data;
        setPatientData(response.data.data);
        setFormValues({
          patient_name: data.fname + " " + data.lname || "",
          DOB: data.DOB || "",
          address: data.street || "",
          city: data.city || "",
          state: data.state || "",
          email: data.email || "",
          contact: data.phone_contact || "",
          zip: data.postal_code || "",
        });
      } catch (error) {
        console.error("Error fetching patient data:", error);
      }
    };

    if (Patient_Access_Token) {
      fetchData();
    }
  }, [Patient_Access_Token]);

  useEffect(() => {
    if (LoginUserType !== "Patient") {
      const fetchData = async () => {
        try {
          const patientResponse = await ApiServices.get(
            `/apis/default/api/patient/${paramsData.uuid}`,
            {
              Authorization: `Bearer ${accessToken}`,
            }
          );
  
          const data = patientResponse.data.data;
          setPatientData(data);
          // setFormValues({
          //   patient_name: data.fname + " " + data.lname || "",
          //   DOB: data.DOB || "",
          //   address: data.street || "",
          //   city: data.city || "",
          //   state: data.state || "",
          //   email: data.email || "",
          //   contact: data.phone_contact || "",
          //   zip: data.postal_code || "",
          // });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
    }
  }, [paramsData.uuid, LoginUserType]);
  

  const handleSubmit = async (e) => {
    setloading(true);
    e.preventDefault();
    try {
      // const allFormValues = { ...formValues, ...signatures };
      const formDataArray = Object.entries(formValues).map(([name, value]) => ({
        label: getLabelFromAvailableForms(name),
        name: name,
        value: value,
      }));

      const currentTimestampOld = new Date().toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });

      const currentTimestamp = currentTimestampOld.replace(
        /(\d+)\/(\d+)\/(\d+), (\d+):(\d+):(\d+)/,
        "$3-$1-$2 $4:$5:$6"
      );

      let payload;

      if (formName === "medical_history_form") {
        payload = {
          medical_history_intake: formDataArray,
          is_medical_history_intake: 1,
          timestamp_medical_history: currentTimestamp,
        };
      } else if (formName === "hippa_cosmos_form") {
        payload = {
          hippa_form: formDataArray,
          is_hippa_form: 1,
          timestamp_hippa_form: currentTimestamp,
        };
      } else if (formName === "financial_disclosure_form") {
        payload = {
          financial_disclosure: formDataArray,
          is_financial_disclosure: 1,
          timestamp_financial_policy: currentTimestamp,
        };
      } else {
        console.error("Invalid formName:", formName);
        return;
      }

      let endpoint = `/apis/default/api/intakeForms/${paramsData.pid}`;

      if (LoginUserType == "Patient") {
        endpoint = `/apis/default/portal/intakeForms/${LoginPatientID}`;
      }
      const response = await ApiServices.put(
        endpoint,
        // `/apis/default/portal/intakeForms/${LoginPatientID}`,
        // `/apis/default/portal/intakeForms/${LoginPatientID}`,
        payload,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      swal({
        title: `${formTitle} Form has been Filled successfully`,
        icon: "success",
        button: "OK",
        timer: 2000,
      });
      setloading(false);
      onChildSubmit(true);
      console.log("Form data saved:", response.data);
    } catch (error) {
      console.error("Error submitting form data:", error);
      setloading(false);
    }
  };

  const [FilledformJsonData, setFilledformJsonData] = useState(null);
  const [FilledformJsonDataStatus, setFilledformJsonDataStatus] =
    useState(null);
  const [FilledArrayForm, setFilledArrayForm] = useState();

  useEffect(() => {
    if (formName == "medical_history_form") {
      setFilledformJsonData(FilledIntakeFormsJsonData.medical_history_intake);
      setFilledformJsonDataStatus(
        FilledIntakeFormsJsonData.is_medical_history_intake
      );
      const dataArrayEmpty = JSON.parse(
        FilledIntakeFormsJsonData.medical_history_intake
      );
      setFilledArrayForm(dataArrayEmpty);
    } else if (formName == "hippa_cosmos_form") {
      setFilledformJsonData(FilledIntakeFormsJsonData.hippa_form);
      setFilledformJsonDataStatus(FilledIntakeFormsJsonData.is_hippa_form);
      const dataArrayEmpty = JSON.parse(FilledIntakeFormsJsonData.hippa_form);
      setFilledArrayForm(dataArrayEmpty);
    } else if (formName == "financial_disclosure_form") {
      setFilledformJsonData(FilledIntakeFormsJsonData.financial_disclosure);
      setFilledformJsonDataStatus(
        FilledIntakeFormsJsonData.is_financial_disclosure
      );
      const dataArrayEmpty = JSON.parse(
        FilledIntakeFormsJsonData.financial_disclosure
      );
      setFilledArrayForm(dataArrayEmpty);
    }
  }, [formName, FilledIntakeFormsJsonData]);

  return (
    <div>
      {loading && <Loader />}

      {PatientData && PatientData.is_portal_sent == 1 ? (
        <div>
          {FilledformJsonDataStatus == 0 ? (
            <div>
              <form>
                {AvailableForms &&
                  Object.entries(AvailableForms).map(([key, field], index) => (
                    <div key={index} className="mb-3">
                      {getFieldComponent(field)}
                    </div>
                  ))}
              </form>
              <Button onClick={handleSubmit}>Submit</Button>
            </div>
          ) : (
            <div>
              <FilledIntakeForm
                FilledArrayForm={FilledArrayForm}
                AvailableForms={AvailableForms}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="mt-5">
          <h3>No Intake Forms Assign to you Yet</h3>
        </div>
      )}
    </div>
  );
};

export default IntakeUnfilledForm;
