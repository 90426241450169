import React from "react";
import MedSpaSectionOne from "../../Components/MedSpaSectionOne/MedSpaSectionOne";
import MedSpaTreatments from "../../Components/MedSpaTreatments/MedSpaTreatments";
import MedSpaSectionTwo from "../../Components/MedSpaSectionTwo/MedSpaSectionTwo";
import MedSpaSectionThree from "../../Components/MedSpaSectionThree/MedSpaSectionThree";
import MedSpaVisitUs from "../../Components/MedSpaVisitUs/MedSpaVisitUs";
import MedSpaFeedback from "../../Components/MedSpaFeedback/MedSpaFeedback";
import ArticlesCarousal from "../../Components/Articles/ArticlesCarousal/ArticlesCarousal";
import ProductCarousal from "../../Components/ProductCarousal/ProductCarousal";
import { Fade } from "react-reveal";
import WorkingHours from "../../Components/WorkingHours/WorkingHours";
import AppointmentCalendar from "../../Components/PatientAppointments/AppointmentCalendar";
import { Col, Row } from "react-bootstrap";

const   MedSpaHomePage = () => {
  return (
    <div>
      <div style={{ paddingTop: "22px" }}>
        {
          <Fade bottom>
            <MedSpaSectionOne />
          </Fade>
        }
      </div>
      <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
            <MedSpaTreatments />
          </Fade>
        }
      </div>
      {/* <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
                <WorkingHours />
             </Fade>
        }
      </div> */}
      <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
            <MedSpaSectionTwo />
          </Fade>
        }
      </div>
      <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
            <ProductCarousal />
          </Fade>
        }
      </div>
      {/* <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
            <ArticlesCarousal />
          </Fade>
        }
      </div> */}
      {/* <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
            <MedSpaFeedback />
          </Fade>
        }
      </div> */}
      <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
            <MedSpaSectionThree />
          </Fade>
        }
      </div>
      {/* <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
            <WorkingHours />
          </Fade>
        }
      </div> */}
      <div style={{ paddingTop: "80px", paddingBottom: "80px" }}>
        {
          <Fade bottom>
            <MedSpaVisitUs />
          </Fade>
        }
      </div>
    </div>
  );
};

export default MedSpaHomePage;
