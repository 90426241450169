import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import AdministratorCalendar from "./AdministratorCalendar";
import AdministratorFullCalendar from "./AdministratorFullCalendar";
import "./AdministratorViewDashboard.css"

const AdministratorViewDashboard = () => {
  return (
    <div style={{backgroundColor:"#F1F5F8"}}>
      <Row>
        <Col sm={4}>
          <Card className="margin-bottom-card">
            <Card.Body>
              <AdministratorCalendar />
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
                <div>
                    <select className="admin-page-dropdown">
                        <option>Select Provider</option>
                    </select>
                    <p className="admin-page-dropdown-para-tag">Selected Provider Name</p>
                </div>

                <div>
                    <select className="admin-page-dropdown">
                        <option>Facility selection</option>
                    </select>
                    <p className="admin-page-dropdown-para-tag" style={{marginBottom:"0px"}}>Selected Facility Name</p>
                </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={8}>
          <AdministratorFullCalendar/>
        </Col>
      </Row>
    </div>
  );
};

export default AdministratorViewDashboard;
