import React from "react";

const FilledIntakeForm = ({ AvailableForms, FilledArrayForm }) => {
  console.log("FilledArrayForm",FilledArrayForm)
  return (
    <div>
      {AvailableForms &&
        AvailableForms.map((emptyField) => (
          <div>
            <p key={emptyField.name}>
              <b>
                {" "}
                {emptyField.label &&
                /<\/?[a-z][\s\S]*>/i.test(emptyField.label) ? (
                  <span
                    style={{ width: "100%" }}
                    dangerouslySetInnerHTML={{ __html: emptyField.label }}
                  />
                ) : (
                  emptyField.label
                )}
              </b>
            </p>
            {/* <i className="form-control">{getFilledValue(emptyField.name)}</i> */}
            {(emptyField.type !== 'paragraph' && emptyField.type !== 'header') && (
              <i className="form-control">{getFilledValue(emptyField.name)}</i>
            )}
          </div>
        ))}
    </div>
  );

  function getFilledValue(fieldName) {
    const filledField = FilledArrayForm.find(
      (filled) => filled.name === fieldName
    );
      // console.log("filledField",filledField)
    if (filledField) {
      if (
        fieldName === "patient_signature" ||
        fieldName === "witness_signature" ||
        fieldName === "practitioner_signature" ||
        fieldName === "representative_signature" ||
        fieldName === "injector_signature"
      ) {
        return <img src={filledField.value} alt="Signature" />;
      } 
      else if (filledField.value === true) {
        return "Yes";
      } else if (filledField.value === false) {
        return "No";
      } else {
        return filledField.value;
      }
    } else {
      return "NULL";
    }
  }
};

export default FilledIntakeForm;


