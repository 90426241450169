import React, { useEffect, useState } from "react";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
const ProductBillingList = () => {
  const [billingData, setBillingData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const accessToken = sessionStorage.getItem("accessToken");

  useEffect(() => {
    const fetchProductBillings = async () => {
      try {
        const billingResponse = await ApiServices.get(
          "/apis/default/api/productbilling",
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );

        // Grouping data by bill_id
        const groupedBillingData = groupByBillId(billingResponse.data);
        const billingArray = Object.values(groupedBillingData);
        setBillingData(billingArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProductBillings();
  }, []);

 console.log("billingData",billingData)
  const groupByBillId = (data) => {
    const groupedData = {};
    data.forEach((item) => {
      const billId = item.bill_id;
      if (!groupedData[billId]) {
        groupedData[billId] = {
          bill_id: billId,
          date_of_billing: item.date_of_billing,
          email: item.email,
          fname: item.fname,
          contact: item.contact,
          lname: item.lname,
          tip: item.tip,
          is_billed: item.is_billed,
          total_amount: item.total_amount,
          pid: item.pid,
        };
      }
    });
    return groupedData;
  };

  const navigate = useNavigate();
  const handleCellClick = (params) => {
    // if (params.colDef.field === "bill_id") {
      const billid = params.data.bill_id;
      navigate(`/admin/view-generated-ptoduct-bill/${billid}/`);
    // }
  };
  const handleGenerateBillClick = (params) => {
    
    const billid = params.bill_id;
      navigate(`/admin/view-generated-ptoduct-bill/${billid}/`);
  };
  const getName = (params) => {
    const patient = params.data;
    return `${patient.fname}  ${patient.lname}`;
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredBillingData = billingData.filter((data) =>
  data &&
  data.fname &&
  data.lname &&
  data.email &&
  data.bill_id &&
  (data.fname.toLowerCase().includes(searchQuery.toLowerCase()) ||
    data.lname.toLowerCase().includes(searchQuery.toLowerCase()) ||
    data.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    data.bill_id.toString().includes(searchQuery.toLowerCase()))
);


  const columnDefs = [
    {
      headerName: "Bill ID",
      field: "bill_id",
      headerClass: "custom-header-of-tables",
      minWidth: 50,
      flex: 1,
      onCellClicked: handleCellClick,
    },
    {
      headerName: "Customer Name",
      field: "name",
      valueGetter: getName,
      headerClass: "custom-header-of-tables",
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Email",
      field: "email",
      headerClass: "custom-header-of-tables",
      minWidth: 240,
      flex: 1,
    },
    {
      headerName: "Contact Number",
      field: "contact",
      headerClass: "custom-header-of-tables",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Date of Billing",
      field: "date_of_billing",
      headerClass: "custom-header-of-tables",
      minWidth: 100,
      flex: 1,
    },

    {
      headerName: "Bill Amount",
      field: "total_amount",
      headerClass: "custom-header-of-tables",
      minWidth: 80,
      flex: 1,
    },
    {
      headerName: "Tip Received",
      field: "tip",
      headerClass: "custom-header-of-tables",
      minWidth: 80,
      flex: 1,
    },
    {
      headerName: "Payment Status",
      field: "is_billed",
      headerClass: "custom-header-of-tables",
      minWidth: 80,
      flex: 1,
      cellRenderer: (params) => {
        const { data } = params;
        return (
          <div>
            {
              <Button
                variant={params.data.is_billed == 1 ? "success" : "info"}
                size="sm"
                style={{ width: "100%" }}
                onClick={() => handleGenerateBillClick(data)}
              >
                {params.data.is_billed == 1 ? "Paid" : "Unpaid"}
              </Button>
            }
          </div>
        );
      },
    },
    {
      headerName: "Client Type",
      field: "pid",
      headerClass: "custom-header-of-tables",
      minWidth: 80,
      flex: 1,
      cellRenderer: (params) => {
        const { data } = params;
        return (
          <div>
            {
              <Button
                variant={params.data.pid == 1 ? "warning" : "secondary"}
                size="sm"
                style={{ width: "100%" }}
                // onClick={() => handleGenerateBillClick(data)}
              >
                {params.data.pid == 1 ? "Registered" : "Non Registered"}
              </Button>
            }
          </div>
        );
      },
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  return (
    <div>
    <div className="mb-2" style={{ display: "flex", gap: "10px", alignItems: "center" }}>
     <input
          type="text"
          placeholder="Search by name, email, or bill id"
          value={searchQuery}
          onChange={handleSearch}
          className="form-control mb-2"
        />
    <Link to="/admin/generate-ptoduct-bill">
    <button   className="login-form-login-button"
                style={{
                  marginTop: "0px",
                  marginBottom: "0.5rem",
                  width: "150px",
                }}>Create New Bill</button>
    </Link>
    </div>
      <div
        className="ag-theme-alpine"
        style={{
          height: "85vh",
          minWidth: "100%",
          // overflow: "hidden"
        }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={filteredBillingData}
          defaultColDef={defaultColDef}
        />
      </div>
    </div>
  );
};

export default ProductBillingList;
