import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import Loader from "../Loader/Loader";
import { Button, Col, Row } from "react-bootstrap";

const CaptureImages = () => {
  const paramsData = useParams();
  const [loading, setloading] = useState(false);
  const [base64Images, setBase64Images] = useState({
    pretreatment_front: "",
    pretreatment_left: "",
    pretreatment_right: "",
  });
  const [stream, setStream] = useState(null);
  const [isFrontCamera, setIsFrontCamera] = useState(false);
  const accessToken = sessionStorage.getItem("accessToken");
  const handleOpenCamera = async () => {
    try {
      const userMediaStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: isFrontCamera ? "user" : "environment" },
        // video: true,
      });
      setStream(userMediaStream);

      const video = document.getElementById("camera");
      video.srcObject = userMediaStream;
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const handleCloseCamera = () => {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      setStream(null);
    }
  };

  const handleCaptureImage = (imageType) => {
    try {
      const video = document.getElementById("camera");
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const capturedBase64 = canvas.toDataURL("image/jpeg");

      setBase64Images((prevImages) => ({
        ...prevImages,
        [imageType]: capturedBase64,
      }));
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };

  const toggleCameraSource = () => {
    console.log("Toggling camera source");
    handleCloseCamera()
    setIsFrontCamera((prevState) => !prevState);
    handleOpenCamera();
  };
  
  

  const handleUpload = async () => {
    setloading(true);
    try {
      const protocol = window.location.protocol;
      const baseUrl = `https://medispacosmos.com/riteclinic-openemr/apis/default/api/saveServiceImage`;

      for (const [imageType, base64Image] of Object.entries(base64Images)) {
        if (base64Image) {
          const data = {
            pid: paramsData.pid,
            eid: paramsData.eid,
            service_id: paramsData.serviceId,
            treatment_image: base64Image,
            image_type: imageType,
          };

          const headers = {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };

          const response = await axios.post(baseUrl, data, { headers });
          console.log(`${imageType} upload response:`, response.data);
        }
        // handleCloseCamera()

        const fetchData = async () => {
          try {
            const CapturedImagesDataResponse = await ApiServices.get(
              `/apis/default/api/getServiceImages/${paramsData.serviceId}/appointment/${paramsData.eid}`,
              {
                Authorization: `Bearer ${accessToken}`,
              }
            );
            const filteredImagesData = CapturedImagesDataResponse.data.filter(
              (item) =>
                item.image_type === "pretreatment_front" ||
                item.image_type === "pretreatment_left" ||
                item.image_type === "pretreatment_right"
            );

            const modifiedImagesData = filteredImagesData.map((index) => ({
              ...index,
              image_url: index.image_url.replace(
                "/var/www/html/",
                "https://medispacosmos.com/"
              ),
            }));

            setCapturedImagesData(modifiedImagesData);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
        setloading(false);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setloading(false);
    }
  };

  const [CapturedImagesData, setCapturedImagesData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const CapturedImagesDataResponse = await ApiServices.get(
          `/apis/default/api/getServiceImages/${paramsData.serviceId}/appointment/${paramsData.eid}`,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );

        const filteredImagesData = CapturedImagesDataResponse.data.filter(
          (item) =>
            item.image_type === "pretreatment_front" ||
            item.image_type === "pretreatment_left" ||
            item.image_type === "pretreatment_right"
        );

        const modifiedImagesData = filteredImagesData.map((index) => ({
          ...index,
          image_url: index.image_url.replace(
            "/var/www/html/",
            "https://medispacosmos.com/"
          ),
        }));

        setCapturedImagesData(modifiedImagesData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [paramsData]);


  const toggleCameraOpenClose = () => {
    if (stream) {
      // If stream is present, close the camera
      handleCloseCamera();
    } else {
      // If stream is not present, open the camera
      handleOpenCamera();
    }
  };
  

  // useEffect(() => {
  //   CapturedImagesData && CapturedImagesData.length === 0
  //     ? handleOpenCamera()
  //     : handleCloseCamera();
  // }, [CapturedImagesData]);

  return (
    <div>
      {loading && <Loader />}
      <div>
        <h3>Pre Treatment Images</h3>
        {CapturedImagesData && CapturedImagesData.length == 0 && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <video
              id="camera"
              autoPlay
              playsInline
              style={{ maxWidth: "100%", maxHeight: "600px" }}
            ></video>
          </div>
        )}
        <div style={{display:"flex",justifyContent:"space-between"}} className="mt-3">

        <Button onClick={toggleCameraSource}>Switch Camera</Button>
        <Button variant="secondary" onClick={toggleCameraOpenClose}>{stream ? "Close Camera" : "Open Camera"}</Button>
        </div>

        {CapturedImagesData && CapturedImagesData.length == 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap:"10px"
            }}
            className="mt-3"
          >
            {/* <button onClick={handleCloseCamera}>Close Camera</button> */}

            <Button onClick={() => handleCaptureImage("pretreatment_left")}>
              Capture Left Image
            </Button>
            <Button onClick={() => handleCaptureImage("pretreatment_front")}>
              Capture Front Image
            </Button>
            <Button onClick={() => handleCaptureImage("pretreatment_right")}>
              Capture Right Image
            </Button>
          </div>
        )}

        {CapturedImagesData && CapturedImagesData.length == 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {base64Images.pretreatment_left && (
              <div>
                <img
                  src={base64Images.pretreatment_left}
                  alt="Captured Left Image"
                  style={{ maxWidth: "100%", maxHeight: "300px" }}
                />
              </div>
            )}

            {base64Images.pretreatment_front && (
              <div>
                <img
                  src={base64Images.pretreatment_front}
                  alt="Captured Front Image"
                  style={{ maxWidth: "100%", maxHeight: "300px" }}
                />
              </div>
            )}

            {base64Images.pretreatment_right && (
              <div>
                <img
                  src={base64Images.pretreatment_right}
                  alt="Captured Right Image"
                  style={{ maxWidth: "100%", maxHeight: "300px" }}
                />
              </div>
            )}
          </div>
        )}

        {CapturedImagesData && CapturedImagesData.length == 0 && (
          <div
            className="mt-3"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button variant="success" onClick={handleUpload}>
              Upload Images
            </Button>
          </div>
        )}
      </div>
      <div>
        <Row className="mt-3">
          {CapturedImagesData &&
            CapturedImagesData?.map((index) => (
              <Col sm={4}>
                <img
                  src={index.image_url}
                  style={{ height: "500px", width: "100%" }}
                  alt={index.image_type}
                />
                <p>{index.image_type}</p>
              </Col>
            ))}
        </Row>
      </div>
    </div>
  );
};

export default CaptureImages;
