import React, { useEffect, useState } from "react";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import swal from "sweetalert";
import GenerateBill from "../../Components/GenerateBill/GenerateBill";
import ServiceBill from "../../Components/ServiceBill/ServiceBill";
import ServiceNotes from "../../Components/ServiceNotes/ServiceNotes";
import Loader from "../../Components/Loader/Loader";
import { FaEdit } from "react-icons/fa";
import ServiceBillNew from "../../Components/ServiceBill/ServiceBillNew";
import GenerateBillNew from "../../Components/GenerateBill/UpdateGenerateBill";
import PatientAllSoapNotes from "../../Components/PatientAllSoapNotes/PatientAllSoapNotes";

const ProviderPatientView = () => {
  const paramsData = useParams();
  const accessToken = sessionStorage.getItem("accessToken");
  const LoginUserID = sessionStorage.getItem("LoginUserID");
  const [loading, setloading] = useState(false);
  // FETCH PATIENT DATA
  const [patientData, setpatientData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const patientResponse = await ApiServices.get(
          `/apis/default/api/patient/${paramsData.uuid}`,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );

        const patientdata = patientResponse.data.data;
        setpatientData(patientdata);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [paramsData, loading]);
  // console.log("paramsData",paramsData)
  const [apiTrigger, setApiTrigger] = useState(false);

  const handleChildSubmit = (success) => {
    if (success) {
      setApiTrigger(!apiTrigger);
    }
  };

  const [AppointmentData, setAppointmentData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const AppointmentDataResponse = await ApiServices.get(
          `/apis/default/api/appointment/${paramsData.eid}`,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );
        const AppointmentData = AppointmentDataResponse.data["0"];
        setAppointmentData(AppointmentData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [paramsData, apiTrigger]);

  const [PreSendedForms, setPreSendedForms] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const PreSendedFormsResponse = await ApiServices.get(
          `/apis/default/api/getFormsPatient/${paramsData.pid}/appointment/${paramsData.eid}`,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );
        const PreSendedFormsData = PreSendedFormsResponse.data;
        setPreSendedForms(PreSendedFormsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [paramsData]);

  // GET ALL FORMS
  const [SevicesData, setSevicesData] = useState();
  const [filteredServices, setFilteredServices] = useState();
  const [FilledIntakeFormsJsonData, setFilledIntakeFormsJsonData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const AppointmentDataResponse = await ApiServices.get(
          `/apis/default/api/masterServices`,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );
        setSevicesData(AppointmentDataResponse.data);
        setFilteredServices(AppointmentDataResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();

    const fetchFilledIntakeJsonData = async () => {
      try {
        let endpoint = `/apis/default/api/intakeForms/${paramsData.pid}`;

        const FilledIntakeFormsJsonDataResponse = await ApiServices.get(
          endpoint,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );

        setFilledIntakeFormsJsonData(
          FilledIntakeFormsJsonDataResponse.data["0"]
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchFilledIntakeJsonData()
  }, [paramsData]);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    const filtered = SevicesData.filter((service) =>
      service.service.toLowerCase().includes(query)
    );
    setFilteredServices(filtered);
    setSearchQuery(query);
  };

  const handleSend = async (mainService) => {
    setloading(true);
    try {
      const postData = {
        pid: paramsData.pid,
        eid: paramsData.eid,
        form_id: mainService.service_no,
      };
      //console.log(postData);
      const response = await ApiServices.post(
        "/apis/default/api/sendFormsPatient",
        postData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      console.log(response.data);
      swal({
        title: `${mainService.service} Form has been sent to the patient portal successfully`,
        icon: "success",
        button: "OK",
        timer: 2000,
      });
      const fetchData = async () => {
        try {
          const PreSendedFormsResponse = await ApiServices.get(
            `/apis/default/api/getFormsPatient/${paramsData.pid}/appointment/${paramsData.eid}`,
            {
              Authorization: `Bearer ${accessToken}`,
            }
          );
          const PreSendedFormsData = PreSendedFormsResponse.data;
          setPreSendedForms(PreSendedFormsData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error("Error sending form data:", error);
    }
  };

  const navigate = useNavigate();
  const handleOpen = (mainService) => {
    navigate(
      `/admin/service-form/${paramsData.eid}/${paramsData.pid}/${mainService}/${paramsData.uuid}`
    );
    // navigate(`/service-form/:eid/:pid/:formname/:uuid`)
    console.log(`Opening ${mainService}`);
  };

  const [formsScreen, setformsScreen] = useState(true);
  const [billingScreen, setbillingScreen] = useState(false);
  const [serviceNoteScreen, setserviceNoteScreen] = useState(false);
  const [soapNoteScreen, setsoapNoteScreen] = useState(false);
  const handleformsScreen = () => {
    setformsScreen(true);
    setbillingScreen(false);
    setserviceNoteScreen(false);
    setsoapNoteScreen(false);
  };
  const handlebillingScreen = () => {
    setformsScreen(false);
    setbillingScreen(true);
    setserviceNoteScreen(false);
    setsoapNoteScreen(false)
  };
  const handleserviceNoteScreen = () => {
    setformsScreen(false);
    setbillingScreen(false);
    setserviceNoteScreen(true);
    setsoapNoteScreen(false)
  };

  const handlesoapNoteScreen = () => {
    setformsScreen(false);
    setbillingScreen(false);
    setserviceNoteScreen(false);
    setsoapNoteScreen(true)
  };

  const handleNoteSubmit = async (
    serviceId,
    soapNote,
    noteValue,
    setNoteValue
  ) => {
    setloading(true);
    try {
      const currentTimestamp = new Date().toLocaleString();
      const updatedSoapNote = {
        ...soapNote,
        Timestamp: currentTimestamp,
        Note: noteValue,
        ProviderID: LoginUserID,
      };
      const postData = {
        service_id: serviceId,
        eid: paramsData.eid,
        pid: paramsData.pid,
        soap_note: [updatedSoapNote],
      };

      const response = await ApiServices.post(
        "/apis/default/api/soapnote",
        postData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );

      console.log(response.data);
      // setNoteValue("");
      const fetchData = async () => {
        try {
          const SevicesFilledDataResponse = await ApiServices.get(
            `/apis/default/api/soapnote/${paramsData.pid}/appointment/${paramsData.eid}`,
            {
              Authorization: `Bearer ${accessToken}`,
            }
          );
          setSevicesFilledData(SevicesFilledDataResponse.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();

      ApiServices.put(
        "/apis/default/api/savePatientServiceForm",
        {
          form_id: serviceId,
          eid: paramsData.eid,
          pid: paramsData.pid,
          show_post_treatment: 1,
          //   form_data: formDataArray,
        },
        {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        }
      );

      setloading(false);
      // Add logic to handle success or any other actions after submission
    } catch (error) {
      console.error("Error submitting soap note:", error);
      setloading(false);
    }
  };

  const handleDeleteForm = async (mainService) => {
    // console.log("mainService",mainService)
    setloading(true);
    try {
      
      const response = await ApiServices.delete(
        `/apis/default/api/patient/${paramsData.pid}/appointment/${paramsData.eid}/sendFormsPatient/${mainService.service_no}`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
     
      swal({
        title: `${mainService.service} Form has been Deleted successfully`,
        icon: "success",
        button: "OK",
        timer: 2000,
      });
      const fetchData = async () => {
        try {
          const PreSendedFormsResponse = await ApiServices.get(
            `/apis/default/api/getFormsPatient/${paramsData.pid}/appointment/${paramsData.eid}`,
            {
              Authorization: `Bearer ${accessToken}`,
            }
          );
          const PreSendedFormsData = PreSendedFormsResponse.data;
          setPreSendedForms(PreSendedFormsData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error("Error sending form data:", error);
      swal({
        title: `${mainService.service} Form Deletion has Error, We are Working on This Part`,
        icon: "error",
        button: "OK",
        timer: 2000,
      });
    }
  };


  const [SevicesFilledData, setSevicesFilledData] = useState();
  // console.log("SevicesFilledData", SevicesFilledData);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const SevicesFilledDataResponse = await ApiServices.get(
          `/apis/default/api/soapnote/${paramsData.pid}/appointment/${paramsData.eid}`,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );
        setSevicesFilledData(SevicesFilledDataResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [paramsData]);

  const OpenCaptureImage = (serviceId) => {
    navigate(
      `/admin/capture-image/${paramsData.uuid}/${paramsData.eid}/${paramsData.pid}/service/${serviceId}`
    );
  };
  const OpenCaptureImagePostTreat = (serviceId) => {
    navigate(
      `/admin/capture-image-post-treat/${paramsData.uuid}/${paramsData.eid}/${paramsData.pid}/service/${serviceId}`
    );
  };

  const [editedNote, setEditedNote] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [NewserviceId, setNewserviceId] = useState();
  const [NewserviceIndex, setNewserviceIndex] = useState();
  const handleEditButtonClick = (note, serviceId, index) => {
    console.log("index", index + 1);
    setNewserviceIndex(index + 1);
    setNewserviceId(serviceId);
    setEditedNote(note.Note);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleModalSubmit = async () => {
    try {
      const updatedNote = {
        ID: NewserviceIndex,
        Note: editedNote,
        Timestamp: new Date().toLocaleString(),
        ProviderID: LoginUserID,
      };

      const putData = {
        service_id: NewserviceId,
        eid: paramsData.eid,
        pid: paramsData.pid,
        soap_note: [updatedNote],
      };

      // Send a PUT request to update the note
      await ApiServices.put("/apis/default/api/soapnote", putData, {
        Authorization: `Bearer ${accessToken}`,
      });
      const fetchData = async () => {
        try {
          const SevicesFilledDataResponse = await ApiServices.get(
            `/apis/default/api/soapnote/${paramsData.pid}/appointment/${paramsData.eid}`,
            {
              Authorization: `Bearer ${accessToken}`,
            }
          );
          setSevicesFilledData(SevicesFilledDataResponse.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
      // Close the modal after a successful update
      handleCloseModal();
    } catch (error) {
      console.error("Error updating soap note:", error);
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          position: "fixed",
          top: 60,
          left: 0,
          right: 0,
          background: "rgb(241, 245, 248)",
          zIndex: 1,
          padding: "10px",
          maxWidth: "100%",
        }}
      >
        <Card>
          <Card.Body>
            <div style={{ fontSize: "12px" }}>
              <Row>
                <Col sm={3}>
                  <b>Patient Name : </b> {patientData && patientData.fname}{" "}
                  {patientData && patientData.lname}
                </Col>
                <Col sm={3}>
                  <b>Gender : </b> {patientData && patientData.sex}
                </Col>
                <Col sm={3}>
                  <b>Date of Birth : </b> {patientData && patientData.DOB}
                </Col>
                <Col sm={3}>
                  <b>Contact Number : </b>{" "}
                  {patientData && patientData.phone_contact}
                </Col>
                <Col sm={3}>
                  <b>Email ID : </b> {patientData && patientData.email}
                </Col>
                <Col sm={6}>
                  <b>Address : </b> {patientData && patientData.street}{" "}
                  {patientData && patientData.city}{" "}
                  {patientData && patientData.state}{" "}
                  {patientData && patientData.country_code}
                </Col>
                <Col sm={3}>
                  <b>Appointment Type : </b>{" "}
                  {AppointmentData && AppointmentData.appointment_type}
                </Col>
                <Col sm={6}>
                  <b style={{ color: "red" }}>Note : </b>{" "}
                  {AppointmentData && AppointmentData.appointment_note}
                </Col>
                <Col sm={6}>
                  <div style={{display:"flex",justifyContent:"space-between",flexWrap:"wrap"}}>
                  <Link to={`/admin/intake-forms/${paramsData.pid}/${paramsData.uuid}`}>
                  <b style={{color : FilledIntakeFormsJsonData&&FilledIntakeFormsJsonData?.is_medical_history_intake == 0 ? "red" : "green"}}>Medical History</b>
                  </Link>
                  <Link to={`/admin/intake-forms/${paramsData.pid}/${paramsData.uuid}`}>

                  <b style={{color : FilledIntakeFormsJsonData&FilledIntakeFormsJsonData?.is_hippa_form == 0 ? "red" : "green"}}>Hippa Form</b>
                  </Link>
                  <Link to={`/admin/intake-forms/${paramsData.pid}/${paramsData.uuid}`}>

                  <b style={{color : FilledIntakeFormsJsonData&FilledIntakeFormsJsonData?.is_financial_disclosure == 0 ? "red" : "green"}}>Financial Disclosure Form</b>
                  </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>
      </div>

      <div className="mb-3" style={{ marginTop: "80px" }}>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <button
            onClick={() => handleformsScreen("UpcomingAppoint")}
            className={
              formsScreen
                ? "appointment-type-btn-active"
                : "appointment-type-btn-inactive"
            }
          >
            Encounter Data
          </button>
      
          <button
            onClick={() => handlebillingScreen("ApprovedAppoint")}
            className={
              billingScreen
                ? "appointment-type-btn-active"
                : "appointment-type-btn-inactive"
            }
          >
            Billing
          </button>
          <button
            onClick={() => handlesoapNoteScreen("SOAP Note")}
            className={
              soapNoteScreen
                ? "appointment-type-btn-active"
                : "appointment-type-btn-inactive"
            }
          >
            SOAP Notes
          </button>
        </div>
      </div>

      {formsScreen && (
        <Row>
          <Col sm={12}>
            <div>
              <div>
                {/* <h1>Approved Services</h1> */}
                <table className="billing-table">
                  <thead>
                    <tr>
                      <th style={{ maxWidth: "80px" }}>Service Number</th>
                      <th>Service Name</th>
                      <th style={{ maxWidth: "50px" }}>Service Form</th>
                      <th>Existing SOAP Note</th>
                      <th>Add SOAP Note</th>
                      {/* <th>Action</th> */}
                      <th style={{ maxWidth: "120px" }}>
                        Pre Treatment Images
                      </th>
                      <th style={{ maxWidth: "120px" }}>
                        Post Treatment Images
                      </th>
                      <th style={{ maxWidth: "80px" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {PreSendedForms &&
                      PreSendedForms.map((item, index) => {
                        const existingSoapNote =
                          SevicesFilledData &&
                          SevicesFilledData?.find(
                            (data) => data.service_id === item.service_no
                          );
                        {
                          /* console.log("existingSoapNote",existingSoapNote) */
                        }
                        const existingSoapNoteNew =
                          existingSoapNote &&
                          existingSoapNote?.soap_note.soap_note;
                        const isSoapNoteAdded =
                          existingSoapNoteNew &&
                          Object.values(existingSoapNoteNew).length > 0;
                        return (
                          <tr key={index}>
                            <td style={{ maxWidth: "50px" }}>
                              {item.service_no}
                            </td>
                            <td>
                              <h5>{item.service}</h5>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Button
                                variant="warning"
                                style={{ width: "70px" }}
                                onClick={() => handleOpen(item.service_no)}
                              >
                                Open
                              </Button>
                            </td>
                            <td>
                              {existingSoapNoteNew
                                ? Object.values(existingSoapNoteNew).map(
                                    (note, index) => (
                                      <p key={index}>
                                        {note.Note} - {note.Timestamp} -{" "}
                                        {note.ProviderID}{" "}
                                        {LoginUserID == note.ProviderID && (
                                          <>
                                            {/* <FaEdit style={{color:"blue",cursor:"pointer"}} /> */}
                                            <Button
                                              onClick={() =>
                                                handleEditButtonClick(
                                                  note,
                                                  item.service_no,
                                                  index
                                                )
                                              }
                                            >
                                              Edit
                                            </Button>
                                          </>
                                        )}
                                      </p>
                                    )
                                  )
                                : ""}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <textarea
                                style={{ width: "100%", minHeight: "200px" }}
                                onChange={(e) =>
                                  (item.noteValue = e.target.value)
                                }
                              />
                              <Button
                                variant="success"
                                onClick={() =>
                                  handleNoteSubmit(
                                    item.service_no,
                                    {
                                      Note: "Test Note",
                                      Timestamp: "24/02/2024 12.15",
                                      ProviderID: "5",
                                    },
                                    item.noteValue
                                  )
                                }
                                disabled={isSoapNoteAdded}
                              >
                                Add
                              </Button>
                            </td>

                            <td style={{ textAlign: "center" }}>
                              <Button
                                onClick={() =>
                                  OpenCaptureImage(item.service_no)
                                }
                              >
                                Capture
                              </Button>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Button
                                onClick={() =>
                                  OpenCaptureImagePostTreat(item.service_no)
                                }
                              >
                                Capture
                              </Button>
                            </td>

                            <td style={{ textAlign: "center",maxWidth:"60px" }}>
                              <Button
                                onClick={() =>
                                  handleDeleteForm(item)
                                }
                                variant="danger"
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>

              <div>
                <Card className="mb-3 mt-3">
                  <Card.Body>
                    <div>
                      <input
                        type="text"
                        value={searchQuery}
                        onChange={handleSearch}
                        placeholder="Search by Service Name"
                        style={{
                          width: "100%",
                          padding: "10px",
                          marginBottom: "10px",
                        }}
                      />
                    </div>

                    <div>
                      <table className="billing-table">
                        <thead>
                          <tr>
                            <th>Service Number</th>
                            <th>Service Name</th>
                            <th>Send</th>
                            {/* <th>Open</th>
                            <th>SOAP Note</th>
                            <th>Action</th>
                            <th>Pre Treatment Images</th>
                            <th>Post Treatment Images</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {filteredServices &&
                            Array.from(
                              new Set(
                                filteredServices.map(
                                  (service) => service.service
                                )
                              )
                            ).map((uniqueService, index) => {
                              if (
                                uniqueService.toLowerCase() == "miscellaneous"
                              ) {
                                return null;
                              }
                              const uniqueServiceData = filteredServices.find(
                                (service) => service.service === uniqueService
                              );
                              const isServiceAlreadySent =
                                PreSendedForms &&
                                PreSendedForms.some(
                                  (item) =>
                                    item.service_no ==
                                    uniqueServiceData.service_no
                                );

                              const existingSoapNote =
                                SevicesFilledData &&
                                SevicesFilledData?.find(
                                  (data) =>
                                    data.service_id ==
                                    uniqueServiceData.service_no
                                );
                              const existingSoapNoteNew =
                                existingSoapNote &&
                                existingSoapNote?.soap_note.soap_note;
                              return (
                                <tr key={index}>
                                  <td>{uniqueServiceData.service_no}</td>
                                  <td>
                                    <h5>{uniqueService}</h5>
                                  </td>
                                  <td>
                                    <Button
                                      variant="success"
                                      style={{ width: "100%" }}
                                      onClick={() =>
                                        handleSend(uniqueServiceData)
                                      }
                                      disabled={isServiceAlreadySent}
                                    >
                                      Send
                                    </Button>
                                  </td>
                                  {/* <td>
                                    <Button
                                      variant="warning"
                                      style={{ width: "100%" }}
                                      onClick={() =>
                                        handleOpen(uniqueServiceData.service_no)
                                      }
                                    >
                                      Open
                                    </Button>
                                  </td>
                                  <td>
                                    {existingSoapNoteNew
                                      ? Object.values(existingSoapNoteNew).map(
                                          (note, index) => (
                                            <p key={index}>
                                              {note.Note} - {note.Timestamp} -{" "}
                                              {note.ProviderID}
                                            </p>
                                          )
                                        )
                                      : ""}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    <textarea
                                      style={{ width: "100%" }}
                                      // value={uniqueServiceData.noteValue}
                                      onChange={(e) =>
                                        (uniqueServiceData.noteValue =
                                          e.target.value)
                                      }
                                    />
                                    <Button
                                      variant="success"
                                      onClick={() =>
                                        handleNoteSubmit(
                                          uniqueServiceData.service_no,
                                          {
                                            Note: "Test Note",
                                            Timestamp: "24/02/2024 12.15",
                                            ProviderID: "5",
                                          },
                                          uniqueServiceData.noteValue
                                        )
                                      }
                                    >
                                      Add
                                    </Button>
                                  </td>

                                  <td>
                                    <Button
                                      onClick={() =>
                                        OpenCaptureImage(
                                          uniqueServiceData.service_no
                                        )
                                      }
                                    >
                                      Capture
                                    </Button>
                                  </td>
                                  <td>
                                    <Button
                                      onClick={() =>
                                        OpenCaptureImagePostTreat(
                                          uniqueServiceData.service_no
                                        )
                                      }
                                    >
                                      Capture
                                    </Button>
                                  </td> */}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      )}
      {billingScreen && (
        <div>
          {AppointmentData && AppointmentData.generated_bill_provider != 1 ? (
            <>
            {/* <GenerateBillNew/> */}
            <GenerateBill onChildSubmit={handleChildSubmit} />
            </>
          ) : (
            <>

            <ServiceBillNew />
            {/* <ServiceBill /> */}
            </>
          )}
        </div>
      )}
      {serviceNoteScreen && (
        <ServiceNotes
          SevicesData={SevicesData}
          PreSendedForms={PreSendedForms}
        />
      )}
      {
        soapNoteScreen &&(
          <PatientAllSoapNotes/>
        )
      }

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            style={{ width: "100%" }}
            value={editedNote}
            onChange={(e) => setEditedNote(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Sign
          </Button>
          <Button variant="primary" onClick={handleModalSubmit}>
            Save as Draft
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProviderPatientView;
