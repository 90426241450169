import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import AppointmentPatientList from '../../GlobalFiles/ApiServices/AppointmentPatientList';

const AdministratorFullCalendar = () => {
  const [patientList, setPatientList] = useState([]);
  const accessToken = sessionStorage.getItem('accessToken');

  const fetchPatientList = async () => {
    try {
      const patients = await AppointmentPatientList.fetchData();
      setPatientList(patients);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchPatientList();
  }, []);

  const transformPatientListToEvents = () => {
    return patientList&&patientList.map((patient) => ({
      title: `${patient.patient_fname} ${patient.patient_lname}`,
      start: `${patient.pc_eventDate}T${patient.pc_startTime}`,
      end: `${patient.pc_eventDate}T${calculateEndTime(patient.pc_startTime)}`,
    }));
  };

  const calculateEndTime = (startTime) => {
    const [hours, minutes, seconds] = startTime.split(':').map(Number);
    const endTime = new Date();
    endTime.setHours(hours, minutes + 20, seconds); 
    return endTime.toTimeString().slice(0, 8);
  };

  const events = transformPatientListToEvents();

  const disableDays = (info) => {
    const day = info.date.getDay();
    if (day === 4 || day === 5 || day === 6 || day === 0) {
      info.dayEl.classList.add('fc-disable-day');
    }
  };

  return (
    <div className='admin-full-calendar-bg'>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
        headerToolbar={{
          left: 'prev,next',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        initialView='timeGridDay'
        events={events}
        titleFormat={{ month: 'long', day: 'numeric', weekday: 'long' }}
        initialDate={new Date()}
        nowIndicator={true}
        slotMinTime={'10:00:00'}
        slotMaxTime={'18:00:00'}
        height={'auto'}
        // hiddenDays={[0, 1, 2, 3]}
      />
    </div>
  );
};

export default AdministratorFullCalendar;
