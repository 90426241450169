import React, { useEffect, useState } from 'react'
import ArticlesDetailsSectionOne from '../../Components/Articles/ArticlesDetails/ArticlesDetailsSectionOne'
import { useParams } from 'react-router-dom';
import ApiServices from '../../GlobalFiles/ApiServices/ApiServices';

const MedSpaArticlesDetailsPage = () => {
  const { id } = useParams();
  const [articlesDetailsData, setarticlesDetailsData] = useState(null);
  const [error, setError] = useState(null); 
  useEffect(() => {
    const apiUrl = `/api/articles/${id}/`;
    ApiServices.get(apiUrl)
      .then((response) => {
        if (response.error) {
          setError(response.error);
        } else {
          setarticlesDetailsData(response.data);
        }
      })
      .catch((error) => {
        setError(error);
      });
  }, [id]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
    <div>
      <ArticlesDetailsSectionOne data={articlesDetailsData}/>
    </div>
    <div>

    </div>
    </div>
  )
}

export default MedSpaArticlesDetailsPage