import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { Button } from "react-bootstrap";
import IntakeUnfilledForm from "./IntakeUnfilledForm";
import Loader from "../Loader/Loader";

const IntakeFormsHomePage = () => {
  const navigate = useNavigate();
  const paramsData = useParams();
  const accessToken = sessionStorage.getItem("accessToken");
  const [IntakeFormsJsonData, setIntakeFormsJsonData] = useState([]);
  const [FilledIntakeFormsJsonData, setFilledIntakeFormsJsonData] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const LoginPatientID = sessionStorage.getItem("LoginPatientID");
  const LoginUserType = sessionStorage.getItem("LoginUserType");
  const[loading,setloading]=useState(false)
  const [apiTrigger, setApiTrigger] = useState(false);

  const handleChildSubmit = (success) => {
    if (success) {
      setApiTrigger(!apiTrigger);
    }
  };

  useEffect(() => {
    setloading(true)
    const fetchIntakeJsonData = async () => {
      try {
        let endpoint = `/apis/default/api/intakeForms`;

        if (LoginUserType == "Patient") {
          endpoint = `/apis/default/portal/intakeForms`;
        }
        const IntakeFormsJsonDataResponse = await ApiServices.get(
          endpoint,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );

        setIntakeFormsJsonData(IntakeFormsJsonDataResponse.data);
        setloading(false)
      } catch (error) {
        console.error("Error fetching data:", error);
        setloading(false)
      }
    };

    fetchIntakeJsonData();


    const fetchFilledIntakeJsonData = async () => {
      setloading(false)
        try {
          let endpoint = `/apis/default/api/intakeForms/${paramsData.pid}`;

          if (LoginUserType == "Patient") {
            endpoint = `/apis/default/portal/intakeForms/${LoginPatientID}`;
          }
          const FilledIntakeFormsJsonDataResponse = await ApiServices.get(
            endpoint,
            {
              Authorization: `Bearer ${accessToken}`,
            }
          );
  
          setFilledIntakeFormsJsonData(FilledIntakeFormsJsonDataResponse.data["0"]);
          setloading(false)
        } catch (error) {
          setloading(false)
          console.error("Error fetching data:", error);
        }
      };
  
      fetchFilledIntakeJsonData();

  }, [accessToken,apiTrigger]);

  const handleTabClick = (index) => {
    setActiveTabIndex(index);
  };

  return (
    <div>
    {loading && <Loader/>}
    <div style={{ position: "relative" , overflow: "hidden"}}>
      <div style={{ display: "flex", justifyContent: "space-around",top: 60,position:"fixed",
            left: 0,
            right: 0,
            background: "rgb(241, 245, 248)",
            zIndex: 1, 
            padding: "10px", 
            maxWidth: "100%" }}>
        {IntakeFormsJsonData &&
          IntakeFormsJsonData?.map((form, index) => (
            <button
              key={index}
              className={`${
                activeTabIndex === index
                  ? "appointment-type-btn-active"
                  : "appointment-type-btn-inactive"
              }`}
              onClick={() => handleTabClick(index)}
            >
              {form.form_title}
            </button>
          ))}
      </div>

      {IntakeFormsJsonData && IntakeFormsJsonData.length > 0 && (
        <div>
          {activeTabIndex !== null && IntakeFormsJsonData[activeTabIndex] && (
            <IntakeUnfilledForm
              formTitle={IntakeFormsJsonData[activeTabIndex].form_title}
              formJson={IntakeFormsJsonData[activeTabIndex].form_json}
              formName={IntakeFormsJsonData[activeTabIndex].form_name}
              FilledIntakeFormsJsonData={FilledIntakeFormsJsonData}
              onChildSubmit={handleChildSubmit}
            />
          )}
        </div>
      )}
    </div>
    </div>
  );
};

export default IntakeFormsHomePage;
