import axios from "axios";

class ApiService {
  get = async (url = {}, headers = {}) => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVERURL + url, 
      {
        headers: headers,
      });
      return { error: null, data: response.data };
    } catch (error) {
      console.log("Error:", error);
      return { error, data: null };
    }
  };

  post = async (url, payload = {}, headers = {}) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVERURL + url,
        payload,
        {
          headers: headers,
        }
      );
      return { error: null, data: response.data };
    } catch (error) {
      console.log("Post Request Error:", error);
      throw error;
    }
  };



  delete = async (url, headers = {}) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_SERVERURL + url,
        {
          headers: headers,
        }
      );
      return { error: null, data: response.data };
    } catch (error) {
      console.log("Delete Request Error:", error);
      throw error;
    }
  };

  

  put = async (url, payload = {}, headers = {}) => {
    try {
      const response = await axios.put(
        process.env.REACT_APP_SERVERURL + url,
        payload,
        {
          headers: headers,
        }
      );
      return { error: null, data: response.data };
    } catch (error) {
      console.log("Error:", error);
      return { error, data: null };
    }
  };

  patch = async (url, payload = {}, headers = {}) => {
    try {
      const response = await axios.patch(
        process.env.REACT_APP_SERVERURL + url,
        payload
      );
      return { error: null, data: response.data };
    } catch (error) {
      console.log("Error:", error);
      return { error, data: null };
    }
  };
}
export default new ApiService();
