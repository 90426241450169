import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { Link } from "react-router-dom";

const MedSpaProductPage = () => {
  const [productsData, setproductsData] = useState([]);
  console.log("productsData", productsData);
  useEffect(() => {
    ApiServices.get("/api/products/").then((response) => {
      // setproductsData(response.data);
      const activeProducts = response.data.filter(
        (product) => product.active_status === true
      );
      setproductsData(activeProducts);
    });
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div
      style={{
        paddingTop: "50px",
        backgroundColor: "rgb(249 250 251)",
        paddingBottom: "50px",
      }}
    >
      <Container>
        <Fade bottom>
          <div>
            <h1 class="explore-treartment-tag">Cosmos Beauty Collection</h1>
            <p className="wel-come-staff-info" style={{ textAlign: "justify" }}>
              Step into a world of indulgence and transformation with Cosmos
              Medi Spa's signature beauty products. Our carefully curated
              collection brings you the pinnacle of skincare innovation,
              offering a harmonious blend of science and luxury. Elevate your
              self-care routine with our exclusive line, designed to reveal your
              radiant beauty and enhance your natural glow. Immerse yourself in
              a journey of unparalleled pampering as you discover the
              transformative power of Cosmos Beauty Collection.
            </p>
          </div>
        </Fade>
        <div style={{paddingTop:"50px"}}>
          <Row>
            {productsData &&
              productsData?.map((index) => (
                <Col md={3} style={{ paddingBottom: "15px" }}>
                  <Fade bottom>
                  <Link
                          to={`/cosmos-products/${index.id}/${index.title}`}
                          style={{ textDecoration: "none" }}
                        >
                    <Card className="services-card-body" style={{height:"450px"}}>
                      <div style={{ position: "relative", overflow: "hidden" }}>
                          <Card.Img
                            variant="top"
                            src={index.image1}
                            style={{ width: "100%", transform: "scale(1.2)" }}
                          />
                        </div>
                      <Card.Body>
                      
                          <Card.Title
                            style={{ borderBottom: "1px solid #1c324a" }}
                          >
                            <div>
                              <div>
                                <h1 className="services-card-title">
                                  {index.title}
                                </h1>
                              </div>
                            </div>
                          </Card.Title>
                          <Card.Text className="serv-ces-card-text" style={{textAlign:"justify"}}>
                          {truncateText(index.description["0"].content, 100)}
                          </Card.Text>
                      </Card.Body>
                    </Card>
                        </Link>
                  </Fade>
                </Col>
              ))}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default MedSpaProductPage;
