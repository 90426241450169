import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Link, useNavigate } from "react-router-dom";
import AppointmentCalendar from "../PatientAppointments/AppointmentCalendar";
import RecurringAppointmentsCalendar from "../RecurringAppointments/RecurringAppointmentsCalendar";
import { ModelTraining } from "@mui/icons-material";

const PatientList = () => {
  const [patientList, setPatientList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredPatientList, setFilteredPatientList] = useState([]);
  const [fetchPatient, setfetchPatient] = useState(false);
  const [AppointmentModal, setAppointmentModal] = useState(false);
  const [RecurringAppointmentModal, setRecurringAppointmentModal] =
    useState(false);
  const [PatientToBookAppointment, setPatientToBookAppointment] =
    useState(null);

  const accessToken = sessionStorage.getItem("accessToken");
  const LoginUserType = sessionStorage.getItem("LoginUserType");

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const patientResponse = await ApiServices.get(
          "/apis/default/api/patient",
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );

        const patients = patientResponse.data.data;
        setPatientList(patients);
        setFilteredPatientList(patients);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAccessToken();
  }, [fetchPatient]);

  const handleBookAppointmentClick = (patient) => {
    setPatientToBookAppointment(patient);
    setAppointmentModal(true);
  };

  const handleModalClose = () => {
    setAppointmentModal(false);
  };

  const RecurringhandleBookAppointmentClick = (patient) => {
    setPatientToBookAppointment(patient);
    setRecurringAppointmentModal(true);
  };

  const RecurringhandleModalClose = () => {
    setRecurringAppointmentModal(false);
  };

  const [deleteModal, setdeleteModal] = useState(false);
  const [credentialsModal, setcredentialsModal] = useState(false);
  const [PatienttoDelete, setPatienttoDelete] = useState(null);
  const handleDeleteModalClose = () => {
    setdeleteModal(false);
  };
  const handleDeleteModalOpen = (patient) => {
    setdeleteModal(true);
    setPatienttoDelete(patient);
  };
  

  const handleDeletePatient = () => {
    let endpoint = `/apis/default/api/patient/${PatienttoDelete.pid}`;
    ApiServices.delete(endpoint, {
      Authorization: `Bearer ${accessToken}`,
    })
      .then((response) => {
        setdeleteModal(false);
        setfetchPatient(!fetchPatient);
      })
      .catch((error) => {
        setdeleteModal(false);
        setfetchPatient(!fetchPatient);
      });
  };

  const handlecredentialsModalClose = () => {
    setcredentialsModal(false);
  };

  const handlecredentialsModal = (patient) => {
    setcredentialsModal(true);
    setPatienttoDelete(patient);
  };

  const handleCreateCredentials= () => {
    let endpoint = `/apis/default/api/usernamepasswordpatient/${PatienttoDelete.pid}`;
    ApiServices.get(endpoint, {
      Authorization: `Bearer ${accessToken}`,
    })
      .then((response) => {
        setcredentialsModal(false);
        setfetchPatient(!fetchPatient);
      })
      .catch((error) => {
        setcredentialsModal(false);
        setfetchPatient(!fetchPatient);
      });
  };

  const getName = (params) => {
    const patient = params.data;
    return `${patient.fname} ${patient.mname} ${patient.lname}`;
  };

  const navigate = useNavigate();
  const handleCellClick = (params) => {
    if (params.colDef.field === "name") {
      const patientUuid = params.data.uuid;
      const patientpid = params.data.pid;
      navigate(`/admin/patient-details/${patientUuid}/${patientpid}/`);
    }
  };

  const handleSearchInputChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchInput(inputValue);

    const filteredPatients = patientList.filter(
      (patient) =>
        patient.fname.toLowerCase().includes(inputValue) ||
        patient.mname.toLowerCase().includes(inputValue) ||
        patient.lname.toLowerCase().includes(inputValue)
    );

    setFilteredPatientList(filteredPatients);
  };

  const handleAssignPackageOpen = (patient) => {
    const patientDataString = JSON.stringify(patient);

    const encodedpatientData = btoa(patientDataString);
    // setPatienttoDelete(patient);
    navigate(`/admin/assign-package/${encodedpatientData}`)
  };

  const columnDefs = [
    {
      headerName: "Title",
      field: "title",
      headerClass: "custom-header-of-tables",
      minWidth: 80,
      flex: 1,
    },
    {
      headerName: "Name",
      field: "name",
      valueGetter: getName,
      headerClass: "custom-header-of-tables",
      onCellClicked: handleCellClick,
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Sex",
      field: "sex",
      headerClass: "custom-header-of-tables",
      minWidth: 100,
      flex: 1,
    },
    {
      headerName: "Date of Birth",
      field: "DOB",
      headerClass: "custom-header-of-tables",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Contact Number",
      field: "phone_contact",
      headerClass: "custom-header-of-tables",
      minWidth: 180,
      flex: 1,
    },
    {
      headerName: "Email",
      field: "email",
      headerClass: "custom-header-of-tables",
      minWidth: 240,
      flex: 1,
    },
    {
      headerName: "Book Appointment",
      field: "book_appointment",
      headerClass: "custom-header-of-tables",
      minWidth: 240,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <Button
            variant="success"
            size="sm"
            onClick={() => handleBookAppointmentClick(params.data)}
          >
            Book An Appointment
          </Button>
        );
      },
    },
    {
      headerName: "Book Appointment",
      field: "book_appointment",
      headerClass: "custom-header-of-tables",
      minWidth: 240,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <Button
            variant="warning"
            size="sm"
            onClick={() => RecurringhandleBookAppointmentClick(params.data)}
          >
            Book Recurring Appointment
          </Button>
        );
      },
    },
    {
      headerName: "Assign Package",
      field: "assign_package",
      headerClass: "custom-header-of-tables",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <Button
            variant="secondary"
            size="sm"
            onClick={() => handleAssignPackageOpen(params.data)}
            // disabled={LoginUserType != "admin"}
          >
            Assign Package
          </Button>
        );
      },
    },
    {
      headerName: "Delete Patient",
      field: "book_appointment",
      headerClass: "custom-header-of-tables",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <Button
            variant="danger"
            size="sm"
            onClick={() => handleDeleteModalOpen(params.data)}
            disabled={LoginUserType != "admin"}
          >
            Delete Patient
          </Button>
        );
      },
    },
    {
      headerName: "Create Credentials",
      field: "book_appointment",
      headerClass: "custom-header-of-tables",
      minWidth: 180,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <Button
            variant="secondary"
            size="sm"
            onClick={() => handlecredentialsModal(params.data)}
            disabled={LoginUserType != "admin"}
          >
            Create Credentials
          </Button>
        );
      },
    },
    
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  return (
    <div style={{ backgroundColor: "#F1F5F8" }}>
      <Row>
        <Col sm={12}>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <input
              type="text"
              placeholder="Search Patient by Name..."
              value={searchInput}
              onChange={handleSearchInputChange}
              className="form-control mb-2"
            />
            <Link to="/admin/patientregistration">
              <button
                className="login-form-login-button"
                style={{
                  marginTop: "0px",
                  marginBottom: "0.5rem",
                  width: "150px",
                }}
              >
                + Add Patient
              </button>
            </Link>
          </div>
          <div
            className="ag-theme-alpine"
            style={{
              height: "85vh",
              minWidth: "100%",
              // overflow: "hidden"
            }}
          >
            <AgGridReact
              columnDefs={columnDefs}
              //   rowData={patientList}
              rowData={filteredPatientList}
              defaultColDef={defaultColDef}
            />
          </div>
        </Col>
      </Row>
      <Modal
        show={AppointmentModal}
        onHide={handleModalClose}
        className="modal-lg"
        centered
      >
        <Modal.Body>
          <AppointmentCalendar
            PatientToBookAppointment={PatientToBookAppointment}
            handleModalClose={handleModalClose}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={RecurringAppointmentModal}
        onHide={RecurringhandleModalClose}
        className="modal-lg"
        centered
      >
        <Modal.Body>
          <RecurringAppointmentsCalendar
            PatientToBookAppointment={PatientToBookAppointment}
            handleModalClose={RecurringhandleModalClose}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={RecurringhandleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleteModal} onHide={handleDeleteModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Do You want to delete below Patient?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {PatienttoDelete && (
            <>
              <Row>
                <Col sm={2}>
                  <b>Name</b>
                </Col>
                <Col sm={10}>
                  {PatienttoDelete && PatienttoDelete.fname}{" "}
                  {PatienttoDelete && PatienttoDelete.lname}
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <b>Email</b>
                </Col>
                <Col sm={10}>{PatienttoDelete && PatienttoDelete.email} </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <b>Contact</b>
                </Col>
                <Col sm={10}>
                  {PatienttoDelete && PatienttoDelete.phone_contact}
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <b>Address</b>
                </Col>
                <Col sm={10}>
                  {PatienttoDelete && PatienttoDelete.street}{" "}
                  {PatienttoDelete && PatienttoDelete.city}{" "}
                  {PatienttoDelete && PatienttoDelete.state}
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDeletePatient}>
            Delete
          </Button>
          <Button variant="secondary" onClick={handleDeleteModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={credentialsModal} onHide={handlecredentialsModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Do You want to Create Login Credentials for below Patient?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {PatienttoDelete && (
            <>
              <Row>
                <Col sm={2}>
                  <b>Name</b>
                </Col>
                <Col sm={10}>
                  {PatienttoDelete && PatienttoDelete.fname}{" "}
                  {PatienttoDelete && PatienttoDelete.lname}
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <b>Email</b>
                </Col>
                <Col sm={10}>{PatienttoDelete && PatienttoDelete.email} </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <b>Contact</b>
                </Col>
                <Col sm={10}>
                  {PatienttoDelete && PatienttoDelete.phone_contact}
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <b>Address</b>
                </Col>
                <Col sm={10}>
                  {PatienttoDelete && PatienttoDelete.street}{" "}
                  {PatienttoDelete && PatienttoDelete.city}{" "}
                  {PatienttoDelete && PatienttoDelete.state}
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleCreateCredentials}>
            Create Credentials
          </Button>
          <Button variant="secondary" onClick={handlecredentialsModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PatientList;
