import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Loader from "../../Loader/Loader";
import EmailOtpVerification from "../../OTPVerification/EmailOtpVerification";
import OvalLoader from "../../Loader/OvalLoader";
import ApiServices from "../../../GlobalFiles/ApiServices/ApiServices";
import zxcvbn from "zxcvbn";
import { Fade } from "react-reveal";

const PatientRegistrationForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [emailtoken, setemailtoken] = useState("");
  const naigate = useNavigate()

  const [formData, setFormData] = useState({
    title: "",
    fname: null,
    mname: "",
    lname: null,
    sex: "",
    email: "",
    phone_contact: "",
    DOB: null,
    postal_code: "",
    city: "",
    state: "",
    country_code: "",
    street: "",
    ssn_number: "",
    token_id: "",
    password: "",
    terms_conditions: false,
    privacy_policy: false,
    blacklist: false,
    biometric_verification: false,
    med_spa: true,
    createportal_credential:"YES",
    allow_patient_portal:"YES",
    hipaa_allowemail:"YES",
    // group_id: "9a62b68e-0f8f-4404-bdae-2a1a8daafd3b",
    // idDetails: [{ id_type: "Drivers license", id_number: "" }],
  });

  const PostalCode = formData.postal_code;

  useEffect(() => {
    if (PostalCode.length > 4) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${PostalCode}&key=AIzaSyC59AXldWP6lbfUhOpnJUsJPhULLWn_K5g`
        )
        .then((response) => {
          const results = response.data.results;
          if (results && results.length > 0) {
            const addressComponents = results[0].address_components;
            const city = addressComponents.find((component) =>
              component.types.includes("locality")
            );
            const state = addressComponents.find((component) =>
              component.types.includes("administrative_area_level_1")
            );
            const country = addressComponents.find((component) =>
              component.types.includes("country")
            );

            setFormData((prevFormData) => ({
              ...prevFormData,
              city: city ? city.long_name : "",
              state: state ? state.long_name : "",
              country_code: country ? country.long_name : "",
            }));
          }
        });
    }
  }, [PostalCode]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleGenderChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      sex: e.target.value,
    }));
  };
  const [errors, setErrors] = useState({
    fname: "",
    lname: "",
    email: "",
    phone_contact: "",
    // password: "",
    DOB: "",
    sex: "",
  });

  const [correctEmail, setcorrectEmail] = useState(false);
  const [VerifiedOtp, setVerifiedOtp] = useState(false);
  const [OvalLoading, setOvalLoading] = useState(false);
  const [VerifiedOtpMessage, setVerifiedOtpMessage] = useState("");
  const [ErrorFromOtpVerification, setErrorFromOtpVerification] = useState("");
  const [showOtpModal, setShowOtpModal] = useState(false);

  const currentDate = new Date().toISOString().split("T")[0];

  const handleOtpVerification = (inputOtp) => {
    axios
      .get(
        `https://medispacosmos.com/riteclinic-openemr/apis/default/api/verificationtoken/${inputOtp}`,
        {}
      )
      .then((response) => {
        console.log("Verify", response.data.data);
        const isOtpVerified = response.data.data.token_status;
        const token_id_holder = response.data.data.token_id_holder;
        setemailtoken(token_id_holder)
        const patientId = response.data.data.patient_id;

      if (patientId) {
        swal({
          title: "Email Already Registered",
          text: "Please log in to your account",
          icon: "warning",
          buttons: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "btn-primary",
              closeModal: true,
            },
          },
          closeOnClickOutside: false,
          closeOnEsc: false,
        }).then((confirmed) => {
          if (confirmed) {
            window.location.href = `${process.env.REACT_APP_EMR_URL}/riteclinic-openemr/portal/`
            // navigate("/");
          }
        });
        // swal("Email Already Registered", "Please log in to your account", "warning");
      }
        if (isOtpVerified) {
          setShowOtpModal(false);
          setVerifiedOtp(true);
          setErrorFromOtpVerification("OTP Verified Successfullly...!");
          setVerifiedOtpMessage("");
        } else {
        }
      })
      .catch((error) => {
        console.error("Failed to verify OTP:", error);
        setErrorFromOtpVerification("OTP is incorrect or has expired.");
      });
  };



  const sendEmailOTP = () => {
    setOvalLoading(true);


    axios
      .post(
        "https://medispacosmos.com/riteclinic-openemr/apis/default/api/verifyemail",
        {
          fname: formData.fname,
          mname: formData.mname,
          lname: formData.lname,
          email: formData.email,
          DOB: formData.DOB,
        }
      )
      .then((response) => {
        setShowOtpModal(true);
        setOvalLoading(false);
      })
      .catch((error) => {
        console.error("Failed to send OTP:", error);
        setOvalLoading(false);
      });
  };

  const openOtpModal = () => {
    sendEmailOTP();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
    if (name === "DOB") {
      const selectedDate = new Date(value);
      const currentDate = new Date();

      if (selectedDate > currentDate) {
        setErrors({ ...errors, [name]: "Please enter a valid birth date" });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    }
    if (name === "email") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!value.match(emailRegex)) {
        setErrors({ ...errors, [name]: "Invalid email address" });
        setcorrectEmail(false);
      } else {
        setErrors({ ...errors, [name]: "" });
        setcorrectEmail(true);
      }
    }
  };

  const handleIdTypeChange = (index, selectedIdType) => {
    const updatedIdDetails = [...formData.idDetails];
    updatedIdDetails[index].id_type = selectedIdType;
    setFormData({ ...formData, idDetails: updatedIdDetails });
  };

  const handleIdCardNumberChange = (index, value) => {
    const updatedIdDetails = [...formData.idDetails];
    updatedIdDetails[index].id_number = value;
    setFormData({ ...formData, idDetails: updatedIdDetails });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const requiredFields = [
      "fname",
      "lname",
      "email",
      "phone_contact",
      // "password",
      "DOB",
      "sex",
    ];
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = "Required*";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setIsLoading(false);
      setErrors(newErrors);
      return; // Don't submit if there are validation errors
    }

    formData.verify_email = formData.email;
    formData.verify_password = formData.password;
    formData.title = title;
    formData.token_id = emailtoken;
    // formData.sex = sex;

    if (!VerifiedOtp) {
      setVerifiedOtpMessage("Please verify your email address");
      setIsLoading(false);
      return;
    }

    
    axios
      // .post(`${process.env.REACT_APP_SERVERURL}/api/user_identity/`, formData)
      .post(`https://medispacosmos.com/riteclinic-openemr/apis/default/api/patient`, formData)
      .then((response) => {

        axios
        .post(
          "https://medispacosmos.com/riteclinic-openemr/apis/default/api/patientpasslink",
          {
            resetPass: false,
            email: formData.email,
            token_id: response.data.data.token_id,
            pid: response.data.data.pid,
          }
        )



        setTimeout(() => {

          setIsLoading(false);

          swal({
            title: "Registration Successful",
            text: "Please Check Your Mailbox For Login Credentials to Cosmos Medi Spa Portal",
            icon: "success",
            button: "OK",
            timer: 5000,
          });

          naigate("/appointment")
          // setTimeout(() => {
          //   window.location.href = `${process.env.REACT_APP_EMR_URL}/riteclinic-openemr/portal/`;
          // }, 3000);
        }, 5000);
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        setIsLoading(false);
        swal({
          title: "Registration Failed",
          text: "An error occurred during registration.",
          icon: "error",
          button: "OK",
          timer: 3000,
        });
      });
  };
  return (
    <div>
      {isLoading && <Loader fname={formData.fname} />}
      <Container>
        <Row className="align-items-center p-4">
          <Col sm={6}>
            <h1 class="explore-treartment-tag">Cosmos Med Spa</h1>
            <h1
              class="explore-treartment-sub-title-head-tag"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              Your Path to Radiant Health Starts Here...
              <br /> Register Now!
            </h1>
            <p
              className="med-spa-staff-info-p-tag"
              style={{ textAlign: "center" }}
            >
              Start your wellness journey with us. Register to experience the
              art of relaxation and the science of well-being.
            </p>
          </Col>
          <Col sm={6}>
            <form>
              <Col>
                <Row>
                  <Col className="register-patient-column">
                    <select
                      className="register-patient-column-input"
                      name="title"
                      value={title}
                      onChange={handleTitleChange}
                    >
                      <option value="">Select Title</option>
                      <option value="Mr.">Mr.</option>
                      <option value="Mrs.">Mrs.</option>
                    </select>
                  </Col>
                  <Col className="register-patient-column">
                    <input
                      type="text"
                      placeholder="First Name"
                      className="register-patient-column-input"
                      name="fname"
                      onChange={handleInputChange}
                      value={formData.fname}
                      disabled={VerifiedOtp}
                    />
                    {errors.fname && (
                      <div className="error-message">{errors.fname}</div>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="register-patient-column">
                    <input
                      type="text"
                      placeholder="Middle Name"
                      className="register-patient-column-input"
                      name="mname"
                      onChange={handleInputChange}
                      value={formData.mname}
                      disabled={VerifiedOtp}
                    />
                  </Col>
                  <Col className="register-patient-column">
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="register-patient-column-input"
                      name="lname"
                      onChange={handleInputChange}
                      value={formData.lname}
                      disabled={VerifiedOtp}
                    />
                    {errors.lname && (
                      <div className="error-message">{errors.lname}</div>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col className="register-patient-column">
                <input
                  type="email"
                  placeholder="Email ID"
                  className="register-patient-column-input"
                  name="email"
                  onChange={handleInputChange}
                  value={formData.email}
                  disabled={VerifiedOtp}
                />
                {correctEmail && (
                  <div>
                    {VerifiedOtp ? (
                      <p
                        className="clear-filters-text"
                        style={{
                          marginTop: "-10px",
                          fontSize: "13px",
                          cursor: "auto",
                          textAlign: "left",
                          paddingBottom: "3px",
                          color: "green",
                        }}
                      >
                        Email Verified Successfully
                      </p>
                    ) : (
                      <div>
                        {OvalLoading ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "-10px",
                              fontSize: "13px",
                              gap: "5px",
                              cursor: "auto",
                            }}
                          >
                            <p
                              className="clear-filters-text"
                              style={{ cursor: "auto" }}
                            >
                              Processing
                            </p>
                            <OvalLoader />
                          </div>
                        ) : (
                          <div
                            style={{
                              marginTop: "-10px",
                              marginBottom: "10px",
                            }}
                          >
                            <Fade bottom collapse>
                            {formData.fname && formData.lname && formData.DOB ? (
                <p
                  className="clear-filters-text"
                  onClick={openOtpModal}
                  style={{
                    marginTop: "-10px",
                    paddingBottom: "3px",
                    fontSize: "16px",
                    color: "red",
                    textAlign: "left",
                  }}
                >
                  Verify Email Address
                </p>
              ) : 
              <p
               style={{
                    marginTop: "-10px",
                    fontSize: "16px",
                    color: "red",
                    textAlign: "left",
                  }}
              >

              Please Fill Firstname, Lastname and Date of Birth to Verify Email
              
              </p>}
                              {/* <p
                                className="clear-filters-text"
                                onClick={openOtpModal}
                                style={{
                                  marginTop: "-10px",
                                  paddingBottom: "3px",
                                  fontSize: "16px",
                                  color: "red",
                                  textAlign: "left",
                                }}
                              >
                                Verify Email Address
                              </p> */}
                            </Fade>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {errors.email && (
                  <div className="error-message">{errors.email}</div>
                )}
              </Col>
              <Col className="register-patient-column">
                <input
                  type="number"
                  placeholder="Phone Number"
                  className="register-patient-column-input"
                  name="phone_contact"
                  onChange={handleInputChange}
                  value={formData.phone_contact}
                />
                {errors.phone_contact && (
                  <div className="error-message">{errors.phone_contact}</div>
                )}
              </Col>
              <Col>
                <Row>
                  <Col className="register-patient-column">
                    <p
                      style={{
                        margin: "0px",
                        paddingLeft: "8px",
                        textAlign: "left",
                        color: "grey",
                      }}
                    >
                      Select Your Gender
                    </p>
                    <select
                      className="register-patient-column-input"
                      name="sex"
                      value={formData.sex}
                      onChange={handleInputChange}
                    >
                      <option value="">Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                    {errors.sex && (
                      <div className="error-message">{errors.sex}</div>
                    )}
                  </Col>

                  <Col className="register-patient-column">
                    <p
                      style={{
                        margin: "0px",
                        paddingLeft: "8px",
                        textAlign: "left",
                        color: "grey",
                      }}
                    >
                      Select Date of Birth
                    </p>
                    <input
                      type="date"
                      placeholder="Date of Birth"
                      className="register-patient-column-input"
                      name="DOB"
                      max={currentDate}
                      onChange={handleInputChange}
                      value={formData.DOB}
                      disabled={VerifiedOtp}
                    />
                    {errors.DOB && (
                      <div className="error-message">{errors.DOB}</div>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="register-patient-column">
                    <input
                      type="number"
                      placeholder="Zip Code"
                      className="register-patient-column-input"
                      name="postal_code"
                      onChange={handleInputChange}
                      value={formData.postal_code}
                    />
                  </Col>

                  <Col className="register-patient-column">
                    <input
                      type="text"
                      placeholder="City"
                      className="register-patient-column-input"
                      name="city"
                      onChange={handleInputChange}
                      value={formData.city}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="register-patient-column">
                    <input
                      type="text"
                      placeholder="State"
                      className="register-patient-column-input"
                      name="state"
                      onChange={handleInputChange}
                      value={formData.state}
                    />
                  </Col>
                  <Col className="register-patient-column">
                    <input
                      type="text"
                      placeholder="Country"
                      className="register-patient-column-input"
                      name="country_code"
                      onChange={handleInputChange}
                      value={formData.country_code}
                    />
                  </Col>
                </Row>
              </Col>

              <Col className="register-patient-column">
                <input
                  type="text"
                  placeholder="Street"
                  className="register-patient-column-input"
                  name="street"
                  onChange={handleInputChange}
                  value={formData.street}
                />
              </Col>
              <Col className="register-patient-column">
                <input
                  type="text"
                  placeholder="SSN Number"
                  className="register-patient-column-input"
                  name="ssn_number"
                  onChange={handleInputChange}
                  value={formData.ssn_number}
                />
              </Col>

              {/* {formData.idDetails.map((idDetail, index) => (
                <Col key={index}>                  
                  <Row>
                    <Col className="register-patient-column">
                      <select
                        className="register-patient-column-input"
                        name={`idType-${index}`}
                        value={idDetail.idType}
                        onChange={(e) =>
                          handleIdTypeChange(index, e.target.value)
                        }
                      >
                        <option>Select ID Type</option>
                        <option>Drivers license</option>
                        <option>State issued identification card</option>
                        <option>US Passport card or book</option>
                        <option>US Green card</option>
                        <option>Passport of other countries</option>
                      </select>
                    </Col>
                    <Col className="register-patient-column">
                      <input
                        type="text"
                        placeholder="ID Card Number"
                        className="register-patient-column-input"
                        value={idDetail.id_number}
                        onChange={(e) =>
                          handleIdCardNumberChange(index, e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              ))} */}

              {/* <Col className="register-patient-column">
                <input
                  type="password"
                  placeholder="Password"
                  className="register-patient-column-input"
                  name="password"
                  onChange={handleInputChange}
                  value={formData.password}
                />
                {errors.password && (
                  <div className="error-message">{errors.password}</div>
                )}
              </Col> */}

              <Col>
                <p className="error-message">{VerifiedOtpMessage}</p>
              </Col>
              <Col>
                <Row>
                  <Col sm={5}>
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="med-spa-custom-button"
                    >
                      Sign Up
                    </button>
                  </Col>
                  <Col sm={7}>
                    <Link
                      to={`${process.env.REACT_APP_EMR_URL}/riteclinic-openemr/portal/`}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className="med-spa-custom-button"
                        style={{
                          backgroundColor: "#06b6b3",
                          color: "white",
                          textAlign: "center",
                          textDecoration: "none",
                        }}
                      >
                        Login if You already Registered with Us
                      </div>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </form>
          </Col>
        </Row>
        <EmailOtpVerification
          show={showOtpModal}
          onClose={() => setShowOtpModal(false)}
          onVerify={handleOtpVerification}
          sendotpagain={sendEmailOTP}
          error={ErrorFromOtpVerification}
          email={formData.email}
        />
      </Container>
    </div>
  );
};

export default PatientRegistrationForm;
