import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Table } from "react-bootstrap";
import ApiServices from "../../../GlobalFiles/ApiServices/ApiServices";
import swal from "sweetalert";

const ProviderBillingServices = () => {
  const paramsData = useParams();
  console.log("paramsData",paramsData)
  const accessToken = sessionStorage.getItem("accessToken");

  const [SevicesData, setSevicesData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [discounts, setDiscounts] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const AppointmentDataResponse = await ApiServices.get(
          `/apis/default/api/masterServices`,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );
        setSevicesData(AppointmentDataResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [paramsData, accessToken]);

  const filteredServices = SevicesData
    ? SevicesData.filter(
        (service) =>
          service.service.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (service.service_subtype &&
            service.service_subtype
              .toLowerCase()
              .includes(searchTerm.toLowerCase()))
      )
    : [];

  const shouldDisableCheckbox = (service) => service.status == 0;

  const handleCheckboxChange = (service) => {
    if (!shouldDisableCheckbox(service)) {
      const selectedService = {
        // service_id: service.service,
        service_id: service.id,
        subtype_service_id: service.service_subtype || null,
        discount: discounts[service.id] || 0,
      };
  
      // Check if the checkbox is checked or unchecked
      const isChecked = selectedServices.some(
        (s) =>
          s.service_id === selectedService.service_id &&
          s.subtype_service_id === selectedService.subtype_service_id
      );
  
      if (isChecked) {
        // If checked, remove the service from selectedServices
        setSelectedServices((prevSelected) =>
          prevSelected.filter(
            (s) =>
              s.service_id !== selectedService.service_id ||
              s.subtype_service_id !== selectedService.subtype_service_id
          )
        );
      } else {
        // If unchecked, add the service to selectedServices
        setSelectedServices((prevSelected) => [...prevSelected, selectedService]);
      }
    }
  };

  const handleDiscountChange = (service, value) => {
    // Update the discounts state
    setDiscounts((prevDiscounts) => ({
      ...prevDiscounts,
      [service.id]: value,
    }));
  
    // Update the selectedServices state
    setSelectedServices((prevSelected) => {
      const updatedSelectedServices = prevSelected.map((selectedService) => {
        if (
          selectedService.service_id === service.service &&
          selectedService.subtype_service_id === service.service_subtype
        ) {
          // Update the discount value
          return {
            ...selectedService,
            discount: value,
          };
        }
        return selectedService;
      });
      return updatedSelectedServices;
    });
  };
  

  const handleSubmit = async (mainService) => {
    try {
      const postData = {
        provider_id: paramsData.id,
        services: selectedServices,
      };
      //console.log(postData);
      const response = await ApiServices.post(
        "/apis/default/api/allowedServiceDsicount",
        postData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      console.log(response.data);
      swal({
        title: `Services to the Provider Assigned  successfully`,
        icon: "success",
        button: "OK",
        timer: 2000,
      });
    } catch (error) {
      console.error("Error sending form data:", error);
    }
  };


  console.log("Selected Services:", selectedServices);

  return (
    <div>
      <div>
        <Card className="mb-3">
          <Card.Body>
            <div className="mb-3">
              <input
                type="text"
                id="searchTerm"
                value={searchTerm}
                placeholder="Search Service or Subservice"
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: "100%", padding: "5px" }}
              />
            </div>
            {filteredServices.length > 0 && (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Checkbox</th>
                    <th>Service</th>
                    <th>Sub Service</th>
                    <th>Unit Cost</th>
                    <th>Discount Price</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredServices.map((service, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          disabled={shouldDisableCheckbox(service)}
                          style={{ cursor: "pointer", padding: "10px" }}
                          onChange={() => handleCheckboxChange(service)}
                        />
                      </td>
                      <td
                        style={{
                          color: service.status == 0 ? "red" : "black",
                        }}
                      >
                        {service.status == 0 ? (
                          <del>{service.service}</del>
                        ) : (
                          <>{service.service}</>
                        )}
                      </td>
                      <td
                        style={{
                          color: service.status == 0 ? "red" : "black",
                        }}
                      >
                        {service.status == 0 ? (
                          <del>{service.service_subtype}</del>
                        ) : (
                          <>{service.service_subtype}</>
                        )}
                      </td>
                      <td>$ {service.unit_cost}</td>
                      <td>
                        <input
                          type="text"
                          placeholder="Discount"
                          value={discounts[service.id] || ""}
                          onChange={(e) =>
                            handleDiscountChange(service, e.target.value)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {filteredServices.length === 0 && (
              <p>No matching services found.</p>
            )}

            <Button onClick={handleSubmit}>Assign The Services</Button>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default ProviderBillingServices;
