import React, { useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { BiMessageRoundedDetail,BiSolidPhoneCall } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import { BsFillChatLeftDotsFill } from "react-icons/bs";
import MedSpaTextUs from './MedSpaTextUs';
import MedSpaCallUs from './MedSpaCallUs';
import MedSpaMailUs from './MedSpaMailUs';
import MedSpaTextSMS from './MedSpaTextSMS';

const MedSpaSupportWindow = () => {
    const[textus,settextus]=useState(false)
    const[callus,setcallus]=useState(true)
    const[mailus,setmailus]=useState(false)
    const[smsus,setsmsus]=useState(false)

    const handletextus =()=>{
        settextus(true)
        setcallus(false)
        setmailus(false)
        setsmsus(false)
    }

    const handlecallus =()=>{
        setcallus(true)
        settextus(false)
        setmailus(false)
        setsmsus(false)
    }

    const handlemailus =()=>{
        setmailus(true)
        setcallus(false)
        settextus(false)
        setsmsus(false)
    }
    const handlsmsus =()=>{
        setmailus(false)
        setcallus(false)
        settextus(false)
        setsmsus(true)
    }
  return (
    <div>
        <Container>
            <Row style={{justifyContent:"space-around"}}>
            {/* <div >
                <Card className={textus==true?"support-card-in-chat-window-active":"support-card-in-chat-window"} onClick={handletextus}>
                    <BsFillChatLeftDotsFill/>
                    <p>Chat</p>
                </Card>
            </div> */}
            <div >
                <Card className={callus==true?"support-card-in-chat-window-active":"support-card-in-chat-window"} onClick={handlecallus}>
                    <BiSolidPhoneCall/>
                    <p>Call</p>
                </Card>
            </div>
            <div >
                <Card className={smsus==true?"support-card-in-chat-window-active":"support-card-in-chat-window"} onClick={handlsmsus}>
                <BiMessageRoundedDetail/>
                    <p>Text</p>
                </Card>
            </div>
            <div >
                <Card className={mailus==true?"support-card-in-chat-window-active":"support-card-in-chat-window"} onClick={handlemailus}>
                    <AiOutlineMail/>
                    <p>Mail</p>
                </Card>
            </div>
            
            </Row>
            <div style={{paddingTop:"10px"}}>
            {textus && <MedSpaTextUs/>}
            {callus && <MedSpaCallUs/>}
            {mailus && <MedSpaMailUs/>}
            {smsus && <MedSpaTextSMS/>}
            </div>
        </Container>
    </div>
  )
}

export default MedSpaSupportWindow