import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillCaretDown } from "react-icons/ai";

const AppointmentFaq = () => {

    const faqdata = [
        {
          question: "How can I book an appointment at Cosmos medi spa?",
          answer:
            "Booking an appointment at our medi spa is easy. You can either call our front desk at 8128030079 during our operating hours, or you can use our convenient online booking system on our website. Select your preferred service, date, and time to schedule your appointment."
        },
        {
          question: "Can I schedule appointments online, or do I need to call?",
          answer:
            "Absolutely! We offer both options for your convenience. You can schedule appointments online through our website at any time, or if you prefer, you can call our front desk to book an appointment during our business hours."
        },
        {
          question: "What is the cancellation policy for appointments?",
          answer:
            "We understand that plans may change. To cancel or reschedule an appointment, we kindly request  you can call our front desk."
        },
        {
          question: "How far in advance should I book my appointment?",
          answer:
            "We recommend booking your appointment as far in advance as possible, especially for popular treatments or specific time slots. However, we do our best to accommodate last-minute requests, and you can check our online booking system for immediate availability."
        },
        {
          question: "Are there any special requirements for booking group or package appointments?",
          answer:
            "Yes, for group or package appointments, we advise contacting our front desk to make arrangements. We can assist you in customizing your experience and scheduling multiple appointments to suit your needs."
        },
        {
          question: "Do you offer same-day or emergency appointments?",
          answer:
            "We do offer same-day appointments whenever availability allows. For emergency appointments or immediate assistance, please call our front desk, and we'll do our best to accommodate your request."
        }
      ];

    

const [openFaqIndex, setOpenFaqIndex] = useState(null);
const handleOpenFaq = (index) => {
    setOpenFaqIndex(index === openFaqIndex ? null : index);
  };
    

  return (
    <div className="med-spa-sec-three-bg">
      <Container style={{textAlign:"left"}}>
        <Row
        //   className="align-items-center"
          style={{ width: "100%", margin: "0px",paddingTop:"5rem",paddingBottom:"5rem" }}
        >
          

          <Col sm={6} 
        //   className="order-2 order-sm-1"
          >
            <h1 className="section-three-head-tag">
              Questions? We've got answers.
            </h1>
          </Col>
          <Col
            sm={6}
            // className="order-2 order-sm-2"
            style={{ padding: "0px" }}
          >
            {faqdata&&faqdata?.map((faq, index) => (
          <div className="dr-faq-width-div" key={index}>
            <div
              className="dr-faq-main-div"
              onClick={() => handleOpenFaq(index)}
            >
              <h3>{faq.question}</h3> <AiFillCaretDown />
            </div>
            {openFaqIndex === index && (
              <div className="dr-faq-flex-div">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AppointmentFaq;
