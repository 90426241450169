import React, { useEffect, useRef, useState } from "react";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import swal from "sweetalert";
import Loader from "../Loader/Loader";

const ServiceDynamicForm = ({
  DynamicFormData,
  CompleteFormData,
  FormName,
  onChildSubmit,
}) => {
  const accessToken = sessionStorage.getItem("accessToken");
  const LoginPatientUUID = sessionStorage.getItem("LoginPatientUUID");
  const LoginUserType = sessionStorage.getItem("LoginUserType");
  const [patientData, setPatientData] = useState(null);
  const [loading, setloading] = useState(false);
  const ParamsData = useParams();
  const AvailableForms = DynamicFormData;
  const AllFormsData = CompleteFormData;
  const BaSicData = {
    fname: "",
    lname: "",
    address: "",
  };

  const canvasRefs = useRef({});
  const drawingRefs = useRef({});

  const [formValues, setFormValues] = useState(BaSicData);
  const handleInputChange = (fieldName, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  const handleCheckboxChange = (fieldName, rowId) => {
    if (formValues.hasOwnProperty(fieldName)) {
      const updatedRows = formValues[fieldName].map((row) => {
        if (row.id === rowId) {
          return { ...row, selected: !row.selected };
        }
        return row;
      });
      setFormValues((prevValues) => ({
        ...prevValues,
        [fieldName]: updatedRows,
      }));
    }
  };
  
  

  const getLabelFromAvailableForms = (name) => {
    const fieldInfo = AvailableForms[name];
    return fieldInfo ? fieldInfo.label : name;
  };

  const handleSubmit = async (e) => {
    setloading(true);
    e.preventDefault();
    try {
      const formDataArray = Object.entries(formValues).map(([name, value]) => ({
        label: getLabelFromAvailableForms(name),
        name: name,
        value: value,
      }));
      
      let endpoint = `/apis/default/api/savePatientServiceForm`;

      if (LoginUserType == "Patient") {
        endpoint = `/apis/default/portal/savePatientServiceForm`;
      }

      const response = await ApiServices.put(
        endpoint,
        // "/apis/default/api/savePatientServiceForm",
        {
          form_id: AllFormsData.service_no,
          eid: ParamsData.eid,
          pid: ParamsData.pid,
          [FormName]: formDataArray,
          //   form_data: formDataArray,
        },
        {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        }
      );

      console.log("Form data saved:", response.data);
      swal({
        title: "Form Submitted Successfully",
        icon: "success",
        button: "OK",
        timer: 2000,
      });
      onChildSubmit(true);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error("Error submitting form data:", error);
      swal({
        title: "Something Went Wrong",
        icon: "danger",
        button: "OK",
        timer: 2000,
      });
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `/apis/default/api/patient/${ParamsData.uuid}`;

          if (LoginUserType == "Patient") {
            endpoint = `/apis/default/portal/patient/${LoginPatientUUID}`;
          }
        const patientResponse = await ApiServices.get(
          endpoint,
          // `/apis/default/api/patient/${ParamsData.uuid}`,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );

        const data = patientResponse.data.data;
        setPatientData(data);
        setFormValues({
          fname: data.fname || "",
          lname: data.lname || "",
          address: data.street || "",
          city: data.city || "",
          state: data.state || "",
          email: data.email || "",
          phonecell: data.phone_contact || "",
          date_of_birth: data.DOB || "",
          mobile_phone: data.phone_contact || "",
          patient_full_name : data.fname + " " + data.lname,
          patient_name : data.fname + " " + data.lname,
          full_name : data.fname + " " + data.lname,
          city_state_zip : data.city + "," + data.state + "," + data.postal_code,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [ParamsData.uuid]);


  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const startDrawing = ({ nativeEvent }, fieldName) => {
    const canvas = canvasRefs.current[fieldName];
    if (canvas) {
      const { offsetX, offsetY } = nativeEvent;
      const ctx = canvas.getContext('2d');
      ctx.beginPath();
      ctx.moveTo(offsetX, offsetY);
      drawingRefs.current[fieldName] = true;
    }
  };

  const draw = ({ nativeEvent }, fieldName) => {
    if (!drawingRefs.current[fieldName]) return;
    const canvas = canvasRefs.current[fieldName];
    if (canvas) {
      const { offsetX, offsetY } = nativeEvent;
      const ctx = canvas.getContext('2d');
      ctx.lineTo(offsetX, offsetY);
      ctx.stroke();
    }
  };

  const endDrawing = (fieldName) => {
    drawingRefs.current[fieldName] = false;
    const canvas = canvasRefs.current[fieldName];
    if (canvas) {
      const imageData = canvas.toDataURL();
      setFormValues((prevValues) => ({
        ...prevValues,
        [fieldName]: imageData,
      }));
    }
  };

  const getFieldComponent = (field,index) => {
    switch (field.type) {
      case "header":
        return (
          <h4 key={field.label}>
            {field.label && /<\/?[a-z][\s\S]*>/i.test(field.label) ? (
              <span
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{ __html: field.label }}
              />
            ) : (
              field.label
            )}
          </h4>
        );
      case "text":
        return (
          <label key={field.label} style={{ width: "100%" }}>
            {/* {field.label} */}
            {field.label && /<\/?[a-z][\s\S]*>/i.test(field.label) ? (
              <span
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{ __html: field.label }}
              />
            ) : (
              field.label
            )}
            <input
              type={field.subtype || "text"}
              className={field.className}
              name={field.name}
              required={field.required}
              value={formValues[field.name] || ""}
              onChange={(e) => handleInputChange(field.name, e.target.value)}
            />
          </label>
        );
      case "textarea":
        return (
          <label key={field.label} style={{ width: "100%" }}>
            {field.label && /<\/?[a-z][\s\S]*>/i.test(field.label) ? (
              <span
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{ __html: field.label }}
              />
            ) : (
              field.label
            )}
            <textarea
              className={field.className}
              name={field.name}
              required={field.required}
              // value={BaSicData[field.name] || ''}
              value={formValues[field.name] || ""}
              onChange={(e) => handleInputChange(field.name, e.target.value)}
            />
          </label>
        );

      case "checkbox-group":
        return (
          <div key={field.name}>
            <label style={{ width: "100%" }}>
              {field.label && /<\/?[a-z][\s\S]*>/i.test(field.label) ? (
                <span
                  style={{ width: "100%" }}
                  dangerouslySetInnerHTML={{ __html: field.label }}
                />
              ) : (
                field.label
              )}
            </label>
            {Array.isArray(field.values) &&
              field.values.map((value, index) => (
                <label key={index} style={{ width: "100%" }}>
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    name={value.value}
                    required={field.required}
                    checked={formValues[value.value] || false}
                    onChange={(e) =>
                      handleInputChange(value.value, e.target.checked)
                    }
                    style={{
                      marginRight: "10px",
                      marginLeft:"5px",
                      cursor: "pointer",
                      padding: "10x",
                    }}
                  />
                  {value.label}
                </label>
              ))}
          </div>
        );

        case "list":
          return (
            <ul key={index}>
              {field.items.map((item, itemIndex) => (
                <li key={itemIndex}>{item}</li>
              ))}
            </ul>
          );

          case "image":
            return (
              <div key={index}>
                <label style={{ width: "100%" }}>{field.label}</label>
                <img
                  src={`https://medispacosmos.com/riteclinic-openemr${field.url}`}
                  alt={field.label}
                  style={{ maxWidth: "100%", height: "500px" }}
                />
              </div>
            );

      case "radio-group":
        return (
          <div key={field.label}>
            {field.label && /<\/?[a-z][\s\S]*>/i.test(field.label) ? (
              <span
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{ __html: field.label }}
              />
            ) : (
              field.label
            )}
            {field.values.map((option, index) => (
              <label key={index} style={{ width: "100%" }}>
                <input
                  type="radio"
                  name={field.name}
                  value={option.value}
                  // checked={option.selected}
                  checked={formValues[field.name] === option.value}
                  onChange={(e) =>
                    handleInputChange(field.name, e.target.value)
                  }
                />
                {option.label}
              </label>
            ))}
          </div>
        );
      case "paragraph":
        if (field.subtype === "p") {
          return (
            <div key={field.label}>
              {/* {field.label && (
                        <p style={{ width: "100%" }}>
                          {field.label}
                        </p>
                      )} */}

              {field.label && /<\/?[a-z][\s\S]*>/i.test(field.label) ? (
                <span
                  style={{ width: "100%" }}
                  dangerouslySetInnerHTML={{ __html: field.label }}
                />
              ) : (
                field.label
              )}
            </div>
          );
        }

        
        case "canvas":
          if (field.name === "practitioner_signature" && LoginUserType === "Patient") {
            return null;
          }
          return (
            <div key={index}>
              <canvas
                ref={(el) => (canvasRefs.current[field.name] = el)}
                onMouseDown={(e) => startDrawing(e, field.name)}
                onMouseMove={(e) => draw(e, field.name)}
                onMouseUp={() => endDrawing(field.name)}
                onMouseOut={() => endDrawing(field.name)}
                width={300}
                height={100}
                style={{ border: "1px solid #000" }}
              />
              <b>{field.name}</b>
              <img
                src={formValues[field.name]}
                alt="Captured Signature"
                style={{ maxWidth: "100%", maxHeight: "300px" }}
              />
            </div>
          );
      
      case "date":
        return (
          <label key={field.label} style={{ width: "100%" }}>
            {field.label && (
              <p style={{ marginBottom: "5px" }}>
                {field.label.replace(/<br>/g, "")}
              </p>
            )}
            <input
              type={field.subtype || "text"}
              className={field.className || "form-control"}
              name={field.name}
              required={field.required || false}
              value={formValues[field.name] || ""}
              onChange={(e) => handleInputChange(field.name, e.target.value)}
            />
          </label>
        );

      case "table":
        return (
          <div key={field.label}>
            {field.label && /<\/?[a-z][\s\S]*>/i.test(field.label) ? (
              <span
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{ __html: field.label }}
              />
            ) : (
              <h4>{field.label}</h4>
            )}
            <table className="billing-table">
              <thead>
                <tr>
                  {/* Render table headers */}
                  {field.columns.map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* Render table rows */}
                {field.rows.map((row) => (
                  <tr key={row.id}>
                    {/* Render checkbox input */}
                    <td>
                      <input
                        type="checkbox"
                        // checked={row.selected}
                        name={row.Saline}
                      //  checked={formValues[value.value] || false}
                        className="custom-checkbox"
                        onChange={() =>
                          handleCheckboxChange(row.Saline, row.id)
                        }
                      />
                    </td>
                    {/* Render other table data */}
                    <td>{row.Saline}</td>
                    <td>{row.Units}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <form>
        {AvailableForms &&
          Object.entries(AvailableForms).map(([key, field], index) => (
            <div key={index} className="mb-3">
              {getFieldComponent(field)}
            </div>
          ))}
      </form>
      <Button onClick={handleSubmit}>Save Form Data</Button>
    </div>
  );
};

export default ServiceDynamicForm;
