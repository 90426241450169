import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Card, Col, Container, Fade, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

const SubServicesCards = ({ data }) => {
  const detailsData = data;
  const [SubServicesdata, setSubServicesdata] = useState([]);

  useEffect(() => {
    if (data) {
      setSubServicesdata(detailsData.subtypes);
    }
  }, [data]);

  const filteredServices = SubServicesdata.filter(SubServicesdata => SubServicesdata.activestatus);

  return (
    <div>
      <Container style={{ textAlign: "left" }}>
        <Row>
          {filteredServices &&
            filteredServices?.map((index) => (
              <Col sm={3} style={{ paddingBottom: "15px" }}>
                <Card className="services-card-body">
                  <Card.Img
                    variant="top"
                    src={index.image}
                    style={{ height: "180px" }}
                  />
                  <Card.Body>
                    <Link
                      to={`/services/${index.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Card.Title style={{ borderBottom: "1px solid #1c324a" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <h1 className="services-card-title">
                              {index.title}
                            </h1>
                          </div>
                          <div>
                            <FiArrowRight />
                          </div>
                        </div>
                      </Card.Title>
                      <Card.Text className="serv-ces-card-text">
                        {/* {index.short_info} */}
                        {index.short_info.length > 130
                          ? `${index.short_info.substring(0, 130)}...`
                          : index.short_info}
                      </Card.Text>
                    </Link>
                  </Card.Body>
                  <div style={{ paddingLeft: "20px", paddingBottom: "5px" }}>
                    <Link to="/appointment" style={{ textDecoration: "none" }}>
                      <button className="med-spa-custom-button">
                        Make an Appointment
                      </button>
                    </Link>
                  </div>
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};

export default SubServicesCards;
