import axios from "axios";
import React from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";

const AppointmentModal = ({
  show,
  handleClose,
  selectedTime,
  selectedDate,
  selectedProviderId,
  newfname,
  selectedCategory
}) => {
  const [AppontmentBooked, setAppontmentBooked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const Patient_Pid = sessionStorage.getItem("Patient_Pid");
  const access_token = sessionStorage.getItem("access_token");
  const navigate = useNavigate()

  const BookAppointment = async () => {
    setIsLoading(true);
    const data = {
      pc_catid: selectedCategory.pc_catid,
      pc_title: selectedCategory.pc_catname,
      pc_duration: "1800",
      pc_hometext: "Test",
      pc_apptstatus: "^",
      pc_eventDate: selectedDate,
      pc_startTime: selectedTime,
      pc_facility: "9",
      pc_billing_location: "10",
      pc_aid: selectedProviderId,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_EMR_URL}/riteclinic-openemr/apis/default/api/patient/${Patient_Pid}/appointment`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));

      setTimeout(() => {
        setIsLoading(false);

        swal({
          title: "Appointment Booked Successfully",
          text: "Your Appointment as guest has been Scheduled.",
          icon: "success",
          button: "OK",
          timer: 2000,
        });
        setAppontmentBooked(true);
        handleClose();
        navigate("/")
      }, 5000);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      swal({
        title: "Appointment Booking Failed",
        text: "An error occurred during Appointment Booking, Please Contact to Our Front Desk.",
        icon: "error",
        button: "OK",
        timer: 3000,
      });
      handleClose();
    }
  };

  return (
    <div>
    
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Appointment Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="med-spa-staff-info-p-tag">
          Thank you for choosing Cosmos Medi Spa for your wellness needs! Your
          appointment request has been received, and we are delighted to confirm
          your booking.
        </p>
        <h1 className="wel-come-staff-info" style={{ fontSize: "24px" }}>
          Confirmation Details:
        </h1>
        <p className="med-spa-staff-info-p-tag">
          <b>Date:</b> {selectedDate} <br />
          <b>Time:</b> {selectedTime}
        </p>
        <p className="med-spa-staff-info-p-tag">
          We look forward to welcoming you to Cosmos Medi Spa and ensuring your
          experience is both relaxing and fulfilling. Should you have any
          questions or require further assistance, feel free to reach out to us.
        </p>
        <p className="med-spa-staff-info-p-tag">
          {" "}
          <b>Warm regards,</b>
          <br />
          The Cosmos Medi Spa Team
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          variant="primary"
          onClick={BookAppointment}
          className="med-spa-custom-button"
        >
          Confirm Appointment
        </button>
      </Modal.Footer>
        {isLoading && <Loader fname={newfname} />}
    </Modal>
</div>
  );
};

export default AppointmentModal;
