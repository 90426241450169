import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Row, Form, Card, Col } from "react-bootstrap";
import DigiLogo from "../../Assets/cosmosLogocolor.png";
import PaymentGateway from "../CardPayments/PaymentGateway";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ExternamPaymentGateway from "../PaymentGateway/ExternamPaymentGateway";


const BillQrPayment = () => {

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PROMISE
  );
  const paramsData = useParams();
  const [tipOption, setTipOption] = useState(null);
  const [customTipAmount, setCustomTipAmount] = useState("");
  const [tipAmount, setTipAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState(paramsData.finalAmount);

  const handleTipOptionChange = (event) => {
    setTipOption(event.target.value);
  };

  const handleCustomTipAmountChange = (event) => {
    setCustomTipAmount(event.target.value);
  };

  const handleApplyTip = () => {
    let newTipAmount = 0;

    if (tipOption === "custom") {
      newTipAmount = parseFloat(customTipAmount).toFixed(2);
    } else if (tipOption !== "") {
      newTipAmount = (
        (parseFloat(tipOption) / 100) *
        paramsData.finalAmount
      ).toFixed(2);
    }

    const newFinalAmount = (
      parseFloat(paramsData.finalAmount) + parseFloat(newTipAmount)
    ).toFixed(2);

    setTipAmount(newTipAmount);
    setFinalAmount(newFinalAmount);
  };

  const handleRemoveTip = () => {
    setTipOption("");
    setCustomTipAmount("");
    setTipAmount(0);
    setFinalAmount(paramsData.finalAmount);
  };
  const cardStyle = {
    margin: "auto",
    height: "90vh",
    width: "100%",
    background: "#6665DD",
    // padding: "20px",
    // borderRadius: "50px",
    color: "white",
  };

  const { fname, lname, email,BillId,paymentFor } = useParams();
  const patientData  = { fname, lname, email,BillId,paymentFor };
  console.log("patientData",patientData)
  const [paymentDashboard,setpaymentDashboard]=useState(false)
  const handlepaymentDashboardOn =()=>{
    setpaymentDashboard(true)
  }
  const handlepaymentDashboardOff=()=>{
    setpaymentDashboard(false)
  }
  return (
    <div>
      <img src={DigiLogo} alt="" style={{ height: "80px", width: "200px" }} />
      <Card style={cardStyle} className="mt-3">
        <Card.Body>
        {paymentDashboard &&
          <div>
                  <Button variant="info" onClick={handlepaymentDashboardOff}>Go Back</Button>
                  </div>
        }
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
          {paymentDashboard ==false ?
            <div>
              <h3 style={{ fontSize: "18px",borderBottom:"2px solid black", textAlign:"center" }}>
                Total Bill - $ {paramsData.finalAmount}
              </h3>

              <p
                style={{
                  textAlign: "justify",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                <i>
                  If our service left you glowing with satisfaction, consider
                  adding a little sparkle with a gratuity. Your support fuels
                  our passion for excellence. <br /> Thank you for shining with
                  us!
                </i>
              </p>
              <div>
                <Form.Group controlId="tipOption">
                  <Row>
                    <Col sm={4}>
                      <Form.Check
                        inline
                        label={`5% (${paramsData.finalAmount * 0.05})`}
                        type="radio"
                        value="5"
                        checked={tipOption === "5"}
                        onChange={handleTipOptionChange}
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Check
                        inline
                        label={`10% (${paramsData.finalAmount * 0.1})`}
                        type="radio"
                        value="10"
                        checked={tipOption === "10"}
                        onChange={handleTipOptionChange}
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Check
                        inline
                        label={`15% (${paramsData.finalAmount * 0.15})`}
                        type="radio"
                        value="15"
                        checked={tipOption === "15"}
                        onChange={handleTipOptionChange}
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Check
                        inline
                        label="Custom"
                        type="radio"
                        value="custom"
                        checked={tipOption === "custom"}
                        onChange={handleTipOptionChange}
                      />
                    </Col>
                  </Row>
                  <div
                    className="mb-3 mt-3"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {tipOption === "custom" && (
                      <Form.Control
                        type="number"
                        placeholder="Enter custom tip amount"
                        value={customTipAmount}
                        onChange={handleCustomTipAmountChange}
                        style={{ maxWidth: "300px" }}
                      />
                    )}
                  </div>
                </Form.Group>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    variant="danger"
                    onClick={handleRemoveTip}
                    disabled={tipAmount == 0}
                  >
                    Remove Tip
                  </Button>
                  <Button
                    className="mr-2"
                    onClick={handleApplyTip}
                    disabled={tipOption == null}
                  >
                    Apply Tip
                  </Button>
                </div>

                <div className="mt-3">Total Tip: {tipAmount}</div>
                <div>Final Amount: {finalAmount}</div>
              </div>
              <div style={{ textAlign: "center" }} className="mt-3">
                <Button variant="success" onClick={handlepaymentDashboardOn}>Proceed to Pay ${finalAmount}</Button>
              </div>
            </div>
            : 
            <div style={{width:"100%"}}>
              
                  <div style={{backgroundColor:"white",color:"black",padding:"20px",borderRadius:"20px"}}>
                  <Elements stripe={stripePromise}>
                <ExternamPaymentGateway
                  patientData={patientData}
                  amount={finalAmount}
                  onSuccess={handlepaymentDashboardOn}
                />
              </Elements>
                  </div>
                  </div>
            }
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default BillQrPayment;
