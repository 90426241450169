import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../../Components/Loader/Loader";
import PatientAccessToken from "../../GlobalFiles/ApiServices/PatientAccessToken";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";

const RecurringAppointmentSlot = ({
  selectedDate,
  PatientToBookAppointment,
  onSuccess,
  updateAppointmentData,
}) => {
  const LoginUserType = sessionStorage.getItem("LoginUserType");
  const [appointmentSchedule, setAppointmentSchedule] = useState();
  const [timeSlots, setTimeSlots] = useState([]);
  const [bookedSlots, setBookedSlots] = useState([]);
  const accessToken = sessionStorage.getItem("accessToken");

  const [SevicesData, setSevicesData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `/apis/default/api/masterServices`;

        if (LoginUserType == "Patient") {
          endpoint = `/apis/default/portal/masterServices`;
        }

        const AppointmentDataResponse = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });
        setSevicesData(AppointmentDataResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const groupedServices = {};
  SevicesData &&
    SevicesData.forEach((service) => {
      if (!groupedServices[service.service]) {
        groupedServices[service.service] = [];
      }
      groupedServices[service.service].push(service);
    });

  useEffect(() => {
    setSelectedService(
      PatientToBookAppointment && PatientToBookAppointment.appointment_type
    );
  }, [updateAppointmentData]);

  const [selectedService, setSelectedService] = useState(null);
  const handleServiceChange = (event) => {
    const selectedServiceData = SevicesData.find(
      (service) => service.service_no == event.target.value
    );

    if (selectedServiceData) {
      setSelectedService({
        service_no: selectedServiceData.service_no,
        service: selectedServiceData.service,
      });
    } else {
    }
  };

  useEffect(() => {
    if (SevicesData && SevicesData.length > 0) {
      const firstService = SevicesData[0];
      setSelectedService({
        service_no: firstService.service_no,
        service: firstService.service,
      });
    }
  }, [SevicesData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `/apis/default/api/appointmentSlot`;

        if (LoginUserType == "Patient") {
          endpoint = `/apis/default/portal/appointmentSlot`;
        }
        const appointmentDataResponse = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });
        const appointmentData = appointmentDataResponse.data["0"];
        setAppointmentSchedule(appointmentData);
        const bookedSlotsData = appointmentDataResponse.data.booked_slots;
        setBookedSlots(bookedSlotsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [selectedDate]);

  useEffect(() => {
    if (appointmentSchedule) {
      generateTimeSlots();
    }
  }, [selectedDate, appointmentSchedule, bookedSlots]);

  const generateTimeSlots = () => {
    const currentDate = new Date(selectedDate);
    const currentTime = new Date();
    const startTime = new Date(currentDate);
    const endTime = new Date(currentDate);

    const startHours = parseInt(
      appointmentSchedule.pc_startTime.split(":")[0],
      10
    );
    const startMinutes = parseInt(
      appointmentSchedule.pc_startTime.split(":")[1],
      10
    );

    const endHours = parseInt(appointmentSchedule.pc_endTime.split(":")[0], 10);
    const endMinutes = parseInt(
      appointmentSchedule.pc_endTime.split(":")[1],
      10
    );

    if (LoginUserType === "Patient") {
      startTime.setHours(10, 0, 0, 0);
      endTime.setHours(18, 0, 0, 0);
    } else {
      startTime.setHours(7, 45, 0, 0);
      endTime.setHours(20, 0, 0, 0);
    }

    const slots = [];

    while (startTime <= endTime) {
      const slotTime = startTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });

      const isBooked = isSlotBooked(currentDate, slotTime);
      slots.push({ time: slotTime, isBooked });
      startTime.setMinutes(startTime.getMinutes() + 45);
    }

    setTimeSlots(slots);
  };

  const isSlotBooked = (selectedDate, slotTime) => {
    const year = selectedDate.getFullYear();
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = selectedDate.getDate().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    const bookedSlotsForDate =
      bookedSlots &&
      bookedSlots.filter((slot) => slot.pc_eventDate === formattedDate);

    const bookedSlotsForTime =
      bookedSlotsForDate &&
      bookedSlotsForDate.filter((slot) => {
        const bookedSlotTime = slot.pc_startTime.substring(0, 5);
        return bookedSlotTime === slotTime.substring(0, 5);
      });

    // Check if there are two bookings for the same start time
    const isBooked = bookedSlotsForTime && bookedSlotsForTime.length === 2;

    const currentTime = new Date();
    const slotDateTime = new Date(`${formattedDate} ${slotTime}`);
    const isPast = slotDateTime < currentTime;

    return isBooked || isPast;
  };

  const [selectedTimeSlot, setselectedTimeSlot] = useState("");
  const [selectedSlotDate, setselectedSlotDate] = useState("");
  const handleSlotSelection = (selectedSlot) => {
    setselectedTimeSlot(selectedSlot.time);
    const year = selectedDate.getFullYear();
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = selectedDate.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setselectedSlotDate(formattedDate);
  };

  const [IsLoading, setIsLoading] = useState(false);

  const BookAppointment = async () => {
    setIsLoading(true);

    let PatientData;
    if (PatientToBookAppointment) {
      PatientData = PatientToBookAppointment;
    } else {
      PatientData = await PatientAccessToken.fetchData();
    }

    const appointments = generatedDates.map(date => {
      return {
        pc_catid: "5",
        pc_title: "Office Visit",
        pc_duration: "1800",
        pc_hometext: "Test",
        pc_apptstatus: "^",
        pc_eventDate: date,
        pc_startTime: selectedTimeSlot,
        pc_facility: "9",
        pc_billing_location: "10",
        pc_aid: "1",
        client_type: "Registered",
        wallet_id: null,
        user_type: null,
        is_billed: 0,
        assigned_by: null,
        generated_bill_provider: 0,
        verified_receptionist: 0,
        appointment_type: selectedService.service ,
        service_id: selectedService.service_no,
        appointment_note: "",
      };
    });

    try {
      for (const data of appointments) {
        const config = {
          method: "post",
          url: PatientToBookAppointment
            ? `${process.env.REACT_APP_SERVERURL}/apis/default/api/patient/${PatientData.pid}/appointment`
            : `${process.env.REACT_APP_SERVERURL}/apis/default/portal/patient/${PatientData.pid}/appointment`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          data: data,
        };
        await axios.request(config);
      }
      
      if (PatientData.is_portal_sent == 0) {
        try {
          let endpoint = `/apis/default/api/intakeForms`;

          if (LoginUserType == "Patient") {
            endpoint = `/apis/default/portal/intakeForms`;
          }
          await ApiServices.put(
            endpoint,
            {
              pid: PatientToBookAppointment ? PatientData.pid : PatientData.pid,
            },
            {
              Authorization: `Bearer ${accessToken}`,
            }
          );
        } catch (error) {
          console.error("Error during API call:", error);
        }
      }

      setTimeout(() => {
        setIsLoading(false);

        swal({
          title: "Appointments Booked Successfully",
          text: "Your Appointments at Cosmos Medi Spa have been Scheduled.",
          icon: "success",
          button: "OK",
          timer: 2000,
        });
        if (typeof onSuccess === "function") {
          onSuccess();
        }
      }, 2000);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      swal({
        title: "Appointment Booking Failed",
        text: "An error occurred during Appointment Booking, Please Contact to Our Front Desk.",
        icon: "error",
        button: "OK",
        timer: 3000,
      });
    }
  };

  const [recurrenceNumber, setRecurrenceNumber] = useState(1);
  const [recurrenceType, setRecurrenceType] = useState("day");
  const [numAppointments, setNumAppointments] = useState(1);
  const [generatedDates, setGeneratedDates] = useState([]);

  const handleNumAppointmentsChange = (event) => {
    setNumAppointments(parseInt(event.target.value));
  };

  const handleRecurrenceNumberChange = (event) => {
    setRecurrenceNumber(event.target.value);
  };

  const handleRecurrenceTypeChange = (event) => {
    setRecurrenceType(event.target.value);
  };

  const generateRecurringDates = () => {
    const dates = [];
    const currentDate = new Date(selectedSlotDate);
    const interval = parseInt(recurrenceNumber, 10);

    for (let i = 0; i < numAppointments; i++) {
      const newDate = new Date(currentDate);
      switch (recurrenceType) {
        case "day":
          newDate.setDate(currentDate.getDate() + interval * i);
          break;
        case "week":
          newDate.setDate(currentDate.getDate() + interval * 7 * i);
          break;
        case "month":
          newDate.setMonth(currentDate.getMonth() + interval * i);
          break;
        case "year":
          newDate.setFullYear(currentDate.getFullYear() + interval * i);
          break;
        default:
          break;
      }
      dates.push(newDate.toISOString().split("T")[0]);
    }

    setGeneratedDates(dates);
  };

  useEffect(() => {
    if (selectedSlotDate) {
      generateRecurringDates();
    }
  }, [selectedSlotDate, recurrenceNumber, recurrenceType, numAppointments]);

  return (
    <div>
      {IsLoading && <Loader />}
      <div style={{ paddingTop: "10px" }}>
        <div>
          <label>
            Select The Service for Appointment
            <select
              style={{
                width: "100%",
                padding: "5px",
                border: "1px solid gray",
                marginBottom: "10px",
              }}
              value={selectedService && selectedService.service_no}
              onChange={handleServiceChange}
            >
              {SevicesData &&
                Array.from(
                  new Set(SevicesData.map((service) => service.service))
                ).map((uniqueService, index) => {
                  const uniqueServiceData = SevicesData.find(
                    (service) => service.service == uniqueService
                  );
                  return (
                    <option key={index} value={uniqueServiceData.service_no}>
                      {uniqueService}
                    </option>
                  );
                })}
            </select>
          </label>
        </div>

        <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
          {timeSlots.map((slot, index) => (
            <button
              key={index}
              className="med-spa-custom-button"
              style={{
                backgroundColor:
                  selectedTimeSlot && selectedTimeSlot === slot.time
                    ? "green"
                    : slot.isBooked
                    ? "#C0C0C0"
                    : "#4851D5",
                cursor: slot.isBooked ? "not-allowed" : "pointer",
                minWidth: "73px",
              }}
              disabled={slot.isBooked}
              onClick={() => handleSlotSelection(slot)}
            >
              {slot.time}
            </button>
          ))}
        </div>

  

        <div style={{ paddingTop: "15px" }}>
          <label>
            Select Number of Appointments
            <select
              style={{
                width: "100%",
                padding: "5px",
                border: "1px solid gray",
                marginBottom: "10px",
              }}
              value={numAppointments}
              onChange={handleNumAppointmentsChange}
            >
              {Array.from({ length: 50 }, (_, i) => i + 1).map((number) => (
                <option key={number} value={number}>
                  {number}
                </option>
              ))}
            </select>
          </label>
        </div>

        <div style={{ paddingTop: "5px",display:"flex" ,gap:"5px"}}>
          <label>
            Recurrence Number
            <select
              style={{
                width: "100%",
                padding: "5px",
                border: "1px solid gray",
                marginBottom: "10px",
              }}
              value={recurrenceNumber}
              onChange={handleRecurrenceNumberChange}
            >
              {Array.from({ length: 15 }, (_, i) => i + 1).map((number) => (
                <option key={number} value={number}>
                  {number}
                </option>
              ))}
            </select>
          </label>

          <label>
            Recurrence Type
            <select
              style={{
                width: "100%",
                padding: "5px",
                border: "1px solid gray",
                marginBottom: "10px",
              }}
              value={recurrenceType}
              onChange={handleRecurrenceTypeChange}
            >
              {["day", "week", "month", "year"].map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </label>
        </div>

        {generatedDates.length > 0 && (
          <div style={{ paddingTop: "5px" }}>
            <h5>Appointment Dates:</h5>
            <ul>
              {generatedDates.map((date, index) => (
                <li key={index}>
                  {date} at {selectedTimeSlot}
                </li>
              ))}
            </ul>
          </div>
        )}

        <div style={{ paddingTop: "15px" }}>
          <div>
            {selectedTimeSlot && (
              <div>
                <p className="section-one-para-tag">
                  Do You want to Confirm Appointments on above Dates.
                </p>

                <div style={{ paddingTop: "10px", textAlign: "right" }}>
                  <button
                    className="med-spa-custom-button"
                    onClick={BookAppointment}
                  >
                    Book Appointment
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecurringAppointmentSlot;
