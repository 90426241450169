import React, { useState } from "react";
import axios from "axios";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import PasswordLogo from "../../Assets/teenyicons_password-solid.svg";
import EmailLogo from "../../Assets/dashicons_email.svg";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import UserLogo from "../../Assets/ep_user-filled.svg";
import Eclipse from "../../Assets/Ellipse1.svg";
import swal from "sweetalert";
import { PiEyeClosedFill } from "react-icons/pi";
import { RxEyeOpen } from "react-icons/rx";

const VerifyAccountEmail = () => {
  const location = useLocation();

  // Extract the value of the 'forward' parameter from the query string
  const queryParams = new URLSearchParams(location.search);
  const forwardValue = queryParams.get("forward");
  const decodedForward = decodeURIComponent(forwardValue);
  const [formData, setFormData] = useState({
    forward: "",
    email: "",
    login_uname: "",
    onetime_pass: "",
    pass_new: "",
    pass_new_confirm: "",
  });

  const [errors, setErrors] = useState({});

  const navigate = useNavigate("");
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Convert email to lowercase before updating the state
    if (name === "email") {
      setFormData({ ...formData, [name]: value.toLowerCase() });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // console.log("formData",formData)

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const validationErrors = validateForm(formData);
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
      formData.forward = forwardValue;
      formData.login_uname = formData.email;
      const response = await ApiServices.post(
        "/apis/default/api/patientneworresetpass",
        formData
      );

      console.log("response",response.data)
      if(response.data.data.status == true){
      swal({
        title: "Credentials Generated Successfully",
        text: "You can Log in to Your account through new Credentials.",
        icon: "success",
        button: "OK",
        timer: 2000,
      });
      navigate("/");
    }
    else{
      swal({
        title: "Something Went Wrong",
        text: "Please Check the One Time Password and Link is Valid or Not.",
        icon: "warning",
        button: "OK",
        timer: 2000,
      });
    }
      console.log("API Response:", response.data);
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
    }
  };

  const validateForm = (data) => {
    const errors = {};

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(data.email)) {
      errors.email = "Invalid email address";
    }
    if (data.onetime_pass.length !== 6) {
      errors.onetime_pass = "One-time password must be 6 characters";
    }

    // if (data.pass_new !== data.pass_new_confirm) {
    //   errors.pass_new_confirm = "Passwords do not match";
    // }

    if (!data.pass_new) {
      errors.pass_new = "New password is required";
    }
  
    if (!data.pass_new_confirm) {
      errors.pass_new_confirm = "Confirm password is required";
    } else if (data.pass_new !== data.pass_new_confirm) {
      errors.pass_new_confirm = "Passwords do not match";
    }

    return errors;
  };

  const cardStyle = {
    margin: "auto",
    height: "auto",
    width: "900px",
    background: "#6665DD",
    padding: "20px",
    borderRadius: "50px",
  };

  const divBackgroundImgStyle = {
    backgroundImage: `url(${Eclipse})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Container
      style={{ display: "flex", justifyContent: "center", height: "100vh" }}
    >
      <Card style={cardStyle}>
        <Card.Body>
          <Row style={{ alignItems: "center" }}>
            <Col sm={6}>
              <div>
                <h1
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "30px",
                  }}
                >
                  Unlock Your Cosmos Medi Spa Patient Portal Credentials
                </h1>
                <p style={{ color: "white", textAlign: "justify" }}>
                  Welcome to Cosmos Medi Spa, your gateway to personalized
                  healthcare. To complete your journey, we need your help.
                  Please add the one-time password you've received in your email
                  to unlock and create your Cosmos Medi Spa Patient Portal
                  credentials. This is your key to seamless access to your
                  health records, appointments, and more.
                </p>
                <p style={{ color: "white", textAlign: "justify" }}>
                  <i>
                    Fill in the username and password fields below, and let the
                    journey to a healthier you begin.
                  </i>
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <div
                style={{
                  paddingBottom: "20px",
                  paddingTop: "10px",
                }}
              >
                <form onSubmit={handleSubmit}>
                  <label
                    style={{
                      color: "white",
                      fontWeight: "600",
                      paddingBottom: "10px",
                    }}
                  >
                    Email :
                  </label>
                  <div className="login-form-flex-div">
                    <img
                      src={EmailLogo}
                      alt=""
                      className="login-form-input-logo"
                    />
                    <input
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="login-form-input-field"
                    />
                  </div>
                  <div style={{ marginTop: "-15px" }}>
                    <span style={{ color: "red" }}>{errors.email}</span>
                  </div>

                  <label
                    style={{
                      color: "white",
                      fontWeight: "600",
                      paddingBottom: "10px",
                    }}
                  >
                    One-Time Password:
                  </label>
                  <div className="login-form-flex-div">
                    <img
                      src={PasswordLogo}
                      alt=""
                      className="login-form-input-logo"
                    />
                    <input
                      type="number"
                      name="onetime_pass"
                      value={formData.onetime_pass}
                      onChange={handleChange}
                      className="login-form-input-field"
                    />
                  </div>
                  <div style={{ marginTop: "-15px" }}>
                    <span style={{ color: "red" }}>{errors.onetime_pass}</span>
                  </div>

                  <label
                    style={{
                      color: "white",
                      fontWeight: "600",
                      paddingBottom: "10px",
                    }}
                  >
                    New Password :
                  </label>
                  <div className="login-form-flex-div">
                    <img
                      src={PasswordLogo}
                      alt=""
                      className="login-form-input-logo"
                    />
                    <input
                      type={showPassword ? "text" : "password"}
                      name="pass_new"
                      value={formData.pass_new}
                      onChange={handleChange}
                      className="login-form-input-field"
                    />
                    {showPassword ? (
                      <PiEyeClosedFill
                        type="button"
                        onClick={togglePasswordVisibility}
                        style={{ color: "white", fontSize: "25px" }}
                      />
                    ) : (
                      <RxEyeOpen
                        type="button"
                        onClick={togglePasswordVisibility}
                        style={{ color: "white", fontSize: "25px" }}
                      />
                    )}
                  </div>

                  <div style={{ marginTop: "-15px" }}>
  <span style={{ color: "red" }}>{errors.pass_new}</span>
</div>

                  <label
                    style={{
                      color: "white",
                      fontWeight: "600",
                      paddingBottom: "10px",
                    }}
                  >
                    Confirm Password :
                  </label>
                  <div className="login-form-flex-div">
                    <img
                      src={PasswordLogo}
                      alt=""
                      className="login-form-input-logo"
                    />
                    <input
                      type={showPassword ? "text" : "password"}
                      name="pass_new_confirm"
                      value={formData.pass_new_confirm}
                      onChange={handleChange}
                      className="login-form-input-field"
                    />
                    {showPassword ? (
                      <PiEyeClosedFill
                        type="button"
                        onClick={togglePasswordVisibility}
                        style={{ color: "white", fontSize: "25px" }}
                      />
                    ) : (
                      <RxEyeOpen
                        type="button"
                        onClick={togglePasswordVisibility}
                        style={{ color: "white", fontSize: "25px" }}
                      />
                    )}
                  </div>
                  <div style={{ marginTop: "-15px" }}>
                    <span style={{ color: "red" }}>
                      {errors.pass_new_confirm}
                    </span>
                  </div>

                  <button className="login-form-login-button" type="submit">
                    Create Credentials
                  </button>
                </form>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default VerifyAccountEmail;
