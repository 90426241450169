import React, { useEffect, useState } from "react";
import fetchServiceBill from "../../GlobalFiles/ApiServices/FetchServiceBill";
import { useParams, useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Button, Modal, Form, Card, Row, Col } from "react-bootstrap";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import QRCode from "qrcode.react";
import PaymentGateway from "../CardPayments/PaymentGateway";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import swal from "sweetalert";
import axios from "axios";
import Loader from "../Loader/Loader";
import GenerateBill from "../GenerateBill/GenerateBill";
import UpdateGenerateBill from "../GenerateBill/UpdateGenerateBill";

const ServiceBillNew = () => {
  const paramsData = useParams();

  const LoginUserType = sessionStorage.getItem("LoginUserType");
  const LoginUserID = sessionStorage.getItem("LoginUserID");
  const accessToken = sessionStorage.getItem("accessToken");
  const LoginPatientUUID = sessionStorage.getItem("LoginPatientUUID");
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PROMISE
  );
  const [loading, setloading] = useState(false);
  const [fetchBillingDataAgin, setfetchBillingDataAgin] = useState(false);
  const [BillingMainData, setBillingMainData] = useState(null);
  const [ServicebillingData, setServicebillingData] = useState([]);
  const [ProductbillingData, setProductbillingData] = useState([]);
  const [finalAmount, setFinalAmount] = useState(0);
  const [amounttoPay, setamounttoPay] = useState(0);
  const [partialPayment, setpartialPayment] = useState(0);
  const [remainingPayment, setremainingPayment] = useState(0);
  const [encodedProductBillDataNew, setencodedProductBillDataNew] = useState();
  const [encodedServiceBillDataNew, setencodedServiceBillDataNew] = useState();
  const [walletDeduction, setWalletDeduction] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { billingData } = await fetchServiceBill.fetchData({
          eid: paramsData.eid,
          pid: paramsData.pid,
        });
        const ServicefilteredData = billingData.filter(
          (item) =>
            item.service_default_price !== null &&
            item.service_discount !== null &&
            item.service_id !== null &&
            item.service_quantity !== null &&
            item.service_title !== null &&
            item.service_total_amount !== null &&
            item.eid
        );
        const ServicemappedData = ServicefilteredData.map((item) => ({
          service_id: item.service_id,
          service_title: item.service_title,
          service_quantity: item.service_quantity,
          service_default_price: item.service_default_price,
          service_discount: item.service_discount,
          service_total_amount: item.service_total_amount,
          eid: item.eid,
        }));
        setServicebillingData(ServicemappedData);
        const encodedServiceBillData = btoa(ServicemappedData);
        setencodedServiceBillDataNew(encodedServiceBillData);

        const ProductfilteredData = billingData.filter(
          (item) =>
            item.product_id !== null &&
            item.product_title !== null &&
            item.product_default_price !== null &&
            item.product_discount !== null &&
            item.product_quantity !== null &&
            item.product_total_amount !== null &&
            item.eid
        );
        const ProductmappedData = ProductfilteredData.map((item) => ({
          product_id: item.product_id,
          product_title: item.product_title,
          product_default_price: item.product_default_price,
          product_discount: item.product_discount,
          product_quantity: item.product_quantity,
          product_total_amount: item.product_total_amount,
          eid: item.eid,
          product: true,
        }));
        setProductbillingData(ProductmappedData);
        const encodedProductBillData = btoa(ProductmappedData);
        setencodedProductBillDataNew(encodedProductBillData);

        const extractedBillingMainData = {
          amount_after_tip: billingData[0].amount_after_tip,
          card1_payment: billingData[0].card1_payment,
          card2_payment: billingData[0].card2_payment,
          cash: billingData[0].cash,
          credit_card: billingData[0].credit_card,
          date_of_billing: billingData[0].date_of_billing,
          eid: billingData[0].eid,
          id: billingData[0].id,
          is_billed: billingData[0].is_billed,
          is_card1_payment: billingData[0].is_card1_payment,
          is_card2_payment: billingData[0].is_card2_payment,
          pid: billingData[0].pid,
          tip: billingData[0].tip,
          total_amount: billingData[0].total_amount,
          total_tax: billingData[0].total_tax,
          verified_receptionist: billingData[0].verified_receptionist,
          wallet_money: billingData[0].wallet_money,
          wallet_id: billingData[0].wallet_id,
          bill_id: billingData[0].bill_id,
        };

        setBillingMainData(extractedBillingMainData);
        const totalAmount = Number(billingData[0].total_amount);
        setFinalAmount(totalAmount);
        setpartialPayment(totalAmount);
        setremainingPayment(0);
        // setFinalAmount(billingData[0].total_amount);
        // setpartialPayment(billingData[0].total_amount);
        // setremainingPayment(billingData[0].total_amount - partialPayment);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [paramsData, fetchBillingDataAgin]);

  const [patientData, setpatientData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `/apis/default/api/patient/${paramsData.uuid}`;

        if (LoginUserType == "Patient") {
          endpoint = `/apis/default/portal/patient/${LoginPatientUUID}`;
        }
        const patientResponse = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });

        const data = patientResponse.data.data;
        setpatientData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [paramsData, LoginPatientUUID]);

  const handleChangepartialPayment = (e) => {
    setpartialPayment(e.target.value);
    setremainingPayment(Number(finalAmount) - Number(partialPayment));
  };

  // SERVICES
  const columnDefs = [
    {
      headerName: "Title",
      field: "service_title",
      headerClass: "custom-header-of-tables",
      minWidth: 80,
      flex: 1,
    },

    {
      headerName: "Quantity",
      field: "service_quantity",
      headerClass: "custom-header-of-tables",
      minWidth: 100,
      flex: 1,
    },
    {
      headerName: "Price",
      field: "service_default_price",
      headerClass: "custom-header-of-tables",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Discount in %",
      field: "service_discount",
      headerClass: "custom-header-of-tables",
      minWidth: 180,
      flex: 1,
    },
    {
      headerName: "Final Amount",
      field: "service_total_amount",
      headerClass: "custom-header-of-tables",
      minWidth: 240,
      flex: 1,
    },

    LoginUserType !== "Patient" && {
      headerName: "Actions",
      headerClass: "custom-header-of-tables",
      cellRenderer: (params) => (
        <Button
          onClick={() => openModal(params.data)}
          disabled={BillingMainData && BillingMainData.is_billed == 1}
        >
          Edit
        </Button>
      ),
    },
  ];
  // Product
  const ProductcolumnDefs = [
    {
      headerName: "Title",
      field: "product_title",
      headerClass: "custom-header-of-tables",
      minWidth: 80,
      flex: 1,
    },

    {
      headerName: "Quantity",
      field: "product_quantity",
      headerClass: "custom-header-of-tables",
      minWidth: 100,
      flex: 1,
    },
    {
      headerName: "Price",
      field: "product_default_price",
      headerClass: "custom-header-of-tables",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Discount in %",
      field: "product_discount",
      headerClass: "custom-header-of-tables",
      minWidth: 180,
      flex: 1,
    },
    {
      headerName: "Final Amount",
      field: "product_total_amount",
      headerClass: "custom-header-of-tables",
      minWidth: 240,
      flex: 1,
    },
    LoginUserType !== "Patient" && {
      headerName: "Actions",
      headerClass: "custom-header-of-tables",
      cellRenderer: (params) => (
        <Button
          onClick={() => openModal(params.data)}
          disabled={BillingMainData && BillingMainData.is_billed == 1}
        >
          Edit
        </Button>
      ),
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editedQuantity, setEditedQuantity] = useState(0);
  const [editedDiscount, setEditedDiscount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const openModal = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal(true);

    if (rowData) {
      if (rowData.product) {
        setEditedQuantity(parseFloat(rowData.product_quantity) || 0);
        setEditedDiscount(parseFloat(rowData.product_discount) || 0);
      } else {
        setEditedQuantity(parseFloat(rowData.service_quantity) || 0);
        setEditedDiscount(parseFloat(rowData.service_discount) || 0);
      }
    }
  };

  const handleDeleteBillSingleEntry = () => {
    let endpoint = `/apis/default/api/appointment/${BillingMainData.bill_id}/billingweb/${selectedRowData.service_id}`;

    if (selectedRowData.product) {
      endpoint = `/apis/default/api/appointment/${BillingMainData.bill_id}/billingwebProduct/${selectedRowData.product_id}`;
    }

    ApiServices.delete(endpoint, {
      Authorization: `Bearer ${accessToken}`,
    })
      .then((response) => {
        setfetchBillingDataAgin(!fetchBillingDataAgin);
        setShowModal(false);
      })
      .catch((error) => {
        setfetchBillingDataAgin(!fetchBillingDataAgin);
        setShowModal(false);
      });
  };

  const handleQuantityChange = (event) => {
    setEditedQuantity(event.target.value);
  };

  const handleDiscountChange = (event) => {
    setEditedDiscount(event.target.value);
  };

  const handleSaveChanges = () => {
    let endpoint = `/apis/default/api/appointment/${BillingMainData.bill_id}/billingweb/${selectedRowData.service_id}`;

    if (selectedRowData.product) {
      endpoint = `/apis/default/api/appointment/${selectedRowData.eid}/billingwebProduct/${selectedRowData.product_id}`;
    }

    let updatedData = {
      service_quantity: editedQuantity,
      service_discount: editedDiscount,
      bill_id: BillingMainData.bill_id,
      service_id: selectedRowData.service_id,
    };

    if (selectedRowData.product) {
      updatedData = {
        product_quantity: editedQuantity,
        product_discount: editedDiscount,
        bill_id: BillingMainData.bill_id,
        product_id: selectedRowData.product_id,
      };
    }

    ApiServices.put(endpoint, updatedData, {
      Authorization: `Bearer ${accessToken}`,
    })
      .then((response) => {
        setfetchBillingDataAgin(!fetchBillingDataAgin);
        setShowModal(false);
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
  };

  const [tipOption, setTipOption] = useState(null);
  const [customTipAmount, setCustomTipAmount] = useState("");
  const [tipAmount, setTipAmount] = useState(0);

  const handleTipOptionChange = (event) => {
    setTipOption(event.target.value);
  };

  const handleCustomTipAmountChange = (event) => {
    setCustomTipAmount(event.target.value);
  };

  const handleApplyTip = () => {
    let newTipAmount = 0;

    if (tipOption === "custom") {
      newTipAmount = parseFloat(customTipAmount).toFixed(2);
    } else if (tipOption !== "") {
      newTipAmount = (
        (parseFloat(tipOption) / 100) *
        BillingMainData.total_amount
      ).toFixed(2);
    }

    const newFinalAmount = (
      parseFloat(BillingMainData.total_amount) + parseFloat(newTipAmount)
    ).toFixed(2);

    setTipAmount(newTipAmount);
    setFinalAmount(newFinalAmount);
  };

  const handleRemoveTip = () => {
    setTipOption("");
    setCustomTipAmount("");
    setTipAmount(0);
    setFinalAmount(BillingMainData.total_amount);
  };

  const [paymentMethod, setPaymentMethod] = useState("");
  const [firstCardAmount, setFirstCardAmount] = useState(0);
  const [cashAmount, setCashAmount] = useState(0);

  const handlePaymentMethodSelect = (method) => {
    setPaymentMethod(method);
  };

  const handleDualCardPayment = () => {
    const secondCardAmount = finalAmount - firstCardAmount;
    console.log(`Payment received via dual card`);
    console.log(`First Card Amount: ${firstCardAmount}`);
    console.log(`Second Card Amount: ${secondCardAmount}`);
  };

  const handleCardCashPayment = () => {
    const cardAmount = finalAmount - cashAmount;
    console.log(`Payment received via card + cash`);
    console.log(`Cash Amount: ${cashAmount}`);
    console.log(`Card Amount: ${cardAmount}`);
  };

  const sendPaymentEmail = async () => {
    setloading(true);
    try {
      const response = await axios.post(
        "https://cosmosapi.medispacosmos.com/api/send-payment-email/",
        {
          email: patientData.email,
          amount: parseFloat(amounttoPay),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      swal({
        title: "Payment Link Send Successful",
        text: `Pleasen Check ${patientData.email} for Payment Link`,
        icon: "success",
        button: "OK",
        timer: 3000,
      });
      setloading(false);
      console.log(JSON.stringify(response.data));
    } catch (error) {
      console.error(error);
      setloading(false);
    }
  };

  const paymentFor = "appointment_billing"
  const handleQRCodeClick = () => {
    const paymentPageURL = `/bill-qr/${patientData.fname}/${patientData.lname}/${patientData.email}/${BillingMainData.eid}/${BillingMainData.pid}/${amounttoPay}/${BillingMainData.bill_id}/${paymentFor}`;
    window.open(paymentPageURL, "_blank");
  };

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const handlePaymentModalOpen = () => {
    setShowPaymentModal(true);
  };
  const handlePaymentModalClose = () => {
    setShowPaymentModal(false);
  };

  const handlePaymentModalCloseSuccess = async () => {
    setShowPaymentModal(false);
    try {
      const verifyBillingData = {
        tip: tipAmount,
        partialPayment: finalAmount,
        eid: paramsData.eid,
        pid: paramsData.pid,
        is_billed: 1,
        bill_id: BillingMainData.bill_id,
        wallet_money: walletDeduction,
        modeOfPayment: [
          {
            PartialPaymentAmount: amounttoPay + walletDeduction,
            PartialPaymentMode: "Wallet + Single Card",
          },
          {
            RemainingPaymentAmount: 0,
            RemainingPaymentMode: "",
          },
        ],
      };

      const response = await ApiServices.put(
        `/apis/default/api/billingupdate`,
        verifyBillingData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );

      await ApiServices.put(
        `/apis/default/api/billingweb/appointment/${paramsData.eid}`,
        {
          is_billed: 1,
          verified_receptionist: 1,
        },
        {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        }
      );

      const walletTransactionData = {
        amount: walletDeduction,
        description: `Deducted amount - ${walletDeduction} against the Appointment ID: ${paramsData.eid} for Bill ${paramsData.bill_id}`,
      };

      const walletTransactionResponse = await ApiServices.put(
        `/apis/default/api/walletTransaction/${BillingMainData.wallet_id}`,
        walletTransactionData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );

      setfetchBillingDataAgin(!fetchBillingDataAgin);

      swal({
        title: "Payment Successful",
        icon: "success",
        button: "OK",
        timer: 3000,
      });
    } catch (error) {
      // Handle error
      console.error("Error:", error);
    }
  };

  const cashPaymentReceived = async () => {
    setloading(true);
    try {
      const verifyBillingData = {
        tip: tipAmount,
        partialPayment: finalAmount,
        eid: paramsData.eid,
        pid: paramsData.pid,
        is_billed: 1,
        bill_id: BillingMainData.bill_id,
        modeOfPayment: [
          {
            PartialPaymentAmount: amounttoPay + walletDeduction,
            PartialPaymentMode: "Wallet + Cash",
          },
          {
            RemainingPaymentAmount: 0,
            RemainingPaymentMode: "",
          },
        ],
      };

      const response = await ApiServices.put(
        `/apis/default/api/billingupdate`,
        verifyBillingData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      ApiServices.put(
        `/apis/default/api/billingweb/appointment/${paramsData.eid}`,
        {
          is_billed: 1,
          verified_receptionist: 1,
        },
        {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        }
      );
      const walletTransactionData = {
        amount: walletDeduction,
        description: `Deducted amount - ${walletDeduction} against the Appointment ID: ${paramsData.eid} for Bill ${paramsData.bill_id}`,
      };

      const walletTransactionResponse = await ApiServices.put(
        `/apis/default/api/walletTransaction/${BillingMainData.wallet_id}`,
        walletTransactionData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      setfetchBillingDataAgin(!fetchBillingDataAgin);
    } catch (error) {
      console.error("Error verifying billing:", error);
    } finally {
      setloading(false);
      setfetchBillingDataAgin(!fetchBillingDataAgin);
    }
  };

  const PaymentReceivedFromWallet = async () => {
    setloading(true);
    try {
      const verifyBillingData = {
        tip: tipAmount,
        wallet_money: finalAmount,
        eid: paramsData.eid,
        pid: paramsData.pid,
        is_billed: 1,
      };

      const response = await ApiServices.put(
        `/apis/default/api/billingupdate`,
        verifyBillingData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      ApiServices.put(
        `/apis/default/api/billingweb/appointment/${paramsData.eid}`,
        {
          is_billed: 1,
          verified_receptionist: 1,
        },
        {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        }
      );
      const walletTransactionData = {
        amount: finalAmount,
        description: `Deducted amount - ${finalAmount} against the Appointment ID: ${paramsData.eid} for Bill ${BillingMainData.bill_id}`,
      };

      const walletTransactionResponse = await ApiServices.put(
        `/apis/default/api/walletTransaction/${BillingMainData.wallet_id}`,
        walletTransactionData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      setfetchBillingDataAgin(!fetchBillingDataAgin);
    } catch (error) {
      console.error("Error verifying billing:", error);
    } finally {
      setloading(false);
      setfetchBillingDataAgin(!fetchBillingDataAgin);
    }
  };

  const [AddNewService, setAddNewService] = useState(false);
  const handleAddNewServiceOpen = () => {
    setAddNewService(true);
  };
  const handleAddNewServiceClose = () => {
    setAddNewService(false);
    setfetchBillingDataAgin(!fetchBillingDataAgin);
  };

  const [walletBalance, setWalletBalance] = useState({ currentBalance: 0 });
  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `/apis/default/api/wallet/${BillingMainData.wallet_id}`;

        if (LoginUserType === "Patient") {
          endpoint = `/apis/default/portal/wallet/${BillingMainData.wallet_id}`;
        }

        const response = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });

        setWalletBalance(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [BillingMainData]);

  useEffect(() => {
    setamounttoPay(finalAmount - walletBalance.currentBalance);
  }, [finalAmount, walletBalance]);

  const [useWallet, setUseWallet] = useState(false);

  useEffect(() => {
    if (useWallet && walletBalance.currentBalance !== undefined) {
      const newAmountToPay = finalAmount - walletBalance.currentBalance;
      if (newAmountToPay > 0) {
        setamounttoPay(newAmountToPay);
        setWalletDeduction(walletBalance.currentBalance);
      } else {
        setamounttoPay(0);
        setWalletDeduction(finalAmount);
      }
    } else {
      setamounttoPay(finalAmount);
      setWalletDeduction(0);
    }
  }, [finalAmount, walletBalance, useWallet]);

  const handleCheckboxChange = (e) => {
    setUseWallet(e.target.checked);
  };

  const [availablePackages, setavailablePackages] = useState([]);

  // console.log("availablePackages",availablePackages)

  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `/apis/default/api/packageAssignments/${paramsData.pid}`;
        if (LoginUserType === "Patient") {
          endpoint = `/apis/default/portal/packageAssignments/${paramsData.pid}`;
        }
        const response = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });
        setavailablePackages(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [BillingMainData,fetchBillingDataAgin]);

  // const matchedServices = ServicebillingData.map((service) => {
  //   const matchedPackage = availablePackages&&availablePackages?.find(
  //     (pkg) => pkg.service_id === service.service_id
  //   );
  //   if (matchedPackage) {
  //     return {
  //       service_id: service.service_id,
  //       service_title: matchedPackage.service_title,
  //       remaining_quantity: matchedPackage.remaining_quantity,
  //       expiry: matchedPackage.expiry,
  //       package_type: matchedPackage.package_type,
  //       bill_quantity: service.service_quantity,
  //       bill_discount: service.service_discount,
  //       assignment_id: matchedPackage.assignment_id,
  //     };
  //   }
  //   return null;
  // }).filter((item) => item !== null);

  const matchedServices = ServicebillingData.map((service) => {
    const availablePackagesArray = Array.isArray(availablePackages) ? availablePackages : [];
  
    const matchedPackage = availablePackagesArray.find(
      (pkg) => pkg.service_id === service.service_id
    );
  
    if (matchedPackage) {
      return {
        service_id: service.service_id,
        service_title: matchedPackage.service_title,
        remaining_quantity: matchedPackage.remaining_quantity,
        expiry: matchedPackage.expiry,
        package_type: matchedPackage.package_type,
        package_id: matchedPackage.package_id,
        bill_quantity: service.service_quantity,
        bill_discount: service.service_discount,
        assignment_id: matchedPackage.assignment_id,
      };
    }
    return null;
  }).filter((item) => item !== null);
  

  const [editedQuantities, setEditedQuantities] = useState({});

  // const handleIncreaseQuantity = (serviceId, remainingQuantity) => {
  //   setEditedQuantities((prevQuantities) => ({
  //     ...prevQuantities,
  //     [serviceId]: Math.min(
  //       (prevQuantities[serviceId] || 0) + 1,
  //       remainingQuantity
  //     ),
  //   }));
  // };

  // const handleDecreaseQuantity = (serviceId, initialQuantity) => {
  //   setEditedQuantities((prevQuantities) => ({
  //     ...prevQuantities,
  //     [serviceId]: Math.max(
  //       (prevQuantities[serviceId] || initialQuantity) - 1,
  //       0
  //     ),
  //   }));
  // };

  const handleIncreaseQuantity = (serviceId, remainingQuantity, billQuantity) => {
    console.log("prevQuantities applied")
    console.log("remainingQuantity",remainingQuantity)
    console.log("billQuantity",billQuantity)
    setEditedQuantities((prevQuantities) => ({
      ...prevQuantities,
      [serviceId]: Math.min(
        (prevQuantities[serviceId] || 0) + 1,
        Math.min(remainingQuantity, billQuantity)
      ),
    }));
  };

  const handleDecreaseQuantity = (serviceId, initialQuantity) => {
    setEditedQuantities((prevQuantities) => ({
      ...prevQuantities,
      [serviceId]: Math.max(
        (prevQuantities[serviceId] || initialQuantity) - 1,
        0
      ),
    }));
  };

  const handleSaveChangesPackage = (serviceId, service) => {
    // Calculate the change in quantity
    console.log("service",service)
    const editedQuantity = editedQuantities[serviceId] || 0;
    const quantityChange = service.bill_quantity - editedQuantity;
    setloading(true);

    let endpoint = `/apis/default/api/appointment/${BillingMainData.bill_id}/billingweb/${serviceId}`;

    let updatedData = {
      service_quantity: service.bill_quantity - editedQuantity,
      service_discount: service.bill_discount,
      bill_id: BillingMainData.bill_id,
      service_id: serviceId,
      quantitytodeductfromPackage: editedQuantities[serviceId],
    };

    ApiServices.put(endpoint, updatedData, {
      Authorization: `Bearer ${accessToken}`,
    })
      .then((response) => {
        let endpointtwo = `/apis/default/api/packageAssignments/${service.assignment_id}`;

        let updatedDataTwo = {
          pid: paramsData.pid,
          status: "active",
          used_quantity: editedQuantity,
          type: "service",
          package_id:service.package_id,
          service_id:service.service_id,
          notes:`${editedQuantity} deducted from ${service.package_type} against Bill ID - ${BillingMainData.bill_id} of Appontment - ${paramsData.eid}`
        };

        ApiServices.put(endpointtwo, updatedDataTwo, {
          Authorization: `Bearer ${accessToken}`,
        });

        setfetchBillingDataAgin(!fetchBillingDataAgin);
        setloading(false);
        swal({
          title: "Package Deducted From Bill",
          icon: "success",
          button: "OK",
          timer: 3000,
        });
      })
      .catch((error) => {
        setloading(false);
        console.error("Error updating data:", error);
      });
  };

  return (
    <div>
      {loading && <Loader />}

      {ServicebillingData && ServicebillingData.length > 0 && (
        <div
          className="ag-theme-alpine"
          style={{
            // height: tableHeight + "px",
            maxHeight: "400px",
            minHeight: "200px",
            minWidth: "100%",
            paddingBottom: "50px",
          }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowData={ServicebillingData}
            defaultColDef={defaultColDef}
            domLayout="autoHeight"
            rowHeight={50}
          />
        </div>
      )}

      {ProductbillingData && ProductbillingData.length > 0 && (
        <div
          className="ag-theme-alpine"
          style={{
            // height: tableHeightProduct + "px",
            maxHeight: "400px",
            minHeight: "200px",
            minWidth: "100%",
          }}
        >
          <AgGridReact
            columnDefs={ProductcolumnDefs}
            rowData={ProductbillingData}
            defaultColDef={defaultColDef}
            domLayout="autoHeight"
            rowHeight={50}
          />
        </div>
      )}

      <div style={{ paddingTop: "30px" }}>
      {LoginUserType != "Patient" &&
      <div>
        {BillingMainData && BillingMainData.is_billed == 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <p
              style={{ cursor: "pointer", color: "#5a2ffc" }}
              onClick={handleAddNewServiceOpen}
            >
              Add New Service or Products
            </p>
            <p style={{ cursor: "pointer", color: "#5a2ffc" }}>
              Mark This Bill as Verified
            </p>
          </div>
        )}
          </div>}
        <h4>
          Total Bill - $ {BillingMainData && BillingMainData.total_amount}
        </h4>
      </div>

      {BillingMainData && BillingMainData.is_billed == 1 ? (
        <h3>
          Payment Status : <Button variant="success">Paid</Button>
        </h3>
      ) : (
        <div>
          <Card>
            <Card.Body>
              <div style={{ marginBottom: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  <h5>Select Payment Method:</h5>
                  {walletBalance && (
                    <h5>
                      Wallet Balance: ${" "}
                      {walletBalance && walletBalance?.currentBalance}
                    </h5>
                  )}
                </div>
                <Form>
                  <Row>
                    {/* <Col sm={2}>
                      <Form.Check
                        type="radio"
                        label="Wallet"
                        name="paymentMethod"
                        onChange={() => handlePaymentMethodSelect("Wallet")}
                      />
                    </Col> */}
                       {LoginUserType != "Patient" &&
                    <Col sm={2}>
                      <Form.Check
                        type="radio"
                        label="Cash"
                        name="paymentMethod"
                        onChange={() => handlePaymentMethodSelect("cash")}
                      />
                    </Col>}
                    <Col sm={2}>
                      <Form.Check
                        type="radio"
                        label="Single Card"
                        name="paymentMethod"
                        onChange={() =>
                          handlePaymentMethodSelect("single-card")
                        }
                      />
                    </Col>
                    <Col sm={2}>
                      <Form.Check
                        type="radio"
                        label="Dual Card"
                        name="paymentMethod"
                        onChange={() => handlePaymentMethodSelect("dual-card")}
                        disabled
                      />
                    </Col>
                    <Col sm={2}>
                      <Form.Check
                        type="radio"
                        label="Card + Cash"
                        name="paymentMethod"
                        onChange={() => handlePaymentMethodSelect("card-cash")}
                        disabled
                      />
                    </Col>
                    <Col sm={2}>
                      <Form.Check
                        type="radio"
                        label="QR"
                        name="paymentMethod"
                        onChange={() => handlePaymentMethodSelect("qr")}
                      />
                    </Col>
                    <Col sm={2}>
                      <Form.Check
                        type="radio"
                        label="Email Link"
                        name="paymentMethod"
                        onChange={() => handlePaymentMethodSelect("email")}
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
            </Card.Body>
          </Card>

          <Card style={{ marginTop: "20px" }}>
            <Card.Body>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                <i>
                  If our service left you glowing with satisfaction, consider
                  adding a little sparkle with a gratuity. Your support fuels
                  our passion for excellence. <br /> Thank you for shining with
                  us!
                </i>
              </p>

              <div>
                <Form.Group controlId="tipOption">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Form.Check
                      inline
                      label={`5% (${
                        BillingMainData && BillingMainData.total_amount * 0.05
                      })`}
                      type="radio"
                      value="5"
                      checked={tipOption === "5"}
                      onChange={handleTipOptionChange}
                    />
                    <Form.Check
                      inline
                      label={`10% (${
                        BillingMainData && BillingMainData.total_amount * 0.1
                      })`}
                      type="radio"
                      value="10"
                      checked={tipOption === "10"}
                      onChange={handleTipOptionChange}
                    />
                    <Form.Check
                      inline
                      label={`15% (${
                        BillingMainData && BillingMainData.total_amount * 0.15
                      })`}
                      type="radio"
                      value="15"
                      checked={tipOption === "15"}
                      onChange={handleTipOptionChange}
                    />
                    <Form.Check
                      inline
                      label="Custom"
                      type="radio"
                      value="custom"
                      checked={tipOption === "custom"}
                      onChange={handleTipOptionChange}
                    />
                  </div>
                  <div
                    className="mb-3 mt-3"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {tipOption === "custom" && (
                      <Form.Control
                        type="number"
                        placeholder="Enter custom tip amount"
                        value={customTipAmount}
                        onChange={handleCustomTipAmountChange}
                        style={{ maxWidth: "300px" }}
                      />
                    )}
                  </div>
                </Form.Group>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    variant="danger"
                    onClick={handleRemoveTip}
                    disabled={tipAmount == 0}
                  >
                    Remove Tip
                  </Button>
                  <Button
                    className="mr-2"
                    onClick={handleApplyTip}
                    disabled={tipOption == null}
                  >
                    Apply Tip
                  </Button>
                </div>
              </div>

              <div className="mt-3">Total Tip: {tipAmount}</div>
              <div>Final Amount: {finalAmount}</div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={useWallet}
                    onChange={handleCheckboxChange}
                  />
                  Use Wallet Balance
                </label>
              </div>
              <div>
              {matchedServices&&matchedServices.length >0 &&
                <h5>
                  You have Below Services from this Bill available in your
                  packages, Do you want to deduct them from this Bill ?
                </h5>}
                {/* {matchedServices &&
                  matchedServices.map((service) => (
                    <div key={service.service_id}>
                      {service.service_title} ({service.package_type}), Valid
                      Upto: {service.expiry}, Remaining Quantity:{" "}
                      {service.remaining_quantity}
                      <br />
                      <div style={{ display: "flex", gap: "10px" }}>
                        <Button
                          variant="danger"
                          onClick={() =>
                            handleDecreaseQuantity(
                              service.service_id,
                              service.service_quantity
                            )
                          }
                          disabled={editedQuantities[service.service_id] <= 0}
                        >
                          -
                        </Button>
                        {editedQuantities[service.service_id] || 0}
                        <Button
                          variant="success"
                          onClick={() =>
                            handleIncreaseQuantity(
                              service.service_id,
                              service.remaining_quantity
                            )
                          }
                          disabled={
                            editedQuantities[service.service_id] >=
                            service.remaining_quantity
                          }
                        >
                          +
                        </Button>
                        <Button
                          variant="success"
                          onClick={() =>
                            handleSaveChangesPackage(
                              service.service_id,
                              service
                            )
                          }
                          disabled={editedQuantities[service.service_id] <= 0}
                        >
                          Save Changes
                        </Button>
                      </div>
                    </div>
                  ))} */}

                  {matchedServices &&
        matchedServices.map((service) => (
          <div key={service.service_id}>
            {service.service_title} ({service.package_type}), Valid
            Upto: {service.expiry}, Remaining Quantity:{" "}
            {service.remaining_quantity}
            <br />
            <div style={{ display: "flex", gap: "10px" }}>
              <Button
                variant="danger"
                onClick={() =>
                  handleDecreaseQuantity(
                    service.service_id,
                    service.service_quantity
                  )
                }
                disabled={editedQuantities[service.service_id] <= 0}
              >
                -
              </Button>
              {editedQuantities[service.service_id] || 0}
              <Button
                variant="success"
                onClick={() =>
                  handleIncreaseQuantity(
                    service.service_id,
                    service.remaining_quantity,
                    service.bill_quantity
                  )
                }
                disabled={
                  editedQuantities[service.service_id] >=
                  Math.min(service.remaining_quantity, service.service_quantity)
                }
              >
                +
              </Button>
              <Button
                variant="success"
                onClick={() => handleSaveChangesPackage(service.service_id, service)}
                disabled={editedQuantities[service.service_id] <= 0}
              >
                Save Changes
              </Button>
            </div>
          </div>
        ))}
              </div>
              <div>
                <strong>Amount to Pay: {amounttoPay}</strong>
              </div>

              {paymentMethod === "Wallet" && (
                <>
                  <Button
                    onClick={PaymentReceivedFromWallet}
                    disabled={
                      walletBalance &&
                      parseInt(walletBalance?.currentBalance) < finalAmount
                    }
                  >
                    ${finalAmount} Payment Received From Wallet
                  </Button>
                  {walletBalance &&
                    parseInt(walletBalance?.currentBalance) < finalAmount && (
                      <p>
                        You don't have sufficient balance to Make Payment
                        through Wallet
                      </p>
                    )}
                </>
              )}

              {paymentMethod === "cash" && (
                <div style={{ textAlign: "center" }}>
                  <Button onClick={cashPaymentReceived}>
                    $ {walletDeduction} From Wallet and ${amounttoPay} From Cash
                    Received
                  </Button>
                </div>
              )}
              {paymentMethod === "single-card" && (
                <div style={{ textAlign: "center" }}>
                  <Button variant="success" onClick={handlePaymentModalOpen}>
                    Proceed to Pay ${amounttoPay}
                  </Button>
                </div>
              )}
              {paymentMethod === "dual-card" && (
                <>
                  <Form.Group controlId="firstCardAmount" className="mt-3">
                    <Form.Label>Amount to Pay with First Card:</Form.Label>
                    <Form.Control
                      type="number"
                      value={firstCardAmount}
                      onChange={(e) =>
                        setFirstCardAmount(parseFloat(e.target.value))
                      }
                      max={finalAmount}
                    />
                  </Form.Group>
                  <Button onClick={handleDualCardPayment}>
                    Pay with Dual Card (First Card: ${firstCardAmount})
                  </Button>
                  <Button onClick={handleDualCardPayment}>
                    Pay with Dual Card (Second Card: $
                    {finalAmount - firstCardAmount})
                  </Button>
                </>
              )}
              {paymentMethod === "card-cash" && (
                <>
                  <Form.Group controlId="cashAmount" className="mt-3">
                    <Form.Label>Amount to Pay in Cash:</Form.Label>
                    <Form.Control
                      type="number"
                      value={cashAmount}
                      onChange={(e) =>
                        setCashAmount(parseFloat(e.target.value))
                      }
                      max={finalAmount}
                    />
                  </Form.Group>
                  <Button onClick={handleCardCashPayment}>
                    Pay with Card + Cash (Cash: ${cashAmount})
                  </Button>
                  <Button onClick={handleCardCashPayment}>
                    Pay with Card + Cash (Card: ${finalAmount - cashAmount})
                  </Button>
                </>
              )}

              {paymentMethod === "email" && (
                <div style={{ marginTop: "20px", textAlign: "center" }}>
                  <Button onClick={sendPaymentEmail} variant="success">
                    Send Payment Link to Email
                  </Button>
                </div>
              )}
              {paymentMethod === "qr" && (
                <div style={{ marginTop: "20px", textAlign: "center" }}>
                  <h3>Scan QR Code to Pay</h3>
                  <QRCode
                    value={`https://medispacosmos.com/cosmos-emr/bill-qr/${patientData.fname}/${patientData.lname}/${patientData.email}/${BillingMainData.eid}/${BillingMainData.pid}/${amounttoPay}/${BillingMainData.bill_id}/${paymentFor}`}
                    onClick={handleQRCodeClick}
                  />
                  <p>Final Amount: {amounttoPay}</p>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      )}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedRowData && selectedRowData?.product == true
              ? selectedRowData && selectedRowData.product_title
              : selectedRowData && selectedRowData.service_title}
          </Modal.Title>
        </Modal.Header>
        {selectedRowData && (
          <Modal.Body>
            <table className="billing-table">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Quantity</th>
                  <th>Discount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {selectedRowData?.product == true
                      ? selectedRowData.product_title
                      : selectedRowData.service_title}{" "}
                    <br />
                  </td>

                  <td>
                    <input
                      type="number"
                      value={editedQuantity}
                      onChange={handleQuantityChange}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={editedDiscount}
                      onChange={handleDiscountChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="danger" onClick={handleDeleteBillSingleEntry}>
            Delete
          </Button>
          <Button variant="secondary" onClick={handleSaveChanges}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showPaymentModal}
        onHide={handlePaymentModalClose}
        centered
        className="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Card Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showPaymentModal && (
            <Elements stripe={stripePromise}>
              <PaymentGateway
                patientData={patientData}
                amount={amounttoPay}
                onSuccess={handlePaymentModalCloseSuccess}
              />
            </Elements>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={AddNewService}
        onHide={handleAddNewServiceClose}
        centered
        size="xl"
        scrollable
        style={{ marginTop: "50px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add More Billing Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateGenerateBill onChildSubmit={handleAddNewServiceClose} BillId={BillingMainData&&BillingMainData?.bill_id}/>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ServiceBillNew;
