import React, { useState } from 'react';
import { TextField, Checkbox, FormControlLabel, Container, Typography, Paper, FormGroup, FormControl, FormLabel } from '@mui/material';

function ConsentForm() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        age: '',
        mobileNumber: '',
        activeConditions: '',
        surgeries: '',
        medicationCare: '',
        sensitivityToMedication: '',
        allergy: '',
        pregnancy: '',
        underAge18: '',
        exclusionCriteria: {
            cardiacPacemaker: false,
            diseasesStimulatedByLight: false,
            impairedImmuneSystem: false,
            hepatitisOrLiverDisease: false,
            historyOfBleeding: false,
        }
    });

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        if (type === 'checkbox') {
            setFormData(prevState => ({
                ...prevState,
                exclusionCriteria: {
                    ...prevState.exclusionCriteria,
                    [name]: checked
                }
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    return (
        <Container maxWidth="sm">
                <Typography variant="h5" component="h2" gutterBottom>V-IPL CONSENT FORM</Typography>
                <div>
                    <Typography variant="h6" component="h3" gutterBottom>Patient Information</Typography>
                    <TextField label="First name" name="firstName" fullWidth margin="normal" onChange={handleInputChange} />
                    <TextField label="Last name" name="lastName" fullWidth margin="normal" onChange={handleInputChange} />
                    <TextField label="Age" name="age" fullWidth margin="normal" onChange={handleInputChange} />
                    <TextField label="Mobile number" name="mobileNumber" fullWidth margin="normal" onChange={handleInputChange} />
                </div>
                <div>
                    <Typography variant="h6" component="h3" gutterBottom>Health Questionnaire:</Typography>
                    {/* Other fields and checkboxes with onChange handlers */}
                </div>
                <div>
                    <Typography variant="h6" component="h3" gutterBottom>Exclusion Criteria from treatment (Contraindications):</Typography>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox name="cardiacPacemaker" onChange={handleInputChange} />} label="Cardiac pacemaker, defibrillator, or other implanted electronic device" />
                        <FormControlLabel control={<Checkbox name="diseasesStimulatedByLight" onChange={handleInputChange} />} label="Any diseases which may be stimulated by light or heat (such as Herpes Simplex)" />
                        {/* Other checkboxes with onChange handlers */}
                    </FormGroup>
                </div>
        </Container>
    );
}

export default ConsentForm;
