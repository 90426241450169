import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Modal,Card } from 'react-bootstrap';
import swal from "sweetalert";
import QRCode from "qrcode.react";
import PaymentGateway from "../CardPayments/PaymentGateway";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ApiServices from '../../GlobalFiles/ApiServices/ApiServices';
import Loader from '../Loader/Loader';
import { useNavigate } from 'react-router-dom';

const CreateWalletGiftVoucher = () => {
  const [initialBalance, setInitialBalance] = useState(100.00);
  const [isGift, setIsGift] = useState(true);
  const [buyerName, setBuyerName] = useState('');
  const [buyerEmail, setBuyerEmail] = useState('');
  const [buyerContact, setBuyerContact] = useState('');
  const [recipientName, setRecipientName] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const accessToken = sessionStorage.getItem("accessToken");
  const LoginUserType = sessionStorage.getItem("LoginUserType");
  const [loading, setloading] = useState(false);
  const CustomerBillData = { buyerName, buyerContact, buyerEmail };
  const finalAmount = initialBalance;
  const navigate = useNavigate()
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PROMISE
  );

  const handleSubmit = async (e) => {
    // Check if e is defined before calling preventDefault
    if (e) {
      e.preventDefault();
    }
  
    // Perform validation
    if (
      !buyerName || !buyerEmail || !buyerContact || 
      !recipientName || !recipientEmail
    ) {
      setShowModal(true);
      return;
    }
  setloading(true)
    // Create payload
    const payload = {
      initialBalance,
      is_gift: isGift,
      buyer_name: buyerName,
      buyer_email: buyerEmail,
      buyer_contact: buyerContact,
      recipient_name: recipientName,
      recipient_email: recipientEmail,
      is_billed:1
    };
  
    try {
      // Send request
      const response = await ApiServices.post(
        '/apis/default/api/wallet',
        payload,
        {
          
            Authorization: `Bearer ${accessToken}`,
        }
      );
      console.log('Wallet Created:', response.data);
      swal({
        title: "Wallet Voucher Created Successful",
        // text: `Pleasen Check ${CustomerBillData.email} for Payment Link`,
        icon: "success",
        button: "OK",
        timer: 3000,
      });
      navigate("/admin/create-gift-cards/")
      setloading(false)
    } catch (error) {
      console.error('Error creating wallet:', error);
      setloading(false)
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (
  //     !buyerName || !buyerEmail || !buyerContact || 
  //     !recipientName || !recipientEmail
  //   ) {
  //     setShowModal(true);
  //     return;
  //   }

  //   const payload = {
  //     initialBalance,
  //     is_gift: isGift,
  //     buyer_name: buyerName,
  //     buyer_email: buyerEmail,
  //     buyer_contact: buyerContact,
  //     recipient_name: recipientName,
  //     recipient_email: recipientEmail
  //   };

  //   try {
  //     const response = await ApiServices.post(
  //       '/apis/default/api/wallet',
  //       payload,
  //       {
         
  //           Authorization: `Bearer ${accessToken}`,
          
  //       }
  //     );
  //     console.log('Wallet Created:', response.data);
  //   } catch (error) {
  //     console.error('Error creating wallet:', error);
  //   }
  // };

  const handleClose = () => setShowModal(false);

  const [paymentMethod, setPaymentMethod] = useState("");
  const [firstCardAmount, setFirstCardAmount] = useState(0);
  const [cashAmount, setCashAmount] = useState(0);

  const handlePaymentMethodSelect = (method) => {
    setPaymentMethod(method);
  };

  const handleDualCardPayment = () => {
    const secondCardAmount = finalAmount - firstCardAmount;
  };

  const handleCardCashPayment = () => {
    const cardAmount = finalAmount - cashAmount;
  };

  const sendPaymentEmail = async () => {
    setloading(true);
    try {
      let endpoint = `/apis/default/api/walletSendLink`;

      if (LoginUserType === "Patient") {
        endpoint = `/apis/default/portal/walletSendLink`;
      }

      const response = await ApiServices.post(
        endpoint,
        {
          patient_fname: buyerName,
          patient_lname: "",
          patient_email: buyerEmail,
          // wallet_id: paramsData.walletid,
          amount: parseFloat(finalAmount),
        },
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      swal({
        title: "Payment Link Send Successful",
        // text: `Pleasen Check ${CustomerBillData.email} for Payment Link`,
        icon: "success",
        button: "OK",
        timer: 3000,
      });
      setloading(false);
      console.log(JSON.stringify(response.data));
    } catch (error) {
      console.error(error);
      setloading(false);
    }
  };

  const handleQRCodeClick = () => {
    // const paymentPageURL = `/wallet-top-up-qr/${CustomerBillData.fname}/${CustomerBillData.lname}/${CustomerBillData.email}/${finalAmount}/${paramsData.walletid}`;
    // const finalURL = `${paymentPageURL}`;
    // window.open(finalURL, "_blank");
  };

  const cashPaymentReceived = async () => {
  // Call handleSubmit without event
  await handleSubmit();
};

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const handlePaymentModalOpen = () => {
    if (!buyerName || !buyerContact || !buyerEmail||!finalAmount||!recipientName||!recipientName||!recipientEmail) {
        // alert("Please fill in all required buyer information.");
        swal({
            title: "Please fill in all the information.",
            icon: "error",
            button: "OK",
            timer: 3000,
          });
        return;
      }
    setShowPaymentModal(true);
  };
  
  const handlePaymentModalClose = () => {
    setShowPaymentModal(false);
  };

  const handlePaymentModalCloseSuccess = async () => {
    // handleSubmit();
  await handleSubmit();

  }

  return (
    <div>
    {loading && <Loader/>}
    {/* <Container> */}
      <Row className="justify-content-md-center">
        <Col md="6">
          <h2>Create Wallet Gift Voucher</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formInitialBalance">
              <Form.Label>Initial Balance</Form.Label>
              <Form.Control 
                type="number" 
                placeholder="Enter initial balance" 
                value={initialBalance} 
                onChange={(e) => setInitialBalance(parseFloat(e.target.value))} 
                required 
              />
            </Form.Group>
            
            <Form.Group controlId="formBuyerName">
              <Form.Label>Buyer Name</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter buyer name" 
                value={buyerName} 
                onChange={(e) => setBuyerName(e.target.value)} 
                required 
              />
            </Form.Group>

            <Form.Group controlId="formBuyerEmail">
              <Form.Label>Buyer Email</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="Enter buyer email" 
                value={buyerEmail} 
                onChange={(e) => setBuyerEmail(e.target.value)} 
                required 
              />
            </Form.Group>

            <Form.Group controlId="formBuyerContact">
              <Form.Label>Buyer Contact</Form.Label>
              <Form.Control 
                type="number" 
                placeholder="Enter buyer contact" 
                value={buyerContact} 
                onChange={(e) => setBuyerContact(e.target.value)} 
                required 
              />
            </Form.Group>

            <Form.Group controlId="formRecipientName">
              <Form.Label>Recipient Name</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter recipient name" 
                value={recipientName} 
                onChange={(e) => setRecipientName(e.target.value)} 
                required 
              />
            </Form.Group>

            <Form.Group controlId="formRecipientEmail">
              <Form.Label>Recipient Email</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="Enter recipient email" 
                value={recipientEmail} 
                onChange={(e) => setRecipientEmail(e.target.value)} 
                required 
              />
            </Form.Group>

            {/* <Button variant="primary" type="submit" className="mt-3">
              Create Wallet Voucher
            </Button> */}
          </Form>
        </Col>
      </Row>

      <Card className="mt-4">
      <Card.Body>
              <div>
                <h5>Select Payment Method:</h5>
                <Form>
                  <Row>
                  {LoginUserType !== "Patient" &&
                    <Col sm={2}>
                      <Form.Check
                        type="radio"
                        label="Cash"
                        name="paymentMethod"
                        onChange={() => handlePaymentMethodSelect("cash")}
                      />
                    </Col>
                    }
                    <Col sm={2}>
                      <Form.Check
                        type="radio"
                        label="Single Card"
                        name="paymentMethod"
                        onChange={() =>
                          handlePaymentMethodSelect("single-card")
                        }
                      />
                    </Col>
                    <Col sm={2}>
                      <Form.Check
                        type="radio"
                        label="Dual Card"
                        name="paymentMethod"
                        onChange={() => handlePaymentMethodSelect("dual-card")}
                        disabled
                      />
                    </Col>
                    <Col sm={2}>
                      <Form.Check
                        type="radio"
                        label="Card + Cash"
                        name="paymentMethod"
                        onChange={() => handlePaymentMethodSelect("card-cash")}
                        disabled
                      />
                    </Col>
                    <Col sm={2}>
                      <Form.Check
                        type="radio"
                        label="QR"
                        name="paymentMethod"
                        onChange={() => handlePaymentMethodSelect("qr")}
                      />
                    </Col>
                    <Col sm={2}>
                      <Form.Check
                        type="radio"
                        label="Email Link"
                        name="paymentMethod"
                        onChange={() => handlePaymentMethodSelect("email")}
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
          
              <div className="mt-3">
                {paymentMethod === "cash" && (
                  <div style={{ marginTop: "20px", textAlign: "center" }}>
                    <Button onClick={cashPaymentReceived}>
                      ${finalAmount} Payment Received in Cash
                    </Button>
                  </div>
                )}
                {paymentMethod === "single-card" && (
                  <div style={{ textAlign: "center" }}>
                    <Button variant="success" onClick={handlePaymentModalOpen}>
                      Proceed to Pay ${finalAmount}
                    </Button>
                  </div>
                )}
                {paymentMethod === "dual-card" && (
                  <div>
                    <Form.Group controlId="firstCardAmount" className="mt-3">
                      <Form.Label>Amount to Pay with First Card:</Form.Label>
                      <Form.Control
                        type="number"
                        value={firstCardAmount}
                        onChange={(e) =>
                          setFirstCardAmount(parseFloat(e.target.value))
                        }
                        max={finalAmount}
                      />
                    </Form.Group>
                    <Button onClick={handleDualCardPayment}>
                      Pay with Dual Card (First Card: ${firstCardAmount})
                    </Button>
                    <Button onClick={handleDualCardPayment}>
                      Pay with Dual Card (Second Card: $
                      {finalAmount - firstCardAmount})
                    </Button>
                  </div>
                )}
                {paymentMethod === "card-cash" && (
                  <div>
                    <Form.Group controlId="cashAmount" className="mt-3">
                      <Form.Label>Amount to Pay in Cash:</Form.Label>
                      <Form.Control
                        type="number"
                        value={cashAmount}
                        onChange={(e) =>
                          setCashAmount(parseFloat(e.target.value))
                        }
                        max={finalAmount}
                      />
                    </Form.Group>
                    <Button onClick={handleCardCashPayment}>
                      Pay with Card + Cash (Cash: ${cashAmount})
                    </Button>
                    <Button onClick={handleCardCashPayment}>
                      Pay with Card + Cash (Card: ${finalAmount - cashAmount})
                    </Button>
                  </div>
                )}
                {paymentMethod === "email" && (
                  <div style={{ marginTop: "20px", textAlign: "center" }}>
                    <Button onClick={sendPaymentEmail} variant="success">
                      Send Payment Link to Email
                    </Button>
                  </div>
                )}

                {paymentMethod === "qr" && (
                  <div style={{ marginTop: "20px", textAlign: "center" }}>
                    <h3>Scan QR Code to Pay</h3>
                    <QRCode
                      // value={`https://medispacosmos.com/cosmos-emr/wallet-top-up-qr/${CustomerBillData.fname}/${CustomerBillData.lname}/${CustomerBillData.email}/${finalAmount}/${paramsData.walletid}/${paymentFor}`}
                      onClick={handleQRCodeClick}
                    />
                    <p>Final Amount: {finalAmount}</p>
                  </div>
                )}
              </div>
            </Card.Body>
      </Card>

      
      <Modal
        show={showPaymentModal}
        onHide={handlePaymentModalClose}
        centered
        className="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Card Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showPaymentModal && (
            <Elements stripe={stripePromise}>
              <PaymentGateway
                patientData={CustomerBillData}
                amount={finalAmount}
                onSuccess={handlePaymentModalCloseSuccess}
              />
            </Elements>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Missing Fields</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please fill in all the required fields.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    {/* </Container> */}
      </div>
  );
};

export default CreateWalletGiftVoucher;
