import axios from "axios";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Loader from "../Loader/Loader";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import PrivacyPolicyModal from "./PrivacyPolicyModal";
import EmailOtpVerification from "../OTPVerification/EmailOtpVerification";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import OvalLoader from "../Loader/OvalLoader";
import AccessToken from "../../GlobalFiles/ApiServices/AccessToken";

const PatientDetailsForm = ({ onSuccess }) => {
  const [loading, setloading] = useState(false);
  const [correctEmail, setcorrectEmail] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    fname: "",
    mname: "",
    lname: "",
    street: "",
    postal_code: "",
    city: "",
    state: "",
    country_code: "",
    phone_contact: "",
    DOB: "",
    sex: "",
    race: "",
    ethnicity: "",
    email: "",
    token_id: "",
  });

  const [communicationPreferences, setCommunicationPreferences] = useState({
    allow_imm_reg_use: "UNASSIGNED",
    allow_imm_info_share: "UNASSIGNED",
    allow_health_info_ex: "UNASSIGNED",
    allow_patient_portal: "YES",
    hipaa_voice: "YES",
    hipaa_notice: "UNASSIGNED",
    hipaa_message: "UNASSIGNED",
    hipaa_allowsms: "YES",
    hipaa_allowemail: "YES",
    hipaa_mail: "YES",
  });

  const [validationErrors, setValidationErrors] = useState({
    fname: "",
    lname: "",
    sex: "",
    DOB: "",
    email: "",
    phone_contact: "",
  });

  const handleRadioChange = (field, value) => {
    setCommunicationPreferences({
      ...communicationPreferences,
      [field]: value,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setFormData({ ...formData, [name]: value });
    if (name === "email") {
      setFormData({ ...formData, [name]: value.toLowerCase() });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    setValidationErrors({ ...validationErrors, [name]: "" });
    if (name === "email") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!value.match(emailRegex)) {
        setValidationErrors({
          ...validationErrors,
          [name]: "Invalid email address",
        });
        setcorrectEmail(false);
      } else {
        setValidationErrors({ ...validationErrors, [name]: "" });
        setcorrectEmail(true);
      }
    }
  };

  const handleSubmit = async () => {
    const errors = {};
    if (!formData.fname) errors.fname = "First name is required.";
    if (!formData.lname) errors.lname = "Last name is required.";
    if (!formData.sex) errors.sex = "Gender is required.";
    if (!formData.DOB) errors.DOB = "Date of birth is required.";
    if (!formData.email) errors.email = "Email is required.";
    if (!formData.phone_contact)
      errors.phone_contact = "Phone Number is required.";
  
    if (Object.keys(errors).length > 0) {
      // Set errors and prevent form submission
      setValidationErrors(errors);
      return;
    }
  
    setloading(true);
  
    ApiServices.post("/apis/default/api/verifyemail", {
      fname: formData.fname,
      mname: formData.mname,
      lname: formData.lname,
      email: formData.email,
      DOB: formData.DOB,
    }).then((response) => {
      let inputotp = response.data.data.OTP;
      console.log("inputotp", inputotp);
      ApiServices.get(
        `/apis/default/api/verificationtoken/${inputotp}`,
        {}
      ).then((response) => {
        console.log("response", response);
        const isOtpVerified = response.data.data.token_status;
        const token_id_holder = response.data.data.token_id_holder;
        setemailtoken(token_id_holder);
        const patientId = response.data.data.patient_id;
  
        if (patientId) {
          swal({
            title: "Email Already Registered",
            text: "Please log in to your account",
            icon: "warning",
            buttons: {
              confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "btn-primary",
                closeModal: true,
              },
            },
            closeOnClickOutside: false,
            closeOnEsc: false,
          }).then((confirmed) => {
            if (confirmed) {
              navigate("/");
            }
          });
        } else {
          setVerifiedOtp(true);
  
          // Make the API request only if VerifiedOtp is true
          const makePatientApiRequest = async () => {
            try {
              const accessToken = await AccessToken.fetchAccessToken();
              sessionStorage.setItem("access_token", accessToken);
  
              const formDataWithPreferences = {
                ...formData,
                ...communicationPreferences,
              };
              formDataWithPreferences.token_id = emailtoken;
  
              const response = await ApiServices.post(
                "/apis/default/api/patient",
                formDataWithPreferences,
                {
                  Authorization: `Bearer ${accessToken}`,
                }
              );
  
              // console.log("Response:", response.data["data"]); 
              sessionStorage.setItem("pid", response.data["data"].pid);
  
              ApiServices.post("/apis/default/api/patientpasslink", {
                resetPass: false,
                email: formData.email,
                token_id: response.data.data.token_id,
                pid: response.data.data.pid,
              });
  
              ApiServices.post(
                "/apis/default/api/intakeForms",
                {
                  pid: response.data.data.pid,
                },
                {
                  Authorization: `Bearer ${accessToken}`,
                }
              );
  
              swal({
                title: "We've sent a link to your email. Please check your mailbox to generate your login credentials.",
                // text: "Please Check Your Mailbox to Generate your Login Credentials.",
                icon: "success",
                button: "OK",
                // timer: 2000,
              });
              // onSuccess();
              navigate("/");
              setloading(false);
            } catch (error) {
              console.error("Error:", error);
              setloading(false);
            }
          };
  
          makePatientApiRequest();
        }
      });
    });
  };
  

  // const handleSubmit = async () => {
  //   const errors = {};
  //   if (!formData.fname) errors.fname = "First name is required.";
  //   if (!formData.lname) errors.lname = "Last name is required.";
  //   if (!formData.sex) errors.sex = "Gender is required.";
  //   if (!formData.DOB) errors.DOB = "Date of birth is required.";
  //   if (!formData.email) errors.email = "Email is required.";
  //   if (!formData.phone_contact)
  //     errors.phone_contact = "Phone Number is required.";

  //   if (Object.keys(errors).length > 0) {
  //     setValidationErrors(errors);
  //     return;
  //   }
  //   setloading(true);

  //   ApiServices.post("/apis/default/api/verifyemail", {
  //     fname: formData.fname,
  //     mname: formData.mname,
  //     lname: formData.lname,
  //     email: formData.email,
  //     DOB: formData.DOB,
  //   }).then((response) => {
  //     let inputotp = response.data.data.OTP;
  //     console.log("inputotp", inputotp);
  //     ApiServices.get(
  //       `/apis/default/api/verificationtoken/${inputotp}`,
  //       {}
  //     ).then((response) => {
  //       console.log("response", response);
  //       const isOtpVerified = response.data.data.token_status;
  //       const token_id_holder = response.data.data.token_id_holder;
  //       setemailtoken(token_id_holder);
  //       const patientId = response.data.data.patient_id;

  //       if (patientId) {
  //         swal({
  //           title: "Email Already Registered",
  //           text: "Please log in to your account",
  //           icon: "warning",
  //           buttons: {
  //             confirm: {
  //               text: "OK",
  //               value: true,
  //               visible: true,
  //               className: "btn-primary",
  //               closeModal: true,
  //             },
  //           },
  //           closeOnClickOutside: false,
  //           closeOnEsc: false,
  //         }).then((confirmed) => {
  //           if (confirmed) {
  //             navigate("/");
  //           }
  //         });
  //       } else {
  //         setVerifiedOtp(true);
  //       }
  //     });
  //   });

  //   if (!VerifiedOtp) {
  //     return;
  //   }
  //   try {
  //     const accessToken = await AccessToken.fetchAccessToken();
  //     sessionStorage.setItem("access_token", accessToken);

  //     const formDataWithPreferences = {
  //       ...formData,
  //       ...communicationPreferences,
  //     };
  //     formDataWithPreferences.token_id = emailtoken;

  //     const response = await ApiServices.post(
  //       "/apis/default/api/patient",
  //       formDataWithPreferences,
  //       {
  //         Authorization: `Bearer ${accessToken}`,
  //       }
  //     );

  //     console.log("Response:", response.data["data"]);
  //     sessionStorage.setItem("pid", response.data["data"].pid);

  //     ApiServices.post("/apis/default/api/patientpasslink", {
  //       resetPass: false,
  //       email: formData.email,
  //       token_id: response.data.data.token_id,
  //       pid: response.data.data.pid,
  //     });

  //     ApiServices.post(
  //       "/apis/default/api/intakeForms",
  //       {
  //         pid: response.data.data.pid,
  //       },
  //       {
  //         Authorization: `Bearer ${accessToken}`,
  //       }
  //     );

  //     swal({
  //       title: "Generate Your Credentials",
  //       text: "Please Check Your Mailbox to Generate your Login Credentials.",
  //       icon: "success",
  //       button: "OK",
  //     });
  //     navigate("/");
  //     setloading(false);
  //   } catch (error) {
  //     console.error("Error:", error);
  //     setloading(false);
  //   }
  // };

  const [showOtpModal, setShowOtpModal] = useState(false);
  const sendEmailOTP = () => {
    setOvalLoading(true);
    ApiServices.post("/apis/default/api/verifyemail", {
      fname: formData.fname,
      mname: formData.mname,
      lname: formData.lname,
      email: formData.email,
      DOB: formData.DOB,
    })
      .then((response) => {
        setShowOtpModal(true);
        setOvalLoading(false);
      })
      .catch((error) => {
        console.error("Failed to send OTP:", error);
        setOvalLoading(false);
      });
  };

  const [VerifiedOtpMessage, setVerifiedOtpMessage] = useState("");
  const [emailtoken, setemailtoken] = useState("");
  const [VerifiedOtp, setVerifiedOtp] = useState(false);
  const [OvalLoading, setOvalLoading] = useState(false);

  const handleOtpVerification = (inputOtp) => {
    ApiServices.get(`/apis/default/api/verificationtoken/${inputOtp}`, {})
      .then((response) => {
        console.log("Verify", response.data.data);
        const isOtpVerified = response.data.data.token_status;
        const token_id_holder = response.data.data.token_id_holder;
        setemailtoken(token_id_holder);
        const patientId = response.data.data.patient_id;

        if (patientId) {
          swal({
            title: "Email Already Registered",
            text: "Please log in to your account",
            icon: "warning",
            buttons: {
              confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "btn-primary",
                closeModal: true,
              },
            },
            closeOnClickOutside: false,
            closeOnEsc: false,
          }).then((confirmed) => {
            if (confirmed) {
              // window.location.href = `${process.env.REACT_APP_EMR_URL}/riteclinic-openemr/portal/`
              navigate("/");
            }
          });
          // swal("Email Already Registered", "Please log in to your account", "warning");
        }
        if (isOtpVerified) {
          setShowOtpModal(false);
          setVerifiedOtp(true);
          // setErrorFromOtpVerification("OTP Verified Successfullly...!");
          setVerifiedOtpMessage("");
        } else {
        }
      })
      .catch((error) => {
        console.error("Failed to verify OTP:", error);
        // setErrorFromOtpVerification("OTP is incorrect or has expired.");
      });
  };

  return (
    <div>
      {loading && <Loader />}
      <div>
        <Container>
          <div>
            <button
              style={{
                backgroundColor: "#5D33FC",
                width: "100%",
                color: "white",
                border: "none",
                borderRadius: "10px",
                padding: "5px",
              }}
            >
              Patient Details
            </button>
          </div>
          <div
            className="round-border-div-of-data"
            style={{ marginTop: "20px" }}
          >
            <div className="round-border-div-of-data-relative-infor">
              Demographics
            </div>
            <Row style={{ marginBottom: "10px" }}>
              <Col sm={3}>
                <select
                  className="patient-registration-input-tag"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                >
                  <option>Title</option>
                  <option>Mr.</option>
                  <option>Mrs.</option>
                  <option>Miss.</option>
                </select>
              </Col>

              <Col sm={3}>
                <div style={{ display: "flex" }}>
                  <input
                    type="text"
                    placeholder="First Name"
                    className="patient-registration-input-tag"
                    name="fname"
                    value={formData.fname}
                    onChange={handleInputChange}
                  />
                  <p className="validation-error-message">*</p>
                </div>
                <p className="validation-error-message">
                  {validationErrors.fname}
                </p>
              </Col>

              <Col sm={3}>
                <div style={{ display: "flex" }}>
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="patient-registration-input-tag"
                    name="lname"
                    value={formData.lname}
                    onChange={handleInputChange}
                  />
                  <p className="validation-error-message">*</p>
                </div>
                <p className="validation-error-message">
                  {validationErrors.lname}
                </p>
              </Col>
              <Col sm={3}>
                <input
                  type="text"
                  placeholder="Middle Name"
                  className="patient-registration-input-tag"
                  name="mname"
                  value={formData.mname}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <div style={{ display: "flex" }}>
                  <select
                    className="patient-registration-input-tag"
                    name="sex"
                    value={formData.sex}
                    onChange={handleInputChange}
                  >
                    <option>Gender</option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Prefer not to say</option>
                  </select>
                  <p className="validation-error-message">*</p>
                </div>
                <p className="validation-error-message">
                  {validationErrors.sex}
                </p>
              </Col>
              <Col sm={3}>
                <div style={{ display: "flex" }}>
                  <input
                    type="date"
                    placeholder="Date of Birth"
                    className="patient-registration-input-tag"
                    name="DOB"
                    value={formData.DOB}
                    onChange={handleInputChange}
                  />
                  <p className="validation-error-message">*</p>
                </div>
                <p className="validation-error-message">
                  {validationErrors.DOB}
                </p>
              </Col>
              <Col sm={3}>
                <select
                  className="patient-registration-input-tag"
                  // name="title"
                  //   value={formData.title}
                  //   onChange={handleInputChange}
                >
                  <option>Marital Status</option>
                  <option>Married</option>
                  <option>Single</option>
                  <option>Prefer not to say</option>
                </select>
              </Col>
              <Col sm={3}>
                <select
                  className="patient-registration-input-tag"
                  name="ethnicity"
                  value={formData.ethnicity}
                  onChange={handleInputChange}
                >
                  <option>Ethnicity</option>
                  <option>African</option>
                  <option>Asian</option>
                  <option>European</option>
                  <option>Middle Eastern</option>
                  <option>Indigenous </option>
                  <option>Latino/Hispanic</option>
                  <option>Pacific Islander</option>
                  <option>Multiracial/Multiethnic</option>
                  <option>Other Ethnic Groups</option>
                  <option>Prefer not to say</option>
                </select>
              </Col>
            </Row>
          </div>

          <div className="round-border-div-of-data">
            <div className="round-border-div-of-data-relative-infor">
              Contact
            </div>
            <Row style={{ marginBottom: "10px" }}>
              <Col sm={4}>
                <textarea
                  className="patient-registration-input-tag"
                  placeholder="House / Apartment Number , Street Name"
                  style={{ height: "100%" }}
                  name="street"
                  value={formData.street}
                  onChange={handleInputChange}
                />
              </Col>
              <Col sm={4}>
                <input
                  type="text"
                  placeholder="City"
                  className="patient-registration-input-tag"
                  style={{ marginBottom: "10px" }}
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  placeholder="State"
                  className="patient-registration-input-tag"
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                />
              </Col>
              <Col sm={4}>
                <div style={{ display: "flex" }}>
                  <input
                    type="text"
                    placeholder="Postal Code"
                    className="patient-registration-input-tag"
                    style={{ marginBottom: "10px" }}
                    name="postal_code"
                    value={formData.postal_code}
                    onChange={handleInputChange}
                  />
                  <p className="validation-error-message">*</p>
                </div>
                <input
                  type="text"
                  placeholder="Country"
                  className="patient-registration-input-tag"
                  name="country_code"
                  value={formData.country_code}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <div style={{ display: "flex" }}>
                  <input
                    type="number"
                    placeholder="Cellphone Number"
                    className="patient-registration-input-tag"
                    name="phone_contact"
                    value={formData.phone_contact}
                    onChange={handleInputChange}
                  />
                  <p className="validation-error-message">*</p>
                </div>
                <p className="validation-error-message">
                  {validationErrors.phone_contact}
                </p>
              </Col>
              {/* <Col sm={4}>
                <input
                  type="number"
                  placeholder="Alternate cell phone"
                  className="patient-registration-input-tag"
                />
              </Col> */}
              <Col sm={4}>
                <div style={{ display: "flex" }}>
                  <input
                    type="email"
                    placeholder="Email Address"
                    className="patient-registration-input-tag"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  <p className="validation-error-message">*</p>
                </div>
                <p className="validation-error-message">
                  {validationErrors.email}
                </p>

                {/* {correctEmail && (
                  <div>
                    {VerifiedOtp ? (
                      <p
                        className="clear-filters-text"
                        style={{
                         
                          cursor: "auto",
                          textAlign: "left",
                          paddingBottom: "3px",
                          color: "green",
                        }}
                      >
                        <b>Email Address Verified Successfully</b>
                      </p>
                    ) : (
                      <div>
                        {OvalLoading ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              cursor: "auto",
                            }}
                          >
                            <p
                              className="validation-error-message"
                              style={{ cursor: "auto" }}
                            >
                              Processing
                            </p>
                            <OvalLoader />
                          </div>
                        ) : (
                          <div>
                            {formData.fname &&
                            formData.lname &&
                            formData.DOB ? (
                              <p
                                className="validation-error-message"
                                style={{ cursor: "pointer" }}
                                onClick={sendEmailOTP}
                              >
                                <b>Click here to Verify Email Address</b>
                              </p>
                            ) : (
                              <p className="validation-error-message">
                                Please Fill Firstname, Lastname, Date of Birth
                                AND Verify Email Address
                              </p>
                            )}

                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )} */}
              </Col>
            </Row>
          </div>

          <div className="round-border-div-of-data">
            <div className="round-border-div-of-data-relative-infor">
              Data access and communication
            </div>
            <Row style={{ marginBottom: "10px" }}>
              <Col sm={4}>
                <div
                  className="patient-registration-input-tag"
                  style={{ padding: "5px" }}
                >
                  <p style={{ margin: "0px" }}>Allow Voice Call</p>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <label>
                      <input
                        type="radio"
                        checked={communicationPreferences.hipaa_voice === "NO"}
                        onChange={() => handleRadioChange("hipaa_voice", "NO")}
                      />
                      No
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={communicationPreferences.hipaa_voice === "YES"}
                        onChange={() => handleRadioChange("hipaa_voice", "YES")}
                      />
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.hipaa_voice === "UNASSIGNED"
                        }
                        onChange={() =>
                          handleRadioChange("hipaa_voice", "UNASSIGNED")
                        }
                      />
                      Unassigned
                    </label>
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div
                  className="patient-registration-input-tag"
                  style={{ padding: "5px" }}
                >
                  <p style={{ margin: "0px" }}>Allow Patient Portal</p>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.allow_patient_portal === "NO"
                        }
                        onChange={() =>
                          handleRadioChange("allow_patient_portal", "NO")
                        }
                      />
                      No
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.allow_patient_portal ===
                          "YES"
                        }
                        onChange={() =>
                          handleRadioChange("allow_patient_portal", "YES")
                        }
                      />
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.allow_patient_portal ===
                          "UNASSIGNED"
                        }
                        onChange={() =>
                          handleRadioChange(
                            "allow_patient_portal",
                            "UNASSIGNED"
                          )
                        }
                      />
                      Unassigned
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              <Col sm={4}>
                <div
                  className="patient-registration-input-tag"
                  style={{ padding: "5px" }}
                >
                  <p style={{ margin: "0px" }}>Allow Notice</p>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <label>
                      <input
                        type="radio"
                        checked={communicationPreferences.hipaa_notice === "NO"}
                        onChange={() => handleRadioChange("hipaa_notice", "NO")}
                      />
                      No
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.hipaa_notice === "YES"
                        }
                        onChange={() =>
                          handleRadioChange("hipaa_notice", "YES")
                        }
                      />
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.hipaa_notice === "UNASSIGNED"
                        }
                        onChange={() =>
                          handleRadioChange("hipaa_notice", "UNASSIGNED")
                        }
                      />
                      Unassigned
                    </label>
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div
                  className="patient-registration-input-tag"
                  style={{ padding: "5px" }}
                >
                  <p style={{ margin: "0px" }}>Allow SMS</p>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.hipaa_allowsms === "NO"
                        }
                        onChange={() =>
                          handleRadioChange("hipaa_allowsms", "NO")
                        }
                      />
                      No
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.hipaa_allowsms === "YES"
                        }
                        onChange={() =>
                          handleRadioChange("hipaa_allowsms", "YES")
                        }
                      />
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.hipaa_allowsms ===
                          "UNASSIGNED"
                        }
                        onChange={() =>
                          handleRadioChange("hipaa_allowsms", "UNASSIGNED")
                        }
                      />
                      Unassigned
                    </label>
                  </div>
                </div>
                {/* <div
                  className="patient-registration-input-tag"
                  style={{ padding: "5px" }}
                >
                  <p style={{ margin: "0px" }}>
                    Allow Immunization Registry Use
                  </p>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.allow_imm_reg_use === "NO"
                        }
                        onChange={() =>
                          handleRadioChange("allow_imm_reg_use", "NO")
                        }
                      />
                      No
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.allow_imm_reg_use === "YES"
                        }
                        onChange={() =>
                          handleRadioChange("allow_imm_reg_use", "YES")
                        }
                      />
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.allow_imm_reg_use ===
                          "UNASSIGNED"
                        }
                        onChange={() =>
                          handleRadioChange("allow_imm_reg_use", "UNASSIGNED")
                        }
                      />
                      Unassigned
                    </label>
                  </div>
                </div> */}
              </Col>
            </Row>
            {/* <Row style={{ marginBottom: "10px" }}>
              <Col sm={4}>
                
              </Col>
              <Col sm={4}>
                <div
                  className="patient-registration-input-tag"
                  style={{ padding: "5px" }}
                >
                  <p style={{ margin: "0px" }}>Allow Immunization Info Share</p>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.allow_imm_info_share === "NO"
                        }
                        onChange={() =>
                          handleRadioChange("allow_imm_info_share", "NO")
                        }
                      />
                      No
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.allow_imm_info_share ===
                          "YES"
                        }
                        onChange={() =>
                          handleRadioChange("allow_imm_info_share", "YES")
                        }
                      />
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.allow_imm_info_share ===
                          "UNASSIGNED"
                        }
                        onChange={() =>
                          handleRadioChange(
                            "allow_imm_info_share",
                            "UNASSIGNED"
                          )
                        }
                      />
                      Unassigned
                    </label>
                  </div>
                </div>
              </Col>
            </Row> */}

            {/* <Row style={{ marginBottom: "10px" }}>
              <Col sm={4}>
                <div
                  className="patient-registration-input-tag"
                  style={{ padding: "5px" }}
                >
                  <p style={{ margin: "0px" }}>Allow Email</p>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.hipaa_allowemail === "NO"
                        }
                        onChange={() =>
                          handleRadioChange("hipaa_allowemail", "NO")
                        }
                      />
                      No
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.hipaa_allowemail === "YES"
                        }
                        onChange={() =>
                          handleRadioChange("hipaa_allowemail", "YES")
                        }
                      />
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.hipaa_allowemail ===
                          "UNASSIGNED"
                        }
                        onChange={() =>
                          handleRadioChange("hipaa_allowemail", "UNASSIGNED")
                        }
                      />
                      Unassigned
                    </label>
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div
                  className="patient-registration-input-tag"
                  style={{ padding: "5px" }}
                >
                  <p style={{ margin: "0px" }}>Allow Health Info Exchange</p>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.allow_health_info_ex === "NO"
                        }
                        onChange={() =>
                          handleRadioChange("allow_health_info_ex", "NO")
                        }
                      />
                      No
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.allow_health_info_ex ===
                          "YES"
                        }
                        onChange={() =>
                          handleRadioChange("allow_health_info_ex", "YES")
                        }
                      />
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={
                          communicationPreferences.allow_health_info_ex ===
                          "UNASSIGNED"
                        }
                        onChange={() =>
                          handleRadioChange(
                            "allow_health_info_ex",
                            "UNASSIGNED"
                          )
                        }
                      />
                      Unassigned
                    </label>
                  </div>
                </div>
              </Col>
            </Row> */}
          </div>
          <Row>
            <Col sm={12}>
              <div className="round-border-div-of-data">
                <div className="round-border-div-of-data-relative-infor">
                  Privacy Policy
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <input
                    className="custom-checkbox"
                    type="checkbox"
                    style={{ margin: "0px", cursor: "pointer" }}
                  />
                  <p style={{ margin: "0px" }}>
                    I have read and agree to the terms of the Cosmos Medi Spa{" "}
                    <Link
                      to="/privacy-policy-terms"
                      style={{ color: "blue", cursor: "pointer" }}
                      target="_blank"
                    >
                      Privacy Policy.
                    </Link>
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={12} style={{ textAlign: "right" }}>
              <button
                onClick={handleSubmit}
                className="patient-details-submit-button"
                // disabled={!VerifiedOtp}
              >
                Register
              </button>
            </Col>
          </Row>
        </Container>
      </div>

      <EmailOtpVerification
        show={showOtpModal}
        onClose={() => setShowOtpModal(false)}
        onVerify={handleOtpVerification}
        sendotpagain={sendEmailOTP}
        // error={ErrorFromOtpVerification}
        email={formData.email}
      />
    </div>
  );
};

export default PatientDetailsForm;
