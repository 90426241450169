import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import { Link } from "react-router-dom";
import DashboardLogo from "../../Assets/SidebarIcons/akar-icons_dashboard.svg";
import AppointmentsLogo from "../../Assets/SidebarIcons/akar-icons_schedule.svg";
import AdminLogo from "../../Assets/SidebarIcons/eos-icons_admin-outlined.svg";
import PatientLogo from "../../Assets/SidebarIcons/tdesign_user.svg";
import DoctorLogo from "../../Assets/SidebarIcons/maki_doctor.svg";
import MessagesLogo from "../../Assets/SidebarIcons/typcn_messages.svg";
import BillingLogo from "../../Assets/SidebarIcons/fluent_feed-16-regular.svg";
import ReportsLogo from "../../Assets/SidebarIcons/carbon_report.svg";
import FlowLogo from "../../Assets/SidebarIcons/fluent_flow-16-filled.svg";
import MiscellaneousLogo from "../../Assets/SidebarIcons/tabler_dots.svg";

const PatientViewSidebarLinks = ({ open,handleDrawerClose }) => {
  const handleLinkClick = () => {
    handleDrawerClose();
  };
  return (
    <div>
      <List style={{paddingTop:"30px"}}>
        <Link to="/patient-dashboard" style={{ textDecoration: "none", color: "black" }}>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleLinkClick}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={DashboardLogo} alt="" className="" />
              </ListItemIcon>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <p className="sidebar-menu-text-p-tag">Dashboard</p>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to="/patient-dashboard/manage-appointments" style={{ textDecoration: "none", color: "black" }}>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleLinkClick}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={AppointmentsLogo} alt="" className="" />
              </ListItemIcon>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <p className="sidebar-menu-text-p-tag">Appointments</p>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>

      


        <Link to="/patient-dashboard/page-under-construction/" style={{ textDecoration: "none", color: "black" }} >
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleLinkClick}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={DoctorLogo} alt="" className="" />
              </ListItemIcon>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <p className="sidebar-menu-text-p-tag">Doctors</p>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to="/patient-dashboard/page-under-construction/" style={{ textDecoration: "none", color: "black" }} >
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleLinkClick}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={MessagesLogo} alt="" className="" />
              </ListItemIcon>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <p className="sidebar-menu-text-p-tag">Messages</p>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to="/patient-dashboard/all-bills/" style={{ textDecoration: "none", color: "black" }} >
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleLinkClick}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={BillingLogo} alt="" className="" />
              </ListItemIcon>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <p className="sidebar-menu-text-p-tag">Billing/Fees</p>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to="/patient-dashboard/service-forms" style={{ textDecoration: "none", color: "black" }} >
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleLinkClick}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={ReportsLogo} alt="" className="" /> {/* Replace with actual icon */}
              </ListItemIcon>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <p className="sidebar-menu-text-p-tag">Forms</p>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to="/patient-dashboard/intake-forms/" style={{ textDecoration: "none", color: "black" }} >
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleLinkClick}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={ReportsLogo} alt="" className="" />
              </ListItemIcon>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <p className="sidebar-menu-text-p-tag">Intake Forms</p>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to="/patient-dashboard/page-under-construction/" style={{ textDecoration: "none", color: "black" }} >
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleLinkClick}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={FlowLogo} alt="" className="" />
              </ListItemIcon>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <p className="sidebar-menu-text-p-tag">Flow</p>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to="/patient-dashboard/page-under-construction/" style={{ textDecoration: "none", color: "black" }} >
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleLinkClick}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={MiscellaneousLogo} alt="" className="" />
              </ListItemIcon>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <p className="sidebar-menu-text-p-tag">Miscellaneous</p>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
    </div>
  );
};

export default PatientViewSidebarLinks;
