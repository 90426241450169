import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
import { FiCheckSquare } from "react-icons/fi";

const MedSpaProductDetailsPage = () => {
  const { id } = useParams();
  const [productDetailsData, setproductDetailsData] = useState(null);

  useEffect(() => {
    const apiUrl = `/api/products/${id}/`;
    ApiServices.get(apiUrl).then((response) => {
      setproductDetailsData(response.data);
    });
  }, [id]);
  console.log("productDetailsData", productDetailsData);
  return (
    <div
      style={{
        paddingTop: "50px",
        backgroundColor: "rgb(249 250 251)",
        paddingBottom: "50px",
      }}
    >
      {productDetailsData && (
        <Container>
          <Row className="align-items-center">
            <Col sm={5}>
              <Fade bottom>
                <img
                  src={productDetailsData && productDetailsData.image1}
                  alt=""
                  style={{
                    height: "100%",
                    width: "100%",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "50px",
                  }}
                />
              </Fade>
            </Col>
            <Col sm={7}>
              <Fade bottom>
                <div style={{ borderBottom: "10px solid #05B6B3" }}>
                  <h1
                    className="section-one-head-tag"
                    style={{ textAlign: "center" }}
                  >
                    {productDetailsData && productDetailsData.title}
                  </h1>
                </div>
              </Fade>
              <div style={{ paddingTop: "20px" }}>
                <Fade bottom>
                  <h1
                    className="section-one-head-tag"
                    style={{ textAlign: "left", fontSize: "2.2rem" }}
                  >
                    <b>Description</b>
                  </h1>
                  {productDetailsData &&
                    productDetailsData.description?.map((text, index) => (
                      <div key={index}>
                        <p
                          className="wel-come-staff-info"
                          style={{ textAlign: "justify", fontSize: "18px" }}
                        >
                          {text.content}
                        </p>
                      </div>
                    ))}
                </Fade>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ paddingTop: "40px" }}>
                <Fade bottom>
                  <h1
                    className="section-one-head-tag"
                    style={{ textAlign: "left", fontSize: "2.2rem" }}
                  >
                    <b>Benefits</b>
                  </h1>
                  {productDetailsData &&
                    productDetailsData.benefits?.map((text, index) => (
                      <div style={{ display: "flex", gap: "10px" }} key={index}>
                        <FiCheckSquare
                          style={{
                            color: "#05B6B3",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                        <p
                          className="wel-come-staff-info"
                          style={{ textAlign: "justify", fontSize: "18px" }}
                        >
                          {text.content}
                        </p>
                      </div>
                    ))}
                </Fade>
              </div>
            </Col>
          </Row>
          <Fade bottom>
            <Row style={{ paddingTop: "40px" }} className="align-items-center">
              <Col>
                <h1
                  className="section-one-head-tag"
                  style={{ textAlign: "left", fontSize: "2.2rem" }}
                >
                  <b>Ingredients</b>
                </h1>
                <Card
                  style={{
                    height: "100%",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Card.Body className="d-flex flex-column">
                    {productDetailsData &&
                      productDetailsData.active_ingredients?.map(
                        (text, index) => (
                          <div key={index} className="flex-grow-1">
                            <p
                              className="wel-come-staff-info"
                              style={{ textAlign: "justify", fontSize: "18px" }}
                            >
                              {text.content}
                            </p>
                          </div>
                        )
                      )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Fade>
          <Fade bottom>
            <Row style={{ paddingTop: "40px" }}>
              <Col>
                <h1
                  className="section-one-head-tag"
                  style={{ textAlign: "left", fontSize: "2.2rem" }}
                >
                  <b>Ingredients Highlight</b>
                </h1>
                <Card style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                  <Card.Body>
                    {productDetailsData &&
                      productDetailsData.ingredient_highlight?.map(
                        (text, index) => (
                          <div key={index}>
                            <p
                              className="wel-come-staff-info"
                              style={{ textAlign: "justify", fontSize: "18px" }}
                            >
                              {text.content}
                            </p>
                          </div>
                        )
                      )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Fade>

          <Fade bottom>
            <Row style={{ paddingTop: "40px" }}>
              <Col>
                <h1
                  className="section-one-head-tag"
                  style={{ textAlign: "left", fontSize: "2.2rem" }}
                >
                  <b>Directions For Use</b>
                </h1>
                <Card style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                  <Card.Body>
                    {productDetailsData &&
                      productDetailsData.direction_for_use?.map(
                        (text, index) => (
                          <div key={index}>
                            <p
                              className="wel-come-staff-info"
                              style={{ textAlign: "justify", fontSize: "18px" }}
                            >
                              {text.content}
                            </p>
                          </div>
                        )
                      )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Fade>
        </Container>
      )}
    </div>
  );
};

export default MedSpaProductDetailsPage;
