import React, { useEffect, useState } from "react";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const PackageGiftCards = () => {
  const [PackageGiftCardsData, setPackageGiftCardsData] = useState(null);
  const [FilteredPackageGiftCardsData, setFilteredPackageGiftCardsData] =
    useState(null);

  const accessToken = sessionStorage.getItem("accessToken");
  const LoginUserType = sessionStorage.getItem("LoginUserType");

  useEffect(() => {
    const fetchPackageGiftCardsData = async () => {
      try {
        const giftResponse = await ApiServices.get(
          "/apis/default/api/redeemPackageAll",
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );

        const data = giftResponse.data;
        setPackageGiftCardsData(data);
        setFilteredPackageGiftCardsData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPackageGiftCardsData();
  }, []);

  const [searchInput, setSearchInput] = useState("");
  const handleSearchInputChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchInput(inputValue);

    if (PackageGiftCardsData) {
      // Check if PackageGiftCardsData is not null
      const filteredPatients = PackageGiftCardsData.filter(
        (patient) =>
          (patient.patient_fname &&
            patient.patient_fname.toLowerCase().includes(inputValue)) ||
          (patient.patient_lname &&
            patient.patient_lname.toLowerCase().includes(inputValue)) ||
          (patient.package_name &&
            patient.package_name.toLowerCase().includes(inputValue)) ||
          (patient.buyer_name &&
            patient.buyer_name.toLowerCase().includes(inputValue))
      );

      setFilteredPackageGiftCardsData(filteredPatients);
    }
  };

  const getName = (params) => {
    const patient = params.data;
    return `${patient.patient_fname}  ${patient.patient_lname}`;
  };

  const columnDefs = [
    {
      headerName: "Gift Token ID",
      field: "gift_token_id",
      headerClass: "custom-header-of-tables",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Package Name",
      field: "package_name",
      headerClass: "custom-header-of-tables",
      minWidth: 180,
      flex: 1,
    },
    {
      headerName: "Buyer Name",
      field: "buyer_name",
      headerClass: "custom-header-of-tables",
      minWidth: 80,
      flex: 1,
    },
    {
      headerName: "Buyer Email",
      field: "buyer_email",
      headerClass: "custom-header-of-tables",
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Buyer Contact",
      field: "buyer_email",
      headerClass: "custom-header-of-tables",
      minWidth: 100,
      flex: 1,
    },

    // {
    //   headerName: "Token Status",
    //   field: "redeemed",
    //   headerClass: "custom-header-of-tables",
    //   minWidth: 140,
    //   flex: 1,
    //   sortable: false,
    //   filter: false,
    //   cellRenderer: (params) => {
    //     const status = params.data?.redeemed.trim();
    //     let buttonColor = "";
    //     let buttonText = "";
    //     let textCololor = "";

    //     switch (status) {
    //       case "1":
    //         buttonColor = "GREEN";
    //         buttonText = "Reedemed";
    //         textCololor = "white";
    //         break;
    //       case "0":
    //         buttonColor = "yellow";
    //         buttonText = "Pending";
    //         textCololor = "black";
    //     }

    //     return (
    //       <div
    //         style={{
    //           backgroundColor: buttonColor,
    //           color: textCololor,
    //           textAlign: "center",
    //         }}
    //         // onClick={() => handleButtonClick(params.data)}
    //       >
    //         {buttonText}
    //       </div>
    //     );
    //   },
    // },

    {
      headerName: "Name",
      valueGetter: getName,
      field: "name",
      headerClass: "custom-header-of-tables",
      minWidth: 240,
      flex: 1,
    },
    {
      headerName: "Patient Email",
      field: "patient_email",
      headerClass: "custom-header-of-tables",
      minWidth: 240,
      flex: 1,
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  return (
    <div>
      <div>
        {/* <input
              type="text"
              placeholder="Search..."
              value={searchInput}
              onChange={handleSearchInputChange}
              className="form-control mb-2"
            /> */}
      </div>
      <div
        className="ag-theme-alpine"
        style={{
          height: "85vh",
          minWidth: "100%",
          // overflow: "hidden"
        }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          //   rowData={patientList}
          rowData={FilteredPackageGiftCardsData}
          defaultColDef={defaultColDef}
        />
      </div>
    </div>
  );
};

export default PackageGiftCards;
