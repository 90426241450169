import React, { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import PasswordLogo from "../../Assets/teenyicons_password-solid.svg";
import EmailLogo from "../../Assets/dashicons_email.svg";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import swal from "sweetalert";
import { PiEyeClosedFill } from "react-icons/pi";
import { RxEyeOpen } from "react-icons/rx";
import Loader from "../Loader/Loader";

const ResetPassword = () => {
  const params = useParams();
  const [loading, setloading] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const otpInputRefs = useRef([]);

  const handleBackspace = (index, e) => {
    if (e.keyCode === 8 && index > 0 && otp[index] === "") {
      otpInputRefs.current[index - 1].focus();
    }
  };
  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== "" && index < 5) {
      otpInputRefs.current[index + 1].focus();
    }
  };

  const [formData, setFormData] = useState({
    login_uname: params.username,
    forgot_otp: "",
    pass_new: "",
    pass_new_confirm: "",
  });
  const [errors, setErrors] = useState({});

  const navigate = useNavigate("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "login_uname") {
      setFormData({ ...formData, [name]: value.toLowerCase() });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    try {
      const validationErrors = validateForm(formData);
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        setloading(false);
        return;
      }

      formData.forgot_otp = otp.join("");
      const response = await ApiServices.post(
        "/apis/default/api/patientpassreset",
        formData
      );

      console.log("response", response.data);
      setloading(false);
      if (response.data.data.status == true) {
        swal({
          title: "Credentials Generated Successfully",
          text: "You can Log in to Your account through new Credentials.",
          icon: "success",
          button: "OK",
          timer: 2000,
        });
        navigate("/");
      } else {
        swal({
          title: "Something Went Wrong",
          text: "Please Check the One Time Password is Valid or Not.",
          icon: "warning",
          button: "OK",
          timer: 2000,
        });
      }
      console.log("API Response:", response.data);
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      setloading(false);
    }
  };

  const validateForm = (data) => {
    const errors = {};
    if (!otp || otp.length !== 6) {
      errors.forgot_otp = "One-time password must be 6 characters";
    }

    if (!data.pass_new) {
      errors.pass_new = "New password is required";
    }

    if (!data.pass_new_confirm) {
      errors.pass_new_confirm = "Confirm password is required";
    } else if (data.pass_new !== data.pass_new_confirm) {
      errors.pass_new_confirm = "Passwords do not match";
    }

    return errors;
  };

  const cardStyle = {
    margin: "auto",
    height: "auto",
    width: "900px",
    background: "#6665DD",
    padding: "20px",
    borderRadius: "50px",
  };

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div>
      {loading && <Loader />}
      <Container
        style={{ display: "flex", justifyContent: "center", height: "100vh" }}
      >
        <Card style={cardStyle}>
          <Card.Body>
            <Row style={{ alignItems: "center" }}>
              <Col sm={6}>
                <div>
                  <h1
                    style={{
                      color: "white",
                      textAlign: "center",
                      fontSize: "30px",
                    }}
                  >
                    Unlock Your Cosmos Medi Spa Patient Portal Credentials
                  </h1>
                  <p style={{ color: "white", textAlign: "justify" }}>
                    Welcome to Cosmos Medi Spa, your gateway to personalized
                    healthcare. To complete your journey, we need your help.
                    Please add the one-time password you've received in your
                    email to unlock and create your Cosmos Medi Spa Patient
                    Portal credentials. This is your key to seamless access to
                    your health records, appointments, and more.
                  </p>
                  <p style={{ color: "white", textAlign: "justify" }}>
                    <i>
                      Fill in the username and password fields below, and let
                      the journey to a healthier you begin.
                    </i>
                  </p>
                </div>
              </Col>
              <Col sm={6}>
                <div
                  style={{
                    paddingBottom: "20px",
                    paddingTop: "10px",
                  }}
                >
                  <form onSubmit={handleSubmit}>
                    <label
                      style={{
                        color: "white",
                        fontWeight: "600",
                        paddingBottom: "10px",
                      }}
                    >
                      Email :
                    </label>
                    <div className="login-form-flex-div">
                      <img
                        src={EmailLogo}
                        alt=""
                        className="login-form-input-logo"
                      />
                      <input
                        type="text"
                        name="email"
                        value={params.username}
                        readOnly
                        className="login-form-input-field"
                      />
                    </div>
                    <div style={{ marginTop: "-15px" }}>
                      <span style={{ color: "red" }}>{errors.email}</span>
                    </div>

                    <label
                      style={{
                        color: "white",
                        fontWeight: "600",
                        paddingBottom: "10px",
                      }}
                    >
                      One-Time Password:
                    </label>
                    <div className="login-form-flex-div">
                      <img
                        src={PasswordLogo}
                        alt=""
                        className="login-form-input-logo"
                      />
                      {/* <input
                      type="text"
                      name="forgot_otp"
                      value={formData.forgot_otp}
                      onChange={handleChange}
                      className="login-form-input-field"
                    /> */}

                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          type="text"
                          min="0"
                          max="9"
                          maxLength="1"
                          value={digit}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (!isNaN(value) && value >= 0 && value <= 9) {
                              handleOtpChange(index, value);
                            }
                          }}
                          onKeyDown={(e) => handleBackspace(index, e)}
                          className="login-form-input-field"
                          ref={(el) => (otpInputRefs.current[index] = el)}
                          style={{
                            border: "1px solid white",
                            textAlign: "center",
                          }}
                        />
                      ))}
                    </div>
                    <div style={{ marginTop: "-15px" }}>
                      <span style={{ color: "red" }}>{errors.forgot_otp}</span>
                    </div>

                    <label
                      style={{
                        color: "white",
                        fontWeight: "600",
                        paddingBottom: "10px",
                      }}
                    >
                      New Password :
                    </label>
                    <div className="login-form-flex-div">
                      <img
                        src={PasswordLogo}
                        alt=""
                        className="login-form-input-logo"
                      />
                      <input
                        type={showPassword ? "text" : "password"}
                        name="pass_new"
                        value={formData.pass_new}
                        onChange={handleChange}
                        className="login-form-input-field"
                      />
                      {showPassword ? (
                        <PiEyeClosedFill
                          type="button"
                          onClick={togglePasswordVisibility}
                          style={{ color: "white", fontSize: "25px" }}
                        />
                      ) : (
                        <RxEyeOpen
                          type="button"
                          onClick={togglePasswordVisibility}
                          style={{ color: "white", fontSize: "25px" }}
                        />
                      )}
                    </div>

                    <div style={{ marginTop: "-15px" }}>
                      <span style={{ color: "red" }}>{errors.pass_new}</span>
                    </div>

                    <label
                      style={{
                        color: "white",
                        fontWeight: "600",
                        paddingBottom: "10px",
                      }}
                    >
                      Confirm Password :
                    </label>
                    <div className="login-form-flex-div">
                      <img
                        src={PasswordLogo}
                        alt=""
                        className="login-form-input-logo"
                      />
                      <input
                        type={showPassword ? "text" : "password"}
                        name="pass_new_confirm"
                        value={formData.pass_new_confirm}
                        onChange={handleChange}
                        className="login-form-input-field"
                      />
                      {showPassword ? (
                        <PiEyeClosedFill
                          type="button"
                          onClick={togglePasswordVisibility}
                          style={{ color: "white", fontSize: "25px" }}
                        />
                      ) : (
                        <RxEyeOpen
                          type="button"
                          onClick={togglePasswordVisibility}
                          style={{ color: "white", fontSize: "25px" }}
                        />
                      )}
                    </div>
                    <div style={{ marginTop: "-15px" }}>
                      <span style={{ color: "red" }}>
                        {errors.pass_new_confirm}
                      </span>
                    </div>

                    <button className="login-form-login-button" type="submit">
                      Create Credentials
                    </button>
                  </form>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default ResetPassword;
