import React, { useEffect, useRef, useState } from "react";
import { Card, Container } from "react-bootstrap";
import DigiLogo from "../../Assets/cosmosLogocolor.png";
import UserLogo from "../../Assets/ep_user-filled.svg";
import Eclipse from "../../Assets/Ellipse1.svg";
import PasswordLogo from "../../Assets/teenyicons_password-solid.svg";
import "../../GlobalFiles/Styles/Styles.css";
import swal from "sweetalert";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { useNavigate } from "react-router-dom";
import { PiEyeClosedFill } from "react-icons/pi";
import { RxEyeOpen } from "react-icons/rx";

const EHRLogin = () => {

  useEffect(()=>{
    sessionStorage.clear();
    },[])
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [RefreshToken, setRefreshToken] = useState("");
  const navigate = useNavigate();
  const passwordRef = useRef(null);
  
  const handlePaste = (e) => {
    e.preventDefault(); 
  };

  const getTokenAndHandleLogin = async () => {
    try {
      const tokenResponse = await ApiServices.post(
        "/oauth2/default/token",

        {
          client_id: "G7H1yJbmR_JzoZV3Xw0wV8LO58pL2hb-YV6KFv-hl94",
          grant_type: "password",
          scope:
            "openid offline_access api:oemr api:fhir api:port user/allergy.read user/allergy.write user/appointment.read user/appointment.write user/dental_issue.read user/dental_issue.write user/document.read user/document.write user/drug.read user/encounter.read user/encounter.write user/facility.read user/facility.write user/immunization.read user/insurance.read user/insurance.write user/insurance_company.read user/insurance_company.write user/insurance_type.read user/list.read user/medical_problem.read user/medical_problem.write user/medication.read user/medication.write user/message.write user/patient.read user/patient.write user/practitioner.read user/practitioner.write user/prescription.read user/procedure.read user/soap_note.read user/soap_note.write user/surgery.read user/surgery.write user/transaction.read user/transaction.write user/vital.read user/vital.write user/AllergyIntolerance.read user/CareTeam.read user/Condition.read user/Coverage.read user/Encounter.read user/Immunization.read user/Location.read user/Medication.read user/MedicationRequest.read user/Observation.read user/Organization.read user/Organization.write user/Patient.read user/Patient.write user/Practitioner.read user/Practitioner.write user/PractitionerRole.read user/Procedure.read user/patientIdentity.write user/faceRecognition.read user/anatomyData.write user/anatomyData.read user/ICD10.read user/ICD10Area.read user/appointmentSlot.read user/appointmentCategories.read patient/patient.read user/providerAppointments.read user/apptStatusUpdate.write user/billingweb.write user/appointmentBooked.read",
          user_role: "users",
          username: username,
          password: password,
        },

        {
          "Content-Type": "application/x-www-form-urlencoded",
        }
      );

      const accessToken = tokenResponse.data.access_token;
      setRefreshToken(tokenResponse.data.refresh_token);

      const response = await ApiServices.get(
        `/apis/default/api/practitioner/${username}`,

        {
          Authorization: `Bearer ${accessToken}`,
        }
      );

      console.log("Login Response:", response.data["0"]);
      sessionStorage.setItem("LoginUserID",response.data["0"].id)
      sessionStorage.setItem("LoginUserType",response.data["0"].user_type)
      sessionStorage.setItem("LoginUserFname",response.data["0"].fname)
      sessionStorage.setItem("LoginUserLname",response.data["0"].lname)
      swal({
        title: "Login Successful",
        text: "Welcome to the Cosmos Medi Spa Staff Portal.",
        icon: "success",
        button: "OK",
        timer: 2000,
      });
      navigate("/admin");
    } catch (error) {
      console.error("Error:", error);
      swal({
        title: "Login Failed",
        text: "Something went wrong.",
        icon: "error",
        button: "OK",
        timer: 2000,
      });
    }
  };

  const cardStyle = {
    margin: "auto",
    height: "530px",
    width: "500px",
    background: "#6665DD",
    padding: "20px",
    borderRadius: "50px",
  };

  const divBackgroundImgStyle = {
    backgroundImage: `url(${Eclipse})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div>
      <Container
        style={{ display: "flex", justifyContent: "center", height: "100vh" }}
      >
        <Card style={cardStyle}>
          <Card.Body>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "20px",
                marginTop: "30px",
              }}
            >
              <img src={DigiLogo} alt="" style={{ height: "90px" }} />
            </div>
            <div>
              <p
                style={{
                  color: "white",
                  fontWeight: "400",
                  fontSize: "18px",
                  textAlign: "center",
                }}
              >
                Practitioner Login
              </p>
            </div>
            <form style={{ marginTop: "30px" }}>
              <div className="login-form-flex-div">
                <div style={divBackgroundImgStyle}>
                  <img
                    src={UserLogo}
                    alt=""
                    className="login-form-input-logo"
                  />
                </div>
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="login-form-input-field"
                />
              </div>

              <div className="login-form-flex-div">
                <img
                  src={PasswordLogo}
                  alt=""
                  className="login-form-input-logo"
                />
                <input
                  // type="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="login-form-input-field"
                  onPaste={handlePaste}
                  ref={passwordRef}
                />
                  {showPassword ? 
                <PiEyeClosedFill type="button" onClick={togglePasswordVisibility} style={{color:"white",fontSize:"25px"}}/>
                :
                <RxEyeOpen type="button" onClick={togglePasswordVisibility} style={{color:"white",fontSize:"25px"}}/>
                
                }
              </div>

              <div>
                <button
                  type="button"
                  onClick={getTokenAndHandleLogin}
                  className="login-form-login-button"
                >
                  Login
                </button>
              </div>
            </form>
            <div>
              <p className="forgot-password-para-tag">Forgot Password?</p>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default EHRLogin;
