import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";


import { useNavigate } from "react-router-dom";
import AccessToken from "../../../GlobalFiles/ApiServices/AccessToken";
import ApiServices from "../../../GlobalFiles/ApiServices/ApiServices";

const AllProvidersList = () => {
  const [patientList, setPatientList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredPatientList, setFilteredPatientList] = useState([]);
  const [patientToDelete, setPatientToDelete] = useState(null);
  const [AccessTokenSaved, setAccessTokenSaved] = useState(null);
  

  const getName = (params) => {
    const patient = params.data;
    return `${patient.fname} ${patient.mname} ${patient.lname}`;
  };

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const accessToken = await AccessToken.fetchAccessToken();
        setAccessTokenSaved(accessToken)
        const patientResponse = await ApiServices.get(
          "/apis/default/api/practitioner",
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );

        const patients = patientResponse.data;
        setPatientList(patients);
        setFilteredPatientList(patients);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchAccessToken();
  }, []);

  const navigate= useNavigate()
  const handleCellClick = (params) => {
    if (params.colDef.field === "name") {
      const providerUuid = params.data.uuid; 
      const providerid = params.data.id; 
      navigate(`/admin/providers-details/${providerUuid}/${providerid}`);
      
    }
  };

  const handleSearchInputChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchInput(inputValue);

    const filteredPatients = patientList.filter(
      (patient) =>
        patient.fname.toLowerCase().includes(inputValue) ||
        patient.mname.toLowerCase().includes(inputValue) ||
        patient.lname.toLowerCase().includes(inputValue)
    );

    setFilteredPatientList(filteredPatients);
  };



  const columnDefs = [
    {
      headerName: "ID",
      field: "id",
      headerClass: "custom-header-of-tables",
      flex:1,
      minWidth: 80,
    },
    {
      headerName: "Name",
      field: "name",
      valueGetter: getName,
      headerClass: "custom-header-of-tables",
      onCellClicked: handleCellClick,
      flex:1,
      minWidth: 200,
    },
    {
      headerName: "Sex",
      field: "sex",
      headerClass: "custom-header-of-tables",
      flex:1,
      minWidth: 100,
    },
    {
      headerName: "Username",
      field: "username",
      headerClass: "custom-header-of-tables",
      flex:1,
      minWidth: 150,
    },
    {
      headerName: "Contact Number",
      field: "phonecell",
      headerClass: "custom-header-of-tables",
      flex:1,
      minWidth: 180,
    },
    {
      headerName: "Email",
      field: "email",
      headerClass: "custom-header-of-tables",
      flex:1,
      minWidth: 250,
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  return (
    <div style={{ backgroundColor: "#F1F5F8" }}>
      <Row>
        <Col sm={12}>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <input
              type="text"
              placeholder="Search Doctor by Name..."
              value={searchInput}
              onChange={handleSearchInputChange}
              className="form-control mb-2"
            />
            {/* <button
              className="login-form-login-button"
              style={{
                marginTop: "0px",
                marginBottom: "0.5rem",
                maxWidth: "150px",
              }}
            >
              + Add Patient
            </button> */}
          </div>
          <div
            className="ag-theme-alpine"
            style={{ height: "85vh", width: "100%", 
            // overflow: "hidden"
             }}
          >
            <AgGridReact
              columnDefs={columnDefs}
              //   rowData={patientList}
              rowData={filteredPatientList}
              defaultColDef={defaultColDef}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AllProvidersList;
