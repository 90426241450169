import React from 'react'
import { Container } from 'react-bootstrap'
import MedSpaTreatmentCarousel from './MedSpaTreatmentCarousel'

const MedSpaTreatments = () => {
  return (
    <div>
        <Container>
            <div>
                <h1 className='explore-treartment-tag'>Explore our treatments</h1>
                <p className='explore-treartment-sub-title-tag'>Our med spa services include skin rejuvenation treatments, body contouring, dermal fillers and injectables, hair restoration, and hormone therapy. Defy age, feel great, and look amazing with our unparalleled medical spa services. Schedule a consultation at one of our COSMOS medical spas near you to learn more.</p>
                <h1 className='explore-treartment-sub-title-head-tag'>What do you want to highlight?</h1>
            </div>
            <MedSpaTreatmentCarousel/>
        </Container>
    </div>
  )
}

export default MedSpaTreatments