import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useNavigate } from "react-router-dom";
import AppointmentPatientList from "../../GlobalFiles/ApiServices/AppointmentPatientList";

const PastAppointments = () => {
  const [patientList, setPatientList] = useState([]);
  const accessToken = sessionStorage.getItem("accessToken");
  const fetchPatientList = async () => {
    try {
      const patients = await AppointmentPatientList.fetchData();
      setPatientList(patients);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchPatientList();
  }, []);
  const [searchInput, setSearchInput] = useState("");
  const [filteredPatientList, setFilteredPatientList] = useState([]);
  const getName = (params) => {
    const patient = params.data;
    return `${patient.patient_fname} ${patient.mname ? patient.mname : ""} ${
      patient.patient_lname
    }`;
  };

  useEffect(() => {
    setFilteredPatientList(filterPatientsByDate(patientList));
  }, [patientList]);

  const filterPatientsByDate = (patients) => {
    const currentDate = new Date().toISOString().split("T")[0];
    return patients.filter((patient) => patient.pc_eventDate <= currentDate);
  };

  const navigate = useNavigate();
  const handleCellClick = (params) => {
    if (params.colDef.field === "name") {
      const patientUuid = params.data.patient_uuid;
      const patienteid = params.data.pc_eid;
      const patientpid = params.data.pc_pid;
      
      navigate(`/admin/patient-details-view/${patientUuid}/${patienteid}/${patientpid}`,{
        state: { patienteid: patienteid }
      });
    }
  };

  const handleSearchInputChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchInput(inputValue);
  
    const filteredPatients = filterPatientsByDate(patientList).filter(
      (patient) =>
        (patient.patient_fname ? patient.patient_fname.toLowerCase().includes(inputValue) : false) ||
        (patient.patient_lname ? patient.patient_lname.toLowerCase().includes(inputValue) : false)
    );
  
    setFilteredPatientList(filteredPatients);
  };
  

  const handleShowBillClick = (params) => {
    console.log("params of Show Bill",params)
    const patientUuid = params.patient_uuid;
    const patienteid = params.pc_eid;
    const patientepid = params.pc_pid;
    navigate(`/admin/patient-details-view/${patientUuid}/${patienteid}/${patientepid}`);
  };

  const handleGenerateBillClick = (params) => {
    console.log("params",params)
    const patientUuid = params.patient_uuid;
    const patienteid = params.pc_eid;
    const patientepid = params.pc_pid;
      navigate(`/admin/patient-details-view/${patientUuid}/${patienteid}/${patientepid}`);
  };

  const columnDefs = [
    {
      headerName: "Name",
      field: "name",
      valueGetter: getName,
      headerClass: "custom-header-of-tables",
      onCellClicked: handleCellClick,
      minWidth: 250,
      flex: 1,
      pinned: 'left'
    },

    {
      headerName: " Date",
      field: "pc_eventDate",
      headerClass: "custom-header-of-tables",
      minWidth: 120,
      flex: 1,
      sort:"desc"
    },
    {
      headerName: "Time",
      field: "pc_startTime",
      headerClass: "custom-header-of-tables",
      minWidth: 100,
      flex: 1,
    },
    {
      headerName: " Type",
      field: "appointment_type",
      headerClass: "custom-header-of-tables",
      minWidth: 140,
      flex: 1,
    },
    {
      headerName: "Client Type",
      field: "client_type",
      headerClass: "custom-header-of-tables",
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: "Appointment Status",
      field: "pc_apptstatus",
      headerClass: "custom-header-of-tables",
      minWidth: 140,
      flex: 1,
      sortable: false,
      filter: false,
      cellRenderer: (params) => {
        const status = params.data.pc_apptstatus.trim();
        let buttonColor = "";
        let buttonText = "";
        let textCololor = "";

        switch (status) {
          case "-":
            buttonColor = "yellow";
            buttonText = "Pending";
            textCololor = "black";
            break;
          case "^":
            buttonColor = "yellow";
            buttonText = "Pending";
            textCololor = "black";
            break;
          case "APPR":
            buttonColor = "green";
            buttonText = "Approved";
            textCololor = "white"; // Corrected assignment here
            break;
          case "REJ":
            buttonColor = "red";
            buttonText = "Rejected";
            textCololor = "white"; // Corrected assignment here
            break;
          default:
            buttonColor = "gray";
            buttonText = "Unknown";
            textCololor = "white"; // Corrected assignment here
        }

        return (
          <div
            style={{
              backgroundColor: buttonColor,
              color: textCololor,
              textAlign: "center",
            }}
            // onClick={() => handleButtonClick(params.data)}
          >
            {buttonText}
          </div>
        );
      },
    },
    {
      headerName: "Bill",
      field: "generatebill",
      headerClass: "custom-header-of-tables",
      minWidth: 160,
      flex: 1,
      sortable: false,
      filter: false,
      cellRenderer: (params) => {
        const { data } = params;
        console.log("show bill params",params.data)
        return (
          <div>
            {/* {params.data.generated_bill_provider != 1 ?
          <Button
            variant="info"
            size="sm"
            onClick={() => handleGenerateBillClick(data)}
          >
            Generate Bill
          </Button>
          : 
          <Button
          
            variant={params.data.is_billed == 1 ? "success" : "danger"}
            size="sm"
            onClick={() => handleShowBillClick(data)}
          >
            Show Bill
          </Button>
          } */}
          {
          
          params.data.generated_bill_provider != 1  ? (
            <Button variant="secondary" size="sm" onClick={() => handleGenerateBillClick(data)} style={{width:"100%"}}>
              Generate Bill
            </Button>
          ) : (
            <Button variant={params.data.is_billed == 1 ? "success" : "info"} size="sm" onClick={() => handleShowBillClick(data)} style={{width:"100%"}}>
            {params.data.is_billed == 1 ? "Pyment Received" : "Show Bill"}
              
            </Button>
          )
        }
          </div>
        );
      },
    },
    
    {
      headerName: "Contact Number",
      field: "phone_contact",
      headerClass: "custom-header-of-tables",
      minWidth: 180,
      flex: 1,
    },
    {
      headerName: "Email",
      field: "patient_email",
      headerClass: "custom-header-of-tables",
      minWidth: 240,
      flex: 1,
    },
    // {
    //   headerName: "Appointment Details",
    //   field: "action",
    //   headerClass: "custom-header-of-tables",
    //   minWidth: 140,
    //   flex: 1,
    //   sortable: false,
    //   filter: false,
    //   cellRenderer: (params) => {
    //     const { data } = params;
    //     return (
    //       <Button variant="danger" size="sm"
    //       onClick={() => handleRejectClick(data.pc_pid, data.pc_eid)}
    //       >
    //         Not Available
    //       </Button>
    //     );
    //   },
    // },
    // {
    //   headerName: "Billing Details",
    //   field: "action",
    //   headerClass: "custom-header-of-tables",
    //   minWidth: 140,
    //   flex: 1,
    //   sortable: false,
    //   filter: false,
    //   cellRenderer: (params) => {
    //     const { data } = params;
    //     return (
    //       <Button variant="info" size="sm"
    //       // onClick={() => handleRejectClick(data.pc_pid, data.pc_eid)}
    //       >
    //         Generate Bill
    //       </Button>
    //     );
    //   },
    // },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  return (
    <div style={{ backgroundColor: "#F1F5F8" }}>
      <Row>
        <Col sm={12}>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <input
              type="text"
              placeholder="Search Patient by Name..."
              value={searchInput}
              onChange={handleSearchInputChange}
              className="form-control mb-2"
            />
          </div>
          <div
            className="ag-theme-alpine"
            style={{
              height: "85vh",
              width: "100%",
              // overflow: "hidden"
            }}
          >
            <AgGridReact
              columnDefs={columnDefs}
              //   rowData={patientList}
              rowData={filteredPatientList}
              defaultColDef={defaultColDef}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PastAppointments;
