import React, { useEffect, useState } from "react";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { Button, Modal } from "react-bootstrap";
import ServiceDynamicForm from "./ServiceDynamicForm";
import { useNavigate } from "react-router-dom";

const ServiceForms = () => {
  const accessToken = sessionStorage.getItem("accessToken");
  const LoginPatientID = sessionStorage.getItem("LoginPatientID");
  const LoginUserType = sessionStorage.getItem("LoginUserType");
  const [AvailableForms, setAvailableForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);

  useEffect(() => {
    const fetchPatientForms = async () => {
      try {
        let endpoint = `/apis/default/api/getFormsPatient/${LoginPatientID}`;

        if (LoginUserType == "Patient") {
          endpoint = `/apis/default/portal/getFormsPatient/${LoginPatientID}`;
        }

        const patientFormsResponse = await ApiServices.get(
          endpoint,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );

        const AvailableForms = patientFormsResponse.data;
        setAvailableForms(AvailableForms);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPatientForms();
  }, [accessToken, LoginPatientID]);

  const navigate = useNavigate();
  const handleopenFormModal = (selectedFormData) => {
    setSelectedForm(selectedFormData);
    console.log("selectedFormData",selectedFormData)
    navigate(
      `/patient-dashboard/service-form/${selectedFormData.eid}/${selectedFormData.pid}/${selectedFormData.service_no}`
    );
  };

  const handleopenFormFilled = (selectedFormData) => {
    setSelectedForm(selectedFormData);
    navigate(
      `/patient-dashboard/filled-service-form/${selectedFormData.eid}/${selectedFormData.pid}/${selectedFormData.service_no}`
    );
  };

  return (
    <div>
      <table className="billing-table">
        <thead>
          <tr>
            <th>Service Name</th>
            <th>Form Name</th>
            <th>Form Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {AvailableForms &&
            AvailableForms.map((index) => (
              <tr>
                <td>{index.service}</td>
                <td>{index.form_id}</td>
                <td>{index.timestamp}</td>
                <td>
                  <Button
                    variant={index.is_accepted == "1" ? "success" : "warning"}
                    style={{ width: "100%" }}
                    // onClick={
                    //   index.is_accepted == "1"
                    //     ? () => handleopenFormFilled(index)
                    //     : () => handleopenFormModal(index)
                    // }
                    onClick={
                     
                         () => handleopenFormModal(index)
                    }
                  >
                  Open
                    {/* {index.is_accepted == "1" ? "Submitted" : "Pending"} */}
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ServiceForms;
