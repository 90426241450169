import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ApiServices from "../../../GlobalFiles/ApiServices/ApiServices";

const ServicesArticles = () => {
  const [articlesList, setarticlesList] = useState(null);

  useEffect(() => {
    const apiUrl = `/api/articles/`;
    ApiServices.get(apiUrl).then((response) => {
      const lastThreeArticles = response.data.slice(-3);
      setarticlesList(lastThreeArticles);  
    });
  }, []);

  console.log("articlesList",articlesList)
  return (
    <div>
      <Container>
        <h1 class="section-one-head-tag">Read More from Our Blog</h1>
        <Row>
        {articlesList &&
            articlesList.map((article) => (
              <Col key={article.id} md={4}>
              <Link to={`/articles/${article.id}`} style={{ textDecoration: "none" }}>
                <Card className="med-spa-article-card-in-service">
                  <Card.Img variant="top" style={{height:"230px"}} src={article.image} />
                  <Card.Body style={{paddingLeft:"0px", paddingRight:"0px"}}>
                    <Card.Title className="med-spa-article-card-in-service-title">{article.title}</Card.Title>
                  </Card.Body>
                </Card>
                </Link>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};

export default ServicesArticles;
