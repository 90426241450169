import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillCaretDown } from "react-icons/ai";

const ServicesDetailsFaq = ({ data }) => {
  const detailsData = data;
  const[faqdata,setfaqdata]=useState('')
  useEffect(()=>{
    if(data){
        setfaqdata(detailsData.faqs)
    }
},[data])

const [openFaqIndex, setOpenFaqIndex] = useState(null);
const handleOpenFaq = (index) => {
    setOpenFaqIndex(index === openFaqIndex ? null : index);
  };
    

  return (
    <div className="med-spa-sec-three-bg">
      <Container style={{textAlign:"left"}}>
        <Row
        //   className="align-items-center"
          style={{ width: "100%", margin: "0px",paddingTop:"5rem",paddingBottom:"5rem" }}
        >
          

          <Col sm={6} 
        //   className="order-2 order-sm-1"
          >
            <h1 className="section-three-head-tag">
              Questions? We've got answers.
            </h1>
          </Col>
          <Col
            sm={6}
            // className="order-2 order-sm-2"
            style={{ padding: "0px" }}
          >
            {faqdata&&faqdata?.map((faq, index) => (
          <div className="dr-faq-width-div" key={index}>
            <div
              className="dr-faq-main-div"
              onClick={() => handleOpenFaq(index)}
            >
              <h3>{faq.question}</h3> <AiFillCaretDown />
            </div>
            {openFaqIndex === index && (
              <div className="dr-faq-flex-div">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServicesDetailsFaq;
