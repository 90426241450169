import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CosmoIntroVideo from "../../Assets/Cosmosentryvideo.mp4";
import LandigImage from "../../Assets/landing.jpg"
import { Link } from "react-router-dom";

const MedSpaSectionOne = () => {
  return (
    <div className="med-spa-section-one-bg">
      <Container>
        <Row className="align-items-center">
          <Col sm={6} className="order-2 order-sm-1" style={{textAlign:"left"}}>
            <h1 className="section-one-head-tag">
              Age Gracefully. <br /> Look Amazing.
            </h1>
            <p className="section-one-para-tag">
              COSMOS Med Spa specializes in High Quality aesthetic medicine and
              wellness. Our medical spa provides elevated and innovative
              aesthetic services for both men and women that empower, enhance
              natural beauty, and inspire confidence.
            </p>
            <Link to="/signup">
              <button className="med-spa-custom-button">
                Register here..
              </button>
            </Link>
          </Col>
          <Col sm={6} className="order-1 order-sm-2">
            {/* <video src='https://viomedspa.b-cdn.net/vio_hero.mp4' preload="auto" autoPlay loop muted  style={{height:"100%", width:"100%"}}></video> */}
            {/* <video
              src={CosmoIntroVideo}
              preload="auto"
              autoPlay
              loop
              muted
              style={{ height: "100%", width: "100%" }}
            ></video> */}

            <img src={LandigImage} alt="" className="section-image-quadra-shape" style={{width:"100%",maxHeight:"435px"}}/>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MedSpaSectionOne;
