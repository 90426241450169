import React, { useState } from "react";
import Calendar from "react-calendar";
import "./AdministratorCalendar.css";

import SearchLogo from "../../Assets/search.svg";
import { Modal } from "react-bootstrap";
import ProviderCalendarAppointments from "../../Components/ProviderCalendarAppointments/ProviderCalendarAppointments";

const AdministratorCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  
  return (
    <div>
      <div className="appointment-calender-top-div">
        <input
          type="text"
          placeholder="Appointment Date"
          className="input-in-calendar"
        />
        <div style={{ display: "flex", gap: "20px" }}>
          <div
            style={{
              width: 40,
              height: 40,
              background: "#CCDDEA",
              borderRadius: 8,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={SearchLogo} />
          </div>

          <div
            style={{
              width: 40,
              height: 40,
              background: "#CCDDEA",
              borderRadius: 8,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ margin: "0px", fontSize: "26px" }}>+</p>
          </div>
        </div>
      </div>

      <Calendar  onChange={handleDateChange}
            value={selectedDate}/>

<Modal show={showModal} onHide={handleModalClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Appointments for {selectedDate.toDateString()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProviderCalendarAppointments selectedDate={selectedDate} />
        </Modal.Body>
        
      </Modal>
    </div>
  );
};

export default AdministratorCalendar;
