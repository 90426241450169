import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const EmailOtpVerification = ({
  show,
  onClose,
  onVerify,
  error,
  email,
  sendotpagain,
}) => {
  const [otp, setOtp] = useState("");

  const handleOtpVerification = () => {
    onVerify(otp);
  };

  return (
    <div>
      <Modal show={show} onHide={onClose}>
        <Modal.Header>
          <Modal.Title>
            <p
              style={{ margin: "0px", fontSize: "2.5rem" }}
              className="section-one-head-tag"
            >
              Token Verification
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{error}</p>
          <p style={{ margin: "0px" }} className="med-spa-staff-info-p-tag">
            Enter the Token sent to your email address : <br />
            {email}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            {/* <input
              type="text"
              pattern="[0-9]*"
              inputMode="numeric"
              maxLength="6"
              placeholder={generatePlaceholders()}
              value={otp}
              className="appt-as-guest-input-field"
              onChange={(e) => {
                // Ensure the input only contains 6 digits
                const newValue = e.target.value
                  .replace(/\D/g, "")
                  .substring(0, 6);
                setOtp(newValue);
              }}
              style={{width:"65px",padding:"5px"}}
            /> */}

            <input
              type="text"
              className="appt-as-guest-input-field"
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value);
              }}
            />
          </div>
          <div>
            <p style={{ margin: "0px" }} className="med-spa-staff-info-p-tag">
              Did not receive the Token?
            </p>
            <p style={{ margin: "0px" }} className="med-spa-staff-info-p-tag">
              Check your email inbox, including spam or junk folders.
            </p>
            <p style={{ margin: "0px" }} className="med-spa-staff-info-p-tag">
              If you still don't receive the OTP, you can
              <p className="clear-filters-text" onClick={sendotpagain}>
                Resend OTP
              </p>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="med-spa-custom-button"
            style={{ backgroundColor: "rgb(229, 231, 235)", color: "black" }}
            onClick={onClose}
          >
            Close
          </button>
          <button
            className="med-spa-custom-button"
            onClick={handleOtpVerification}
          >
            Verify Token
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EmailOtpVerification;
