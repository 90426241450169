import axios from 'axios';
import React, { useEffect, useState } from 'react';

const UpdateBillingServices = ({ practitionerdata }) => {
  const [doctorServices, setDoctorServices] = useState([]);
  const [updatedServices, setUpdatedServices] = useState([]);
  const [allServices, setAllServices] = useState([]);

  useEffect(() => {
    // Fetch doctor services
    // axios.get(`http://127.0.0.1:8000/api/doctor-services/by-doctor/${practitionerdata.id}/`)
    axios.get(`https://cosmosapi.medispacosmos.com/api/doctor-services/by-doctor/${practitionerdata.id}/`)
      .then(response => {
        setDoctorServices(response.data);
      })
      .catch(error => {
        console.error('Error fetching doctor services:', error);
      });

    // Fetch all services
    // axios.get('http://127.0.0.1:8000/api/services/')
    axios.get('https://cosmosapi.medispacosmos.com/api/services/')
      .then(response => {
        setAllServices(response.data);
      })
      .catch(error => {
        console.error('Error fetching all services:', error);
      });
  }, [practitionerdata.id]);

  const getServiceTitleById = (serviceId) => {
    const service = allServices.find(service => service.id === serviceId);
    return service ? service.title : 'Service Not Found';
  };

  const handleUpdateDiscount = (id, updatedDiscount) => {
    setUpdatedServices(prevUpdatedServices => {
      const updatedServiceIndex = prevUpdatedServices.findIndex(service => service.id === id);

      if (updatedServiceIndex !== -1) {
        prevUpdatedServices[updatedServiceIndex].discount = updatedDiscount;
      } else {
        prevUpdatedServices.push({ id, discount: updatedDiscount });
      }

      return [...prevUpdatedServices];
    });
  };

  const handleUpdateActiveStatus = (id, updatedActiveStatus) => {
    setUpdatedServices(prevUpdatedServices => {
      const updatedServiceIndex = prevUpdatedServices.findIndex(service => service.id === id);

      if (updatedServiceIndex !== -1) {
        prevUpdatedServices[updatedServiceIndex].active_status = updatedActiveStatus;
      } else {
        prevUpdatedServices.push({ id, active_status: updatedActiveStatus });
      }

      return [...prevUpdatedServices];
    });
  };

  const handleUpdateAll = () => {
    updatedServices.forEach(updatedService => {
      const { id, discount, active_status } = updatedService;

    //   axios.patch(`http://127.0.0.1:8000/api/doctor-services/${id}/`, { discount, active_status })
      axios.patch(`https://cosmosapi.medispacosmos.com/api/doctor-services/${id}/`, { discount, active_status })
        .then(response => {
          console.log(`Discount and active_status for doctor service ${id} updated successfully:`, response.data);
        })
        .catch(error => {
          console.error(`Error updating discount and active_status for doctor service ${id}:`, error);
        });
    });

    setUpdatedServices([]);
  };

  return (
    <div>
      <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid black' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid black', padding: '8px' }}>Service Title</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>Discount</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>Active Status</th>
          </tr>
        </thead>
        <tbody>
          {doctorServices && doctorServices?.map((service) => (
            <tr key={service.id}>
              <td style={{ border: '1px solid black', padding: '8px' }}>{getServiceTitleById(service.service)}</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>{service.discount}</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>
                <input
                  type='number'
                  value={service.discount ? service.discount.amount : 0}
                  onChange={(e) => handleUpdateDiscount(service.id, e.target.value)}
                />
              </td>
              <td style={{ border: '1px solid black', padding: '8px' }}>
                <label>
                  <input
                    type='checkbox'
                    defaultChecked={service.active_status}
                    onChange={(e) => handleUpdateActiveStatus(service.id, e.target.checked)}
                  />
                </label>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleUpdateAll}>Update All</button>
    </div>
  );
};

export default UpdateBillingServices;





// import axios from 'axios';
// import React, { useEffect, useState } from 'react';

// const UpdateBillingServices = ({ practitionerdata }) => {
//   const [doctorServices, setDoctorServices] = useState([]);
//   const [updatedServices, setUpdatedServices] = useState([]);
//   const [allServices, setAllServices] = useState([]);

//   useEffect(() => {
//     // Fetch doctor services
//     axios.get(`http://127.0.0.1:8000/api/doctor-services/by-doctor/${practitionerdata.id}/`)
//       .then(response => {
//         setDoctorServices(response.data);
//       })
//       .catch(error => {
//         console.error('Error fetching doctor services:', error);
//       });

//     // Fetch all services
//     axios.get('http://127.0.0.1:8000/api/services/')
//       .then(response => {
//         setAllServices(response.data);
//       })
//       .catch(error => {
//         console.error('Error fetching all services:', error);
//       });
//   }, [practitionerdata.id]);

//   const getServiceTitleById = (serviceId) => {
//     const service = allServices.find(service => service.id === serviceId);
//     return service ? service.title : 'Service Not Found';
//   };

//   const handleUpdateDiscount = (id, updatedDiscount) => {
//     setUpdatedServices(prevUpdatedServices => {
//       const updatedServiceIndex = prevUpdatedServices.findIndex(service => service.id === id);

//       if (updatedServiceIndex !== -1) {
//         prevUpdatedServices[updatedServiceIndex].discount = updatedDiscount;
//       } else {
//         prevUpdatedServices.push({ id, discount: updatedDiscount });
//       }

//       return [...prevUpdatedServices];
//     });
//   };

//   const handleUpdateActiveStatus = (id, updatedActiveStatus) => {
//     setUpdatedServices(prevUpdatedServices => {
//       const updatedServiceIndex = prevUpdatedServices.findIndex(service => service.id === id);

//       if (updatedServiceIndex !== -1) {
//         prevUpdatedServices[updatedServiceIndex].active_status = updatedActiveStatus;
//       } else {
//         prevUpdatedServices.push({ id, active_status: updatedActiveStatus });
//       }

//       return [...prevUpdatedServices];
//     });
//   };

//   const handleUpdateAll = () => {
//     updatedServices.forEach(updatedService => {
//       const { id, discount, active_status } = updatedService;

//       axios.patch(`http://127.0.0.1:8000/api/doctor-services/${id}/`, { discount, active_status })
//         .then(response => {
//           console.log(`Discount and active_status for doctor service ${id} updated successfully:`, response.data);
//         })
//         .catch(error => {
//           console.error(`Error updating discount and active_status for doctor service ${id}:`, error);
//         });
//     });

//     setUpdatedServices([]);
//   };

//   return (
//     <div>
//       {doctorServices && doctorServices?.map((service) => (
//         <div key={service.id} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
//           <h3>{getServiceTitleById(service.service)}</h3>
          
//           <h3>{service.discount}</h3>
//           <input
//             type='number'
//             value={service.discount ? service.discount.amount : 0}
//             onChange={(e) => handleUpdateDiscount(service.id, e.target.value)}
//           />
//           <label>
//             Active Status:
//             <input
//               type='checkbox'
//               defaultChecked={service.active_status}
//               onChange={(e) => handleUpdateActiveStatus(service.id, e.target.checked)}
//             />
//           </label>
//         </div>
//       ))}
//       <button onClick={handleUpdateAll}>Update All</button>
//     </div>
//   );
// };

// export default UpdateBillingServices;
