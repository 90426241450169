import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { useParams } from "react-router-dom";

const ServiceNotes = ({ SevicesData, PreSendedForms }) => {
  const paramsData = useParams();

  const [filteredServices, setFilteredServices] = useState(SevicesData);
  const accessToken = sessionStorage.getItem("accessToken");

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    const filtered = SevicesData.filter((service) =>
      service.service.toLowerCase().includes(query)
    );
    setFilteredServices(filtered);
    setSearchQuery(query);
  };

  const handleNoteSubmit = async (serviceId, soapNote, noteValue) => {
    try {
      const currentTimestamp = new Date().toLocaleString();
      const updatedSoapNote = {
        ...soapNote,
        Timestamp: currentTimestamp,
        Note: noteValue,
      };
      const postData = {
        service_id: serviceId,
        eid: paramsData.eid,
        pid: paramsData.pid,
        soap_note: [updatedSoapNote],
      };

      const response = await ApiServices.post(
        "/apis/default/api/soapnote",
        postData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );

      console.log(response.data);

      // Add logic to handle success or any other actions after submission
    } catch (error) {
      console.error("Error submitting soap note:", error);
    }
  };

  const [SevicesFilledData, setSevicesFilledData] = useState();
  console.log("SevicesFilledData", SevicesFilledData);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const SevicesFilledDataResponse = await ApiServices.get(
          `/apis/default/api/soapnote/${paramsData.pid}/appointment/${paramsData.eid}`,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );
        setSevicesFilledData(SevicesFilledDataResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [paramsData]);

  return (
    <div>
      <div className="mt-3">
        <h1>Pre Sended Services</h1>
        <table className="billing-table">
          <thead>
            <tr>
              <th>Service Number</th>
              <th>Service Name</th>
              <th>Given SOAP Note</th>
              <th>SOAP Note</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {PreSendedForms &&
              PreSendedForms.map((item, index) => {
                const existingSoapNote =
                  SevicesFilledData &&
                  SevicesFilledData?.find(
                    (data) => data.service_id === item.service_no
                  );
                const existingSoapNoteNew =
                  existingSoapNote && existingSoapNote?.soap_note.soap_note;
                return (
                  <tr key={index}>
                    <td>{item.service_no}</td>
                    <td>
                      <h5>{item.service}</h5>
                    </td>
                    <td>
                      {existingSoapNoteNew
                        ? Object.values(existingSoapNoteNew).map(
                            (note, index) => (
                              <p key={index}>
                                {note.Note} - {note.Timestamp} -{" "}
                                {note.ProviderID}
                              </p>
                            )
                          )
                        : ""}
                    </td>

                    <td>
                      <textarea
                        style={{ width: "100%" }}
                        value={item.noteValue}
                        onChange={(e) => (item.noteValue = e.target.value)}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() =>
                          handleNoteSubmit(
                            item.service_no,
                            {
                              Note: "Test Note",
                              Timestamp: "24/02/2024 12.15",
                              ProviderID: "5",
                            },
                            item.noteValue
                          )
                        }
                      >
                        Submit
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <div>
        <Card className="mb-3 mt-3">
          <Card.Body>
            <div>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Search by Service Name"
                style={{
                  width: "100%",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              />
            </div>

            <div>
              <table className="billing-table">
                <thead>
                  <tr>
                    <th>Service Number</th>
                    <th>Service Name</th>
                    <th>SOAP Note</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredServices &&
                    Array.from(
                      new Set(
                        filteredServices.map((service) => service.service)
                      )
                    ).map((uniqueService, index) => {
                      if (uniqueService.toLowerCase() == "miscellaneous") {
                        return null;
                      }
                      const uniqueServiceData = filteredServices.find(
                        (service) => service.service === uniqueService
                      );

                      const existingSoapNote =
                        SevicesFilledData &&
                        SevicesFilledData?.find(
                          (data) => data.service_id == uniqueServiceData.service_no
                        );
                      const existingSoapNoteNew =
                        existingSoapNote && existingSoapNote?.soap_note.soap_note

                        console.log("existingSoapNoteNew",uniqueServiceData)
                      return (
                        <tr key={index}>
                          <td>{uniqueServiceData.service_no}</td>
                          <td>
                            <h5>{uniqueService}</h5>
                          </td>
                          <td>
                            {existingSoapNoteNew
                              ? Object.values(existingSoapNoteNew).map(
                                  (note, index) => (
                                    <p key={index}>
                                      {note.Note} - {note.Timestamp} -{" "}
                                      {note.ProviderID}
                                    </p>
                                  )
                                )
                              : ""}
                          </td>
                          <td>
                            <textarea
                              style={{ width: "100%" }}
                              value={uniqueServiceData.noteValue} // Set initial value here if needed
                              onChange={(e) =>
                                (uniqueServiceData.noteValue = e.target.value)
                              }
                            />
                          </td>
                          <td>
                            <Button
                              onClick={() =>
                                handleNoteSubmit(
                                  uniqueServiceData.service_no,
                                  {
                                    Note: "Test Note",
                                    Timestamp: "24/02/2024 12.15",
                                    ProviderID: "5",
                                  },
                                  uniqueServiceData.noteValue
                                )
                              }
                            >
                              Submit
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default ServiceNotes;
