import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const ContactUsMap = () => {
  const containerStyle = {
    width: '100%',
    height: '500px',
  };

  const center = {
    lat: 39.1645952, 
    lng: -86.4765943,
  };

  const [showInfo, setShowInfo] = useState(false);

  const handleMarkerClick = () => {
    setShowInfo(!showInfo);
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyC59AXldWP6lbfUhOpnJUsJPhULLWn_K5g">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
      >
        <Marker position={center} onClick={handleMarkerClick} />

        {showInfo && (
          <InfoWindow
            position={center}
            onCloseClick={() => setShowInfo(false)}
          >
            <div>
              <h3>Cosmos Medispa</h3>
              <p>Place name and details go here.</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default ContactUsMap;
