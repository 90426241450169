import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import {
  Card,
  Col,
  ListGroup,
  Row,
  Table,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import Loader from "../Loader/Loader";
import swal from "sweetalert";
import QRCode from "qrcode.react";
import PaymentGateway from "../CardPayments/PaymentGateway";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const AssignPackages = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROMISE);
  const { patientdata } = useParams();
  const decodedpatientdata = atob(patientdata);
  const parsedpatientdata = JSON.parse(decodedpatientdata);

  const [availableDefaultPackages, setavailableDefaultPackages] = useState([]);
  const [groupedPackages, setGroupedPackages] = useState({});
  const [fetchBillingDataAgin, setfetchBillingDataAgin] = useState(false);
  const [loading, setloading] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isSamePatient, setIsSamePatient] = useState(true);
  const [isGift, setIsGift] = useState(false);
  const [buyerName, setBuyerName] = useState("");
  const [buyerContact, setBuyerContact] = useState("");
  const [buyerEmail, setBuyerEmail] = useState("");
  const [buyerOption, setBuyerOption] = useState("");
  console.log("buyerOption", buyerOption);
  const [notes, setNotes] = useState("");
  const navigate = useNavigate();
  const accessToken = sessionStorage.getItem("accessToken");
  const LoginUserID = sessionStorage.getItem("LoginUserID");
  const LoginUserType = sessionStorage.getItem("LoginUserType");

  const pid = parsedpatientdata.pid;
  const { fname, lname, email } = parsedpatientdata;
  const CustomerBillData = { fname, lname, email };
  console.log("CustomerBillData", CustomerBillData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiServices.get(`/apis/default/api/packages`, {
          Authorization: `Bearer ${accessToken}`,
        });
        const packages = response.data;
        setavailableDefaultPackages(packages);

        const grouped = packages.reduce((acc, service) => {
          const {
            package_id,
            package_type,
            expiry,
            service_id,
            service_title,
            quantity,
            service_default_price,
            service_discount,
            total_amount,
            service_total_amount,
          } = service;
          if (!acc[package_id]) {
            acc[package_id] = {
              package_id,
              package_type,
              expiry,
              total_amount,
              services: [],
            };
          }
          acc[package_id].services.push({
            service_id,
            service_title,
            quantity,
            service_default_price,
            service_discount,
            service_total_amount,
          });
          return acc;
        }, {});
        setGroupedPackages(grouped);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };

    fetchData();
  }, [accessToken, fetchBillingDataAgin]);

  const [finalAmount, setfinalAmount] = useState(null);

  const handlePackageSelect = (package_id, total_amount) => {
    setSelectedPackage(package_id);
    setfinalAmount(total_amount);
    const selectedPackageDetails = groupedPackages[package_id];
    console.log("selectedPackageDetails", selectedPackageDetails);
  };
  console.log("finalAmount:::::::::::", finalAmount);

  const handleSamePatientChange = (e) => {
    const checked = e.target.checked;
    setIsSamePatient(checked);
    if (checked) {
      setBuyerName(`${parsedpatientdata.fname} ${parsedpatientdata.lname}`);
    } else {
      setBuyerName("");
    }
  };

  const handleGiftChange = (e) => {
    setIsGift(e.target.checked);
  };

  const handleBuyerOptionChange = (e) => {
    const option = e.target.value;
    setBuyerOption(option);
    if (option === "samePatient") {
      setBuyerName(`${parsedpatientdata.fname} ${parsedpatientdata.lname}`);
      setBuyerContact(parsedpatientdata.phone_contact);
      setBuyerEmail(parsedpatientdata.email);
    } else {
      setBuyerName("");
      setBuyerContact("");
      setBuyerEmail("");
    }
  };

  const handleBuyerNameChange = (e) => {
    setBuyerName(e.target.value);
  };
  const handleBuyerContactChange = (e) => {
    setBuyerContact(e.target.value);
  };

  const handleBuyerEmailChange = (e) => {
    setBuyerEmail(e.target.value);
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handleSubmit = async () => {
    if (!selectedPackage) {
      alert("Please select a package.");
      return;
    }

    const selectedPackageDetails = groupedPackages[selectedPackage];
    const { package_type, expiry, services } = selectedPackageDetails;
    setloading(true);
    const payload = {
      pid: pid,
      aid: LoginUserID,
      assigned_date: new Date().toLocaleDateString(),
      buyer_name: buyerName,
      buyer_email: buyerEmail,
      buyer_contact: buyerContact,
      is_same_patient: true,
      // is_gift: buyerOption == "gift" ? true : false,
      is_gift: false,
      gift_token_id: "",
      notes: notes,
      type: "service",
      package_id: selectedPackage,
      services: services.map((service) => ({
        service_title: service.service_title,
        package_id: selectedPackage,
        service_id: service.service_id,
        service_quantity: service.quantity,
        service_discount: service.service_discount,
        service_default_price: service.service_default_price,
        package_provider: parsedpatientdata.provider_id,
        package_type: package_type,
        package_provider: LoginUserID,
        Expiry: expiry || "N/A",
        type: "service",
      })),
    };

    try {
      const response = await ApiServices.post(
        `/apis/default/api/packageAssignments`,
        payload,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      // alert(response.data);
      setloading(false);
      swal({
        title: "Package Assigned Successfully",
        icon: "success",
        button: "OK",
        timer: 3000,
      });
      navigate("/admin/patientlist");
    } catch (error) {
      console.error("Error assigning package:", error);
      // alert("Failed to assign package.");
      setloading(false);
      swal({
        title: "Something Went Wrong",
        icon: "error",
        button: "OK",
        timer: 3000,
      });
    }
  };

  const [paymentMethod, setPaymentMethod] = useState("");
  const [firstCardAmount, setFirstCardAmount] = useState(0);
  const [cashAmount, setCashAmount] = useState(0);

  const handlePaymentMethodSelect = (method) => {
    setPaymentMethod(method);
  };

  const handleDualCardPayment = () => {
    const secondCardAmount = finalAmount - firstCardAmount;
  };

  const handleCardCashPayment = () => {
    const cardAmount = finalAmount - cashAmount;
  };

  const sendPaymentEmail = async () => {
    setloading(true);
    try {
      let endpoint = `/apis/default/api/walletSendLink`;

      if (LoginUserType === "Patient") {
        endpoint = `/apis/default/portal/walletSendLink`;
      }

      const response = await ApiServices.post(
        endpoint,
        {
          patient_fname: buyerName,
          patient_lname: "",
          patient_email: buyerEmail,
          // wallet_id: paramsData.walletid,
          amount: parseFloat(finalAmount),
        },
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      swal({
        title: "Payment Link Send Successful",
        // text: `Pleasen Check ${CustomerBillData.email} for Payment Link`,
        icon: "success",
        button: "OK",
        timer: 3000,
      });
      setloading(false);
      console.log(JSON.stringify(response.data));
    } catch (error) {
      console.error(error);
      setloading(false);
    }
  };

  const handleQRCodeClick = () => {
    // const paymentPageURL = `/wallet-top-up-qr/${CustomerBillData.fname}/${CustomerBillData.lname}/${CustomerBillData.email}/${finalAmount}/${paramsData.walletid}`;
    // const finalURL = `${paymentPageURL}`;
    // window.open(finalURL, "_blank");
  };

  const cashPaymentReceived = async () => {
    handleSubmit();
  };

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const handlePaymentModalOpen = () => {
    setShowPaymentModal(true);
  };
  const handlePaymentModalClose = () => {
    setShowPaymentModal(false);
  };

  const handlePaymentModalCloseSuccess = async () => {
    handleSubmit();
  };

  return (
    <div>
      {loading && <Loader />}
      <div className="mb-4">
        <Card>
          <Card.Body>
            <Row>
              <Col sm={4}>
                <b>Name :</b>{" "}
                {parsedpatientdata &&
                  `${parsedpatientdata.fname} ${parsedpatientdata.lname}`}
              </Col>
              <Col sm={4}>
                <b>Email :</b> {parsedpatientdata && parsedpatientdata.email}
              </Col>
              <Col sm={4}>
                <b>Contact :</b>{" "}
                {parsedpatientdata && parsedpatientdata.phone_contact}
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <b>Gender :</b> {parsedpatientdata && parsedpatientdata.sex}
              </Col>
              <Col sm={4}>
                <b>Address :</b>{" "}
                {parsedpatientdata &&
                  `${parsedpatientdata.street} ${parsedpatientdata.city} ${parsedpatientdata.state}, ${parsedpatientdata.postal_code}`}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      {Object.keys(groupedPackages).map((package_id) => (
        <Card key={package_id} className="mt-3">
          <Card.Header>
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <Col sm={1}>
                <Form.Check
                  type="radio"
                  label="Select"
                  checked={selectedPackage === package_id}
                  onChange={() =>
                    handlePackageSelect(
                      package_id,
                      groupedPackages[package_id].total_amount
                    )
                  }
                />
              </Col>
              <Col sm={2}>
                <strong>Package ID:</strong>{" "}
                {groupedPackages[package_id].package_id} <br />
              </Col>
              <Col sm={3}>
                <strong>Package Type:</strong>{" "}
                {groupedPackages[package_id].package_type} <br />
              </Col>
              <Col sm={3}>
                <strong>Expiry:</strong>{" "}
                {groupedPackages[package_id].expiry || "N/A"}
              </Col>
              <Col sm={3}>
                <strong>Subtotal:</strong> ${" "}
                {groupedPackages[package_id].total_amount || "N/A"}
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <ListGroup>
              <ListGroup.Item>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Service Title</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Discount</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupedPackages[package_id].services.map((service) => (
                      <tr key={service.service_id}>
                        <td>{service.service_title}</td>
                        <td>{service.quantity}</td>
                        <td>${service.service_default_price}</td>
                        <td>${service.service_discount}</td>
                        <td>${service.service_total_amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
      ))}

      <Card className="mt-4">
        <Card.Body>
          <Form>
            {/* <Form.Group>
              <Form.Check
                type="radio"
                label="Is Same Patient"
                name="buyerOption"
                value="samePatient"
                checked={buyerOption === "samePatient"}
                onChange={handleBuyerOptionChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="radio"
                label="Is Gift"
                name="buyerOption"
                value="gift"
                checked={buyerOption === "gift"}
                onChange={handleBuyerOptionChange}
              />
            </Form.Group> */}
            {!isSamePatient && (
              <>
                <Form.Group>
                  <Form.Label>Buyer Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={buyerName}
                    onChange={handleBuyerNameChange}
                    readOnly
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Buyer Contact</Form.Label>
                  <Form.Control
                    type="number"
                    value={buyerContact}
                    onChange={handleBuyerContactChange}
                    readOnly
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Buyer Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={buyerEmail}
                    onChange={handleBuyerEmailChange}
                    readOnly
                  />
                </Form.Group>
              </>
            )}
            <Form.Group>
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={notes}
                onChange={handleNotesChange}
              />
            </Form.Group>
            <Button variant="primary" onClick={handleSubmit} className="mt-3">
              Assign Package
            </Button>
          </Form>
        </Card.Body>
      </Card>

      <Card className="mt-4">
        <Card.Body>
          <div>
            <h5>Select Payment Method:</h5>
            <Form>
              <Row>
                {LoginUserType !== "Patient" && (
                  <Col sm={2}>
                    <Form.Check
                      type="radio"
                      label="Cash"
                      name="paymentMethod"
                      onChange={() => handlePaymentMethodSelect("cash")}
                    />
                  </Col>
                )}
                <Col sm={2}>
                  <Form.Check
                    type="radio"
                    label="Single Card"
                    name="paymentMethod"
                    onChange={() => handlePaymentMethodSelect("single-card")}
                  />
                </Col>
                <Col sm={2}>
                  <Form.Check
                    type="radio"
                    label="Dual Card"
                    name="paymentMethod"
                    onChange={() => handlePaymentMethodSelect("dual-card")}
                    disabled
                  />
                </Col>
                <Col sm={2}>
                  <Form.Check
                    type="radio"
                    label="Card + Cash"
                    name="paymentMethod"
                    onChange={() => handlePaymentMethodSelect("card-cash")}
                    disabled
                  />
                </Col>
                <Col sm={2}>
                  <Form.Check
                    type="radio"
                    label="QR"
                    name="paymentMethod"
                    onChange={() => handlePaymentMethodSelect("qr")}
                  />
                </Col>
                <Col sm={2}>
                  <Form.Check
                    type="radio"
                    label="Email Link"
                    name="paymentMethod"
                    onChange={() => handlePaymentMethodSelect("email")}
                  />
                </Col>
              </Row>
            </Form>
          </div>

          <div className="mt-3">
            {paymentMethod === "cash" && (
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <Button onClick={cashPaymentReceived}>
                  ${finalAmount} Payment Received in Cash
                </Button>
              </div>
            )}
            {paymentMethod === "single-card" && (
              <div style={{ textAlign: "center" }}>
                <Button variant="success" onClick={handlePaymentModalOpen}>
                  Proceed to Pay ${finalAmount}
                </Button>
              </div>
            )}
            {paymentMethod === "dual-card" && (
              <div>
                <Form.Group controlId="firstCardAmount" className="mt-3">
                  <Form.Label>Amount to Pay with First Card:</Form.Label>
                  <Form.Control
                    type="number"
                    value={firstCardAmount}
                    onChange={(e) =>
                      setFirstCardAmount(parseFloat(e.target.value))
                    }
                    max={finalAmount}
                  />
                </Form.Group>
                <Button onClick={handleDualCardPayment}>
                  Pay with Dual Card (First Card: ${firstCardAmount})
                </Button>
                <Button onClick={handleDualCardPayment}>
                  Pay with Dual Card (Second Card: $
                  {finalAmount - firstCardAmount})
                </Button>
              </div>
            )}
            {paymentMethod === "card-cash" && (
              <div>
                <Form.Group controlId="cashAmount" className="mt-3">
                  <Form.Label>Amount to Pay in Cash:</Form.Label>
                  <Form.Control
                    type="number"
                    value={cashAmount}
                    onChange={(e) => setCashAmount(parseFloat(e.target.value))}
                    max={finalAmount}
                  />
                </Form.Group>
                <Button onClick={handleCardCashPayment}>
                  Pay with Card + Cash (Cash: ${cashAmount})
                </Button>
                <Button onClick={handleCardCashPayment}>
                  Pay with Card + Cash (Card: ${finalAmount - cashAmount})
                </Button>
              </div>
            )}
            {paymentMethod === "email" && (
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <Button onClick={sendPaymentEmail} variant="success">
                  Send Payment Link to Email
                </Button>
              </div>
            )}

            {paymentMethod === "qr" && (
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <h3>Scan QR Code to Pay</h3>
                <QRCode
                  // value={`https://medispacosmos.com/cosmos-emr/wallet-top-up-qr/${CustomerBillData.fname}/${CustomerBillData.lname}/${CustomerBillData.email}/${finalAmount}/${paramsData.walletid}/${paymentFor}`}
                  onClick={handleQRCodeClick}
                />
                <p>Final Amount: {finalAmount}</p>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>

      <Modal
        show={showPaymentModal}
        onHide={handlePaymentModalClose}
        centered
        className="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Card Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showPaymentModal && (
            <Elements stripe={stripePromise}>
              <PaymentGateway
                patientData={CustomerBillData}
                amount={finalAmount}
                onSuccess={handlePaymentModalCloseSuccess}
              />
            </Elements>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AssignPackages;
