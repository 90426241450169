import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import axios from "axios";

const ServiceFormGet = () => {
  const accessToken = sessionStorage.getItem("accessToken");
  const LoginUserType = sessionStorage.getItem("LoginUserType");
  const [AvailableFilledForm, setAvailableFilledForm] = useState();
  const [AvailableEmptyForm, setAvailableEmptyForm] = useState();
  const ParamsData = useParams();
  console.log("AvailableFilledForm ::", AvailableFilledForm);

  useEffect(() => {
    const fetchPatientForms = async () => {
      try {
        let endpoint = `/apis/default/api/patient/${ParamsData.pid}/serviceForms/${ParamsData.formname}/appointment/${ParamsData.eid}`;

        if (LoginUserType == "Patient") {
          endpoint = `/apis/default/portal/patient/${ParamsData.pid}/serviceForms/${ParamsData.formname}/appointment/${ParamsData.eid}`;
        }
        const patientFormsResponse = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });
        console.log("patientFormsResponse", patientFormsResponse);
        const AvailableEmptyForms =
          patientFormsResponse.data["0"].consent_treatment_json;
        const AvailableFilledForm = patientFormsResponse.data["0"].form_data;
        try {
          const dataArrayEmpty = JSON.parse(AvailableEmptyForms);
          setAvailableEmptyForm(dataArrayEmpty);
          const dataArrayFilled = JSON.parse(AvailableFilledForm);
          setAvailableFilledForm(dataArrayFilled);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPatientForms();
  }, []);

  return (
    <div>
      {AvailableEmptyForm &&
        AvailableEmptyForm.map((emptyField) => (
          <p key={emptyField.name}>
            <b>{emptyField.label}</b> : {getFilledValue(emptyField.name)}
          </p>
        ))}
    </div>
  );

  function getFilledValue(fieldName) {
    const filledField =
      AvailableFilledForm &&
      AvailableFilledForm.find((filled) => filled.name === fieldName);
    return filledField ? filledField.value : "";
  }
};

export default ServiceFormGet;
