import React, { useEffect, useState } from "react";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { Card, Col, Button, Form } from "react-bootstrap";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";

const CreateDefaultPackages = ({ BillSuccess }) => {
  const [doctorServices, setDoctorServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [serviceSearch, setServiceSearch] = useState("");
  const [expiryOption, setExpiryOption] = useState("1 Month");
  const [packageType, setPackageType] = useState("");
  const accessToken = sessionStorage.getItem("accessToken");
  const LoginUserID = sessionStorage.getItem("LoginUserID");
  const paramsData = useParams();
  const [loading, setloading] = useState(false);

  console.log("selectedServices",selectedServices)

  useEffect(() => {
    ApiServices.get(`/apis/default/api/masterServices`, {
      Authorization: `Bearer ${accessToken}`,
    }).then((response) => {
      setDoctorServices(response.data);
    });
  }, [accessToken]);

  const filteredServices = doctorServices.filter((service) =>
    service.service.toLowerCase().includes(serviceSearch.toLowerCase())
  );

  const handleServiceSelection = (service) => {
    if (!selectedServices.some((s) => s.id === service.id)) {
      setSelectedServices((prevSelectedServices) => [
        ...prevSelectedServices,
        {
          id: service.id,
          title: service.label_on_bill,
          default_price: service.unit_cost,
          discount: 0,
          quantity: 1,
          serviceName: service.service,
        },
      ]);
    }
  };

  const handleQuantityChange = (serviceId, newQuantity) => {
    const validatedQuantity = Math.max(1, newQuantity);
    setSelectedServices((prevSelectedServices) =>
      prevSelectedServices.map((service) =>
        service.id === serviceId ? { ...service, quantity: validatedQuantity } : service
      )
    );
  };

  const handleDiscountChange = (serviceId, newDiscount) => {
    const validatedDiscount = Math.max(0, Math.min(100, newDiscount));
    setSelectedServices((prevSelectedServices) =>
      prevSelectedServices.map((service) =>
        service.id === serviceId ? { ...service, discount: validatedDiscount } : service
      )
    );
  };

  const handleServiceRemoval = (serviceId) => {
    const checkbox = document.getElementById(`checkbox_${serviceId}`);
    if (checkbox) {
      checkbox.checked = false;
    }

    setSelectedServices((prevSelectedServices) =>
      prevSelectedServices.filter((service) => service.id !== serviceId)
    );
  };

  const handleCheckboxChange = (service, isChecked) => {
    if (isChecked) {
      handleServiceSelection(service);
    } else {
      handleServiceRemoval(service.service_no);
    }
  };

  const calculateExpiryDate = (option) => {
    const currentDate = new Date();
    let expiryDate = new Date(currentDate);
    const monthsToAdd = parseInt(option.split(" ")[0], 10);
    expiryDate.setMonth(expiryDate.getMonth() + monthsToAdd);
    return expiryDate.toISOString().split("T")[0];
  };
const navigate = useNavigate()
  const handleBillingSubmit = async () => {
    setloading(true)
    const currentDate = new Date().toISOString().split("T")[0];
    const postData = {
      // pid: paramsData.pid,
      // aid: paramsData.aid,
      // assigned_date: currentDate,
      // buyer_name: "",
      // buyer_email: "",
      // buyer_contact: "",
      // notes: "",
      // is_gift: false,
      // is_same_patient: false,
      package_name:packageType,
      package_description:"",
      services: selectedServices.map((service) => ({
        service_title: service.title,
        service_id: service.id,
        service_quantity: service.quantity,
        service_discount: service.discount,
        service_default_price: service.default_price,
        package_type: packageType,
        package_provider: LoginUserID,
        expiry: calculateExpiryDate(expiryOption),
        type:"service"
      })),
    };

    try {
      const response = await ApiServices.post(
        "/apis/default/api/packages",
        postData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      console.log(response.data);
      setloading(false)
      navigate("/admin/cosmos-service-packages/")
      swal({
        title: "Package has been generated successfully",
        icon: "success",
        button: "OK",
        timer: 2000,
      });
    } catch (error) {
      setloading(false)
      console.error(error);
    }
  };

  return (
    <div>
      <Col sm={12}>
        <div>
          <input
            type="text"
            placeholder="Search services"
            value={serviceSearch}
            onChange={(e) => setServiceSearch(e.target.value)}
            style={{ width: "100%", marginBottom: "10px" }}
          />
          <div
            style={{
              maxHeight: "60vh",
              overflowY: "auto",
              borderBottom: "1px solid black",
              marginTop: "10px",
            }}
          >
            <table className="billing-table">
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Service</th>
                  <th>Sub Service</th>
                  <th>Default Price</th>
                </tr>
              </thead>
              <tbody>
                {filteredServices &&
                  filteredServices.map((doctorService) => (
                    <tr key={doctorService.id}>
                      <td>
                        <input
                          id={`checkbox_${doctorService.id}`}
                          type="checkbox"
                          onChange={(e) =>
                            handleCheckboxChange(
                              doctorService,
                              e.target.checked
                            )
                          }
                          // disabled={BillSuccess}
                        />
                      </td>
                      <td>{doctorService.service}</td>
                      <td>{doctorService.service_subtype}</td>
                      <td>{doctorService.unit_cost}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </Col>

      <Col sm={12} className="mt-3">
        <Card>
          <Card.Body>
            <div className="mt-3">
              <table className="billing-table">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Default Price</th>
                    <th>Quantity</th>
                    <th>Discount Percentage</th>
                    <th>Discounted Price</th>
                    <th>Total Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedServices.map((selectedService) => {
                    const serviceId = selectedService.id;
                    const discountedPrice =
                      selectedService.default_price -
                      (selectedService.default_price * selectedService.discount) / 100;
                    const totalAmountForService =
                      discountedPrice * selectedService.quantity;

                    return (
                      <tr key={serviceId}>
                        <td>
                          {selectedService.serviceName !== "miscellaneous" ? (
                            selectedService.title
                          ) : (
                            <input
                              type="text"
                              placeholder="Custom Title"
                              value={selectedService.title || ""}
                              onChange={(e) => {
                                const customTitle = e.target.value;
                                setSelectedServices((prevSelectedServices) => {
                                  const updatedServices = prevSelectedServices.map((service) =>
                                    service.id === serviceId
                                      ? { ...service, title: customTitle }
                                      : service
                                  );
                                  return updatedServices;
                                });
                              }}
                              disabled={BillSuccess}
                            />
                          )}
                        </td>
                        <td>
                          {selectedService.serviceName !== "miscellaneous" ? (
                            selectedService.default_price
                          ) : (
                            <input
                              type="number"
                              style={{ width: "80px" }}
                              placeholder="Custom Price"
                              value={selectedService.default_price || ""}
                              onChange={(e) => {
                                const customPrice = e.target.value;
                                setSelectedServices((prevSelectedServices) => {
                                  const updatedServices = prevSelectedServices.map((service) =>
                                    service.id === serviceId
                                      ? { ...service, default_price: customPrice }
                                      : service
                                  );
                                  return updatedServices;
                                });
                              }}
                              disabled={BillSuccess}
                            />
                          )}
                        </td>
                        <td>
                          <Button
                            variant="danger"
                            onClick={() =>
                              handleQuantityChange(serviceId, selectedService.quantity - 1)
                            }
                            disabled={BillSuccess}
                          >
                            -
                          </Button>
                          <span style={{ padding: "10px" }}>
                            {selectedService.quantity}
                          </span>
                          <Button
                            variant="success"
                            onClick={() =>
                              handleQuantityChange(serviceId, selectedService.quantity + 1)
                            }
                            disabled={BillSuccess}
                          >
                            +
                          </Button>
                        </td>
                        <td>
                          <input
                            type="number"
                            value={selectedService.discount}
                            onChange={(e) =>
                              handleDiscountChange(serviceId, e.target.value)
                            }
                            style={{ width: "100px" }}
                            disabled={BillSuccess}
                          />
                        </td>
                        <td>{discountedPrice}</td>
                        <td>{totalAmountForService}</td>
                        <td>
                          <Button
                            variant="danger"
                            onClick={() => handleServiceRemoval(serviceId)}
                            disabled={BillSuccess}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="mt-3">
              <Form.Group controlId="expiryOption">
                <Form.Label>Expiry Date</Form.Label>
                <Form.Control
                  as="select"
                  value={expiryOption}
                  onChange={(e) => setExpiryOption(e.target.value)}
                >
                  <option value="1 Month">1 Month</option>
                  <option value="2 Months">2 Months</option>
                  <option value="3 Months">3 Months</option>
                  <option value="4 Months">4 Months</option>
                  <option value="5 Months">5 Months</option>
                  <option value="6 Months">6 Months</option>
                  <option value="7 Months">7 Months</option>
                  <option value="8 Months">8 Months</option>
                  <option value="9 Months">9 Months</option>
                  <option value="10 Months">10 Months</option>
                  <option value="11 Months">11 Months</option>
                  <option value="12 Months">12 Months</option>
                  <option value="18 Months">18 Months</option>
                  <option value="24 Months">24 Months</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="packageType">
                <Form.Label>Package Type</Form.Label>
                <Form.Control
                  type="text"
                  value={packageType}
                  onChange={(e) => setPackageType(e.target.value)}
                  placeholder="Enter Package Type Name"
                />
              </Form.Group>
              <Button variant="success" onClick={handleBillingSubmit}>
                Create Package
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default CreateDefaultPackages;
