import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import AdministratorViewDashboard from '../../Container/AdministratorViewDashboard/AdministratorViewDashboard'
import PatientList from '../../Components/PatientList/PatientList'
import FormBuilderJs from "../../Components/FormBuilder/FormBuilderJS";
import PatientGroup from "../../Components/PatientGroup/PatientGroup";
import AdminPatientDetails from '../../Container/AdminPatientView/AdminPatientDetails'
import ProviderAppointments from '../../Components/ProviderAppointments/ProviderAppointments'
import PageUnderConstruction from '../../Components/PageUnderConstruction/PageUnderConstruction';
import AllProvidersList from '../../Container/MainAdminFunctionalities/AllProvidersList/AllProvidersList';
import ProviderDetails from '../../Container/MainAdminFunctionalities/ProviderDetails/ProviderDetails';
import GenerateBill from '../../Components/GenerateBill/GenerateBill';
import ServiceBill from '../../Components/ServiceBill/ServiceBill';
import PatientAccountRegistration from '../../Components/PatientAccountRegistration/PatientAccountRegistration';
import ServiceDynamicForm from '../../Components/ServiceForms/ServiceDynamicForm';
import ProviderPatientView from '../../Container/ProviderPatientView/ProviderPatientView';
import SuccessfullPayments from '../../Components/SuccessfullPayments/SuccessfullPayments';
import ServiceFormsMainPage from '../../Components/ServiceForms/ServiceFormsMainPage';
import CaptureImages from '../../Components/CaptureImages/CaptureImages';
import CaptureImagesPost from '../../Components/CaptureImages/CaptureImagesPost';
import IntakeFormsHomePage from '../../Components/IntakeForms/IntakeFormsHomePage';
import ProductBillingGenerate from '../../Components/ProductBilling/ProductBillingGenerate';
import GeneratedProductBill from '../../Components/ProductBilling/GeneratedProductBill';
import CreatePatientByProvider from '../../Components/CreatePatientByProvider/CreatePatientByProvider';
import ProductBillingList from '../../Components/ProductBilling/ProductBillingList';
import PatientDahsboard from '../../Components/PatientDahsboard/PatientDahsboard';
import AvailablePackagesList from '../../Components/Packages/AvailablePackagesList';
import CreateDefaultPackages from '../../Components/Packages/CreateDefaultPackages';
import PatientWallet from '../../Components/PatientWallet/PatientWallet';
import AssignPackages from '../../Components/Packages/AssignPackages';
import EncryDecryption from '../../Components/EncryDecryption/EncryDecryption';
import PatientPackagesDetails from '../../Components/Packages/PatientPackagesDetails';
import ServiceBillNew from '../../Components/ServiceBill/ServiceBillNew';
import GiftCards from '../../Components/GiftCards/GiftCards';
import CreatePackageGiftCard from '../../Components/GiftCards/CreatePackageGiftCard';
import CreateWalletGiftVoucher from '../../Components/GiftCards/CreateWalletGiftVoucher';


const AdministratorRoutes = () => {
  

  return (
    <div>
    
    <Routes>
      <Route path='/' element=<AdministratorViewDashboard/>/>
      <Route path='/patientlist' element=<PatientList/>/>
      <Route path='/capture-image/:uuid/:eid/:pid/service/:serviceId' element=<CaptureImages/>/>
      <Route path='/capture-image-post-treat/:uuid/:eid/:pid/service/:serviceId' element=<CaptureImagesPost/>/>
      <Route path='/patient-details/:uuid/:pid' element=<PatientDahsboard/>/>
      <Route path='/patient-details-view/:uuid/:eid/:pid' element=<ProviderPatientView/>/>
      <Route path="/service-form/:eid/:pid/:formname/:uuid" element=<ServiceFormsMainPage/> />
      <Route path="/provider-appointments" element=<ProviderAppointments/> />
      <Route path="/all-providers" element=<AllProvidersList/> />
      <Route path="/providers-details/:uuid/:id/" element=<ProviderDetails/> />
      <Route path="/generate-bill/:uuid/:eid/:pid" element=<GenerateBill/> />
      <Route path="/show-bill/:uuid/:eid/:pid" element=<ServiceBillNew/> />
      <Route path="/page-under-construction" element=<PageUnderConstruction/> />
      <Route path='/formbuilder' element=<FormBuilderJs/>/>
      <Route path="/patientgroup" element=<PatientGroup /> />
      <Route path="/successfull-payments" element=<SuccessfullPayments/> />
      <Route path="/service-form/:formname" element=<ServiceDynamicForm/> />
      <Route path="/intake-forms/:pid/:uuid" element=<IntakeFormsHomePage/> />
      <Route path="/list-of-product-bill" element=<ProductBillingList/> />
      <Route path="/generate-ptoduct-bill" element=<ProductBillingGenerate/> />
      <Route path="/view-generated-ptoduct-bill/:billid" element=<GeneratedProductBill/> />
      <Route path="/cosmos-service-packages" element=<AvailablePackagesList/> />
      <Route path="/cosmos-service-packages-create" element=<CreateDefaultPackages/> />
      <Route path="/assign-package/:patientdata" element=<AssignPackages/> />
      <Route path="/patient-wallet/:pid/:fname/:lname/:email/:walletid" element=<PatientWallet/> />
      <Route path="/patient-packages/:pid/:fname/:lname/:email/:walletid" element=<PatientPackagesDetails/> />
      <Route path="/encryption" element=<EncryDecryption/> />
        <Route
          path="/patientregistration"
          element=<CreatePatientByProvider />
        />
      <Route path="/create-gift-cards" element=<GiftCards/> />
      <Route path="/cosmos-package-create-gift-cards" element=<CreatePackageGiftCard/> />
      <Route path="/cosmos-wallet-create-gift-voucher" element=<CreateWalletGiftVoucher/> />
    </Routes>
    </div>
  )
}

export default AdministratorRoutes