import axios from "axios";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import swal from "sweetalert";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";

const InsuranceDetailsForm = () => {
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    provider: "",
    plan_name: "",
    policy_number: "",
    group_number: "",
    date: "",
    copay: "",
    policy_type: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    provider: "",
    plan_name: "",
    policy_number: "",
    group_number: "",
    date: "",
    copay: "",
    
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setValidationErrors({ ...validationErrors, [name]: "" });
  };

  const handleSaveInsuranceDetails = async () => {
    const errors = {};
    if (!formData.provider) errors.provider = "Coverage Provider name is required.";
    if (!formData.plan_name) errors.plan_name = "Coverage Plan Name is required.";
    if (!formData.policy_number) errors.policy_number = "Policy Number is required.";
    if (!formData.group_number) errors.group_number = "Group Number is required.";
    if (!formData.date) errors.date = "Policy Begin Date is required.";
    if (!formData.copay) errors.copay = "Co-payment is required.";
  
    if (Object.keys(errors).length > 0) {
      // Set errors and prevent form submission
      setValidationErrors(errors);
      return;
    }
    setloading(true);
    try {
      const pid = sessionStorage.getItem("pid");

      const access_token = sessionStorage.getItem("access_token");

      if (!pid || !access_token) {
        console.error("pid or access_token not available");
        return;
      }

      const apiUrl = `/apis/default/api/patient/${pid}/insurance/primary`;

      const requestData = {
        provider: formData.provider,
        plan_name: formData.plan_name,
        policy_number: formData.policy_number,
        group_number: formData.group_number,
        date: formData.date,
        copay: formData.copay,
        policy_type: "",
      };

      const response = await ApiServices.post(apiUrl, requestData, {
        Authorization: `Bearer ${access_token}`,
      });
      setloading(false);
      swal({
        title: "Registration Successful",
        text: "Welcome to the Digi Health.",
        icon: "success",
        button: "OK",
        timer: 2000,
      });
      navigate("/");
      console.log("Insurance details saved successfully:", response.data);
    } catch (error) {
      setloading(false);
      console.error("Error saving insurance details:", error);
      swal({
        title: "Registration Failed",
        text: "Something went wrong.",
        icon: "error",
        button: "OK",
        timer: 2000,
      });
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <Container>
        <div>
          <button
            style={{
              backgroundColor: "#5D33FC",
              width: "100%",
              color: "white",
              border: "none",
              borderRadius: "10px",
              padding: "5px",
            }}
          >
            Coverage Details
          </button>
        </div>

        <div className="round-border-div-of-data" style={{ marginTop: "20px" }}>
          <div className="round-border-div-of-data-relative-infor">
            Demographics
          </div>
          <Row style={{ marginBottom: "10px" }}>
            <Col sm={5}>
              <label>Provider</label>
              <input
                type="number"
                placeholder="Provider"
                className="patient-registration-input-tag"
                name="provider"
                value={formData.provider}
                onChange={handleInputChange}
              />
              <p className="validation-error-message">{validationErrors.provider}</p>
            </Col>

            <Col sm={5}>
              <label>Plan Name</label>
              <input
                type="text"
                placeholder="Plan Name"
                className="patient-registration-input-tag"
                name="plan_name"
                value={formData.plan_name}
                onChange={handleInputChange}
              />
              <p className="validation-error-message">{validationErrors.plan_name}</p>
            </Col>
          </Row>

          <Row style={{ marginBottom: "10px" }}>
            <Col sm={5}>
              <label>Policy Number</label>
              <input
                type="text"
                placeholder="Policy Number"
                className="patient-registration-input-tag"
                name="policy_number"
                value={formData.policy_number}
                onChange={handleInputChange}
              />
                <p className="validation-error-message">{validationErrors.policy_number}</p>
            </Col>

            <Col sm={5}>
              <label>Group Number</label>
              <input
                type="number"
                placeholder="Group Number"
                className="patient-registration-input-tag"
                name="group_number"
                value={formData.group_number}
                onChange={handleInputChange}
              />
              <p className="validation-error-message">{validationErrors.group_number}</p>
            </Col>
          </Row>

          <Row style={{ marginBottom: "10px" }}>
            <Col sm={5}>
              <label>Policy Begin Date</label>
              <input
                type="date"
                placeholder="Policy Begin Date"
                className="patient-registration-input-tag"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
              />
              <p className="validation-error-message">{validationErrors.date}</p>
            </Col>
            <Col sm={5}>
              <label>Co-payment</label>
              <input
                type="number"
                placeholder="Co-payment"
                className="patient-registration-input-tag"
                name="copay"
                value={formData.copay}
                onChange={handleInputChange}
              />
              <p className="validation-error-message">{validationErrors.copay}</p>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col sm={4}>
              <button
                onClick={handleSaveInsuranceDetails}
                className="patient-details-submit-button"
              >
                Submit
              </button>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default InsuranceDetailsForm;
