import React, { useEffect, useRef, useState } from "react";
import { Card, Container, Modal } from "react-bootstrap";
import DigiLogo from "../../Assets/cosmosLogocolor.png";
import PasswordLogo from "../../Assets/teenyicons_password-solid.svg";
import EmailLogo from "../../Assets/dashicons_email.svg";
import "../../GlobalFiles/Styles/Styles.css";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import AccessToken from "../../GlobalFiles/ApiServices/AccessToken";
import Loader from "../Loader/Loader";
import { AES } from "crypto-js";
import { PiEyeClosedFill } from "react-icons/pi";
import { RxEyeOpen } from "react-icons/rx";

const PatientLogin = () => {
  useEffect(() => {
    sessionStorage.clear();
  }, []);

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const passwordRef = useRef(null);
  const [createCredentialsLink, setcreateCredentialsLink] = useState("");
  const [createCredentialsModal, setcreateCredentialsModal] = useState(false);

  const ClosecreateCredentialsModal = () => {
    setcreateCredentialsModal(false);
  };

  const [otp, setOtp] = useState(Array(6).fill(""));
  const otpInputRefs = useRef([]);
  const handleBackspace = (index, e) => {
    if (e.keyCode === 8 && index > 0 && otp[index] === "") {
      otpInputRefs.current[index - 1].focus();
    }
  };
  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== "" && index < 5) {
      otpInputRefs.current[index + 1].focus();
    }
  };

  // const getTokenAndHandleLogin = async () => {
  //   setLoading(true);
  //   try {
  //     const tokenResponse = await ApiServices.post(
  //       "/oauth2/default/token",
  //       {
  //         client_id: "G7H1yJbmR_JzoZV3Xw0wV8LO58pL2hb-YV6KFv-hl94",
  //         grant_type: "password",
  //         scope: "openid offline_access api:oemr api:port patient/patient.read",
  //         user_role: "patient",
  //         username: email,
  //         password: password,
  //         email: email,
  //       },
  //       {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //       }
  //     );

  //     const accessToken = tokenResponse.data.access_token;
  //     sessionStorage.setItem("Patient_Access_Token", accessToken);

  //     const response = await ApiServices.get("/apis/default/portal/patient", {
  //       Authorization: `Bearer ${accessToken}`,
  //     });

  //     console.log("Login response of Patient", response.data.data);
  //     sessionStorage.setItem("LoginUserFname", response.data.data.fname);
  //     sessionStorage.setItem("LoginUserLname", response.data.data.lname);

  //     const otpsentResponse = await ApiServices.post(
  //       "/apis/default/api/otpsend",
  //       {
  //         username: email,
  //       },
  //       {
  //         "Content-Type": "application/json",
  //       }
  //     );

  //     swal({
  //       title: "OTP Sent Successfully",
  //       text: "Please Check Your Mailbox For Dual Authentication Login",
  //       icon: "success",
  //       button: "OK",
  //     });
  //     const encryptedEmail = AES.encrypt(
  //       email,
  //       process.env.REACT_APP_USER_ENC_DEC_KEY
  //     ).toString();
  //     const encryptedPassword = AES.encrypt(
  //       password,
  //       process.env.REACT_APP_USER_ENC_DEC_KEY
  //     ).toString();
  //     sessionStorage.setItem("encryptedEmail", encryptedEmail);
  //     sessionStorage.setItem("encryptedPassword", encryptedPassword);
  //     setLoading(false);
  //     sessionStorage.setItem("LoginUserType", "Patient");
  //     const adminaccessToken = await AccessToken.fetchAccessToken();
  //     sessionStorage.setItem("accessToken", adminaccessToken);
  //     navigate(`/login-authentication/${email}/`);
  //   } catch (error) {
  //     console.error("Error:", error);
  //     setLoading(false);
  //     swal({
  //       title: "Login Failed",
  //       text: "Something went wrong.",
  //       icon: "error",
  //       button: "OK",
  //       timer: 2000,
  //     });
  //   }
  // };

  const getTokenAndHandleLogin = async () => {
    setLoading(true);
    try {
      const tokenResponse = await ApiServices.post(
        "/oauth2/default/token",
        {
          client_id: "G7H1yJbmR_JzoZV3Xw0wV8LO58pL2hb-YV6KFv-hl94",
          grant_type: "password",
          scope: "openid offline_access api:oemr api:port patient/patient.read",
          user_role: "patient",
          username: email,
          password: password,
          email: email,
        },
        {
          "Content-Type": "application/x-www-form-urlencoded",
        }
      );

      const accessToken = tokenResponse.data.access_token;
      sessionStorage.setItem("Patient_Access_Token", accessToken);

      const response = await ApiServices.get("/apis/default/portal/patient", {
        Authorization: `Bearer ${accessToken}`,
      });

      console.log("Login response of Patient", response.data.data);
      sessionStorage.setItem("LoginUserFname", response.data.data.fname);
      sessionStorage.setItem("LoginUserLname", response.data.data.lname);

      const otpsentResponse = await ApiServices.post(
        "/apis/default/api/otpsend",
        {
          username: email,
        },
        {
          "Content-Type": "application/json",
        }
      );

      swal({
        title: "OTP Sent Successfully",
        text: "Please Check Your Mailbox For Dual Authentication Login",
        icon: "success",
        button: "OK",
      });

      const encryptedEmail = AES.encrypt(
        email,
        process.env.REACT_APP_USER_ENC_DEC_KEY
      ).toString();
      const encryptedPassword = AES.encrypt(
        password,
        process.env.REACT_APP_USER_ENC_DEC_KEY
      ).toString();
      sessionStorage.setItem("encryptedEmail", encryptedEmail);
      sessionStorage.setItem("encryptedPassword", encryptedPassword);
      setLoading(false);
      sessionStorage.setItem("LoginUserType", "Patient");
      const adminaccessToken = await AccessToken.fetchAccessToken();
      sessionStorage.setItem("accessToken", adminaccessToken);
      navigate(`/login-authentication/${email}/`);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      if (
        error.response.status === 403 &&
        error.response.data.message ===
          "Patient account not yet verified (portal_onetime set)"
      ) {
        const pid = error.response.data.pid;
        const patientPassLinkResponse = await ApiServices.post(
          "/apis/default/api/patientpasslink",
          {
            resetPass: true,
            email: email,
            // token_id: response.data.data.token_id,
            pid: error.response.data.pid,
          }
        );
        const link = patientPassLinkResponse.data.data.forward;
        setcreateCredentialsLink(link);
        setcreateCredentialsModal(true);
        console.log("link", link);
        swal({
          title: "Account Not Verified",
          text: "Your account is not verified yet. Please verify.",
          icon: "warning",
          button: "OK",
        });
        console.log("PID:", pid);
      } else {
        swal({
          title: "Login Failed",
          text: "Something went wrong.",
          icon: "error",
          button: "OK",
          timer: 2000,
        });
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const cardStyle = {
    margin: "auto",
    height: "530px",
    width: "500px",
    background: "#6665DD",
    padding: "20px",
    borderRadius: "50px",
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase());
  };

  const handlePaste = (e) => {
    e.preventDefault();
  };

  const handleSubmitCredentials = async (e) => {
    const enteredOtp = otp.join("");
    try {
      const response = await ApiServices.post(
        "/apis/default/api/patientneworresetpassupdate",
        {
          forward: createCredentialsLink,
          onetime_pass: enteredOtp,
          login_uname: email,
          email: email,
        }
      );

      if (response.data.data.status === true) {
        try {
          const tokenResponse = await ApiServices.post(
            "/oauth2/default/token",
            {
              client_id: "G7H1yJbmR_JzoZV3Xw0wV8LO58pL2hb-YV6KFv-hl94",
              grant_type: "password",
              scope:
                "openid offline_access api:oemr api:port patient/patient.read",
              user_role: "patient",
              username: email,
              password: password,
              email: email,
            },
            {
              "Content-Type": "application/x-www-form-urlencoded",
            }
          );

          const accessToken = tokenResponse.data.access_token;
          sessionStorage.setItem("Patient_Access_Token", accessToken);

          const apiResponse = await ApiServices.get(
            "/apis/default/portal/patient",
            {
              Authorization: `Bearer ${accessToken}`,
            }
          );

          sessionStorage.setItem("LoginUserFname", apiResponse.data.data.fname);
          sessionStorage.setItem("LoginUserLname", apiResponse.data.data.lname);
          const encryptedEmail = AES.encrypt(
            email,
            process.env.REACT_APP_USER_ENC_DEC_KEY
          ).toString();
          const encryptedPassword = AES.encrypt(
            password,
            process.env.REACT_APP_USER_ENC_DEC_KEY
          ).toString();
          sessionStorage.setItem("encryptedEmail", encryptedEmail);
          sessionStorage.setItem("encryptedPassword", encryptedPassword);
          sessionStorage.setItem("LoginUserType", "Patient");
          const adminaccessToken = await AccessToken.fetchAccessToken();
          sessionStorage.setItem("accessToken", adminaccessToken);

          swal({
            title: "Registration and Login Successfully",
            icon: "success",
            button: "OK",
            timer: 2000,
          }).then(() => {
            sessionStorage.setItem("Patient_Pid", apiResponse.data["data"].id);
            navigate("/patient-dashboard");
          });
        } catch (error) {
          console.error("Error:", error.response?.data || error.message);
          swal({
            title: "Error",
            text: "An error occurred while processing your request. Please try again later.",
            icon: "error",
            button: "OK",
          });
        }
      } else {
        swal({
          title: "OTP is Incorrect or Used",
          text: "Please Check Your mailbox for correct OTP.",
          icon: "error",
          button: "OK",
        });
        setOtp(Array(6).fill(""));
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      swal({
        title: "Error",
        text: "An error occurred while processing your request. Please try again later.",
        icon: "error",
        button: "OK",
      });
    }
  };

  useEffect(() => {
    const allDigitsEntered = otp.every(digit => digit !== "");
    if (allDigitsEntered) {
      handleSubmitCredentials();
    }
  }, [otp]);

  return (
    <div>
      {loading && <Loader />}
      <Container
        style={{ display: "flex", justifyContent: "center", height: "100vh" }}
      >
        <Card style={cardStyle}>
          <Card.Body>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "20px",
              }}
            >
              <img src={DigiLogo} alt="" style={{ height: "90px" }} />
            </div>
            <form style={{ marginTop: "50px" }}>
              <div className="login-form-flex-div">
                <img src={EmailLogo} alt="" className="login-form-input-logo" />
                <input
                  type="text"
                  value={email}
                  placeholder="Registered Email Address"
                  // onChange={(e) => setEmail(e.target.value)}
                  onChange={handleEmailChange}
                  className="login-form-input-field"
                />
              </div>
              <div className="login-form-flex-div">
                <img
                  src={PasswordLogo}
                  alt=""
                  className="login-form-input-logo"
                />
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="login-form-input-field"
                  onPaste={handlePaste}
                  ref={passwordRef}
                />

                {showPassword ? (
                  <PiEyeClosedFill
                    type="button"
                    onClick={togglePasswordVisibility}
                    style={{ color: "white", fontSize: "25px" }}
                  />
                ) : (
                  <RxEyeOpen
                    type="button"
                    onClick={togglePasswordVisibility}
                    style={{ color: "white", fontSize: "25px" }}
                  />
                )}
              </div>
              <div>
                <button
                  type="button"
                  onClick={getTokenAndHandleLogin}
                  className="login-form-login-button"
                >
                  Login
                </button>
              </div>
            </form>
            <div></div>
            <div className="login-form-register-div">
              <p>If you're new to the portal, sign up here.</p>
              <Link to="/patientregistration">
                <button>Register</button>
              </Link>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Link to="/providerlogin">
                <p className="forgot-password-para-tag">Provider Login</p>
              </Link>
              <Link to="/forgot-password">
                <p className="forgot-password-para-tag">Forgot Password</p>
              </Link>
            </div>
          </Card.Body>
        </Card>
      </Container>

      <Modal show={createCredentialsModal} onHide={ClosecreateCredentialsModal}>
        <Modal.Header>
          <Modal.Title>
            <p
              style={{ margin: "0px", fontSize: "2.5rem" }}
              className="section-one-head-tag"
            >
              Token Verification
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ margin: "0px" }} className="med-spa-staff-info-p-tag">
            Enter the Token sent to your email address : <br />
            {email}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <div className="login-form-flex-div" style={{ border: "none" }}>
              {otp &&
                otp?.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    min="0"
                    max="9"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!isNaN(value) && value >= 0 && value <= 9) {
                        handleOtpChange(index, value);
                      }
                    }}
                    onKeyDown={(e) => handleBackspace(index, e)}
                    className="login-form-input-field"
                    ref={(el) => (otpInputRefs.current[index] = el)}
                    style={{
                      border: "1px solid rgb(28,50,74)",
                      textAlign: "center",
                      color: "black",
                    }}
                  />
                ))}
            </div>
          </div>
          <div>
            <p style={{ margin: "0px" }} className="med-spa-staff-info-p-tag">
              Check your email inbox, including spam or junk folders.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="med-spa-custom-button"
            style={{
              backgroundColor: "rgb(229, 231, 235)",
              color: "black",
            }}
            onClick={ClosecreateCredentialsModal}
          >
            Close
          </button>
          <button
            className="med-spa-custom-button"
            onClick={handleSubmitCredentials}
          >
            Verify Token
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PatientLogin;
