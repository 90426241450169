import React from 'react'
import "./MedSpaServicesPage.css"
import ServicesSectionOne from '../../Components/ServicesSectionOne/ServicesSectionOne'
import ServiceFilters from '../../Components/ServicesCards/ServiceFilters'
import { Fade } from 'react-reveal'

const MedSpaServicesPage = () => {
  return (
    <div>
     {
          <Fade bottom>

    <div style={{paddingTop:"50px",backgroundColor:"rgb(249 250 251)"}}>
      <ServiceFilters/>
    </div>
          </Fade>}
    </div>
  )
}

export default MedSpaServicesPage