import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PatientDetailsForm from "./PatientDetailsForm";
import InsuranceDetailsForm from "./InsuranceDetailsForm";

const PatientAccountRegistration = () => {
  const [PatientDetailsFormScreen, setPatientDetailsFormScreen] =
    useState(true);
  const [InsuranceDetailsFormScreen, setInsuranceDetailsFormScreen] =
    useState(false);


  const handlePatientDetailsFormScreen = () => {
    setPatientDetailsFormScreen(true);
    setInsuranceDetailsFormScreen(false);
  };

  const handleInsuranceDetailsFormScreen = () => {
    setPatientDetailsFormScreen(false);
    setInsuranceDetailsFormScreen(true);
  };

  // const updateFormScreens = () => {
  //   setPatientDetailsFormScreen(false);
  //   setInsuranceDetailsFormScreen(true);
  // };
  return (
    <div className="background-screen-of-application">
      <Container>
        <div>
          <h1
            style={{
              textAlign: "center",
              color: "#5D33FC",
              fontSize: "32px",
              fontWeight: "400",
            }}
          >
            Account Registration
          </h1>
        </div>
        {/* <div>
          <div class="container-of-progress-of-patient-reg">
            <div
              class="line-of-progress-of-patient-reg"
              style={{ backgroundColor: "#5D33FC" }}
            ></div>
            <div
              onClick={handlePatientDetailsFormScreen}
              style={{ cursor: "pointer" }}
            >
              <div class="circle-of-progress-of-patient-reg">1</div>
              <p
                style={{
                  margin: "0px",
                  position: "absolute",
                  marginLeft: "-35px",
                  color: "#5D33FC",
                }}
              >
                Patient Details
              </p>
            </div>
            <div
              class="line-of-progress-of-patient-reg"
              style={{
                backgroundColor: InsuranceDetailsFormScreen
                  ? "#5D33FC"
                  : "#C2B5F5",
              }}
            ></div>
            <div
              onClick={handleInsuranceDetailsFormScreen}
              style={{ cursor: "pointer" }}
            >
              <div class="circle-of-progress-of-patient-reg">2</div>
              <p
                style={{
                  margin: "0px",
                  position: "absolute",
                  marginLeft: "-85px",
                  color: "#5D33FC",
                }}
              >
                Coverage Details
              </p>
            </div>
          </div>
        </div> */}
      </Container>
      <div style={{ paddingTop: "50px" }}>
        {PatientDetailsFormScreen && (
          <PatientDetailsForm
            // onSuccess={updateFormScreens}
          />
        )}
        {InsuranceDetailsFormScreen && <InsuranceDetailsForm />}
      </div>
    </div>
  );
};

export default PatientAccountRegistration;
