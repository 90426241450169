import React, { useEffect, useState } from 'react';
import UpcomingAppointments from './UpcomingAppointments';
import TodaysAppointments from './TodaysAppointments';
import PastAppointments from './PastAppointments';
import ApprovedAppointments from './ApprovedAppointments';
import CancelledAppoints from './CancelledAppoints';

const ProviderAppointments = () => {
  const [UpcomingAppoint, setUpcomingAppoint] = useState(false);
  const [TodaysAppoint, setTodaysAppoint] = useState(true);
  const [PastAppoint, setPastAppoint] = useState(false);
  const [ApprovedAppoint, setApprovedAppoint] = useState(false);
  const [CancelledAppoint, setCancelledAppoint] = useState(false);

  
  const LoginUserType =sessionStorage.getItem("LoginUserType")

  useEffect(() => {
    // Retrieve the selected appointment type from sessionStorage on component mount
    const storedAppointmentType = sessionStorage.getItem('selectedAppointmentType');
    if (storedAppointmentType) {
      handleAppointmentTypeChange(storedAppointmentType);
    }
  }, []);

  const handleAppointmentTypeChange = (appointmentType) => {
    // Update state based on the selected appointment type
    setUpcomingAppoint(appointmentType === 'UpcomingAppoint');
    setTodaysAppoint(appointmentType === 'TodaysAppoint');
    setPastAppoint(appointmentType === 'PastAppoint');
    setApprovedAppoint(appointmentType === 'ApprovedAppoint');
    setCancelledAppoint(appointmentType === 'CancelledAppoint');

    // Store the selected appointment type in sessionStorage
    sessionStorage.setItem('selectedAppointmentType', appointmentType);
  };

  return (
    <div>
      <div>
      {LoginUserType == "receptionist" || LoginUserType === "admin" || LoginUserType === "provider"? 
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <button onClick={() => handleAppointmentTypeChange('UpcomingAppoint')} className={UpcomingAppoint ? "appointment-type-btn-active" : "appointment-type-btn-inactive"}>New Appointments</button>
        <button onClick={() => handleAppointmentTypeChange('ApprovedAppoint')} className={ApprovedAppoint ? "appointment-type-btn-active" : "appointment-type-btn-inactive"}>Approved Appointments</button>
        <button onClick={() => handleAppointmentTypeChange('TodaysAppoint')} className={TodaysAppoint ? "appointment-type-btn-active" : "appointment-type-btn-inactive"}>Today's Appointments</button>
        <button onClick={() => handleAppointmentTypeChange('PastAppoint')} className={PastAppoint ? "appointment-type-btn-active" : "appointment-type-btn-inactive"}>Past Appointments</button>
        <button onClick={() => handleAppointmentTypeChange('CancelledAppoint')} className={CancelledAppoint ? "appointment-type-btn-active" : "appointment-type-btn-inactive"}>Cancelled Appointments</button>
      </div>
      : 
      
      
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <button onClick={() => handleAppointmentTypeChange('ApprovedAppoint')} className={ApprovedAppoint ? "appointment-type-btn-active" : "appointment-type-btn-inactive"}>Approved Appointments</button>
        <button onClick={() => handleAppointmentTypeChange('TodaysAppoint')} className={TodaysAppoint ? "appointment-type-btn-active" : "appointment-type-btn-inactive"}>Today's Appointments</button>
        <button onClick={() => handleAppointmentTypeChange('PastAppoint')} className={PastAppoint ? "appointment-type-btn-active" : "appointment-type-btn-inactive"}>Past Appointments</button>
      </div>
      }
      </div>
      <div style={{ paddingTop: "20px", paddingBottom: "40px" }}>
        {UpcomingAppoint && <UpcomingAppointments />}
        {TodaysAppoint && <TodaysAppointments />}
        {PastAppoint && <PastAppointments />}
        {ApprovedAppoint && <ApprovedAppointments />}
        {CancelledAppoint && <CancelledAppoints />}
      </div>
    </div>
  );
};

export default ProviderAppointments;
