import React, { useEffect, useState } from "react";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppointmentCalendar from "../PatientAppointments/AppointmentCalendar";
import PatientDashboardAppointments from "./PatientDashboardAppointments";
import { FaWallet } from "react-icons/fa";
import { TbPackages } from "react-icons/tb";
import PatientAllSoapNotes from "../PatientAllSoapNotes/PatientAllSoapNotes";

const PatientDahsboard = () => {
  const paramsData = useParams();
  const [patientData, setPatientData] = useState(null);
  const Patient_Access_Token = sessionStorage.getItem("Patient_Access_Token");
  const LoginUserType = sessionStorage.getItem("LoginUserType");
  const accessToken = sessionStorage.getItem("accessToken");

  console.log("patientData", patientData);

  const [apiTrigger, setApiTrigger] = useState(false);
  const handleChildSubmit = (success) => {
    if (success) {
      setApiTrigger(!apiTrigger);
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await ApiServices.get("/apis/default/portal/patient", {
  //         Authorization: `Bearer ${Patient_Access_Token}`,
  //       });

  //       const data = response.data.data;
  //       sessionStorage.setItem("LoginPatientID", data.pid);
  //       sessionStorage.setItem("LoginPatientUUID", data.uuid);
  //       setPatientData(data);
  //     } catch (error) {
  //       console.error("Error fetching patient data:", error);
  //     }
  //   };

  //   if (Patient_Access_Token) {
  //     fetchData();
  //   }
  // }, [Patient_Access_Token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (LoginUserType === "Patient") {
          const patientResponse = await ApiServices.get(
            "/apis/default/portal/patient",
            {
              Authorization: `Bearer ${Patient_Access_Token}`,
            }
          );

          const data = patientResponse.data.data;
          sessionStorage.setItem("LoginPatientID", data.pid);
          sessionStorage.setItem("LoginPatientUUID", data.uuid);
          setPatientData(data);
        } else {
          const patientResponse = await ApiServices.get(
            `/apis/default/api/patient/${paramsData.uuid}`,
            {
              Authorization: `Bearer ${accessToken}`,
            }
          );

          const data = patientResponse.data.data;
          setPatientData(data);
        }
      } catch (error) {
        console.error("Error fetching patient data:", error);
      }
    };

    if (Patient_Access_Token || LoginUserType !== "Patient") {
      fetchData();
    }
  }, [Patient_Access_Token, LoginUserType, paramsData, accessToken]);

  const [nextAppointments, setNextAppointments] = useState([]);
  const [pendingBills, setPendingBills] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!patientData || !patientData.pid) {
        console.error("Patient data or pid is null or undefined");
        return;
      }

      try {
        let endpoint = `/apis/default/api/patient/${patientData.pid}/appointment`;

        if (LoginUserType == "Patient") {
          endpoint = `/apis/default/portal/patient/${patientData.pid}/appointment`;
        }

        const appointmentDataResponse = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });

        // console.log("appointmentDataResponse",appointmentDataResponse)
        const currentDate = new Date();

        if (!appointmentDataResponse || !appointmentDataResponse.data) {
          console.error(
            "Appointment data response or data is null or undefined"
          );
          return;
        }
        const filteredAppointments =
          appointmentDataResponse &&
          appointmentDataResponse.data?.filter((appointment) => {
            const appointmentDate = new Date(
              `${appointment.pc_eventDate}T${appointment.pc_startTime}`
            );

            return appointmentDate > currentDate;
          });

        const sortedAppointments =
          filteredAppointments &&
          filteredAppointments?.sort((a, b) => {
            const dateA = new Date(`${a.pc_eventDate}T${a.pc_startTime}`);
            const dateB = new Date(`${b.pc_eventDate}T${b.pc_startTime}`);
            return dateA - dateB;
          });
        const nextAppointments = sortedAppointments.slice(0, 2);
        setNextAppointments(nextAppointments);

        // FILTER PENDING BILLS
        const filteredBills =
          appointmentDataResponse &&
          appointmentDataResponse.data
            .filter((bill) => bill.verified_receptionist === 1)
            .sort((a, b) => {
              const dateA = new Date(`${a.pc_eventDate}T${a.pc_startTime}`);
              const dateB = new Date(`${b.pc_eventDate}T${b.pc_startTime}`);
              return dateB - dateA;
            })
            .slice(0, 2);

        setPendingBills(filteredBills);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [patientData, accessToken, apiTrigger]);

  const [pendingForms, setPendingForms] = useState([]);
  const [FilledIntakeFormsJsonData, setFilledIntakeFormsJsonData] = useState(
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `/apis/default/api/getFormsPatient/${patientData.pid}`;

        if (LoginUserType == "Patient") {
          endpoint = `/apis/default/portal/getFormsPatient/${patientData.pid}`;
        }
        const pendingFormsResponse = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });
        if (!pendingFormsResponse || !pendingFormsResponse.data) {
          console.error("Pending forms response or data is null or undefined");
          return;
        }
        const currentTimestamp = new Date().getTime();
        const upcomingForms =
          pendingFormsResponse &&
          pendingFormsResponse?.data
            .filter(
              (form) => new Date(form.timestamp).getTime() > currentTimestamp
            )
            .sort(
              (a, b) =>
                new Date(a.timestamp).getTime() -
                new Date(b.timestamp).getTime()
            )
            .slice(0, 2);

        // If there are no upcoming forms, get the last two forms
        const lastTwoForms =
          upcomingForms.length === 0
            ? pendingFormsResponse.data
                .sort(
                  (a, b) =>
                    new Date(b.timestamp).getTime() -
                    new Date(a.timestamp).getTime()
                )
                .slice(0, 2)
            : upcomingForms;

        setPendingForms(lastTwoForms);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();

    const fetchFilledIntakeJsonData = async () => {
      try {
        let endpoint = `/apis/default/api/intakeForms/${patientData.pid}`;

        if (LoginUserType == "Patient") {
          endpoint = `/apis/default/portal/intakeForms/${patientData.pid}`;
        }
        const FilledIntakeFormsJsonDataResponse = await ApiServices.get(
          endpoint,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );

        setFilledIntakeFormsJsonData(
          FilledIntakeFormsJsonDataResponse.data["0"]
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchFilledIntakeJsonData();
  }, [patientData, accessToken]);
  const navigate = useNavigate();

  const handleopenFormFilled = (selectedFormData) => {
    console.log("selectedFormData", selectedFormData);
    navigate(
      `/patient-dashboard/service-form/${selectedFormData.eid}/${selectedFormData.pid}/${selectedFormData.form_id}`
    );
  };

  const handleShowBillClick = (params) => {
    const patientUuid = params.puuid;
    const patienteid = params.pc_eid;
    const patientepid = params.pc_pid;
    navigate(
      `/patient-dashboard/show-bill/${patientUuid}/${patienteid}/${patientepid}`
    );
  };

  const handleOpenIntake = () => {
    LoginUserType !== "Patient"
      ? navigate(`/admin/intake-forms/${patientData.pid}/${patientData.uuid}`)
      : navigate("/patient-dashboard/intake-forms");
  };

  const [walletBalance, setWalletBalance] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `/apis/default/api/wallet/${patientData.wallet_id}`;

        if (LoginUserType === "Patient") {
          endpoint = `/apis/default/portal/wallet/${patientData.wallet_id}`;
        }

        const response = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });

        setWalletBalance(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [patientData, accessToken, LoginUserType]);

  return (
    <div className="digi-application-bg-color" style={{ height: "100%" }}>
      <Row>
        <Col sm={6}>
          <Card className="patient-dashboard-cards-">
            <Card.Header>
              <Button style={{ width: "100%" }} variant="info">
                Basic Details
              </Button>
            </Card.Header>
            <Card.Body>
              <div style={{ display: "flex", gap: "5px" }}>
                <div style={{ textAlign: "left" }}>
                  <p className="patient-basic-details-para">
                    {patientData && patientData.fname}{" "}
                    {patientData && patientData.mname}{" "}
                    {patientData && patientData.lname}
                  </p>
                  <p className="patient-basic-details-para">
                    Date of Birth :{" "}
                    <span>{patientData && patientData.DOB}</span>
                  </p>
                  <p className="patient-basic-details-para">
                    Gender : <span>{patientData && patientData.sex}</span>
                  </p>
                  <p className="patient-basic-details-para">
                    Contact :{" "}
                    <span>{patientData && patientData.phone_contact}</span>
                  </p>
                  <p className="patient-basic-details-para">
                    Email : <span>{patientData && patientData.email}</span>
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>

          {FilledIntakeFormsJsonData &&
            FilledIntakeFormsJsonData.is_portal_sent == 1 && (
              <Card className="patient-dashboard-cards- mt-3">
                <Card.Header>
                  <Button style={{ width: "100%" }} variant="primary">
                    Intake Forms
                  </Button>
                </Card.Header>
                <Card.Body>
                  <div>
                    <table className="billing-table">
                      <tr>
                        <th>Name of Form</th>
                        <th>Last Submitted On</th>
                        <th>Status</th>
                      </tr>

                      <tr>
                        <td>Medical History</td>
                        <td>
                          {FilledIntakeFormsJsonData &&
                            FilledIntakeFormsJsonData.timestamp_medical_history}
                        </td>
                        <td>
                          <Button
                            // variant="warning"
                            variant={
                              FilledIntakeFormsJsonData &&
                              FilledIntakeFormsJsonData.is_medical_history_intake ==
                                0
                                ? "warning"
                                : "success"
                            }
                            style={{ width: "100%" }}
                            onClick={handleOpenIntake}
                          >
                            {FilledIntakeFormsJsonData &&
                            FilledIntakeFormsJsonData.is_medical_history_intake ==
                              0
                              ? "Pending"
                              : "Submitted"}
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>HIPAA Form</td>
                        <td>
                          {FilledIntakeFormsJsonData &&
                            FilledIntakeFormsJsonData.timestamp_hippa_form}
                        </td>
                        <td>
                          <Button
                            // variant="warning"
                            variant={
                              FilledIntakeFormsJsonData &&
                              FilledIntakeFormsJsonData.is_hippa_form == 0
                                ? "warning"
                                : "success"
                            }
                            style={{ width: "100%" }}
                            onClick={handleOpenIntake}
                          >
                            {FilledIntakeFormsJsonData &&
                            FilledIntakeFormsJsonData.is_hippa_form == 0
                              ? "Pending"
                              : "Submitted"}
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>Financial Disclosure Form</td>
                        <td>
                          {/* {calculateRemainingDays(FilledIntakeFormsJsonData &&
                        FilledIntakeFormsJsonData.timestamp_financial_policy)} */}
                          {FilledIntakeFormsJsonData &&
                            FilledIntakeFormsJsonData.timestamp_financial_policy}
                        </td>
                        <td>
                          <Button
                            // variant="warning"
                            variant={
                              FilledIntakeFormsJsonData &&
                              FilledIntakeFormsJsonData.is_financial_disclosure ==
                                0
                                ? "warning"
                                : "success"
                            }
                            style={{ width: "100%" }}
                            onClick={handleOpenIntake}
                          >
                            {FilledIntakeFormsJsonData &&
                            FilledIntakeFormsJsonData.is_financial_disclosure ==
                              0
                              ? "Pending"
                              : "Submitted"}
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            )}

          <Card className="patient-dashboard-cards- mt-3">
            <Card.Body>
              <Row>
                <Col sm={6}>
                  <Link
                    to={
                      LoginUserType == "Patient"
                        ?  patientData &&  `/patient-dashboard/patient-wallet/${patientData.pid}/${patientData.fname}/${patientData.lname}/${patientData.email}/${patientData.wallet_id}`
                        : patientData &&  `/admin/patient-wallet/${patientData.pid}/${patientData.fname}/${patientData.lname}/${patientData.email}/${patientData.wallet_id}`
                    }
                    style={{ textDecoration: "none" }}
                  >
                    <b>
                      <FaWallet style={{ fontSize: "25px" }} /> My Wallet
                    </b>
                  </Link>
                </Col>
                <Col sm={6} style={{ textAlign: "right" }}>
                  <b>Available Balance -</b> $
                  {walletBalance && walletBalance?.currentBalance}
                </Col>
              </Row>
              <Row className="mt-3">
              <Link
                    to={
                      LoginUserType == "Patient"
                        ?  patientData &&  `/patient-dashboard/patient-packages/${patientData.pid}/${patientData.fname}/${patientData.lname}/${patientData.email}/${patientData.wallet_id}`
                        : patientData &&  `/admin/patient-packages/${patientData.pid}/${patientData.fname}/${patientData.lname}/${patientData.email}/${patientData.wallet_id}`
                    }
                    style={{ textDecoration: "none" }}
                  >
                <b>
                  <TbPackages style={{ fontSize: "30px" }} /> My Packages{" "}
                </b>
                </Link>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={6}>
          {LoginUserType == "Patient" ? (
            <Card>
              <Card.Header>
                <Button style={{ width: "100%" }} variant="success">
                  Book New Appointment
                </Button>
              </Card.Header>
              <Card.Body>
                <AppointmentCalendar handleModalClose={handleChildSubmit} />
              </Card.Body>
            </Card>
          )
          :
          <div>
          <Card className="patient-dashboard-cards-">
            <Card.Header className="mb-3">
              <Button
                style={{ width: "100%", color: "white" }}
                variant="primary"
              >
                All SOAP Notes
              </Button>
              </Card.Header>
              <Card.Body>

            <PatientAllSoapNotes/>
              </Card.Body>
            </Card>
          </div>
          }
        </Col>
      </Row>

      <Row className="mt-3">
        {patientData != null && (
          <Card className="patient-dashboard-cards-">
            <Card.Header className="mb-3">
              <Button
                style={{ width: "100%", color: "white" }}
                variant="primary"
              >
                All Appointments
              </Button>
            </Card.Header>
            <PatientDashboardAppointments patientData={patientData}/>
          </Card>
        )}
      </Row>
    </div>
  );
};

export default PatientDahsboard;
