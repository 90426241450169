import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Loader from "../Loader/Loader";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import EmailOtpVerification from "../OTPVerification/EmailOtpVerification";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import AccessToken from "../../GlobalFiles/ApiServices/AccessToken";

const CreatePatientByProvider = ({ onSuccess }) => {
  const [loading, setloading] = useState(false);
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [correctEmail, setcorrectEmail] = useState(false);
  const navigate = useNavigate();

  const handlePrivacyPolicyClick = () => {
    setShowPrivacyPolicyModal(true);
  };

  const handleClosePrivacyPolicyModal = () => {
    setShowPrivacyPolicyModal(false);
  };
  const [formData, setFormData] = useState({
    title: "",
    fname: "",
    mname: "",
    lname: "",
    street: "",
    postal_code: "",
    city: "",
    state: "",
    country_code: "",
    phone_contact: "",
    DOB: "",
    sex: "",
    race: "",
    ethnicity: "",
    email: "",
    token_id: "",
  });

  const [communicationPreferences, setCommunicationPreferences] = useState({
    allow_imm_reg_use: "UNASSIGNED",
    allow_imm_info_share: "UNASSIGNED",
    allow_health_info_ex: "UNASSIGNED",
    allow_patient_portal: "YES",
    hipaa_voice: "YES",
    hipaa_notice: "YES",
    hipaa_message: "UNASSIGNED",
    hipaa_allowsms: "YES",
    hipaa_allowemail: "YES",
    hipaa_mail: "UNASSIGNED",
  });

  const [validationErrors, setValidationErrors] = useState({
    fname: "",
    lname: "",
    sex: "",
    DOB: "",
    email: "",
    phone_contact: "",
  });

  const handleRadioChange = (field, value) => {
    setCommunicationPreferences({
      ...communicationPreferences,
      [field]: value,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setFormData({ ...formData, [name]: value });
    if (name === "email") {
      setFormData({ ...formData, [name]: value.toLowerCase() });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    setValidationErrors({ ...validationErrors, [name]: "" });
    if (name === "email") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!value.match(emailRegex)) {
        setValidationErrors({
          ...validationErrors,
          [name]: "Invalid email address",
        });
        setcorrectEmail(false);
      } else {
        setValidationErrors({ ...validationErrors, [name]: "" });
        setcorrectEmail(true);
      }
    }
  };

  const [VerifiedOtp, setVerifiedOtp] = useState(false);
  const [navigationLink, setnavigationLink] = useState("");

  const handleOkClick = () => {
    navigate(navigationLink);
  };

  const handleSubmit = async () => {
    const errors = {};
    if (!formData.fname) errors.fname = "First name required.";
    if (!formData.lname) errors.lname = "Last name  required.";
    if (!formData.sex) errors.sex = "Gender is required.";
    if (!formData.DOB) errors.DOB = "Date of birth is required.";
    if (!formData.email) errors.email = "Email is required.";
    if (!formData.phone_contact)
      errors.phone_contact = "Phone Number is required.";

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setloading(true);

    ApiServices.post("/apis/default/api/verifyemail", {
      fname: formData.fname,
      mname: formData.mname,
      lname: formData.lname,
      email: formData.email,
      DOB: formData.DOB,
    }).then((response) => {
      let inputotp = response.data.data.OTP;
      ApiServices.get(
        `/apis/default/api/verificationtoken/${inputotp}`,
        {}
      ).then((response) => {
        console.log("response", response);
        const isOtpVerified = response.data.data.token_status;
        const token_id_holder = response.data.data.token_id_holder;
        setemailtoken(token_id_holder);
        const patientId = response.data.data.patient_id;

        if (patientId) {
            setloading(false)
          swal({
            title: "Email Already Registered",
            text: "Please log in to your account",
            icon: "warning",
            buttons: {
              confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "btn-primary",
                closeModal: true,
              },
            },
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {
          setVerifiedOtp(true);

          // Make the API request only if VerifiedOtp is true
          const makePatientApiRequest = async () => {
            try {
              const accessToken = await AccessToken.fetchAccessToken();
              sessionStorage.setItem("access_token", accessToken);

              const formDataWithPreferences = {
                ...formData,
                ...communicationPreferences,
              };
              formDataWithPreferences.token_id = emailtoken;

              const response = await ApiServices.post(
                "/apis/default/api/patient",
                formDataWithPreferences,
                {
                  Authorization: `Bearer ${accessToken}`,
                }
              );

              console.log("Response:", response.data["data"]);
              //   sessionStorage.setItem("pid", response.data["data"].pid);

              ApiServices.get(
                `/apis/default/api/usernamepasswordpatient/${response.data.data.pid}`,
                {
                  Authorization: `Bearer ${accessToken}`,
                }
              );

              ApiServices.post(
                "/apis/default/api/intakeForms",
                {
                  pid: response.data.data.pid,
                },
                {
                  Authorization: `Bearer ${accessToken}`,
                }
              );

              swal({
                title: "Patient Created Successfully",
                icon: "success",
                button: "OK",
                timer: 2000,
              });
              setloading(false);
              navigate("/admin/patientlist")
            } catch (error) {
              console.error("Error:", error);
              setloading(false);
            }
          };

          makePatientApiRequest();
        }
      });
    });
  };
  const [emailtoken, setemailtoken] = useState("");
 

  return (
    <div>
      {loading && <Loader />}
      <div>
        <Container>
          <Row className="align-items-center p-4">
            <Col sm={12}>
              <Row>
                <Col sm={6}>
                  <select
                    className="register-patient-column-input"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                  >
                    <option>Title</option>
                    <option>Mr.</option>
                    <option>Mrs.</option>
                    <option>Miss.</option>
                  </select>
                </Col>

                <Col sm={6}>
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      placeholder="First Name"
                      className="register-patient-column-input"
                      name="fname"
                      value={formData.fname}
                      onChange={handleInputChange}
                    />
                    <p className="validation-error-message">*</p>
                  </div>
                  <p className="validation-error-message">
                    {validationErrors.fname}
                  </p>
                </Col>

                <Col sm={6}>
                  <input
                    type="text"
                    placeholder="Middle Name"
                    className="register-patient-column-input"
                    name="mname"
                    value={formData.mname}
                    onChange={handleInputChange}
                  />
                </Col>
                <Col sm={6}>
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="register-patient-column-input"
                      name="lname"
                      value={formData.lname}
                      onChange={handleInputChange}
                    />
                    <p className="validation-error-message">*</p>
                  </div>
                  <p className="validation-error-message">
                    {validationErrors.lname}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <p
                    style={{
                      margin: "0px",
                      paddingLeft: "8px",
                      textAlign: "left",
                      color: "grey",
                    }}
                  >
                    Select Your Gender
                  </p>
                  <div style={{ display: "flex" }}>
                    <select
                      className="register-patient-column-input"
                      name="sex"
                      value={formData.sex}
                      onChange={handleInputChange}
                    >
                      <option>Gender</option>
                      <option>Male</option>
                      <option>Female</option>
                      <option>Prefer not to say</option>
                    </select>

                    <p className="validation-error-message">*</p>
                  </div>
                  <p className="validation-error-message">
                    {validationErrors.sex}
                  </p>
                </Col>
                <Col sm={6}>
                  <p
                    style={{
                      margin: "0px",
                      paddingLeft: "8px",
                      textAlign: "left",
                      color: "grey",
                    }}
                  >
                    Select Date of Birth
                  </p>
                  <div style={{ display: "flex" }}>
                    <input
                      type="date"
                      placeholder="Date of Birth"
                      className="register-patient-column-input"
                      name="DOB"
                      value={formData.DOB}
                      onChange={handleInputChange}
                    />
                    <p className="validation-error-message">*</p>
                  </div>
                  <p className="validation-error-message">
                    {validationErrors.DOB}
                  </p>
                </Col>
                <Col sm={6}>
                  <select
                    className="register-patient-column-input"
                    
                  >
                    <option>Marital Status</option>
                    <option>Married</option>
                    <option>Single</option>
                    <option>Prefer not to say</option>
                  </select>
                </Col>
                <Col sm={6}>
                  <select
                    className="register-patient-column-input"
                    name="ethnicity"
                    value={formData.ethnicity}
                    onChange={handleInputChange}
                  >
                    <option>Ethnicity</option>
                    <option>African</option>
                    <option>Asian</option>
                    <option>European</option>
                    <option>Middle Eastern</option>
                    <option>Indigenous </option>
                    <option>Latino/Hispanic</option>
                    <option>Pacific Islander</option>
                    <option>Multiracial/Multiethnic</option>
                    <option>Other Ethnic Groups</option>
                    <option>Prefer not to say</option>
                  </select>
                </Col>
              </Row>

              <Row>
                <Col sm={4} className="mb-2">
                  <textarea
                    className="register-patient-column-input"
                    placeholder="Street"
                    style={{ height: "100%" }}
                    name="street"
                    value={formData.street}
                    onChange={handleInputChange}
                  />
                </Col>
                <Col sm={4}>
                  <input
                    type="text"
                    placeholder="City"
                    className="register-patient-column-input"
                    style={{ marginBottom: "10px" }}
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                  <input
                    type="text"
                    placeholder="State"
                    className="register-patient-column-input"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                  />
                </Col>
                <Col sm={4}>
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      placeholder="Postal Code"
                      className="register-patient-column-input"
                      style={{ marginBottom: "10px" }}
                      name="postal_code"
                      value={formData.postal_code}
                      onChange={handleInputChange}
                    />
                    <p className="validation-error-message">*</p>
                  </div>
                 
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={4}>
                  <div style={{ display: "flex" }}>
                    <input
                      type="number"
                      placeholder="Cellphone Number"
                      className="register-patient-column-input"
                      name="phone_contact"
                      value={formData.phone_contact}
                      onChange={handleInputChange}
                    />
                    <p className="validation-error-message">*</p>
                  </div>
                  <p className="validation-error-message">
                    {validationErrors.phone_contact}
                  </p>
                </Col>

                <Col sm={8}>
                  <div style={{ display: "flex" }}>
                    <input
                      type="email"
                      placeholder="Email Address"
                      className="register-patient-column-input"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    <p className="validation-error-message">*</p>
                  </div>
                  <p className="validation-error-message">
                    <b>{validationErrors.email}</b>
                  </p>

                 
                </Col>
              </Row>

              

              <Row>
                <Col sm={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <input
                    //   className="custom-checkbox"
                      type="checkbox"
                      style={{
                        margin: "0px",
                        cursor: "pointer",
                        width: "35px",
                      }}
                    />
                    <p style={{ margin: "0px" }}>
                      {/* I have read and agree to the terms of the Cosmos Medi Spa{" "} */}
                      By checking this box, I confirm that I have read and agree
                      to the{" "}
                      <Link
                        to="/privacy-policy"
                        style={{ color: "blue", cursor: "pointer" }}
                        target="_blank"
                      >
                        Terms and Conditions
                      </Link>{" "}
                      ,{" "}
                      <Link
                        to="/privacy-policy"
                        style={{ color: "blue", cursor: "pointer" }}
                        target="_blank"
                      >
                        Privacy Policy
                      </Link>{" "}
                      and{" "}
                      {/* <Link
                        to="/financial-policy"
                        style={{ color: "blue", cursor: "pointer" }}
                        target="_blank"
                      > */}
                      Financial Policy
                      {/* </Link> {" "} */} of Cosmos Medi Spa.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} style={{ textAlign: "right" }} className="mt-2">
                  <button
                    onClick={handleSubmit}
                    className="med-spa-custom-button"
                  >
                    Submit
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

     
    </div>
  );
};

export default CreatePatientByProvider;
