import React, { useState } from 'react';
import { BsFillChatLeftDotsFill } from 'react-icons/bs';
import axios from 'axios';
import ApiServices from '../../GlobalFiles/ApiServices/ApiServices';

const MedSpaTextSMS = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [messageSent, setMessageSent] = useState(false);

  const handleSendMessage = async () => {
    // Add +91 country code to the phone number
    const formattedPhoneNumber = `+91${phoneNumber}`;

    // Perform a POST request to your API using Axios
    try {
      const response = await ApiServices.post('/api/create-enquiry/', {
        phone_number: formattedPhoneNumber,
        enquiry_message: message,
      });
      console.log("response::::",response)
      if (response.data.message = "Enquiry saved successfully") {
        setMessageSent(true);
      } else {
        // Handle error cases here if needed
        console.error('Failed to send message');
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  // Validate and format the phone number input
  const handlePhoneNumberChange = (e) => {
    const inputPhoneNumber = e.target.value;
    const sanitizedPhoneNumber = inputPhoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
    const formattedPhoneNumber = sanitizedPhoneNumber.slice(-10); // Keep only the last 10 digits

    setPhoneNumber(formattedPhoneNumber);
  };

  return (
    <div>
      <div className="call-now-div-bg">
        <div className="call-now-div-bg-style">
          <BsFillChatLeftDotsFill className="call-now-div-logo" />
        </div>
      </div>
      <div className="give-us-a-call-main-div" >
        <h3>Text Us</h3>
        <p style={{padding:"5px"}}>Post Your Message Below, Our Team Will connect with you soon!</p>
      </div>

      {messageSent ? (
        <div>
          <p style={{ textAlign: 'center', paddingTop: '30px' }}>
            Thank you! Your message has been received. Our team will get back to you soon.
          </p>
        </div>
      ) : (
        <div>
          <div style={{ paddingTop: '20px' }}>
            <input
              type="text"
              placeholder="Enter Your Phone Number"
              className="text-your-query"
              value={phoneNumber}
              onChange={handlePhoneNumberChange} // Handle phone number input changes
            />
            <textarea
              className="text-your-query"
              placeholder="Enter Your Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className="call-now-button-div">
            <button className="call-now-button-div-button" onClick={handleSendMessage}>
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MedSpaTextSMS;
