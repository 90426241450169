


import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import AppointmentPatientList from "../../GlobalFiles/ApiServices/AppointmentPatientList";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import AppointmentCalendar from "../PatientAppointments/AppointmentCalendar";

const CancelledAppoints = () => {
  const accessToken = sessionStorage.getItem("accessToken");
  const LoginUserID = sessionStorage.getItem("LoginUserID");
  const [patientList, setPatientList] = useState([]);
  const fetchPatientList = async () => {
    try {
      const patients = await AppointmentPatientList.fetchData();
      setPatientList(patients);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchPatientList();
  }, []);
  const [searchInput, setSearchInput] = useState("");
  const [filteredPatientList, setFilteredPatientList] = useState([]);
  const getName = (params) => {
    const patient = params.data;
    return `${patient.patient_fname} ${patient.mname ? patient.mname : ""} ${
      patient.patient_lname
    }`;
  };

  const getProviderName = (params) => {
    const patient = params.data;
    return `${patient.provider_fname}  ${patient.provider_lname}`;
  };

  useEffect(() => {
    setFilteredPatientList(filterPatientsByDate(patientList));
  }, [patientList]);

  const filterPatientsByDate = (patients) => {
    const currentDate = new Date().toISOString().split("T")[0];
    return patients.filter(
      (patient) =>
        patient.pc_eventDate >= currentDate && 
        (patient.pc_apptstatus === "REJ" || patient.pc_apptstatus === "?")
    );
  };
  

  const navigate = useNavigate();
  const handleCellClick = (params) => {
    if (params.colDef.field === "name") {
      const patientUuid = params.data.patient_uuid;
      const patienteid = params.data.pc_eid;
      const patientpid = params.data.pc_pid;

      // navigate(`/admin/patient-details/${patientUuid}/`,{
      navigate(
        `/admin/patient-details-view/${patientUuid}/${patienteid}/${patientpid}`,
        {
          state: { patienteid: patienteid },
        }
      );
    }
  };

  const handleSearchInputChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchInput(inputValue);
  
    const filteredPatients = filterPatientsByDate(patientList).filter(
      (patient) =>
        (patient.patient_fname ? patient.patient_fname.toLowerCase().includes(inputValue) : false) ||
        (patient.patient_lname ? patient.patient_lname.toLowerCase().includes(inputValue) : false)
    );
  
    setFilteredPatientList(filteredPatients);
  };
  

  const [selectedAppointment, setselectedAppointment] = useState();
  const [addNoteModal, setaddNoteModal] = useState(false);
  const [appointmentNote, setAppointmentNote] = useState("");
  const handleaddNoteModalclose = () => {
    setaddNoteModal(false);
  };
  const handleAddNoteClick = (params) => {
    if (params.colDef.field === "appointment_note") {
      const appointmentdata = params.data;
      setselectedAppointment(appointmentdata);
      setAppointmentNote(appointmentdata.appointment_note);
    }
    setaddNoteModal(true);
  };

  const handleAddNoteSave = async () => {
    try {
      const response = await ApiServices.put(
        `/apis/default/api/patient/${selectedAppointment.pc_pid}/appointment/${selectedAppointment.pc_eid}`,
        {
          appointment_note: appointmentNote,
          pc_catid: selectedAppointment.pc_catid,
          pc_title: selectedAppointment.pc_title,
          pc_duration: selectedAppointment.pc_duration,
          pc_hometext: selectedAppointment.pc_hometext,
          pc_apptstatus: selectedAppointment.pc_apptstatus,
          pc_eventDate: selectedAppointment.pc_eventDate,
          pc_startTime: selectedAppointment.pc_startTime,
          pc_facility: selectedAppointment.pc_facility,
          pc_billing_location: selectedAppointment.pc_billing_location,
          pc_aid: selectedAppointment.pc_aid,
          client_type: selectedAppointment.client_type,
          wallet_id: selectedAppointment.wallet_id,
          user_type: selectedAppointment.user_type,
          is_billed: 0,
          assigned_by: selectedAppointment.assigned_by,
          verified_receptionist: 0,
          appointment_type: selectedAppointment.appointment_type,
        },
        {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        }
      );

      fetchPatientList();
      swal({
        title: "Appointment Note Added Successfully",
        icon: "success",
        button: "OK",
        timer: 2000,
      });
      console.log("Update response:", response);
      setaddNoteModal(false);
    } catch (error) {
      console.error("Error updating appointment note:", error);
      swal({
        title: "Something Went Wrong",
        icon: "error",
        button: "OK",
        timer: 2000,
      });
    }
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const [PatientToBookAppointment, setPatientToBookAppointment] =
    useState(null);
  const [updateAppointmentData, setupdateAppointmentData] = useState(false);
  const handleEditClick = (patient) => {
    setPatientToBookAppointment(patient);
    setShowEditModal(true);
    setupdateAppointmentData(true);
  };
  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setupdateAppointmentData(false);
    fetchPatientList();
  };

  const [practitionerList, setPractitionerList] = useState([]);

  const [selectedPractitioner, setSelectedPractitioner] = useState(null);
  useEffect(() => {
    const fetchPractitionerList = async () => {
      try {
        const practitionerResponse = await ApiServices.get(
          "/apis/default/api/practitioner",
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );

        const practitioners = practitionerResponse.data;
        setPractitionerList(practitioners);
      } catch (error) {
        console.error("Error fetching practitioner data:", error);
      }
    };

    fetchPractitionerList();
  }, []);

  const handleCellClickAssign = (params) => {
    if (params.colDef.field === "assigned_to") {
      const appointmentdata = params.data;
      setselectedAppointment(appointmentdata);
    }
  };

  const handlePractitionerChange = async (e) => {
    const selectedPractitionerId = e.target.value;
    const selectedPractitionerDetails = practitionerList.find(
      (practitioner) => practitioner.id === selectedPractitionerId
    );
    setSelectedPractitioner(selectedPractitionerDetails);

    if (selectedPractitionerDetails && selectedAppointment) {
      try {
        const response = await ApiServices.put(
          `/apis/default/api/patient/${selectedAppointment.pc_pid}/appointment/${selectedAppointment.pc_eid}`,
          {
            pc_catid: selectedAppointment.pc_catid,
            pc_title: selectedAppointment.pc_title,
            pc_duration: selectedAppointment.pc_duration,
            pc_hometext: selectedAppointment.pc_hometext,
            // pc_apptstatus: selectedAppointment.pc_apptstatus,
            pc_apptstatus: selectedAppointment.pc_apptstatus,
            pc_eventDate: selectedAppointment.pc_eventDate,
            pc_startTime: selectedAppointment.pc_startTime,
            pc_facility: selectedAppointment.pc_facility,
            pc_billing_location: selectedAppointment.pc_billing_location,
            pc_aid: selectedPractitionerDetails.id,
            client_type: selectedAppointment.client_type,
            wallet_id: selectedAppointment.wallet_id,
            user_type: selectedAppointment.user_type,
            appointment_note: selectedAppointment.appointment_note,
            appointment_type: selectedAppointment.appointment_type,
            is_billed: 0,
            assigned_by: LoginUserID,
            verified_receptionist: 0,
          },
          {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          }
        );
        // await handleApproveClick(selectedAppointment.pc_pid, selectedAppointment.pc_eid);

        fetchPatientList();
        swal({
          title: "Appointment Assigned and Approved Successfully",
          icon: "success",
          button: "OK",
          timer: 2000,
        });
        console.log("Assignment response:", response);
      } catch (error) {
        console.error("Error assigning appointment:", error);
        swal({
          title: "Something Went Wrong",
          icon: "error",
          button: "OK",
          timer: 2000,
        });
      }
    }
  };

  const handleRejectClick = async (patientId, encounterId) => {
    try {
      const response = await ApiServices.put(
        "/apis/default/api/apptStatusUpdate",
        {
          pid: patientId,
          encounter_id: encounterId,
          apptstatus: "REJ",
        },
        {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        }
      );
      fetchPatientList();
      swal({
        title: "Appointment Rejected Successfully",
        // text: "Welcome to the Digi Health.",
        icon: "success",
        button: "OK",
        timer: 2000,
      });
      console.log("Approval response:", response);
    } catch (error) {
      console.error("Error approving appointment:", error);
      swal({
        title: "Soething Went Wrong",
        // text: "Welcome to the Digi Health.",
        icon: "error",
        button: "OK",
        timer: 2000,
      });
    }
  };

  const columnDefs = [
    {
      headerName: "Name",
      field: "name",
      valueGetter: getName,
      headerClass: "custom-header-of-tables",
      onCellClicked: handleCellClick,
      minWidth: 200,
      flex: 1,
      pinned: "left",
      // cellStyle: { backgroundColor: '#808080ab',color:"white" }
    },

    {
      headerName: "Date",
      field: "pc_eventDate",
      headerClass: "custom-header-of-tables",
      minWidth: 120,
      flex: 1,
      sort: "desc",
    },
    {
      headerName: "Time",
      field: "pc_startTime",
      headerClass: "custom-header-of-tables",
      minWidth: 100,
      flex: 1,
    },
    {
      headerName: "Type",
      field: "appointment_type",
      headerClass: "custom-header-of-tables",
      minWidth: 140,
      flex: 1,
    },
    {
      headerName: "Note",
      field: "appointment_note",
      headerClass: "custom-header-of-tables",
      minWidth: 140,
      flex: 1,
      cellRenderer: (params) => {
        const { data } = params;
        return data.appointment_note ? (
          <div onClick={() => handleAddNoteClick(params)}>
            {data.appointment_note}
          </div>
        ) : (
          <Button
            variant="info"
            size="sm"
            style={{ width: "100%" }}
            onClick={() => handleAddNoteClick(params)}
          >
            Add Note
          </Button>
        );
      },
    },
    {
      headerName: "Provider",
      field: "provider_name",
      valueGetter: getProviderName,
      headerClass: "custom-header-of-tables",
      minWidth: 140,
      flex: 1,
    },

    {
      headerName: "Change Provider",
      field: "assigned_to",
      headerClass: "custom-header-of-tables",
      minWidth: 250,
      flex: 1,
      onCellClicked: handleCellClickAssign,
      cellRenderer: (params) => {
        const { data } = params;
        return (
          <select
            value={selectedPractitioner}
            onChange={handlePractitionerChange}
            style={{ padding: "5px", border: "none" }}
          >
            <option value="" disabled>
              Select Practitioner
            </option>
            {practitionerList.map((practitioner) => (
              <option key={practitioner.id} value={practitioner.id}>
                {practitioner.fname} {practitioner.lname}
              </option>
            ))}
          </select>
        );
      },
    },

    {
      headerName: "Client Type",
      field: "client_type",
      headerClass: "custom-header-of-tables",
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: "Edit Appointment",
      field: "edit",
      headerClass: "custom-header-of-tables",
      minWidth: 100,
      flex: 1,
      sortable: false,
      filter: false,
      cellRenderer: (params) => {
        const { data } = params;
        return (
          <Button
            variant="secondary"
            size="sm"
            style={{ width: "100%" }}
            onClick={() => handleEditClick(data)}
          >
            Edit
          </Button>
        );
      },
    },
    // {
    //   headerName: "Action",
    //   field: "action",
    //   headerClass: "custom-header-of-tables",
    //   minWidth: 140,
    //   flex: 1,
    //   sortable: false,
    //   filter: false,
    //   cellRenderer: (params) => {
    //     const { data } = params;
    //     return (
    //       <Button
    //         variant="danger"
    //         size="sm"
    //         onClick={() => handleRejectClick(data.pc_pid, data.pc_eid)}
    //         style={{ width: "100%" }}
    //       >
    //         Cancel
    //       </Button>
    //     );
    //   },
    // },
    {
      headerName: "Contact Number",
      field: "patient_contact",
      headerClass: "custom-header-of-tables",
      minWidth: 180,
      flex: 1,
    },
    {
      headerName: "Email",
      field: "patient_email",
      headerClass: "custom-header-of-tables",
      minWidth: 240,
      flex: 1,
    },
    {
      headerName: "Assigned By",
      field: "assigner_email",
      headerClass: "custom-header-of-tables",
      minWidth: 140,
      flex: 1,
      sortable: false,
      filter: false,
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  return (
    <div style={{ backgroundColor: "#F1F5F8" }}>
      <Row>
        <Col sm={12}>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <input
              type="text"
              placeholder="Search Patient by Name..."
              value={searchInput}
              onChange={handleSearchInputChange}
              className="form-control mb-2"
            />
          </div>
          <div
            className="ag-theme-alpine"
            style={{
              height: "85vh",
              width: "100%",
              // overflow: "hidden"
            }}
          >
            <AgGridReact
              columnDefs={columnDefs}
              //   rowData={patientList}
              rowData={filteredPatientList}
              defaultColDef={defaultColDef}
            />
          </div>
        </Col>
      </Row>

      <Modal
        show={addNoteModal}
        onHide={handleaddNoteModalclose}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Appointment Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            rows="4"
            cols="50"
            placeholder="Add appointment note..."
            value={appointmentNote}
            onChange={(e) => setAppointmentNote(e.target.value)}
            style={{ width: "100%", padding: "5px" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleaddNoteModalclose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddNoteSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showEditModal}
        onHide={handleCloseEditModal}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AppointmentCalendar
            PatientToBookAppointment={PatientToBookAppointment}
            handleModalClose={handleCloseEditModal}
            updateAppointmentData={updateAppointmentData}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CancelledAppoints;
