import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import AppointmentCalendar from "./AppointmentCalendar"
import PatientAllAppointments from './PatientAllAppointments'

const PatientAppointmentsPage = () => {
  return (
    <div style={{height:"100vh"}}>
        <Row>
            <Col sm={6}>
            <Card>
            <Card.Body>
                <AppointmentCalendar/>
            </Card.Body>
            </Card>
            </Col>
            <Col sm={6}>
              <Card>
                <Card.Body>
                  <PatientAllAppointments/>
                </Card.Body>
              </Card>
            </Col>
        </Row>
    </div>
  )
}

export default PatientAppointmentsPage