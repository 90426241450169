import React from 'react'
import "./MedSpaPatientRegistrationPage.css"
import PatientRegistrationForm from '../../Components/PatientRegistration/PatientRegistrationForm/PatientRegistrationForm'
import { Fade } from 'react-reveal'
import PatientDetailsForm from '../../Components/PatientRegistration/PatientDetailsForm'

const MedSpaPatientRegistrationPage = () => {
  return (
    <div className='med-spa-pat-reg-bg-colo-of-page'>
        <div style={{paddingTop:"30px",paddingBottom:"30px"}}>
        <Fade bottom>
            {/* <PatientRegistrationForm/> */}
            <PatientDetailsForm/>
            </Fade>
        </div>
    </div>
  )
}

export default MedSpaPatientRegistrationPage