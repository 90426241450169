import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PackageGiftCards from './PackageGiftCards'
import WalletGiftCards from './WalletGiftCards'

const GiftCards = () => {
 const[packagesGiftBlock,setpackagesGiftBlock]=useState(true)
 const[walletGiftBlock,setwalletGiftBlock]=useState(false)

 const handleGiftBlockTypeChange = (giftBlockType) => {
  // Update state based on the selected appointment type
  setpackagesGiftBlock(giftBlockType === 'packagesGiftBlock');
  setwalletGiftBlock(giftBlockType === 'walletGiftBlock');
};

  return (
    <div>
        <div style={{display:"flex",justifyContent:"space-between"}}>
        <Link to="/admin/cosmos-wallet-create-gift-voucher">
        <Button>Generate Wallet Gift Voucher</Button>
        </Link>
        <Link to="/admin/cosmos-package-create-gift-cards">
        <Button>Generate Packages Gift Card</Button>
        </Link>
        </div>

        <div style={{ display: "flex", justifyContent: "space-around" }} className='mt-3'>
        <button onClick={() => handleGiftBlockTypeChange('packagesGiftBlock')} className={packagesGiftBlock ? "appointment-type-btn-active" : "appointment-type-btn-inactive"}>Package Gift Cards</button>
        <button onClick={() => handleGiftBlockTypeChange('walletGiftBlock')} className={walletGiftBlock ? "appointment-type-btn-active" : "appointment-type-btn-inactive"}>Wallet Gift Cards</button>
      </div>

      <div className='mt-3'>
      {packagesGiftBlock && <PackageGiftCards/>}
      {walletGiftBlock && <WalletGiftCards/>}
    </div>
    </div>
  )
}

export default GiftCards