import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Row, Form, Card, Col } from "react-bootstrap";
import DigiLogo from "../../Assets/cosmosLogocolor.png";
import PaymentGateway from "../CardPayments/PaymentGateway";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import swal from "sweetalert";
import ExternamPaymentGateway from "../PaymentGateway/ExternamPaymentGateway";

const PatientWalletTopUpQR = () => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PROMISE
  );
  const paramsData = useParams();

  const [finalAmount, setFinalAmount] = useState(paramsData.finalAmount);


  const cardStyle = {
    margin: "auto",

    width: "100%",
    background: "rgb(241, 245, 248)",

    color: "black",
    minHeight: "100vh",
  };

  const { fname, lname, email,walletid,paymentFor } = useParams();
  const patientData = { fname, lname, email,walletid,paymentFor };


  const handlepaymentsuccess = () => {
    swal({
      title: "Payment Successful",
      icon: "success",
      button: "OK",
      timer: 2000,
    }).then(() => {
      const finalURL = "https://medispacosmos.com/";
      window.location.href = finalURL;
    });
  };
  
 
  return (
    <div>
      <img src={DigiLogo} alt="" style={{ height: "80px", width: "200px" }} />
      <Card style={cardStyle} className="mt-3">
        <Card.Body>
       
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              //   height: "70vh",
            }}
          >
            
              <div style={{ width: "100%" }} className="mt-5">
                <div
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    padding: "20px",
                    borderRadius: "20px",
                  }}
                >
                  <Elements stripe={stripePromise}>
                    <ExternamPaymentGateway
                      patientData={patientData}
                      amount={finalAmount}
                      onSuccess={handlepaymentsuccess}
                    />
                  </Elements>
                </div>
              </div>
            
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default PatientWalletTopUpQR;
