import React from "react";
import { Route, Routes } from "react-router-dom";
import PatientDahsboard from "../../Components/PatientDahsboard/PatientDahsboard";
import PatientAppointmentsPage from "../../Components/PatientAppointments/PatientAppointmentsPage";
import PageUnderConstruction from "../../Components/PageUnderConstruction/PageUnderConstruction";
import PatientBillsList from "../../Components/PatientBills/PatientBillsList";
import ServiceBill from "../../Components/ServiceBill/ServiceBill";
import ServiceForms from "../../Components/ServiceForms/ServiceForms";
import ServiceFormGet from "../../Components/ServiceForms/ServiceFormGet";
import PatientAllAppointments from "../../Components/PatientAppointments/PatientAllAppointments";
import ServiceFormsMainPage from "../../Components/ServiceForms/ServiceFormsMainPage";
import IntakeFormsHomePage from "../../Components/IntakeForms/IntakeFormsHomePage";
import PatientWallet from "../../Components/PatientWallet/PatientWallet";
import PatientPackagesDetails from "../../Components/Packages/PatientPackagesDetails";
import ServiceBillNew from "../../Components/ServiceBill/ServiceBillNew";
import UserDetailsPage from "../../Components/UserDetailsPage/UserDetailsPage";

const PatientRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element=<PatientDahsboard /> />
        <Route path="/manage-appointments" element=<PatientAppointmentsPage/> />
        <Route path="/all-bills" element=<PatientBillsList/> />
        <Route path="/intake-forms" element=<IntakeFormsHomePage/> />
        <Route path="/all-appointments" element=<PatientAllAppointments/> />
        <Route path="/show-bill/:uuid/:eid/:pid" element=<ServiceBillNew/> />
        <Route path="/patient-wallet/:pid/:fname/:lname/:email/:walletid" element=<PatientWallet/> />
        <Route path="/patient-packages/:pid/:fname/:lname/:email/:walletid" element=<PatientPackagesDetails/> />
        <Route path="/page-under-construction" element=<PageUnderConstruction/> />
        <Route path="/service-forms" element=<ServiceForms/> />
        {/* <Route path="/service-form/:eid/:pid/:formname" element=<ServiceDynamicForm/> /> */}
        <Route path="/service-form/:eid/:pid/:formname" element=<ServiceFormsMainPage/> />
        <Route path="/filled-service-form/:eid/:pid/:formname" element=<ServiceFormGet/> />
        <Route path="/my-details-page" element=<UserDetailsPage/> />
      </Routes>
    </div>
  );
};

export default PatientRoutes;
