import React, { useState } from "react";
import "./MedSpaSupportScreen.css";

import { FcCustomerSupport } from "react-icons/fc";
import { AiOutlineClose } from "react-icons/ai";
import MedSpaSupportWindow from "./MedSpaSupportWindow";

const MedSpaSupportScreen = () => {
  const [isChatOpen, setChatOpen] = useState(false);

  const toggleChat = () => {
    setChatOpen(!isChatOpen);
  };
  return (
    <div className="med-spa-support-screen-fix-main">
    <div className="med-spa-support-screen-fix">
      <div className="med-spa-support-screen-logo-div">
      {isChatOpen==true ?
        <AiOutlineClose
          className="med-spa-support-screen-logo"
          onClick={toggleChat}
          style={{color:"white"}}
        />
      :
        <FcCustomerSupport
          className="med-spa-support-screen-logo"
          onClick={toggleChat}
        />}
      </div>
      </div>
      {isChatOpen && (
        <div className="chat-window">
          <MedSpaSupportWindow/>
        </div>
      )}
    </div>
  );
};

export default MedSpaSupportScreen;
