import React, { useEffect, useState } from 'react'
import ProviderBillingServices from '../ProviderBillingServices/ProviderBillingServices'
import AccessToken from '../../../GlobalFiles/ApiServices/AccessToken'
import { useParams } from 'react-router-dom'
import ApiServices from '../../../GlobalFiles/ApiServices/ApiServices'
import UpdateBillingServices from '../ProviderBillingServices/UpdateBillingServices'

const ProviderDetails = () => {
  const[basicDetails,setbasicDetails]=useState(true)
  const[manageBilling,setmanageBilling]=useState(false)
  const[updateBilling,setupdateBilling]=useState(false)

  const handlebasicDetails =()=>{
    setbasicDetails(true)
    setmanageBilling(false)
    setupdateBilling(false)
  }
  const handlemanageBilling =()=>{
    setbasicDetails(false)
    setmanageBilling(true)
    setupdateBilling(false)
  }
  const handlUpdateBilling =()=>{
    setbasicDetails(false)
    setmanageBilling(false)
    setupdateBilling(true)
  }
  const { uuid } = useParams();
  const [practitionerdata,setpractitionerdata]=useState()
  
  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const accessToken = await AccessToken.fetchAccessToken();
        const practitionerResponse = await ApiServices.get(
          "/apis/default/api/practitioner",
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );
        const filteredPractitioner = practitionerResponse.data.find(
          (practitioner) => practitioner.uuid === uuid
        );
        setpractitionerdata(filteredPractitioner);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAccessToken();
  }, []);

  return (
    <div>
      <div style={{display:"flex",justifyContent:"space-around"}}>
      <button onClick={handlebasicDetails} className={basicDetails ? "appointment-type-btn-active" : "appointment-type-btn-inactive"}>Details</button>
      <button onClick={handlemanageBilling} className={manageBilling ? "appointment-type-btn-active" : "appointment-type-btn-inactive"}>Add Services Discount</button>
      <button onClick={handlUpdateBilling} className={updateBilling ? "appointment-type-btn-active" : "appointment-type-btn-inactive"}>Update Services Discount</button>
      </div>
      <div style={{paddingTop:"20px",paddingBottom:"40px"}}>
        {manageBilling && <ProviderBillingServices practitionerdata={practitionerdata}/>}
        {updateBilling && <UpdateBillingServices practitionerdata={practitionerdata}/>}
      </div>
    </div>
  )
}

export default ProviderDetails