import ApiServices from "./ApiServices";

class PatientAccessToken {
    async fetchData() {
        try {
        const PatientAccessTokenGet = sessionStorage.getItem("Patient_Access_Token");
      const response = await ApiServices.get(
        "/apis/default/portal/patient",
        {
          
            Authorization: `Bearer ${PatientAccessTokenGet}`,
          
        }
      );
      // Assuming your API response has a 'data' property
      const data = response.data.data;
      return data;
    } catch (error) {
      console.error("Error fetching patient data:", error);
      throw error; // Rethrow the error to be caught by the calling code
    }
  }
}

export default new PatientAccessToken();
