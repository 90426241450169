import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { AgGridReact } from "ag-grid-react";
import { Form, Button, Modal } from "react-bootstrap";
import Loader from "../Loader/Loader";
import swal from "sweetalert";

const PatientPackagesDetails = () => {
  const paramsData = useParams();
  const LoginUserType = sessionStorage.getItem("LoginUserType");
  const accessToken = sessionStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);
  const [fetchBillingDataAgin, setfetchBillingDataAgin] = useState(false);
  const [availablePackages, setavailablePackages] = useState([]);
  const [PackageLogData, setPackageLogData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `/apis/default/api/packageAssignments/${paramsData.pid}`;

        if (LoginUserType === "Patient") {
          endpoint = `/apis/default/portal/packageAssignments/${paramsData.pid}`;
        }

        const response = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });

        if (response.data && Array.isArray(response.data)) {
          setavailablePackages(response.data);
        } else {
          setavailablePackages([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setavailablePackages([]);
      }
    };
    fetchData();

    const fetchPackageLogData = async () => {
      try {
        let endpoint = `/apis/default/api/packageUsageLog/${paramsData.pid}`;

        if (LoginUserType === "Patient") {
          endpoint = `/apis/default/portal/packageUsageLog/${paramsData.pid}`;
        }

        const response = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });

        if (response.data && Array.isArray(response.data)) {
          setPackageLogData(response.data);
        } else {
          setPackageLogData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setPackageLogData([]);
      }
    };

    fetchPackageLogData();
  }, [paramsData, fetchBillingDataAgin]);

  const columnDefs = [
    {
      headerName: "Package Name",
      field: "package_type",
      headerClass: "custom-header-of-tables",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Service Name",
      field: "service_title",
      headerClass: "custom-header-of-tables",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Service Quantity",
      field: "service_quantity",
      headerClass: "custom-header-of-tables",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Package Assign Date",
      field: "assigned_date",
      headerClass: "custom-header-of-tables",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Package Expiry Date",
      field: "expiry",
      headerClass: "custom-header-of-tables",
      flex: 1,
      minWidth: 150,
    },
  ];

  const UsedPackagecolumnDefs = [
    {
      headerName: "Package Name",
      field: "package_name",
      headerClass: "custom-header-of-tables",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Service Name",
      field: "label_on_bill",
      headerClass: "custom-header-of-tables",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Quantity Used",
      field: "quantity_used",
      headerClass: "custom-header-of-tables",
      flex: 1,
      minWidth: 50,
      maxWidth: 150,
    },
    {
      headerName: "Usage Date",
      field: "usage_date",
      headerClass: "custom-header-of-tables",
      flex: 1,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      headerName: "Detail",
      field: "notes",
      headerClass: "custom-header-of-tables",
      flex: 1,
      minWidth: 250,
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  const [giftCardModel, setgiftCardModel] = useState(false);
  const handlegiftCardModelOpen = () => {
    setgiftCardModel(true);
  };
  const handlegiftCardModelClose = () => {
    setgiftCardModel(false);
  };

  const [giftTokenId, setGiftTokenId] = useState("");

  const handleRedeemGiftCard = async () => {
    if (!giftTokenId) {
      alert("Please enter a gift token ID.");
      return;
    }

    const payload = {
      gift_token_id: giftTokenId,
      pid: paramsData.pid,
    };

    setLoading(true);

    try {
      let endpoint = `/apis/default/api/redeemPackage`;

      if (LoginUserType === "Patient") {
        endpoint = `/apis/default/portal/redeemPackage`;
      }

      const response = await ApiServices.post(endpoint, payload, {
        Authorization: `Bearer ${accessToken}`,
      });
      if (response.data.error) {
        swal({
          title: "Invalid or Expired Gift Token",
          text: response.data.error,
          icon: "error",
          button: "OK",
          timer: 3000,
        });
      } else {
        swal({
          title: "Gift Card Redeemed Successfully",
          icon: "success",
          button: "OK",
          timer: 3000,
        })}
    
      setLoading(false);
    
      setfetchBillingDataAgin(!fetchBillingDataAgin);
      handlegiftCardModelClose();
    } catch (error) {
      console.error("Error redeeming gift card:", error);
      setLoading(false);
      swal({
        title: "Something Went Wrong",
        icon: "error",
        button: "OK",
        timer: 3000,
      });
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <div className="mb-2" style={{ textAlign: "right" }}>
        <Button onClick={handlegiftCardModelOpen}>Redeem my Gift Card</Button>
      </div>
      <div
        className="ag-theme-alpine"
        style={{ width: "100%", overflow: "hidden", height: "430px" }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={Array.isArray(availablePackages) ? availablePackages : []}
          defaultColDef={defaultColDef}
        />
      </div>
      <div className="mt-5">
        <h3>Packages History</h3>
      </div>
      <div className="mt-5">
        <div
          className="ag-theme-alpine"
          style={{ width: "100%", overflow: "hidden", height: "430px" }}
        >
          <AgGridReact
            columnDefs={UsedPackagecolumnDefs}
            rowData={Array.isArray(PackageLogData) ? PackageLogData : []}
            defaultColDef={defaultColDef}
          />
        </div>
      </div>

      <Modal
        show={giftCardModel}
        onHide={handlegiftCardModelClose}

        centered
        size="xl"
        scrollable
        style={{ marginTop: "50px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Redeem my Gift Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="giftTokenId">
            <Form.Label>Gift Token ID</Form.Label>
            <Form.Control
              type="text"
              value={giftTokenId}
              onChange={(e) => setGiftTokenId(e.target.value)}
              required
            />
          </Form.Group>
          <Button
            variant="primary"
            onClick={handleRedeemGiftCard}
            disabled={loading}
            className="mt-3"
          >
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PatientPackagesDetails;
