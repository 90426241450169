import React, { useEffect, useState } from "react";
import "./ProductCarousal.css";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { Card, Col, Container } from "react-bootstrap";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";

const ProductCarousal = () => {
  const [productsData, setProductsData] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    ApiServices.get("/api/products/").then((response) => {
      setProductsData(response.data);
    });
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      style={{
        paddingTop: "30px",
        paddingBottom: "30px",
        background: "linear-gradient(161deg, transparent,#05b6b34f, transparent)"
      }}
    >
      <Container>
        <Fade bottom>
          <div>
            <h1 className="explore-treartment-tag">Cosmos Beauty Collection</h1>
          </div>
        </Fade>
        <div style={{ paddingTop: "50px" }}>
          <Fade bottom>
            <div className="scroll-parent" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <div className={`flex-carousal-div ${isHovered ? 'paused' : ''}`}>
                {productsData &&
                  productsData.map((index, i) => (
                    <Col md={3} style={{ paddingBottom: "15px" }} key={i}>
                      <Link
                        to={`/cosmos-products/${index.id}/${index.title}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Card
                          className="services-card-body"
                          style={{ height: "400px" }}
                        >
                          <div
                            style={{ position: "relative", overflow: "hidden" }}
                          >
                            <Card.Img
                              variant="top"
                              src={index.image1}
                              style={{ width: "100%", transform: "scale(1.2)" }}
                            />
                          </div>
                          <Card.Body>
                            <Card.Title
                              style={{ borderBottom: "1px solid #1c324a" }}
                            >
                              <div>
                                <div>
                                  <h1
                                    className="services-card-title"
                                    style={{ fontSize: "1rem" }}
                                  >
                                    {index.title}
                                  </h1>
                                </div>
                              </div>
                            </Card.Title>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  ))}
              </div>
            </div>
          </Fade>
        </div>
      </Container>
    </div>
  );
};

export default ProductCarousal;



