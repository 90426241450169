import React, { useEffect, useState } from 'react';
import ApiServices from '../../GlobalFiles/ApiServices/ApiServices';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { Fade } from 'react-reveal';

const MedSpaPrivacyPolicyPage = () => {
  const [privacyPolicyData, setPrivacyPolicyData] = useState([]);

  useEffect(() => {
    ApiServices.get("/api/privacy_policy/")
      .then((response) => {
        // Filter out items with active_status !== true
        const filteredData = response.data.filter((item) => item.active_status === true);
        // Sort data based on the sequence property
        const sortedData = filteredData.sort((a, b) => a.sequence - b.sequence);
        setPrivacyPolicyData(sortedData);
      });
  }, []);

  // Group data by title
  const groupedData = privacyPolicyData.reduce((acc, item) => {
    acc[item.title] = acc[item.title] || [];
    acc[item.title].push(item);
    return acc;
  }, {});

  // Order the groups based on the sequence of the titles
  const orderedTitles = [
    "Introduction",
    "Information We Collect",
    "Use of Information",
    "Information Sharing and Disclosure",
    "Data Security",
    "Your Choices and Rights",
    "Children's Privacy",
    "Updates to this Privacy Policy",
    "Contact Us",
  ];

  return (
    <div style={{backgroundColor:"rgb(249, 250, 251)"}}>
    <div style={{ paddingTop: "50px", paddingBottom: "50px" }}>
      <Container>
          <Fade bottom>
        {orderedTitles.map((title, index) => (
          <React.Fragment key={index}>
            <h3 className='mt-4' style={{textAlign:"left"}}>{title}</h3>
            <Row>
              {groupedData[title]?.map((item) => (
                <Col key={item.id}>
                  <Card style={{height:"100%"}}>
                    <Card.Body>
                      <p style={{ fontWeight: "700" }}>{item.subtitle}</p>
                      <p>{item.description}</p>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </React.Fragment>
        ))}
            </Fade>
      </Container>
    </div>
    </div>
  );
}

export default MedSpaPrivacyPolicyPage;
