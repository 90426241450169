import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import MedSpaTextSMS from "../../MedSpaSupportScreen/MedSpaTextSMS";
import { Fade } from "react-reveal";

const ContactUsSecTwo = () => {
  const handleAddressClick = () => {
    const address = encodeURIComponent("4317 E 3rd St, Bloomington, IN 47401, United States");
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${address}`;
    window.open(googleMapsUrl, "_blank");
  };
  return (
    <div className="med-spa-section-one-bg" style={{paddingTop:"50px",paddingBottom:"50px"}}>
      <Container>

      <div style={{paddingTop:"20px"}}>
        <h1 class="explore-treartment-tag">Welcome to Cosmos Medi Spa</h1>
        <p class="wel-come-staff-info" style={{textAlign:"center"}}>
        At Cosmos Medi Spa, we're dedicated to enhancing your natural beauty, promoting relaxation, and rejuvenating your sense of well-being. We're more than just a medical spa; we're your sanctuary for self-care and transformation.</p>
        </div>
      <Fade bottom>
        <Row style={{gap:"10px"}}>
          <Col sm={3}>
            <Card style={{ width: "100%" }}>
              <Card.Body>
                <Card.Title style={{ textAlign: "center" }}>Address</Card.Title>
                <Card.Text style={{ textAlign: "center" }} onClick={handleAddressClick}>4317 E 3rd St, Bloomington, IN 47401, United States</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={1}></Col>
          <Col sm={3}>
          <Card style={{ width: "100%",height:"100%" }}>
              <Card.Body>
                <Card.Title style={{ textAlign: "center" }}>Call Us On</Card.Title>
                <Card.Text style={{ textAlign: "center" }}>
                    
                    <a className='med-spa-footer-para-tag' href='tel:8128030079'>
                    <button className="call-now-button-div-button">8128030079</button>
                    </a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={1}></Col>
          <Col sm={3}>
          <Card style={{ width: "100%",height:"100%" }}>
              <Card.Body>
                <Card.Title style={{ textAlign: "center" }}>Mail Us On</Card.Title>
                <a className='med-spa-footer-para-tag' href='mailto:info@medispacosmos.com'>
                <button className="call-now-button-div-button">info@medispacosmos.com</button>
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        </Fade>
        <Fade bottom>
        <div style={{paddingTop:"25px"}}>
            <Col sm={12} className="d-flex align-items-center justify-content-center">
                <MedSpaTextSMS/>
                </Col>
        </div>
        </Fade>
      </Container>
    </div>
  );
};

export default ContactUsSecTwo;
