import React, { useEffect, useState } from 'react';
import { Button, Card, Col, ListGroup, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ApiServices from '../../GlobalFiles/ApiServices/ApiServices';
import swal from 'sweetalert';
import Loader from '../Loader/Loader';

const AvailablePackagesList = () => {
  const [availableDefaultPackages, setavailableDefaultPackages] = useState([]);
  const [groupedPackages, setGroupedPackages] = useState({});
  const [fetchBillingDataAgin, setfetchBillingDataAgin] = useState(false);
  const [loading, setloading] = useState(false);

  const accessToken = sessionStorage.getItem("accessToken");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiServices.get(`/apis/default/api/packages`, {
          Authorization: `Bearer ${accessToken}`,
        });
        const packages = response.data;
        setavailableDefaultPackages(packages);

        const grouped = packages.reduce((acc, service) => {
          const { package_id, package_type, expiry, service_id, service_title, quantity, service_default_price, service_discount,service_total_amount,total_amount } = service;
          if (!acc[package_id]) {
            acc[package_id] = { package_id, package_type, total_amount,expiry, services: [] };
          }
          acc[package_id].services.push({ service_id, service_title, quantity, service_default_price, service_discount,service_total_amount });
          return acc;
        }, {});
        setGroupedPackages(grouped);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };

    fetchData();
  }, [accessToken, fetchBillingDataAgin]);

  const deletePackage = async (package_id) => {
    console.log("package_id", package_id)
    setloading(true);
    try {
      await ApiServices.delete(`/apis/default/api/packages/${package_id}`, 
      {
          Authorization: `Bearer ${accessToken}`,
        },
      );
      swal({
        title: "Package has been deleted successfully",
        icon: "success",
        button: "OK",
        timer: 2000,
      });
      setfetchBillingDataAgin(!fetchBillingDataAgin);
    } catch (error) {
      console.log(error);
      swal({
        title: "Error deleting package",
        text: error.message,
        icon: "error",
        button: "OK",
      });
      setfetchBillingDataAgin(!fetchBillingDataAgin);
    } finally {
      setloading(false);
    }
  };

  

  return (
    <div>
      {loading && <Loader />}
      <Link to="/admin/cosmos-service-packages-create">
        <Button>Create Package</Button>
      </Link>

      {Object.keys(groupedPackages).map(package_id => (
        <Card key={package_id} className="mt-3">
          <Card.Header>
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <Col sm={2}>
                <strong>Package ID:</strong> {groupedPackages[package_id].package_id} <br />
              </Col>
              <Col sm={3}>

                <strong>Package Type:</strong> {groupedPackages[package_id].package_type} <br />
              </Col>
              <Col sm={3}>

                <strong>Expiry:</strong> {groupedPackages[package_id].expiry || 'N/A'}
              </Col>
              <Col sm={2}>
              <strong>Subtotal:</strong> ${groupedPackages[package_id].total_amount || 'N/A'}

              </Col>
              <Col sm={2} style={{textAlign:"right"}}>

              <Button
                variant="danger"
                onClick={() => deletePackage(package_id)}
                disabled={loading}
              >
                {loading ? 'Deleting...' : 'Delete'}
              </Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <ListGroup>
              <ListGroup.Item>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Service Title</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Discount</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupedPackages[package_id].services.map(service => (
                      <tr key={service.service_id}>
                        <td>{service.service_title}</td>
                        <td>{service.quantity}</td>
                        <td>${service.service_default_price}</td>
                        <td>${service.service_discount}</td>
                        <td>${service.service_total_amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default AvailablePackagesList;
