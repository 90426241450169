import React, { useEffect, useState } from 'react';
import ApiServices from '../../GlobalFiles/ApiServices/ApiServices';
import { useParams } from 'react-router-dom';
import { Table, Container, Modal, Button } from 'react-bootstrap';

const PatientAllSoapNotes = () => {
  const Accesstoken = sessionStorage.getItem("accessToken");
  const paramsData = useParams();
  const [allSoapNotes, setAllSoapNotes] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);

  // Fetch SOAP notes
  useEffect(() => {
    ApiServices.get(`/apis/default/api/soapnote/${paramsData.pid}`, {
      Authorization: `Bearer ${Accesstoken}`,
    })
      .then((response) => {
        const parsedNotes = response.data.map((note) => {
          const soapNoteContent = JSON.parse(note.soap_note).soap_note['1'];
          return {
            id: note.id,
            pc_eid: note.pc_eid,
            service_title: note.service_title,
            Note: soapNoteContent.Note,
            Timestamp: soapNoteContent.Timestamp,
            ProviderID: soapNoteContent.ProviderID,
          };
        });
        setAllSoapNotes(parsedNotes);
        setFilteredNotes(parsedNotes);
      })
      .catch((error) => {
        setAllSoapNotes([]);
        setFilteredNotes([]);
      });
  }, [Accesstoken, paramsData.pid]);

  // Function to handle row click and show modal with details
  const handleRowClick = (note) => {
    setSelectedNote(note);
    setShowModal(true);
  };

  // Function to close modal
  const handleCloseModal = () => setShowModal(false);

  return (
<div>
      
      {/* Show message if no SOAP notes */}
      {filteredNotes.length === 0 ? (
        <p>No SOAP notes found.</p>
      ) : (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Service Title</th>
              <th>Note</th>
              <th>Timestamp</th>
              <th>Provider ID</th>
            </tr>
          </thead>
          <tbody>
            {filteredNotes.map((note) => (
              <tr key={note.id} onClick={() => handleRowClick(note)}>
                <td>{note.service_title}</td>
                <td>{note.Note}</td>
                <td>{note.Timestamp}</td>
                <td>{note.ProviderID}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Modal for showing SOAP note details */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>SOAP Note Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedNote && (
            <div>
              <p><strong>Service Title:</strong> {selectedNote.service_title}</p>
              <p><strong>Note:</strong> {selectedNote.Note}</p>
              <p><strong>Timestamp:</strong> {selectedNote.Timestamp}</p>
              <p><strong>Provider ID:</strong> {selectedNote.ProviderID}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
</div>
  );
};

export default PatientAllSoapNotes;
