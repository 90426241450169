import React from "react";
import { Col,  Row } from "react-bootstrap";
import Discussion from "../../Assets/derm-pat.png"
import { Link } from "react-router-dom";

const MedSpaSectionThree = () => {
  return (
    <div className="med-spa-sec-three-bg">
        <Row className="align-items-center" style={{width:"100%",margin:"0px",}}>
          <Col sm={6} className="order-1 order-sm-1" style={{padding:"0px"}} >
            <img
              // src="https://www.viomedspa.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fbecome_member.45815bd5.jpg&w=1080&q=75"
              src={Discussion}
              style={{ height: "100%", width: "100%", maxHeight:"600px" }}
              alt=''
            />
          </Col>

          <Col sm={6} className="order-2 order-sm-2" style={{textAlign:"left"}}>
          <h1 class="explore-treartment-tag" style={{textAlign:"left"}}>Join Us</h1>
            <h1 className="section-three-head-tag">
            Become a COSMOS Med Spa Member
            </h1>
            <p className="section-three-para-tag">
            Looking to get even more out of your COSMOS Med Spa experience? Explore our exclusive membership plans. With a COSMOS Med Spa membership, beauty and wellness treatments aren’t a one-time luxury. They’re a part of your regular routine. Learn more about our med spa membership options, benefits, and exclusive promotions.
            </p>
            <Link to="/signup">
            <button className="med-spa-custom-button-section-three">Get Started</button>
            </Link>
          </Col>
        </Row>
    </div>
  );
};

export default MedSpaSectionThree;
