import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';

const ServicesDetailsSectionTwo = ({ data }) => {
  const detailsData = data;

  return (
    <div className='ser-vice-details-bg-section'>
        <Container style={{textAlign:"left"}}>
            <Row className="align-items-center">
                <Col sm={7}>
                    <div className='ser-vice-section-data-on-img'>
                        <h1>How It Works</h1>
                        <p>{detailsData&&detailsData?.howworks}</p>
                    </div>
                    <div className='ser-vice-section-data-on-img'>
                        <h1>What It Treats</h1>
                        <p>{detailsData&&detailsData?.whattreats}</p>
                    </div>
                    <div className='ser-vice-section-data-on-img' style={{borderBottom:"none"}}>
                        <h1>Benefits</h1>
                        <p>{detailsData&&detailsData?.benefits}</p>
                    </div>
                </Col>
                <Col sm={5}>
                    <img alt='' className='service-details-bg-img' src={detailsData&&detailsData?.bgimage}/>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default ServicesDetailsSectionTwo