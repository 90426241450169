import React, { useState, useRef, useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import DigiLogo from "../../Assets/cosmosLogocolor.png";
import PasswordLogo from "../../Assets/teenyicons_password-solid.svg";
import EmailLogo from "../../Assets/dashicons_email.svg";
import "../../GlobalFiles/Styles/Styles.css";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import Loader from "../Loader/Loader";

const OtpVerification = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [email, setEmail] = useState(params.email);
  const navigate = useNavigate();
  const otpInputRefs = useRef([]);

  const handleBackspace = (index, e) => {
    if (e.keyCode === 8 && index > 0 && otp[index] === "") {
      otpInputRefs.current[index - 1].focus();
    }
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  
    if (value !== "" && index < 5) {
      otpInputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('text').slice(0, 6);
    const newOtp = pasteData.split("").map((char, index) => char);
    setOtp(newOtp);
    
    newOtp.forEach((value, index) => {
      otpInputRefs.current[index].value = value;
    });
    if (pasteData.length === 6) {
      getTokenAndHandleLogin();
    }
  };

  useEffect(() => {
    const allDigitsEntered = otp.every(digit => digit !== "");
    if (allDigitsEntered) {
      getTokenAndHandleLogin();
    }
  }, [otp]);

  const getTokenAndHandleLogin = async () => {
    setLoading(true);
    const enteredOtp = otp.join("");
    try {
      const response = await ApiServices.post(
        "/apis/default/api/verifyotpsent",
        {
          username: email,
          otp: enteredOtp,
        },
        {
          "Content-Type": "application/json",
        }
      );
      console.log("response", response.data);
      if (response.data.data.status) {
        swal({
          title: "OTP Verified Successfully",
          icon: "success",
          button: "OK",
          timer: 2000,
        });
        setLoading(false);
        navigate("/patient-dashboard");
      } else {
        setLoading(false);
        swal({
          title: "Login Failed",
          text: "Your one time password is wrong, incorrect, or used",
          icon: "error",
          button: "OK",
          timer: 2000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      swal({
        title: "Login Failed",
        text: "Your one time password is wrong, incorrect, or used",
        icon: "error",
        button: "OK",
        timer: 2000,
      });
    }
  };

  const cardStyle = {
    margin: "auto",
    height: "530px",
    width: "500px",
    background: "#6665DD",
    padding: "20px",
    borderRadius: "50px",
  };

  return (
    <div>
      {loading && <Loader />}
      <Container style={{ display: "flex", justifyContent: "center", height: "100vh" }}>
        <Card style={cardStyle}>
          <Card.Body>
            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "20px" }}>
              <img src={DigiLogo} alt="" style={{ height: "90px" }} />
            </div>

            <p style={{ color: "white", textAlign: "center" }}>
              To access your portal, please check your mailbox for the One-Time
              Password (OTP) we've sent you.
              <br /> Enter the OTP below to proceed with the login process.
            </p>

            <form style={{ marginTop: "30px" }}>
              <div className="login-form-flex-div">
                <img src={EmailLogo} alt="" className="login-form-input-logo" />
                <input
                  type="text"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="login-form-input-field"
                  readOnly
                />
              </div>

              <p style={{ color: "white", textAlign: "center" }}>
                Enter the OTP below to proceed with the login process.
              </p>
              <div className="login-form-flex-div" style={{ border: "none" }} onPaste={handlePaste}>
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    min="0"
                    max="9"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!isNaN(value) && value >= 0 && value <= 9) {
                        handleOtpChange(index, value);
                      }
                    }}
                    onKeyDown={(e) => handleBackspace(index, e)}
                    className="login-form-input-field"
                    ref={(el) => (otpInputRefs.current[index] = el)}
                    style={{ border: "1px solid white", textAlign: "center" }}
                  />
                ))}
              </div>

              <div>
                <button
                  type="button"
                  onClick={getTokenAndHandleLogin}
                  className="login-form-login-button"
                >
                  Verify OTP
                </button>
              </div>
            </form>
            <div></div>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default OtpVerification;
