import React, { useEffect, useState } from "react";
import {  Col, Container, Dropdown, Row } from "react-bootstrap";
import ServicesCards from "./ServicesCards";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";

const ServiceFilters = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  useEffect(() => {
    async function fetchCategories() {
      try {
        const response = await ApiServices.get(
          "/api/treatments/"
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    }

    fetchCategories();
  }, []);

  const [servicesData, setServicesData] = useState([]);
  useEffect(() => {
    ApiServices.get("/api/services/").then((response) => {
      setServicesData(response.data);
    });
  }, []);
  return (
    <div>
      <div>
        <h1 class="explore-treartment-tag">
          Everything to meet your beauty needs
        </h1>
        <h1 class="section-one-head-tag" style={{ textAlign: "center" }}>
          Select Your Service
        </h1>
      </div>
      <Container>
        <Row style={{ paddingTop: "80px" }}>
          <Col style={{textAlign:"left"}}>
            <Dropdown>
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
                className="custom-dropdown-button"
              >
                {selectedCategory || "Select Category"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {categories.map((category) => (
                  <Dropdown.Item
                    key={category.id}
                    onClick={() => setSelectedCategory(category.name)}
                  >
                    {category.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col className="d-flex justify-content-end">
            <p
              className="clear-filters-text"
              onClick={() => setSelectedCategory("")}
            >
              Clear Filters
            </p>
          </Col>
        </Row>
      </Container>
      <div style={{paddingTop:"40px"}}>
        <ServicesCards data={servicesData} selectedCategory={selectedCategory}/>
      </div>
    </div>
  );
};

export default ServiceFilters;
