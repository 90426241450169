import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import axios from "axios";
import { AiOutlineSend } from "react-icons/ai";
import { ThreeDots } from "react-loader-spinner";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
const MedSpaTextUs = () => {
  const [userInput, setUserInput] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [isBotTyping, setIsBotTyping] = useState(false);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = async () => {
    if (userInput.trim() === "") return;

    // Show user's message instantly
    const newUserInputMessage = { text: userInput, sender: "user" };
    setChatHistory([...chatHistory, newUserInputMessage]);

    // Clear the input
    setUserInput("");

    // Show "Bot is Typing..."
    setIsBotTyping(true);

    try {
      const response = await ApiServices.post(
        `/api/answer/?q=${userInput}`
      );

      // Simulate a 2-second delay before showing the bot's response
      setTimeout(() => {
        setIsBotTyping(false); // Hide "Bot is Typing..."

        // Show the bot's response along with the user's message
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          { text: response.data.answer, sender: "bot" },
        ]);
      }, 2000);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    // Send a welcome message from the bot when the component mounts
    setChatHistory([
      { text: "Hello user, how can I help you?", sender: "bot" },
    ]);
  }, []);

  return (
    <div>
      <div className="chat-history">
        {chatHistory.map((message, index) => (
          <div
            key={index}
            className={`chat-message ${
              message.sender === "user" ? "user-message" : "bot-message"
            }`}
          >
            {message.text}
          </div>
        ))}
        {/* {isBotTyping && <div className="bot-message">Typing...</div>} */}
        
        {isBotTyping && (
          <div className="bot-message-for-pending">
            <ThreeDots
              height="20"
              width="80"
              radius="9"
              color="#000000b0"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        )}
      </div>
      <Row className="chat-boat-text-us-input-row">
        <input
          type="text"
          className="chat-boat-text-us-input-row-input"
          placeholder="Post your question here..."
          value={userInput}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
        />
        <AiOutlineSend onClick={handleSubmit} className="send-query-button" />
      </Row>
    </div>
  );
};

export default MedSpaTextUs;
