import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BsFacebook,BsInstagram,BsTwitter } from 'react-icons/bs';
import BrandLogo from "../../Assets/cosmos-png.png"
import { Link } from 'react-router-dom';
const MedSpaFooter = () => {
  return (
    <div>
    <div className='med-spa-sec-three-bg'>
        <Container style={{paddingTop:"80px",paddingBottom:"30px", textAlign:"left"}}>
            <Row>
                <Col sm={4}>
                <img src={BrandLogo} alt='' className='brand-logo-style'/>
                    {/* <h1>Cosmos</h1>
                    <p>med spa</p> */}
                    <div style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
  <a href="https://www.facebook.com/medispacosmos" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
    <BsFacebook style={{ cursor: "pointer" }} />
  </a>
  <a href="https://www.instagram.com/medispacosmos" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
    <BsInstagram style={{ cursor: "pointer" }} />
  </a>
  <a href="https://twitter.com/medispacosmos" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
    <BsTwitter style={{ cursor: "pointer" }} />
  </a>
</div>

                    
                </Col>
                <Col sm={2}>
                    <h1 className='med-spa-footer-header-tag'>CONNECT</h1>
                    <Link to="/signup">
                    <p className='med-spa-footer-para-tag'>Schedule Consultation</p>
                    </Link>
                    <p className='med-spa-footer-para-tag'>Own a Cosmos</p>
                    <Link to="/providerlogin" target="_blank">
                    <p className='med-spa-footer-para-tag'>Provider Login</p>
                    </Link>
                </Col>
                <Col sm={2}>
                    <h1 className='med-spa-footer-header-tag'>BROWSE</h1>
                    <Link to="/services">
                    <p className='med-spa-footer-para-tag'>Services</p>
                    </Link>
                    <Link to="/signup">
                    <p className='med-spa-footer-para-tag'>Memberships</p>
                    </Link>
                    <Link to="/articles">
                    <p className='med-spa-footer-para-tag'>Articles</p>
                    </Link>
                </Col>
                <Col sm={2}>
                    <h1 className='med-spa-footer-header-tag'>VISIT</h1>
                    <p className='med-spa-footer-para-tag'>Locations</p>
                    <Link to="/about">
                    <p className='med-spa-footer-para-tag'>About Cosmos</p>
                    </Link>
                </Col>
                <Col sm={2} style={{textAlign:"left"}}>
                    <h1 className='med-spa-footer-header-tag'>CONTACT</h1>
                    {/* <p className='med-spa-footer-para-tag'>4001002</p>
                    <p className='med-spa-footer-para-tag'>info@medispacosmos.com</p> */}

                    <a className='med-spa-footer-para-tag' href='tel:8128030079'>8128030079</a>
                    <br/>
    <a className='med-spa-footer-para-tag' href='mailto:info@medispacosmos.com'>info@medispacosmos.com</a>
                </Col>
            </Row>
        </Container>
        <div className='med-spa-second-footer'>
            <Container  style={{paddingTop:"30px",paddingBottom:"10px",textAlign:"left"}}>
                <Row>
                    <Col sm={2}>
                        <Link to="/privacy-policy" style={{textDecoration:"none", cursor:"none"}}>
                        <p className='med-spa-footer-para-tag'>Privacy Policy</p>
                        </Link>
                        <Link to="/admin" style={{textDecoration:"none", cursor:"none"}}>
                        <p style={{color:"rgb(6 10 14)", textDecoration:"none"}} className='med-spa-footer-para-tag'>admin</p>
                        </Link>
                    </Col>
                    <Col sm={1}>
                    <p className='med-spa-footer-para-tag'>Terms</p>
                    </Col>
                    <Col sm={3}>
                    <p className='med-spa-footer-para-tag'>Appointment Cancellation Policy</p>
                    </Col>
                    <Col sm={2}>
                    <p className='med-spa-footer-para-tag'>Personal Data Request</p>
                    </Col>
                    <Col sm={4} style={{textAlign:"end"}}>
                    <p className='med-spa-footer-para-tag'>© 2023 Cosmos Franchise Group, LLC. </p>
                    <p className='med-spa-footer-para-tag'>All rights Reserved. Site by RiteClinic</p>
                    </Col>
                </Row>
            </Container>
        </div>
    </div>
    </div>
  )
}

export default MedSpaFooter