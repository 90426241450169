import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AES, enc } from "crypto-js";
import AccessToken from "../../GlobalFiles/ApiServices/AccessToken";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import NewLoginLoader from "../Loader/NewLoginLoader";

const NewRegisterPatientLogin = () => {
  const paramsData = useParams();

  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const decodedEndodedEmail = atob(paramsData.endodedEmail);
    const decodedPassword = atob(paramsData.endodedPassword);

    const newusername = AES.decrypt(
      decodedEndodedEmail,
      process.env.REACT_APP_USER_ENC_DEC_KEY
    ).toString(enc.Utf8);
    setEmail(newusername);
    const newpassword = AES.decrypt(
      decodedPassword,
      process.env.REACT_APP_USER_ENC_DEC_KEY
    ).toString(enc.Utf8);
    setPassword(newpassword);
  }, [paramsData]);

  useEffect(() => {
    if (email && password) {
      login();
    }
  }, [email, password]);

  const login = async () => {
    try {
      const tokenResponse = await ApiServices.post(
        "/oauth2/default/token",
        {
          client_id: "G7H1yJbmR_JzoZV3Xw0wV8LO58pL2hb-YV6KFv-hl94",
          grant_type: "password",
          scope: "openid offline_access api:oemr api:port patient/patient.read",
          user_role: "patient",
          username: email,
          password: password,
          email: email,
        },
        {
          "Content-Type": "application/x-www-form-urlencoded",
        }
      );

      const accessToken = tokenResponse.data.access_token;
      sessionStorage.setItem("Patient_Access_Token", accessToken);

      const encryptedEmail = AES.encrypt(
        email,
        process.env.REACT_APP_USER_ENC_DEC_KEY
      ).toString();
      const encryptedPassword = AES.encrypt(
        password,
        process.env.REACT_APP_USER_ENC_DEC_KEY
      ).toString();
      sessionStorage.setItem("encryptedEmail", encryptedEmail);
      sessionStorage.setItem("encryptedPassword", encryptedPassword);
      sessionStorage.setItem("LoginUserType", "Patient");
      const adminaccessToken = await AccessToken.fetchAccessToken();
      sessionStorage.setItem("accessToken", adminaccessToken);
      setTimeout(() => {
        setLoading(false);
        navigate(`/patient-dashboard/`);
      }, 5000);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return <div>{loading && <NewLoginLoader />}</div>;
};

export default NewRegisterPatientLogin;
