import React from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { Fade } from "react-reveal";
const MedSpaFianancialPolicyPage = () => {
  return (
    <div>
      <div style={{ backgroundColor: "rgb(249, 250, 251)" }}>
        <div style={{ paddingTop: "50px", paddingBottom: "50px" }}>
          <Container>
            <Fade bottom>
              <h3 className="mt-4" style={{ textAlign: "left" }}>
                COSMOS MEDISPA FINANCIAL DISCLOSURE
              </h3>
              <Row>
                <Col>
                  <Card style={{ height: "100%" }}>
                    <Card.Body>
                      {/* <p style={{ fontWeight: "700" }}>{item.subtitle}</p> */}
                      <p>
                        As patients contemplate aesthetic medical spa
                        treatments, they frequently need information about the
                        financial aspects of their treatment and the various
                        payment methods available to them. Our staff members are
                        specially trained in the financial options available to
                        our patients, and they are readily available to assist
                        you with these issues in any way that you may require.
                        Because we provide elective cosmetic procedures, the
                        care provided at COSMOS MEDISPA is not covered by any
                        medical insurance programs, and we do not participate in
                        any such plans.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Card style={{ height: "100%" }}>
                    <Card.Body>
                      <p style={{ fontWeight: "700" }}>PAYMENT OPTIONS</p>
                      <p>
                        Payment for all medical spa procedures is due at the
                        time of the treatment. For specially packaged or grouped
                        treatments, payment for the entire package is due at the
                        time of the first scheduled treatment.{" "}
                        <b>
                          A credit card is required to reserve an appointment
                          for treatment scheduled in advance.
                        </b>{" "}
                        We provide several payment options which may be used
                        individually or combined according to your desires:
                      </p>{" "}
                      <ul>
                        <li>
                          <b>CASH : </b>Cash
                        </li>
                        <li>
                          <b>MAJOR CREDIT CARDS : </b>VISA, MasterCard, and
                          American Express
                        </li>
                        <li>
                          <b>CARE CREDIT : </b>Our staff can assist you in
                          obtaining a special line of credit through Care Credit
                          for financing medical procedures if you wish.
                        </li>
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Card style={{ height: "100%" }}>
                    <Card.Body>
                      <p style={{ fontWeight: "700" }}>
                        CANCELLATION AND REFUNDS
                      </p>
                      <p>
                        We understand that a situation may arise that could
                        force you to cancel or postpone your treatment. Please
                        understand that such changes affect not only our staff
                        but our other patients as well, and we therefore request
                        your courtesy and concern. If you need to cancel your
                        appointment, please allow 24 hours to notify us of the
                        cancellation. Should we receive less than 24 hours of
                        notification, or should you fail to keep your
                        appointment, your credit card will be charged $50 for
                        the visit. Unopened products will be accepted within 14
                        days with receipt for unopened items and we will issue
                        an immediate credit to the original payment method.
                        Opened products will be accepted within 14 days with a
                        receipt and we will issue a store credit for the amount
                        of the purchase.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Card style={{ height: "100%" }}>
                    <Card.Body>
                      <p style={{ fontWeight: "700" }}>
                      THERE CAN BE NO REFUNDS FOR SERVICES ALREADY PROVIDED. 

                      </p>
                      <p>
                      In the event that a package or series of treatments has begun, these services will be considered to have been rendered even though the full series may not have been completed. Should you wish to discontinue your treatment in the midst of a series, credit for the pro rata share of unused treatments at the discounted package price may be extended and may be used to purchase other treatments or products offered by COSMOS MEDISPA, or it may be transferred to another individual to be used in exchange for treatments or products of comparable value to the credit. 

                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Card style={{ height: "100%" }}>
                    <Card.Body>
                      <p style={{ fontWeight: "700" }}>
                      LATE POLICY: 


                      </p>
                      <p>
                      If you arrive at your appointment more than 10 minutes past your scheduled time, you may be asked to reschedule your appointment.

                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Card style={{ height: "100%" }}>
                    <Card.Body>
                      <p style={{ fontWeight: "700" }}>
                      REVISIONAL TREATMENT OR TREATMENT OF COMPLICATIONS
                      </p>
                      <p>
                      The practice of medicine and surgery is not an exact science, and medical spa treatments are the practice of medicine. Although good results are anticipated, there can be no guarantee or warranty, expressed or implied, by anyone as to the actual results you may get. Occasionally additional treatments and/or treatment for problems or complications may be required. These could result in additional charges for which you may be responsible. Your insurance, if you have it, may or may not cover the expenses related to actual complications or other medically related problems arising out of treatment at Cosmos Medispa; I acknowledge that I have read and fully understand the foregoing Financial Policies and my obligations related thereto,
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Card style={{ height: "100%" }}>
                    <Card.Body>
                     
                      <p>
                      These Financial Policies are subject to change without notice. If you have any questions or need assistance with any financial matters relating to your treatment, please contact our office for help.

                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Fade>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default MedSpaFianancialPolicyPage;
