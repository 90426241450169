import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";

const ArticlesDetailsSectionOne = ({ data }) => {
  const detailsData = data;

  const [tableContent, settableContent] = useState();
  const [contentdetails, setcontentdetails] = useState();

  useEffect(() => {
    if (data) {
      settableContent(detailsData.content);
      setcontentdetails(detailsData.contentdetails);
    }
  }, [data]);
  return (
    <div>
      <Container style={{textAlign:"left"}}>
        <div className="med-spa-article-details-header-flex">
          <p className="med-spa-article-details-header-flex-p">Articles</p>
          <AiOutlineRight />
          <Link>
            <p className="med-spa-article-details-header-flex-title">
              {detailsData && detailsData?.title}
            </p>
          </Link>
        </div>
        <h1
          class="section-one-head-tag"
          style={{
            textAlign: "center",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          {detailsData && detailsData?.title}
        </h1>
        <img
          alt=""
          className="med-spa-articles-main-flex"
          src={detailsData && detailsData?.image}
        />
        <Col></Col>
        <Row style={{ paddingTop: "50px", paddingBottom: "50px" }}>
          <Col sm={2}> </Col>
          <Col sm={8}>
            <Container>
              <Card className="article-table-of-content-card">
                <Card.Title>Table of Contents</Card.Title>
                {tableContent &&
                  tableContent?.map((index) => (
                    <Card.Text>{index.title}</Card.Text>
                  ))}
              </Card>
            </Container>
          </Col>
          <Col sm={2}> </Col>
        </Row>
        <Row
          style={{
            borderBottom: "1px solid rgb(229 231 235)",
            paddingBottom: "50px",
          }}
        >
          <Col sm={2}> </Col>
          <Col sm={8}>
            <Container>
              <p className="med-spa-articles-para">
                {detailsData && detailsData?.subtitle}
              </p>

              {contentdetails &&
                contentdetails?.map((index) => (
                  <div>
                    <h1 className="med-spa-articles-header">{index.title}</h1>
                    <p className="med-spa-articles-para">{index.description}</p>
                  </div>
                ))}
            </Container>
          </Col>
          <Col sm={2}> </Col>
        </Row>
        <div className="interested-boo-appt-div">
          <h1 className="book-appt-head-line">
            Interested? Book an appointment today!
          </h1>
          <Link to="/signup">
            <button class="med-spa-custom-button" style={{ minWidth: "250px" }}>
              Learn More
            </button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default ArticlesDetailsSectionOne;
