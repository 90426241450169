import React, { useState, useEffect } from "react";
import { Button, Form, Container, Row, Col, Alert, Card } from "react-bootstrap";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";


const UserDetailsPage = () => {
  const Patient_Access_Token = sessionStorage.getItem("Patient_Access_Token");
  const [patientData, setPatientData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [errors, setErrors] = useState({});
  const [updatedData, setUpdatedData] = useState({
    fname: "",
    lname: "",
    mname: "",
    DOB: "",
    street: "",
    postal_code: "",
    city: "",
    state: "",
    phone_contact: "",
    sex: "",
    email: "",
  });
  const accessToken = sessionStorage.getItem("accessToken");


  useEffect(() => {
    const fetchData = async () => {
      try {
        const patientResponse = await ApiServices.get(
          "/apis/default/portal/patient",
          {
        
              Authorization: `Bearer ${Patient_Access_Token}`,
            
          }
        );
        const data = patientResponse.data.data;
        sessionStorage.setItem("LoginPatientID", data.pid);
        sessionStorage.setItem("LoginPatientUUID", data.uuid);
        setPatientData(data);
        setUpdatedData(data); // Initialize the edit form with the fetched data
      } catch (error) {
        console.error("Error fetching patient data:", error);
      }
    };

    fetchData();
  }, [Patient_Access_Token]);

  const handleEditToggle = () => {
    setIsEditMode(!isEditMode);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData({ ...updatedData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    // Required field checks
    if (!updatedData.fname) newErrors.fname = "First Name is required";
    if (!updatedData.lname) newErrors.lname = "Last Name is required";
    if (!updatedData.DOB) newErrors.DOB = "Date of Birth is required";
    if (!updatedData.street) newErrors.street = "Street is required";
    if (!updatedData.city) newErrors.city = "City is required";
    if (!updatedData.state) newErrors.state = "State is required";
    if (!updatedData.phone_contact) newErrors.phone_contact = "Phone number is required";
    if (!updatedData.email) newErrors.email = "Email is required";

    // Phone validation
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(updatedData.phone_contact)) {
      newErrors.phone_contact = "Phone number must be 10 digits";
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(updatedData.email)) {
      newErrors.email = "Invalid email format";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async () => {
    if (validateForm()) {
      try {
        const response = await ApiServices.put(
          `/apis/default/portal/patient/${patientData.uuid}`,
          updatedData,
          {
           
              Authorization: `Bearer ${Patient_Access_Token}`,
            
          }
        );
        console.log("Data updated successfully", response.data);
        setPatientData(updatedData);
        setIsEditMode(false); // Exit edit mode after successful update
      } catch (error) {
        console.error("Error updating patient data:", error);
      }
    }
  };

  const getInitials = (fname, lname) => {
    if (fname && lname) {
      return `${fname[0]}${lname[0]}`.toUpperCase();
    }
    return "NA";
  };

  if (!patientData) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="mt-4">
      {isEditMode ? (
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group controlId="fname">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="fname"
                  value={updatedData.fname}
                  onChange={handleInputChange}
                  isInvalid={!!errors.fname}
                />
                <Form.Control.Feedback type="invalid">{errors.fname}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="lname">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lname"
                  value={updatedData.lname}
                  onChange={handleInputChange}
                  isInvalid={!!errors.lname}
                />
                <Form.Control.Feedback type="invalid">{errors.lname}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="DOB">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  name="DOB"
                  value={updatedData.DOB}
                  onChange={handleInputChange}
                  isInvalid={!!errors.DOB}
                />
                <Form.Control.Feedback type="invalid">{errors.DOB}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="sex">
                <Form.Label>Sex</Form.Label>
                <Form.Control
                  as="select"
                  name="sex"
                  value={updatedData.sex}
                  onChange={handleInputChange}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="phone_contact">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  name="phone_contact"
                  value={updatedData.phone_contact}
                  onChange={handleInputChange}
                  isInvalid={!!errors.phone_contact}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone_contact}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={updatedData.email}
                  onChange={handleInputChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Form.Group controlId="street">
                <Form.Label>Street</Form.Label>
                <Form.Control
                  type="text"
                  name="street"
                  value={updatedData.street}
                  onChange={handleInputChange}
                  isInvalid={!!errors.street}
                />
                <Form.Control.Feedback type="invalid">{errors.street}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  value={updatedData.city}
                  onChange={handleInputChange}
                  isInvalid={!!errors.city}
                />
                <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="state">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  name="state"
                  value={updatedData.state}
                  onChange={handleInputChange}
                  isInvalid={!!errors.state}
                />
                <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Button className="mt-3" variant="primary" onClick={handleFormSubmit}>
            Submit
          </Button>
          <Button
            className="mt-3 ml-2"
            variant="secondary"
            onClick={handleEditToggle}
          >
            Cancel
          </Button>
        </Form>
      ) : (
        <div>
          {/* <p><strong>First Name:</strong> {patientData.fname}</p>
          <p><strong>Last Name:</strong> {patientData.lname}</p>
          <p><strong>Date of Birth:</strong> {patientData.DOB}</p>
          <p><strong>Sex:</strong> {patientData.sex}</p>
          <p><strong>Phone:</strong> {patientData.phone_contact}</p>
          <p><strong>Email:</strong> {patientData.email}</p>
          <p><strong>Street:</strong> {patientData.street}</p>
          <p><strong>City:</strong> {patientData.city}</p>
          <p><strong>State:</strong> {patientData.state}</p>
          <Button variant="primary" onClick={handleEditToggle}>
            Edit
          </Button> */}
          <div className="profile-container">
      <Card className="profile-card shadow-sm p-4">
        {/* Profile Header */}
        <div className="profile-header text-center mb-4">
          <div className="profile-icon mb-3">
            <div className="profile-initials">
              {getInitials(patientData.fname, patientData.lname)}
            </div>
          </div>
          <h4>{patientData.fname} {patientData.lname}</h4>
          <p className="text-muted">{patientData.email}</p>
        </div>

        {/* Profile Details */}
        <Row>
          <Col md={6}>
            <p><strong>First Name:</strong> {patientData.fname}</p>
            <p><strong>Date of Birth:</strong> {patientData.DOB}</p>
            <p><strong>Sex:</strong> {patientData.sex}</p>
          </Col>
          <Col md={6}>
            <p><strong>Last Name:</strong> {patientData.lname}</p>
            <p><strong>Phone:</strong> {patientData.phone_contact}</p>
            <p><strong>Email:</strong> {patientData.email}</p>
          </Col>
        </Row>

        {/* Address Details */}
        <Row className="mt-4">
          <Col md={12}>
            <p><strong>Street:</strong> {patientData.street}</p>
            <p><strong>City:</strong> {patientData.city}</p>
            <p><strong>State:</strong> {patientData.state}</p>
          </Col>
        </Row>

        {/* Edit Button */}
        <div className="text-center mt-4">
          <Button variant="primary" onClick={handleEditToggle} className="edit-btn">
            Edit
          </Button>
        </div>
      </Card>
    </div>
        </div>
      )}
    </Container>
  );
};

export default UserDetailsPage;
