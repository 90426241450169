import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";

import AccessToken from "../../GlobalFiles/ApiServices/AccessToken";
import PatientAccessToken from "../../GlobalFiles/ApiServices/PatientAccessToken";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const PatientAllAppointments = () => {
  const [AppointmentData, setAppointmentData] = useState([]);
  const accessToken = sessionStorage.getItem("accessToken");
  const LoginUserType = sessionStorage.getItem("LoginUserType");

  useEffect(() => {
    const fetchData = async () => {
      const PatientData = await PatientAccessToken.fetchData()

      let endpoint = `/apis/default/api/patient/${PatientData.pid}/appointment`;

      if (LoginUserType == "Patient") {
        endpoint = `/apis/default/portal/patient/${PatientData.pid}/appointment`;
      }
      try {
        ;

        const appointmentDataResponse = await ApiServices.get(
          endpoint,
          // `/apis/default/api/appointment/${PatientData.pid}`,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );
        setAppointmentData(appointmentDataResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const navigate = useNavigate();
  const handleShowBillClick = (params) => {
    const patientUuid = params.puuid;
    const patienteid = params.pc_eid;
    const patientepid = params.pc_pid;
    navigate(
      `/patient-dashboard/show-bill/${patientUuid}/${patienteid}/${patientepid}`
    );
  };

  const columnDefs = [
    {
      headerName: "Appointment Date",
      field: "pc_eventDate",
      headerClass: "custom-header-of-tables",
      flex: 1,
      minWidth: 150,
      // maxWidth: "220",
      
    },
    {
      headerName: "Appointment Time",
      field: "pc_startTime",
      headerClass: "custom-header-of-tables",
      // maxWidth: "240",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Reason for Appointment",
      field: "appointment_type",
      headerClass: "custom-header-of-tables",
      // maxWidth: "240",
      flex: 1,
      minWidth: 150,
    },
    

    {
      headerName: "Appointment Status",
      field: "pc_apptstatus",
      headerClass: "custom-header-of-tables",
      flex: 1,
      minWidth: 150,
      cellRenderer: (params) => {
        const status = params.data.pc_apptstatus.trim();
        let buttonColor = "";
        let buttonText = "";
        let textCololor = "";
    
        switch (status) {
          case "^":
            buttonColor = "yellow";
            buttonText = "Pending";
            textCololor = "black";
            break;
          case "APPR":
            buttonColor = "green";
            buttonText = "Approved";
            textCololor = "white";
            break;
          case "REJ":
            buttonColor = "red";
            buttonText = "Rejected";
            textCololor = "white";
            break;
          default:
            buttonColor = "gray";
            buttonText = "Unknown";
            textCololor = "white";
        }
    
        const currentDate = new Date().toISOString().split("T")[0];
        
        const appointmentDate = params.data.pc_eventDate;
        
    

    
        return (
          <div>
          
          <div>
            <div
              style={{ backgroundColor: buttonColor, color: textCololor }}
              
            >
              {buttonText}
            </div>
            
          </div>
          
          </div>
        );
      },
    }
    ,
    {
      headerName: "Show Bill",
      field: "show_bill",
      headerClass: "custom-header-of-tables",
      // maxWidth: "240",
      flex: 1,
      minWidth: 150,
      cellRenderer: (params) => {
        const { data } = params;
        return (
          <Button
            variant="info"
            size="sm"
            onClick={() => handleShowBillClick(data)}
            disabled={params.data.verified_receptionist==0}
          >
            Show Bill
          </Button>
        );
      },
    }
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };



  return (
    <div>
      <div style={{ display: "flex", gap: "10px", alignItems: "center", }}>
        <input
          type="text"
          placeholder="All Appointments..."
          className="form-control mb-2"
        />
      </div>
      <div
        className="ag-theme-alpine"
        style={{ width: "100%", overflow: "hidden", height: "430px" }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={AppointmentData}
          defaultColDef={defaultColDef}
        />
      </div>
    </div>
  );
};

export default PatientAllAppointments;
