import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import PatientGuestRegistration from "../../../Components/PatientRegistration/PatientGuestRegistration/PatientGuestRegistration";
import { Fade } from "react-reveal";
import Loader from "../../../Components/Loader/Loader";
import { useState } from "react";
import PatientLogin from "../../../Components/PatientLogin/PatientLogin";

const AppointmentSecionOne = () => {
  const [loading, setLoading] = useState(false);
  const [registerLogin, setRegisterLogin] = useState(false)

  const handleRegisterLogin =() => {
    setRegisterLogin(true)
  }

  const handleLoadingChange = (newLoading) => {
    setLoading(newLoading);
  }
  return (
    <div>
      {loading && <Loader />}
      <Container style={{ textAlign: "left" }}>
        <Fade bottom>
          <div style={{ paddingTop: "20px" }}>

            <h1 class="explore-treartment-sub-title-head-tag">
              Welcome to our hassle-free 'Appointment' Page <br />
              Book Your Bliss here!
            </h1>
          </div>
        </Fade>
        <div style={{ paddingTop: "20px", paddingBottom: "40px" }}>
          <Fade bottom>
            <Row>
              <Col sm={6} style={{ marginBottom: "20px" }}>
                {registerLogin ?

                  <Fade bottom>
                  <Card style={{ height: "100%" }}>
                    <Card.Body>
                      <PatientLogin onLoadingChange={handleLoadingChange}/>
                    </Card.Body>
                  </Card>
                  </Fade>

                  :
                  <Card style={{ height: "100%" }}>
                    <Card.Body>
                      <h1
                        class="wel-come-staff-info"
                        style={{ textAlign: "left", fontSize: "2.2rem" }}
                      >
                        Already Registered Patients
                      </h1>
                      <p
                        className="med-spa-staff-info-p-tag"
                        style={{ margin: "0px", paddingBottom: "10px" }}
                      >
                        Unlock the complete Cosmos experience — log in to your Medi Spa portal now.

                      </p>
                    </Card.Body>
                    <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", display: "flex", justifyContent: "space-between" }}>
                      <Link
                        to="/patient-login"
                       
                      >
                        <button class="med-spa-custom-button">Login</button>
                      </Link>
                      {/* <button class="med-spa-custom-button" onClick={handleRegisterLogin}>Click here to Book Appointment</button> */}

                    </div>
                  </Card>
                }
              </Col>

              <Col sm={6} style={{ marginBottom: "20px" }}>
                <Card style={{ height: "100%" }}>
                  <Card.Body>
                    <h1
                      class="wel-come-staff-info"
                      style={{ textAlign: "left", fontSize: "2.2rem" }}
                    >
                      New Patients Register here ..!
                    </h1>
                    <p
                      className="med-spa-staff-info-p-tag"
                      style={{ margin: "0px", paddingBottom: "10px" }}
                    >
                      New to Cosmos Medi Spa? <br />
                      Welcome! To get started, we invite you to register with us.
                      Creating an account is easy and allows you to save your
                      preferences for future appointments.
                    </p>
                  </Card.Body>
                  <div style={{ paddingLeft: "20px", paddingBottom: "20px" }}>
                    <Link to="/signup">
                      <button class="med-spa-custom-button">Register</button>
                    </Link>
                  </div>
                </Card>
              </Col>
            </Row>
          </Fade>
          <Fade bottom>
            {/* <Card style={{marginTop:"10px"}}>
            <Card.Body> */}

            {/* <PatientGuestRegistration onLoadingChange={handleLoadingChange} /> */}
            {/* </Card.Body>
          </Card> */}
          </Fade>
        </div>
      </Container>
    </div>
  );
};

export default AppointmentSecionOne;
