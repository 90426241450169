// FetchServiceBill.js

import ApiServices from "./ApiServices";

class FetchServiceBill {
  async fetchData({ eid, pid }) {
    try {
      const LoginUserType = sessionStorage.getItem("LoginUserType");
      const accessToken = sessionStorage.getItem("accessToken");
      let endpoint = `/apis/default/api/patient/${pid}/billingwebGet/${eid}`;

      if (LoginUserType == "Patient") {
        endpoint = `/apis/default/portal/patient/${pid}/billingwebGet/${eid}`;
      }

      const billingResponse = await ApiServices.get(
        // `/apis/default/api/patient/${pid}/billingwebGet/${eid}`
        endpoint
        ,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      const billingData = billingResponse.data;

      return { billingData };
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}

const fetchServiceBill = new FetchServiceBill();
export default fetchServiceBill;
