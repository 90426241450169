import React from "react";
import "./MedSpaAppointmentPage.css";
import AppointmentSecionOne from "./Section/AppointmentSecionOne";
import AppointmentFaq from "./Section/AppointmentFaq";
import MedSpaFeedback from "../../Components/MedSpaFeedback/MedSpaFeedback";
import { Fade } from "react-reveal";
import WorkingHours from "../../Components/WorkingHours/WorkingHours";

const MedSpaAppointmentPage = () => {
  return (
    <div>
      <div
        style={{ paddingTop: "30px", backgroundColor: "rgb(249, 250, 251)" }}
      >
        <AppointmentSecionOne />
      </div>
      
      <div >
        <Fade bottom>
          <AppointmentFaq />
        </Fade>
      </div>
      <div style={{ paddingTop: "30px", backgroundColor: "rgb(249, 250, 251)",paddingBottom:"30px" }}>
        <Fade bottom>
          <WorkingHours />
        </Fade>
      </div>
        {/* <div
          style={{ paddingTop: "80px", backgroundColor: "rgb(249, 250, 251)" }}
        >
          <Fade bottom>
            <MedSpaFeedback />
          </Fade>
        </div> */}
    </div>
  );
};

export default MedSpaAppointmentPage;
