import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Row, Form, Card, Col } from "react-bootstrap";
import DigiLogo from "../../Assets/cosmosLogocolor.png";
import PaymentGateway from "../CardPayments/PaymentGateway";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import swal from "sweetalert";

const ProductBillQrPayment = () => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PROMISE
  );
  const paramsData = useParams();
  const [tipOption, setTipOption] = useState(null);
  const [customTipAmount, setCustomTipAmount] = useState("");
  const [tipAmount, setTipAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState(paramsData.finalAmount);

  const handleTipOptionChange = (event) => {
    setTipOption(event.target.value);
  };

  const handleCustomTipAmountChange = (event) => {
    setCustomTipAmount(event.target.value);
  };

  const handleApplyTip = () => {
    let newTipAmount = 0;

    if (tipOption === "custom") {
      newTipAmount = parseFloat(customTipAmount).toFixed(2);
    } else if (tipOption !== "") {
      newTipAmount = (
        (parseFloat(tipOption) / 100) *
        paramsData.finalAmount
      ).toFixed(2);
    }

    const newFinalAmount = (
      parseFloat(paramsData.finalAmount) + parseFloat(newTipAmount)
    ).toFixed(2);

    setTipAmount(newTipAmount);
    setFinalAmount(newFinalAmount);
  };

  const handleRemoveTip = () => {
    setTipOption("");
    setCustomTipAmount("");
    setTipAmount(0);
    setFinalAmount(paramsData.finalAmount);
  };
  const cardStyle = {
    margin: "auto",

    width: "100%",
    background: "rgb(241, 245, 248)",

    color: "black",
    minHeight: "100vh",
  };

  const { fname, lname, email } = useParams();
  const patientData = { fname, lname, email };

  const [paymentDashboard, setpaymentDashboard] = useState(false);
  const handlepaymentDashboardOn = () => {
    setpaymentDashboard(true);
    // window.open(finalURL, "_blank");
  };
  const handlepaymentDashboardOff = () => {
    setpaymentDashboard(false);
  };

  const handlepaymentsuccess = () => {
    swal({
      title: "Payment Successful",
      icon: "success",
      button: "OK",
      timer: 2000,
    }).then(() => {
      const finalURL = "https://medispacosmos.com/";
      window.location.href = finalURL;
    });
  };
  
  const { productBillData } = useParams();
  const decodedProductBillData = atob(productBillData);
  const parsedProductBillData = JSON.parse(decodedProductBillData);
  // console.log("parsedProductBillData", parsedProductBillData);
  const ProductcolumnDefs = [
    {
      headerName: "Title",
      field: "product_title",
      headerClass: "custom-header-of-tables",
      minWidth: 150,
      flex: 1,
    },

    {
      headerName: "Quantity",
      field: "product_quantity",
      headerClass: "custom-header-of-tables",
      minWidth: 100,
      flex: 1,
    },
    {
      headerName: "Price",
      field: "product_default_price",
      headerClass: "custom-header-of-tables",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Discount in %",
      field: "product_discount",
      headerClass: "custom-header-of-tables",
      minWidth: 180,
      flex: 1,
    },
    {
      headerName: "Final Amount",
      field: "product_total_amount",
      headerClass: "custom-header-of-tables",
      minWidth: 240,
      flex: 1,
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };
  return (
    <div>
      <img src={DigiLogo} alt="" style={{ height: "80px", width: "200px" }} />
      <Card style={cardStyle} className="mt-3">
        <Card.Body>
          {paymentDashboard && (
            <div>
              <Button variant="info" onClick={handlepaymentDashboardOff}>
                Go Back
              </Button>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              //   height: "70vh",
            }}
          >
            {paymentDashboard == false ? (
              <div>


                <div>
                  {parsedProductBillData&&parsedProductBillData.map((index)=>(
                    <div>
                    <p style={{marginBottom:"0px",fontWeight:"700",marginTop:"5px"}}>{index.product_title}</p>
                    <table className="qr-billing-table" >
                    <thead>
                      <tr>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Final Amount</th>
                      </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{index.product_default_price}</td>
                          <td>{index.product_quantity}</td>
                          <td>{index.product_total_amount}</td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                  ))}
                </div>

                <Card className="mt-5">
                  <Card.Body>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      <i>
                        If our service left you glowing with satisfaction,
                        consider adding a little sparkle with a gratuity. Your
                        support fuels our passion for excellence. <br /> Thank
                        you for shining with us!
                      </i>
                    </p>
                    <div>
                      <Form.Group controlId="tipOption">
                        <Row>
                          <Col sm={3}>
                            <Form.Check
                              inline
                              label={`5% (${paramsData.finalAmount * 0.05})`}
                              type="radio"
                              value="5"
                              checked={tipOption === "5"}
                              onChange={handleTipOptionChange}
                            />
                          </Col>
                          <Col sm={3}>
                            <Form.Check
                              inline
                              label={`10% (${paramsData.finalAmount * 0.1})`}
                              type="radio"
                              value="10"
                              checked={tipOption === "10"}
                              onChange={handleTipOptionChange}
                            />
                          </Col>
                          <Col sm={3}>
                            <Form.Check
                              inline
                              label={`15% (${paramsData.finalAmount * 0.15})`}
                              type="radio"
                              value="15"
                              checked={tipOption === "15"}
                              onChange={handleTipOptionChange}
                            />
                          </Col>
                          <Col sm={3}>
                            <Form.Check
                              inline
                              label="Custom"
                              type="radio"
                              value="custom"
                              checked={tipOption === "custom"}
                              onChange={handleTipOptionChange}
                            />
                          </Col>
                        </Row>
                        <div
                          className="mb-3 mt-3"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {tipOption === "custom" && (
                            <Form.Control
                              type="number"
                              placeholder="Enter custom tip amount"
                              value={customTipAmount}
                              onChange={handleCustomTipAmountChange}
                              style={{ maxWidth: "300px" }}
                            />
                          )}
                        </div>
                      </Form.Group>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                        }}
                      >
                        <Button
                          variant="danger"
                          onClick={handleRemoveTip}
                          disabled={tipAmount == 0}
                        >
                          Remove Tip
                        </Button>
                        <Button
                          className="mr-2"
                          onClick={handleApplyTip}
                          disabled={tipOption == null}
                        >
                          Apply Tip
                        </Button>
                      </div>

                      <div className="mt-3">Total Tip: {tipAmount}</div>
                      <div>Final Amount: {finalAmount}</div>
                    </div>
                    <div style={{ textAlign: "center" }} className="mt-3">
                      <Button
                        variant="success"
                        onClick={handlepaymentDashboardOn}
                      >
                        Proceed to Pay ${finalAmount}
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ) : (
              <div style={{ width: "100%" }} className="mt-5">
                <div
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    padding: "20px",
                    borderRadius: "20px",
                  }}
                >
                  <Elements stripe={stripePromise}>
                    <PaymentGateway
                      patientData={patientData}
                      amount={finalAmount}
                      onSuccess={handlepaymentsuccess}
                    />
                  </Elements>
                </div>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProductBillQrPayment;
