import React, { useState } from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import axios from 'axios';
import ApiServices from '../../GlobalFiles/ApiServices/ApiServices';

const MedSpaMailUs = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [messageSent, setMessageSent] = useState(false);

  const handleSendEmail = async () => {
    // Perform a POST request to your API using Axios
    try {
      const response = await ApiServices.post('/api/email-queries/', {
        email_id: email,
        query_message: message,
      });
      console.log("response::::",response)
      if (response.data.id) {
        setMessageSent(true);
      } else {
        // Handle error cases here if needed
        console.error('Failed to send email');
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <div>
      <div className="call-now-div-bg">
        <div className="call-now-div-bg-style">
          <AiOutlineMail className="call-now-div-logo" />
        </div>
      </div>
      <div className="give-us-a-call-main-div">
        <h3>Mail Us</h3>
        <p>Post Your Email and Message Below, Our Team Will get back to you soon!</p>
      </div>

      {messageSent ? (
        <div>
          <p style={{ textAlign: 'center', paddingTop: '30px' }}>
            Thank you! Your email has been received. Our team will get back to you soon.
          </p>
        </div>
      ) : (
        <div>
          <div style={{ paddingTop: '20px' }}>
            <input
              type="email"
              placeholder="Enter Your Email"
              className="text-your-query"
              value={email}
              onChange={(e) => setEmail(e.target.value)} // Handle email input changes
            />
            <textarea
              className="text-your-query"
              placeholder="Enter Your Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className="call-now-button-div">
            <button className="call-now-button-div-button" onClick={handleSendEmail}>
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MedSpaMailUs;
