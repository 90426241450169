import React, { useEffect, useState } from "react";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Card, Col, Form, Row, Button, Modal } from "react-bootstrap";
import axios from "axios";
import swal from "sweetalert";
import QRCode from "qrcode.react";
import { useParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import PaymentGateway from "../CardPayments/PaymentGateway";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const GeneratedProductBill = () => {
  const [ProductBillData, setProductBillData] = useState([]);
  const [encodedProductBillDataNew, setencodedProductBillDataNew] = useState(
    []
  );
  const [CustomerBillData, setCustomerBillData] = useState();
  const [finalAmount, setFinalAmount] = useState();
  const [loading, setloading] = useState(false);
  const [fetchBillingDataAgin, setfetchBillingDataAgin] = useState(false);
  const accessToken = sessionStorage.getItem("accessToken");
  const LoginUserType = sessionStorage.getItem("LoginUserType");
  const Patient_Access_Token = sessionStorage.getItem("Patient_Access_Token");
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PROMISE
  );
  const params = useParams();
  useEffect(() => {
    ApiServices.get(`/apis/default/api/productbilling/${params.billid}`, {
      Authorization: `Bearer ${accessToken}`,
    }).then((response) => {
      const ProductmappedData = response.data.map((item) => ({
        product_id: item.product_id,
        product_title: item.product_title,
        product_default_price: item.product_default_price,
        product_discount: item.product_discount,
        product_quantity: item.product_quantity,
        product_total_amount: item.product_total_amount,
      }));
      setProductBillData(ProductmappedData);
      const extractedCustomerBillData = {
        amount_after_tip: response.data[0].amount_after_tip,
        card1_payment: response.data[0].card1_payment,
        card2_payment: response.data[0].card2_payment,
        cash: response.data[0].cash,
        credit_card: response.data[0].credit_card,
        date_of_billing: response.data[0].date_of_billing,
        id: response.data[0].id,
        is_billed: response.data[0].is_billed,
        is_card1_payment: response.data[0].is_card1_payment,
        is_card2_payment: response.data[0].is_card2_payment,
        pid: response.data[0].pid,
        tip: response.data[0].tip,
        total_amount: response.data[0].total_amount,
        total_tax: response.data[0].total_tax,
        verified_receptionist: response.data[0].verified_receptionist,
        fname: response.data[0].fname,
        lname: response.data[0].lname,
        email: response.data[0].email,
        contact: response.data[0].contact,
        wallet_id: response.data[0].wallet_id,
      };
      setCustomerBillData(extractedCustomerBillData);
      setFinalAmount(response.data[0].total_amount);
      const productBillDataString = JSON.stringify(ProductmappedData);

      const encodedProductBillData = btoa(productBillDataString);
      setencodedProductBillDataNew(encodedProductBillData);
    });
  }, [fetchBillingDataAgin]);

  const [walletBalance, setWalletBalance] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `/apis/default/api/wallet/${CustomerBillData.wallet_id}`;

        if (LoginUserType === "Patient") {
          endpoint = `/apis/default/portal/wallet/${CustomerBillData.wallet_id}`;
        }

        const response = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });

        setWalletBalance(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [CustomerBillData]);

  const ProductcolumnDefs = [
    {
      headerName: "Title",
      field: "product_title",
      headerClass: "custom-header-of-tables",
      minWidth: 80,
      flex: 1,
    },

    {
      headerName: "Quantity",
      field: "product_quantity",
      headerClass: "custom-header-of-tables",
      minWidth: 100,
      flex: 1,
    },
    {
      headerName: "Price",
      field: "product_default_price",
      headerClass: "custom-header-of-tables",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Discount in %",
      field: "product_discount",
      headerClass: "custom-header-of-tables",
      minWidth: 180,
      flex: 1,
    },
    {
      headerName: "Final Amount",
      field: "product_total_amount",
      headerClass: "custom-header-of-tables",
      minWidth: 240,
      flex: 1,
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  const [tipOption, setTipOption] = useState(null);
  const [customTipAmount, setCustomTipAmount] = useState("");
  const [tipAmount, setTipAmount] = useState(0);

  const handleTipOptionChange = (event) => {
    setTipOption(event.target.value);
  };

  const handleCustomTipAmountChange = (event) => {
    setCustomTipAmount(event.target.value);
  };

  const handleApplyTip = () => {
    let newTipAmount = 0;

    if (tipOption === "custom") {
      newTipAmount = parseFloat(customTipAmount).toFixed(2);
    } else if (tipOption !== "") {
      newTipAmount = (
        (parseFloat(tipOption) / 100) *
        CustomerBillData.total_amount
      ).toFixed(2);
    }

    const newFinalAmount = (
      parseFloat(CustomerBillData.total_amount) + parseFloat(newTipAmount)
    ).toFixed(2);

    setTipAmount(newTipAmount);
    setFinalAmount(newFinalAmount);
  };

  const handleRemoveTip = () => {
    setTipOption("");
    setCustomTipAmount("");
    setTipAmount(0);
    setFinalAmount(CustomerBillData.total_amount);
  };

  const [paymentMethod, setPaymentMethod] = useState("");
  const [firstCardAmount, setFirstCardAmount] = useState(0);
  const [cashAmount, setCashAmount] = useState(0);

  const handlePaymentMethodSelect = (method) => {
    setPaymentMethod(method);
  };

  const handleDualCardPayment = () => {
    const secondCardAmount = finalAmount - firstCardAmount;
    console.log(`Payment received via dual card`);
    console.log(`First Card Amount: ${firstCardAmount}`);
    console.log(`Second Card Amount: ${secondCardAmount}`);
  };

  const handleCardCashPayment = () => {
    const cardAmount = finalAmount - cashAmount;
    console.log(`Payment received via card + cash`);
    console.log(`Cash Amount: ${cashAmount}`);
    console.log(`Card Amount: ${cardAmount}`);
  };

  const sendPaymentEmail = async () => {
    setloading(true);
    try {
      const response = await axios.post(
        "https://cosmosapi.medispacosmos.com/api/send-payment-email/",
        {
          email: CustomerBillData.email,
          amount: parseFloat(finalAmount),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      swal({
        title: "Payment Link Send Successful",
        text: `Pleasen Check ${CustomerBillData.email} for Payment Link`,
        icon: "success",
        button: "OK",
        timer: 3000,
      });
      setloading(false);
      console.log(JSON.stringify(response.data));
    } catch (error) {
      console.error(error);
      setloading(false);
    }
  };

  const handleQRCodeClick = () => {
    // window.open(paymentPageURL, "_blank");
    const productBillDataString = JSON.stringify(ProductBillData);

    const encodedProductBillData = btoa(productBillDataString);
    const paymentPageURL = `/products-bill-qr/${CustomerBillData.fname}/${CustomerBillData.lname}/${CustomerBillData.email}/${finalAmount}`;

    const finalURL = `${paymentPageURL}/${encodedProductBillData}`;
    window.open(finalURL, "_blank");
  };

  const cashPaymentReceived = async () => {
    setloading(true);
    try {
      const verifyBillingData = {
        tip: tipAmount,
        cash: finalAmount,
        is_billed: 1,
        verified_receptionist: 1,
      };

      const response = await ApiServices.put(
        `/apis/default/api/productbilling/${params.billid}`,
        verifyBillingData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    } catch (error) {
      console.error("Error verifying billing:", error);
    } finally {
      setloading(false);
      setfetchBillingDataAgin(!fetchBillingDataAgin);
    }
  };
  const walletPaymentDeduct = async () => {
    setloading(true);
    try {
      const verifyBillingData = {
        tip: tipAmount,
        cash: finalAmount,
        is_billed: 1,
        verified_receptionist: 1,
      };

      const response = await ApiServices.put(
        `/apis/default/api/productbilling/${params.billid}`,
        verifyBillingData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );

      const walletTransactionData = {
        amount: finalAmount,
        description: `Deducted amount - ${finalAmount} against Product Purchase of Bill ID: ${params.billid}`,
      };

      const walletTransactionResponse = await ApiServices.put(
        `/apis/default/api/walletTransaction/${CustomerBillData.wallet_id}`,
        walletTransactionData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );

      // Check if wallet transaction was successful
      if (walletTransactionResponse.status === 200) {
        console.log("Wallet transaction successful");
      } else {
        console.error("Wallet transaction failed:", walletTransactionResponse);
      }
    } catch (error) {
      console.error("Error verifying billing:", error);
    } finally {
      setloading(false);
      setfetchBillingDataAgin(!fetchBillingDataAgin);
    }
  };

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const handlePaymentModalOpen = () => {
    setShowPaymentModal(true);
  };
  const handlePaymentModalClose = () => {
    setShowPaymentModal(false);
  };
  const handlePaymentModalCloseSuccess = async () => {
    setShowPaymentModal(false);
    try {
      const verifyBillingData = {
        tip: tipAmount,
        credit_card: finalAmount,
        is_billed: 1,
        verified_receptionist: 1,
      };

      const response = await ApiServices.put(
        `/apis/default/api/productbilling/${params.billid}`,
        verifyBillingData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      swal({
        title: "Payment Successful",
        icon: "success",
        button: "OK",
        timer: 3000,
      });
      setfetchBillingDataAgin(!fetchBillingDataAgin);
    } catch (error) {
      // Handle error
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <div className="mb-4">
        <Card>
          <Card.Body>
            <Row>
              <Col sm={4}>
                <b>Name :</b> {CustomerBillData && CustomerBillData.fname}{" "}
                {CustomerBillData && CustomerBillData.lname}
              </Col>
              <Col sm={4}>
                <b>Email :</b> {CustomerBillData && CustomerBillData.email}
              </Col>
              <Col sm={4}>
                <b>Contact :</b> {CustomerBillData && CustomerBillData.contact}
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <b>Date :</b>{" "}
                {CustomerBillData && CustomerBillData.date_of_billing}{" "}
              </Col>
              <Col sm={4}>
                <b>Status :</b>{" "}
                {CustomerBillData && CustomerBillData.is_billed == 0 ? (
                  <span style={{ color: "red" }}>Unpaid</span>
                ) : (
                  <span style={{ color: "green" }}>Paid</span>
                )}
              </Col>
              <Col sm={4}>
                <b>Total Amount :</b> ${" "}
                {CustomerBillData && CustomerBillData.total_amount}{" "}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <div
        className="ag-theme-alpine"
        style={{
          maxHeight: "400px",
          minHeight: "200px",
          minWidth: "100%",
        }}
      >
        <AgGridReact
          columnDefs={ProductcolumnDefs}
          rowData={ProductBillData}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          rowHeight={50}
        />
      </div>

      {CustomerBillData && CustomerBillData.is_billed == 0 && (
        <>
          <div className="mt-3">
            <Card>
              <Card.Body>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    <h5>Select Payment Method:</h5>
                    {walletBalance && (
                      <h5>
                        Wallet Balance: ${" "}
                        {walletBalance && walletBalance?.currentBalance}
                      </h5>
                    )}
                  </div>
                  <Form>
                    <Row>
                      {walletBalance && (
                        <Col sm={2}>
                          <Form.Check
                            type="radio"
                            label="Wallet"
                            name="paymentMethod"
                            onChange={() => handlePaymentMethodSelect("Wallet")}
                          />
                        </Col>
                      )}
                      <Col sm={1}>
                        <Form.Check
                          type="radio"
                          label="Cash"
                          name="paymentMethod"
                          onChange={() => handlePaymentMethodSelect("cash")}
                        />
                      </Col>
                      <Col sm={2}>
                        <Form.Check
                          type="radio"
                          label="Single Card"
                          name="paymentMethod"
                          onChange={() =>
                            handlePaymentMethodSelect("single-card")
                          }
                        />
                      </Col>
                      <Col sm={2}>
                        <Form.Check
                          type="radio"
                          label="Dual Card"
                          name="paymentMethod"
                          onChange={() =>
                            handlePaymentMethodSelect("dual-card")
                          }
                          disabled
                        />
                      </Col>
                      <Col sm={2}>
                        <Form.Check
                          type="radio"
                          label="Card + Cash"
                          name="paymentMethod"
                          onChange={() =>
                            handlePaymentMethodSelect("card-cash")
                          }
                          disabled
                        />
                      </Col>
                      <Col sm={1}>
                        <Form.Check
                          type="radio"
                          label="QR"
                          name="paymentMethod"
                          onChange={() => handlePaymentMethodSelect("qr")}
                        />
                      </Col>
                      <Col sm={2}>
                        <Form.Check
                          type="radio"
                          label="Email Link"
                          name="paymentMethod"
                          onChange={() => handlePaymentMethodSelect("email")}
                        />
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="mt-3">
            {paymentMethod === "Wallet" && (
              <Card style={{ marginTop: "20px" }}>
                <Card.Body>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    <i>
                      If our service left you glowing with satisfaction,
                      consider adding a little sparkle with a gratuity. Your
                      support fuels our passion for excellence. <br /> Thank you
                      for shining with us!
                    </i>
                  </p>

                  <div>
                    <Form.Group controlId="tipOption">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Form.Check
                          inline
                          label={`5% (${
                            CustomerBillData &&
                            CustomerBillData.total_amount * 0.05
                          })`}
                          type="radio"
                          value="5"
                          checked={tipOption === "5"}
                          onChange={handleTipOptionChange}
                        />
                        <Form.Check
                          inline
                          label={`10% (${
                            CustomerBillData &&
                            CustomerBillData.total_amount * 0.1
                          })`}
                          type="radio"
                          value="10"
                          checked={tipOption === "10"}
                          onChange={handleTipOptionChange}
                        />
                        <Form.Check
                          inline
                          label={`15% (${
                            CustomerBillData &&
                            CustomerBillData.total_amount * 0.15
                          })`}
                          type="radio"
                          value="15"
                          checked={tipOption === "15"}
                          onChange={handleTipOptionChange}
                        />
                        <Form.Check
                          inline
                          label="Custom"
                          type="radio"
                          value="custom"
                          checked={tipOption === "custom"}
                          onChange={handleTipOptionChange}
                        />
                      </div>
                      <div
                        className="mb-3 mt-3"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {tipOption === "custom" && (
                          <Form.Control
                            type="number"
                            placeholder="Enter custom tip amount"
                            value={customTipAmount}
                            onChange={handleCustomTipAmountChange}
                            style={{ maxWidth: "300px" }}
                          />
                        )}
                      </div>
                    </Form.Group>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <Button
                        variant="danger"
                        onClick={handleRemoveTip}
                        disabled={tipAmount == 0}
                      >
                        Remove Tip
                      </Button>
                      <Button
                        className="mr-2"
                        onClick={handleApplyTip}
                        disabled={tipOption == null}
                      >
                        Apply Tip
                      </Button>
                    </div>

                    <div className="mt-3">Total Tip: {tipAmount}</div>
                    <div>Final Amount: {finalAmount}</div>
                  </div>
                  <Button
                    onClick={walletPaymentDeduct}
                    disabled={
                      walletBalance &&
                      parseInt(walletBalance?.currentBalance) < finalAmount
                    }
                  >
                    ${finalAmount} Payment Received From Wallet
                  </Button>

                  {walletBalance &&
                    parseInt(walletBalance?.currentBalance) < finalAmount && (
                      <p>
                        You don't have sufficient balance to Make Payment
                        through Wallet
                      </p>
                    )}
                </Card.Body>
              </Card>
            )}
            {paymentMethod === "cash" && (
              <Card style={{ marginTop: "20px" }}>
                <Card.Body>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    <i>
                      If our service left you glowing with satisfaction,
                      consider adding a little sparkle with a gratuity. Your
                      support fuels our passion for excellence. <br /> Thank you
                      for shining with us!
                    </i>
                  </p>

                  <div>
                    <Form.Group controlId="tipOption">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Form.Check
                          inline
                          label={`5% (${
                            CustomerBillData &&
                            CustomerBillData.total_amount * 0.05
                          })`}
                          type="radio"
                          value="5"
                          checked={tipOption === "5"}
                          onChange={handleTipOptionChange}
                        />
                        <Form.Check
                          inline
                          label={`10% (${
                            CustomerBillData &&
                            CustomerBillData.total_amount * 0.1
                          })`}
                          type="radio"
                          value="10"
                          checked={tipOption === "10"}
                          onChange={handleTipOptionChange}
                        />
                        <Form.Check
                          inline
                          label={`15% (${
                            CustomerBillData &&
                            CustomerBillData.total_amount * 0.15
                          })`}
                          type="radio"
                          value="15"
                          checked={tipOption === "15"}
                          onChange={handleTipOptionChange}
                        />
                        <Form.Check
                          inline
                          label="Custom"
                          type="radio"
                          value="custom"
                          checked={tipOption === "custom"}
                          onChange={handleTipOptionChange}
                        />
                      </div>
                      <div
                        className="mb-3 mt-3"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {tipOption === "custom" && (
                          <Form.Control
                            type="number"
                            placeholder="Enter custom tip amount"
                            value={customTipAmount}
                            onChange={handleCustomTipAmountChange}
                            style={{ maxWidth: "300px" }}
                          />
                        )}
                      </div>
                    </Form.Group>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <Button
                        variant="danger"
                        onClick={handleRemoveTip}
                        disabled={tipAmount == 0}
                      >
                        Remove Tip
                      </Button>
                      <Button
                        className="mr-2"
                        onClick={handleApplyTip}
                        disabled={tipOption == null}
                      >
                        Apply Tip
                      </Button>
                    </div>

                    <div className="mt-3">Total Tip: {tipAmount}</div>
                    <div>Final Amount: {finalAmount}</div>
                  </div>
                  <Button onClick={cashPaymentReceived}>
                    ${finalAmount} Payment Received in Cash
                  </Button>
                </Card.Body>
              </Card>
            )}
            {paymentMethod === "single-card" && (
              <Card style={{ marginTop: "20px" }}>
                <Card.Body>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    <i>
                      If our service left you glowing with satisfaction,
                      consider adding a little sparkle with a gratuity. Your
                      support fuels our passion for excellence. <br /> Thank you
                      for shining with us!
                    </i>
                  </p>

                  <div>
                    <Form.Group controlId="tipOption">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Form.Check
                          inline
                          label={`5% (${
                            CustomerBillData &&
                            CustomerBillData.total_amount * 0.05
                          })`}
                          type="radio"
                          value="5"
                          checked={tipOption === "5"}
                          onChange={handleTipOptionChange}
                        />
                        <Form.Check
                          inline
                          label={`10% (${
                            CustomerBillData &&
                            CustomerBillData.total_amount * 0.1
                          })`}
                          type="radio"
                          value="10"
                          checked={tipOption === "10"}
                          onChange={handleTipOptionChange}
                        />
                        <Form.Check
                          inline
                          label={`15% (${
                            CustomerBillData &&
                            CustomerBillData.total_amount * 0.15
                          })`}
                          type="radio"
                          value="15"
                          checked={tipOption === "15"}
                          onChange={handleTipOptionChange}
                        />
                        <Form.Check
                          inline
                          label="Custom"
                          type="radio"
                          value="custom"
                          checked={tipOption === "custom"}
                          onChange={handleTipOptionChange}
                        />
                      </div>
                      <div
                        className="mb-3 mt-3"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {tipOption === "custom" && (
                          <Form.Control
                            type="number"
                            placeholder="Enter custom tip amount"
                            value={customTipAmount}
                            onChange={handleCustomTipAmountChange}
                            style={{ maxWidth: "300px" }}
                          />
                        )}
                      </div>
                    </Form.Group>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <Button
                        variant="danger"
                        onClick={handleRemoveTip}
                        disabled={tipAmount == 0}
                      >
                        Remove Tip
                      </Button>
                      <Button
                        className="mr-2"
                        onClick={handleApplyTip}
                        disabled={tipOption == null}
                      >
                        Apply Tip
                      </Button>
                    </div>

                    <div className="mt-3">Total Tip: {tipAmount}</div>
                    <div>Final Amount: {finalAmount}</div>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Button variant="success" onClick={handlePaymentModalOpen}>
                      Proceed to Pay ${finalAmount}
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            )}
            {paymentMethod === "dual-card" && (
              <div>
                <Card style={{ marginTop: "20px" }}>
                  <Card.Body>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      <i>
                        If our service left you glowing with satisfaction,
                        consider adding a little sparkle with a gratuity. Your
                        support fuels our passion for excellence. <br /> Thank
                        you for shining with us!
                      </i>
                    </p>

                    <div>
                      <Form.Group controlId="tipOption">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Form.Check
                            inline
                            label={`5% (${
                              CustomerBillData &&
                              CustomerBillData.total_amount * 0.05
                            })`}
                            type="radio"
                            value="5"
                            checked={tipOption === "5"}
                            onChange={handleTipOptionChange}
                          />
                          <Form.Check
                            inline
                            label={`10% (${
                              CustomerBillData &&
                              CustomerBillData.total_amount * 0.1
                            })`}
                            type="radio"
                            value="10"
                            checked={tipOption === "10"}
                            onChange={handleTipOptionChange}
                          />
                          <Form.Check
                            inline
                            label={`15% (${
                              CustomerBillData &&
                              CustomerBillData.total_amount * 0.15
                            })`}
                            type="radio"
                            value="15"
                            checked={tipOption === "15"}
                            onChange={handleTipOptionChange}
                          />
                          <Form.Check
                            inline
                            label="Custom"
                            type="radio"
                            value="custom"
                            checked={tipOption === "custom"}
                            onChange={handleTipOptionChange}
                          />
                        </div>
                        <div
                          className="mb-3 mt-3"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {tipOption === "custom" && (
                            <Form.Control
                              type="number"
                              placeholder="Enter custom tip amount"
                              value={customTipAmount}
                              onChange={handleCustomTipAmountChange}
                              style={{ maxWidth: "300px" }}
                            />
                          )}
                        </div>
                      </Form.Group>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                        }}
                      >
                        <Button
                          variant="danger"
                          onClick={handleRemoveTip}
                          disabled={tipAmount == 0}
                        >
                          Remove Tip
                        </Button>
                        <Button
                          className="mr-2"
                          onClick={handleApplyTip}
                          disabled={tipOption == null}
                        >
                          Apply Tip
                        </Button>
                      </div>

                      <div className="mt-3">Total Tip: {tipAmount}</div>
                      <div>Final Amount: {finalAmount}</div>
                    </div>
                    <Form.Group controlId="firstCardAmount" className="mt-3">
                      <Form.Label>Amount to Pay with First Card:</Form.Label>
                      <Form.Control
                        type="number"
                        value={firstCardAmount}
                        onChange={(e) =>
                          setFirstCardAmount(parseFloat(e.target.value))
                        }
                        max={finalAmount}
                      />
                    </Form.Group>
                    <Button onClick={handleDualCardPayment}>
                      Pay with Dual Card (First Card: ${firstCardAmount})
                    </Button>
                    <Button onClick={handleDualCardPayment}>
                      Pay with Dual Card (Second Card: $
                      {finalAmount - firstCardAmount})
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            )}
            {paymentMethod === "card-cash" && (
              <div>
                <Card style={{ marginTop: "20px" }}>
                  <Card.Body>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      <i>
                        If our service left you glowing with satisfaction,
                        consider adding a little sparkle with a gratuity. Your
                        support fuels our passion for excellence. <br /> Thank
                        you for shining with us!
                      </i>
                    </p>

                    <div>
                      <Form.Group controlId="tipOption">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Form.Check
                            inline
                            label={`5% (${
                              CustomerBillData &&
                              CustomerBillData.total_amount * 0.05
                            })`}
                            type="radio"
                            value="5"
                            checked={tipOption === "5"}
                            onChange={handleTipOptionChange}
                          />
                          <Form.Check
                            inline
                            label={`10% (${
                              CustomerBillData &&
                              CustomerBillData.total_amount * 0.1
                            })`}
                            type="radio"
                            value="10"
                            checked={tipOption === "10"}
                            onChange={handleTipOptionChange}
                          />
                          <Form.Check
                            inline
                            label={`15% (${
                              CustomerBillData &&
                              CustomerBillData.total_amount * 0.15
                            })`}
                            type="radio"
                            value="15"
                            checked={tipOption === "15"}
                            onChange={handleTipOptionChange}
                          />
                          <Form.Check
                            inline
                            label="Custom"
                            type="radio"
                            value="custom"
                            checked={tipOption === "custom"}
                            onChange={handleTipOptionChange}
                          />
                        </div>
                        <div
                          className="mb-3 mt-3"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {tipOption === "custom" && (
                            <Form.Control
                              type="number"
                              placeholder="Enter custom tip amount"
                              value={customTipAmount}
                              onChange={handleCustomTipAmountChange}
                              style={{ maxWidth: "300px" }}
                            />
                          )}
                        </div>
                      </Form.Group>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                        }}
                      >
                        <Button
                          variant="danger"
                          onClick={handleRemoveTip}
                          disabled={tipAmount == 0}
                        >
                          Remove Tip
                        </Button>
                        <Button
                          className="mr-2"
                          onClick={handleApplyTip}
                          disabled={tipOption == null}
                        >
                          Apply Tip
                        </Button>
                      </div>

                      <div className="mt-3">Total Tip: {tipAmount}</div>
                      <div>Final Amount: {finalAmount}</div>
                    </div>

                    <Form.Group controlId="cashAmount" className="mt-3">
                      <Form.Label>Amount to Pay in Cash:</Form.Label>
                      <Form.Control
                        type="number"
                        value={cashAmount}
                        onChange={(e) =>
                          setCashAmount(parseFloat(e.target.value))
                        }
                        max={finalAmount}
                      />
                    </Form.Group>
                    <Button onClick={handleCardCashPayment}>
                      Pay with Card + Cash (Cash: ${cashAmount})
                    </Button>
                    <Button onClick={handleCardCashPayment}>
                      Pay with Card + Cash (Card: ${finalAmount - cashAmount})
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            )}
            {paymentMethod === "email" && (
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <Button onClick={sendPaymentEmail} variant="success">
                  Send Payment Link to Email
                </Button>
              </div>
            )}

            {paymentMethod === "qr" && (
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <Card>
                  <Card.Body>
                    <h3>Scan QR Code to Pay</h3>
                    <QRCode
                      value={`https://medispacosmos.com/cosmos-emr/products-bill-qr/${CustomerBillData.fname}/${CustomerBillData.lname}/${CustomerBillData.email}/${finalAmount}/${encodedProductBillDataNew}`}
                      onClick={handleQRCodeClick}
                    />
                    <p>Final Amount: {finalAmount}</p>
                  </Card.Body>
                </Card>
              </div>
            )}
          </div>

          <Modal
            show={showPaymentModal}
            onHide={handlePaymentModalClose}
            centered
            className="modal-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Enter Card Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {showPaymentModal && (
                <Elements stripe={stripePromise}>
                  <PaymentGateway
                    patientData={CustomerBillData}
                    amount={finalAmount}
                    onSuccess={handlePaymentModalCloseSuccess}
                  />
                </Elements>
              )}
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
};

export default GeneratedProductBill;
