import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import Loader from "../Loader/Loader";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";


const PractitionerRegistrationForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fname: "",
    mname: "",
    lname: "",
    username: "",
    password: "",
    street: "",
    zip: "",
    city: "",
    state: "",
    country_code: "",
    phonecell: "",
    email: "",
    facility: "",
    facility_id: "",
    billing_facility: "",
    billing_facility_id: "",
    npi: "",
    access_group: "",
    active: 1,
    authorized: 1,
    portal_user: 1,
    calendar: 1,
    see_auth: 1
  });
  
  const [facilities, setFacilities] = useState([]);
  const [acluser, setAcluser] = useState([]);
  const [selectedAcls, setSelectedAcls] = useState([]);
  const [loading, setloading] = useState(false);

  const handleAclChange = (e) => {
    const valueMapping = {
      'Administrators': 'admin',
      'Accounting': 'back',
      'Clinicians': 'clin',
      'Physicians': 'doc',
      'Front Office': 'front',
      'Emergency Login': 'breakglass'
    };
    const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
    setSelectedAcls(selectedOptions);

    const selectedOptionsVal = Array.from(e.target.selectedOptions, (option) => {
      const mappedValue = valueMapping[option.value] || option.value;
      return mappedValue;
    });
    alert(selectedOptionsVal);
    setFormData({ ...formData, access_group: selectedOptionsVal});
  };

  const fetchFacilities = async () => {
    try {
      const authResponse = await axios.post(
        "http://13.40.141.190/digihealthemr/oauth2/default/token",
        {
          client_id: "dmfdSjsrYN4dV-GAeEFL2vE8T0FOwNHFY3Yvl0E55f4",
          grant_type: "password",
          scope:
            "openid offline_access api:oemr api:fhir api:port user/allergy.read user/allergy.write user/appointment.read user/appointment.write user/dental_issue.read user/dental_issue.write user/document.read user/document.write user/drug.read user/encounter.read user/encounter.write user/facility.read user/facility.write user/immunization.read user/insurance.read user/insurance.write user/insurance_company.read user/insurance_company.write user/insurance_type.read user/list.read user/medical_problem.read user/medical_problem.write user/medication.read user/medication.write user/message.write user/patient.read user/patient.write user/practitioner.read user/practitioner.write user/prescription.read user/procedure.read user/soap_note.read user/soap_note.write user/surgery.read user/surgery.write user/transaction.read user/transaction.write user/vital.read user/vital.write user/AllergyIntolerance.read user/CareTeam.read user/Condition.read user/Coverage.read user/Encounter.read user/Immunization.read user/Location.read user/Medication.read user/MedicationRequest.read user/Observation.read user/Organization.read user/Organization.write user/Patient.read user/Patient.write user/Practitioner.read user/Practitioner.write user/PractitionerRole.read user/Procedure.read user/aclGroup.read",
          user_role: "users",
          username: "admin@admin.com",
          password: "admin@admin.com",
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const { access_token } = authResponse.data;
      sessionStorage.setItem("access_token", access_token);
      const response = await axios.get(
        "http://13.40.141.190/digihealthemr/apis/default/api/facility",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setFacilities(response.data.data);

      const responseACL = await axios.get(
        "http://13.40.141.190/digihealthemr/apis/default/api/aclGroup",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setAcluser(responseACL.data.data);
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  useEffect(() => {
      fetchFacilities();
  }, []); 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "facility") {
      const { name, value, id } = e.target;
      if (value.trim() !== "") {
        // Split the value into facility id and name
        //const [facilityId, facilityName] = value.split(',');
        setFormData({
            ...formData,
            [name]: value,
            ['facility_id']: id
        });
      } else {
        // Handle empty or invalid value
          setFormData({
            ...formData,
            ['facility']: "",
            ['facility_id']: ""
          });
      }
    }else if(name === "billing_facility"){
      if (value.trim() !== "") {
        const { name, value, id } = e.target;
        // Split the value into facility id and name
        //const [facilityId, facilityName] = value.split(',');
        setFormData({
            ...formData,
            [name]: value,
            ['billing_facility_id']: id
        });
      } else {
        // Handle empty or invalid value
          setFormData({
            ...formData,
            ['billing_facility']: "",
            ['billing_facility_id']: ""
          });
      }
    }else{
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async () => {
    setloading(true);
    //alert(JSON.stringify(formData));
    try {
      const authResponse = await axios.post(
        "http://13.40.141.190/digihealthemr/oauth2/default/token",
        {
          client_id: "dmfdSjsrYN4dV-GAeEFL2vE8T0FOwNHFY3Yvl0E55f4",
          grant_type: "password",
          scope:
            "openid offline_access api:oemr api:fhir api:port user/allergy.read user/allergy.write user/appointment.read user/appointment.write user/dental_issue.read user/dental_issue.write user/document.read user/document.write user/drug.read user/encounter.read user/encounter.write user/facility.read user/facility.write user/immunization.read user/insurance.read user/insurance.write user/insurance_company.read user/insurance_company.write user/insurance_type.read user/list.read user/medical_problem.read user/medical_problem.write user/medication.read user/medication.write user/message.write user/patient.read user/patient.write user/practitioner.read user/practitioner.write user/prescription.read user/procedure.read user/soap_note.read user/soap_note.write user/surgery.read user/surgery.write user/transaction.read user/transaction.write user/vital.read user/vital.write user/AllergyIntolerance.read user/CareTeam.read user/Condition.read user/Coverage.read user/Encounter.read user/Immunization.read user/Location.read user/Medication.read user/MedicationRequest.read user/Observation.read user/Organization.read user/Organization.write user/Patient.read user/Patient.write user/Practitioner.read user/Practitioner.write user/PractitionerRole.read user/Procedure.read user/aclGroup.read",
          user_role: "users",
          username: "admin@admin.com",
          password: "admin@admin.com",
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const { access_token } = authResponse.data;
      sessionStorage.setItem("access_token", access_token);

      const response = await axios.post(
        "http://13.40.141.190/digihealthemr/apis/default/api/practitioner",
        formData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setloading(false);
      swal({
        title: "Practitioner Registration Successful",
        text: "Practitioner has been added to system successfully!",
        icon: "success",
        button: "OK",
        timer: 2000,
      });
      navigate("/admin");
      console.log("Response:", response.data["data"]);
      sessionStorage.setItem("id", response.data["data"].id);
    } catch (error) {
      console.error("Error:", error);
      swal({
        title: "Registration Failed",
        text: "Something went wrong.",
        icon: "error",
        button: "OK",
        timer: 2000,
      });
    }
  };

  return (
    <div>
      <div>
      {loading && <Loader />}
        <Container>
          <div>
            <button
              style={{
                backgroundColor: "#5A2FFC",
                width: "100%",
                color: "white",
                border: "none",
                borderRadius: "10px",
                padding: "5px",
              }}
            >
              Practitioner Registration
            </button>
          </div>
          <div
            className="round-border-div-of-data"
            style={{ marginTop: "20px" }}
          >
          <div className="round-border-div-of-data-relative-infor">Practitioner Details</div>
            <Row style={{ marginBottom: "10px" }}>
              <Col sm={3}>
                <input
                  type="text"
                  placeholder="First Name"
                  className="practitioner-registration-input-tag"
                  name="fname"
                  value={formData.fname}
                  onChange={handleInputChange}
                />
              </Col>
              <Col sm={3}>
                <input
                  type="text"
                  placeholder="Middle Name"
                  className="practitioner-registration-input-tag"
                  name="mname"
                  value={formData.mname}
                  onChange={handleInputChange}
                />
              </Col>
              <Col sm={3}>
                <input
                  type="text"
                  placeholder="Last Name"
                  className="practitioner-registration-input-tag"
                  name="lname"
                  value={formData.lname}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <input
                  type="text"
                  placeholder="Username"
                  className="practitioner-registration-input-tag"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                />
              </Col>
                <Col sm={3}>
                <input
                  type="password"
                  placeholder="Password"
                  className="practitioner-registration-input-tag"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
          </div>

          <div className="round-border-div-of-data">
          <div className="round-border-div-of-data-relative-infor">Contact</div>
            <Row style={{ marginBottom: "10px" }}>
              <Col sm={4}>
                <textarea
                  className="practitioner-registration-input-tag"
                  placeholder="Street and/or Landmark"
                  style={{ height: "100%" }}
                  name="street"
                  value={formData.street}
                  onChange={handleInputChange}
                />
              </Col>
              <Col sm={4}>
                <input
                  type="text"
                  placeholder="City"
                  className="practitioner-registration-input-tag"
                  style={{ marginBottom: "10px" }}
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  placeholder="State"
                  className="practitioner-registration-input-tag"
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                />
              </Col>
              <Col sm={4}>
                <input
                  type="text"
                  placeholder="Postal Code"
                  className="practitioner-registration-input-tag"
                  style={{ marginBottom: "10px" }}
                  name="zip"
                  value={formData.zip}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <input
                  type="number"
                  placeholder="Cell phone"
                  className="practitioner-registration-input-tag"
                  name="phonecell"
                  value={formData.phonecell}
                  onChange={handleInputChange}
                />
              </Col>
              <Col sm={4}>
                <input
                  type="email"
                  placeholder="Email Address"
                  className="practitioner-registration-input-tag"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
          </div>
          <div className="round-border-div-of-data">
          <div className="round-border-div-of-data-relative-infor">Facility Details & role</div>
          <Row style={{ marginBottom: "10px" }}>
              <Col sm={3}>
              <select
                className="practitioner-registration-input-tag"
                name="facility"
                value={formData.facility}
                onChange={handleInputChange}
              >
                <option value="" disabled></option>
                {facilities.map((facility) => (
                  <option key={facility.id} value={facility.name}>
                    {facility.name}
                </option>
                ))}
              </select> 
              </Col>
              <Col sm={3}>
              <select
                className="practitioner-registration-input-tag"
                name="billing_facility"
                value={formData.billing_facility}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                </option>
                {facilities.map((facility) => (
                  <option key={facility.id} value={facility.name}>
                  {facility.name}
                  </option>
                ))}
              </select>
              </Col>
              <Col sm={3}>
              <select
                className="practitioner-registration-input-tag"
                multiple
                value={selectedAcls}
                onChange={handleAclChange}
              >
                <option value="" disabled>
                  select access role
                </option>
                {acluser.map((acl) => (
                  <option key={acl} value={acl}>
                  {acl}
                  </option>
                ))}
              </select>
              </Col>
            </Row>
          </div>
          <Row>
            <Col sm={4}>
              <button onClick={handleSubmit}>Submit</button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PractitionerRegistrationForm;
