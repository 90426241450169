import AccessToken from "./AccessToken";
import ApiServices from "./ApiServices";

class AppointmentPatientList {
  async fetchData() {
    try {
      // const accessToken = await AccessToken.fetchAccessToken();
      const accessToken = sessionStorage.getItem("accessToken");
      const response = await ApiServices.get(
        // `/apis/default/api/providerAppointments/${providerId}`,
        `/apis/default/api/appointmentBooked`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      // Assuming your API response has a 'data' property
      console.log("response",response)
      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error fetching patient data:", error);
      throw error; // Rethrow the error to be caught by the calling code
    }
  }
}

export default new AppointmentPatientList();
