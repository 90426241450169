import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Form, Button, Row, Card } from "react-bootstrap";
import swal from "sweetalert";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

const ProductBillingGenerate = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const accessToken = sessionStorage.getItem("accessToken");
  const LoginUserID = sessionStorage.getItem("LoginUserID");
  const [patientList, setPatientList] = useState([]);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    email: "",
    fname: "",
    lname: "",
    contact: "",
    pid: "",
  });
  console.log("formData",formData)
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  console.log("setSelectedProducts",selectedProducts)
  const handleEmailSelect = (selectedEmail) => {
    const selectedPatient = patientList.find(
      (patient) => patient.email === selectedEmail
    );
    if (selectedPatient) {
      setFormData({
        ...formData,
        email: selectedPatient.email,
        fname: selectedPatient.fname,
        lname: selectedPatient.lname,
        contact: selectedPatient.phone_contact,
        pid: selectedPatient.pid,
      });
    }
  };

  useEffect(() => {
    axios
      .get(`https://cosmosapi.medispacosmos.com/api/products/`)
      .then((response) => {
        setAllProducts(response.data);
      });
  }, []);

  useEffect(() => {
    const fetchPatient = async () => {
      try {
        const patientResponse = await ApiServices.get(
          "/apis/default/api/patient",
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );
        const patients = patientResponse.data.data;
        setPatientList(patients);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPatient();
  }, []);

  const handleProductSelection = (productId, checked) => {
    if (checked) {
      const selectedProduct = allProducts.find(
        (product) => product.id === productId
      );

      if (selectedProduct) {
        setSelectedProducts((prevSelectedProducts) => [
          ...prevSelectedProducts,
          {
            id: selectedProduct.id,
            title: selectedProduct.title,
            default_price: selectedProduct.price,
            discount: 0,
            quantity: 1,
          },
        ]);
      }
    } else {
      setSelectedProducts((prevSelectedProducts) =>
        prevSelectedProducts.filter((product) => product.id !== productId)
      );
    }
  };

  const handleProductQuantityChange = (productId, newQuantity) => {
    const validatedQuantity = Math.max(1, newQuantity);
    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.map((product) =>
        product.id === productId
          ? { ...product, quantity: validatedQuantity }
          : product
      )
    );
  };

  const handleProductDiscountChange = (productId, newDiscount) => {
    const validatedDiscount = Math.min(Math.max(0, newDiscount), 100);
    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.map((product) =>
        product.id === productId
          ? { ...product, discount: validatedDiscount }
          : product
      )
    );
  };

  const handleProductRemoval = (productId) => {
    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.filter((product) => product.id !== productId)
    );
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = allProducts.filter((product) =>
    product.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleBillingSubmit = async () => {
    if (!formData.fname || !formData.lname || !formData.email) {
        swal({
          title: "Error",
          text: "Please fill in all required fields (First Name, Last Name, Email)",
          icon: "error",
          button: "OK",
          timer: 2000,
        });
        return; 
      }
      setloading(true)
    const currentDate = new Date().toISOString().split("T")[0];
    const postData = {
      email: formData.email,
      fname: formData.fname,
      lname: formData.lname,
      contact: formData.contact,
      pid: formData.pid,
      date_of_billing: currentDate,
      products: selectedProducts.map((product) => ({
        product_id: product.id,
        product_title: product.title,
        product_provider: LoginUserID,
        product_quantity: product.quantity,
        product_discount: product.discount,
        product_default_price: product.default_price,
      })),
      tip: 0,
      amount_after_tip: 0,
      is_billed: 0,
      verified_receptionist: 0,
      cash: 0,
      wallet_money: 0,
      credit_card: 0,
    };
    try {
      const response = await ApiServices.post(
        "/apis/default/api/productbilling",
        postData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      console.log(response.data);
      swal({
        title: "Bill has been generated successfully",
        icon: "success",
        button: "OK",
        timer: 2000,
      });
      setloading(false)
      navigate(`/admin/view-generated-ptoduct-bill/${response.data.bill_id}`)
      setSelectedProducts([])
    } catch (error) {
      if (error.response && error.response.data) {
        swal({
          title: "Error",
          text: error.response.data.message || "Something went wrong",
          icon: "error",
          button: "OK",
          timer: 2000,
        });
        setloading(false)
      }
      else{
        setloading(false)
      }
    }
  };
  const handleProductTitleChange = (productId, newTitle) => {
    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.map((product) =>
        product.id === productId
          ? { ...product, title: newTitle }
          : product
      )
    );
  };

  const handleProductPriceChange = (productId, newPrice) => {
    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.map((product) =>
        product.id === productId
          ? { ...product, default_price: newPrice }
          : product
      )
    );
  };
  

  return (
    <div>
    {loading && <Loader/>}
      <Col sm={12} className="mt-3">
        <Form.Control
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={handleSearch}
        />
        <div className="table-responsive mt-3">
          <table className="billing-table">
            <thead>
              <tr>
                <th>Select</th>
                <th>Title</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.map((product) => (
                <tr key={product.id}>
                  <td>
                    <input
                      id={`checkbox_product_${product.id}`}
                      type="checkbox"
                      onChange={(e) =>
                        handleProductSelection(product.id, e.target.checked)
                      }
                      style={{ cursor: "pointer" }}
                      checked={selectedProducts.some(
                        (selectedProduct) => selectedProduct.id === product.id
                      )}
                    />
                  </td>
                  <td>{product.title}</td>
                  <td>$ {product.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Col>

      {selectedProducts.length > 0 && (
        <Card className="mt-5">
          <Card.Body>
            <div>
              <Col sm={12}>
                <Form>
                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId="formEmail">
                        <Form.Label>Enter Email - Only For Non Registered Patients</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="formEmail">
                        <Form.Label>Select Email -  Only For Registered Patients</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) => handleEmailSelect(e.target.value)}
                          value={formData.email}
                        >
                          <option value="">Select email</option>
                          {patientList.map((patient) => (
                            <option key={patient.id} value={patient.email}>
                              {patient.email}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <Form.Group controlId="formFName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="fname"
                          placeholder="Enter first name"
                          value={formData.fname}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group controlId="formLName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="lname"
                          placeholder="Enter last name"
                          value={formData.lname}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group controlId="formContact">
                        <Form.Label>Contact</Form.Label>
                        <Form.Control
                          type="text"
                          name="contact"
                          placeholder="Enter contact number"
                          value={formData.contact}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col cm={12} className="mt-3">
                <div className="mt-3">
                  <table className="billing-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Default Price</th>
                        <th>Quantity</th>
                        <th>Discount Percentage</th>
                        <th>Discounted Price</th>
                        <th>Total Amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {selectedProducts &&
                        selectedProducts.map((selectedProduct) => {
                          const productId = selectedProduct.id;
                          const discountedPrice =
                            selectedProduct.default_price -
                            (selectedProduct.default_price *
                              selectedProduct.discount) /
                              100;
                          const totalAmountForProduct =
                            discountedPrice * selectedProduct.quantity;

                          return (
                            <tr key={productId}>
                              <td>{selectedProduct.title}</td>
                              <td>{selectedProduct.default_price}</td>
                              <td>
                                <Button
                                  variant="danger"
                                  onClick={() =>
                                    handleProductQuantityChange(
                                      productId,
                                      selectedProduct.quantity - 1
                                    )
                                  }
                                >
                                  -
                                </Button>
                                <span style={{ padding: "10px" }}>
                                  {selectedProduct.quantity}
                                </span>
                                <Button
                                  variant="success"
                                  onClick={() =>
                                    handleProductQuantityChange(
                                      productId,
                                      selectedProduct.quantity + 1
                                    )
                                  }
                                >
                                  +
                                </Button>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={selectedProduct.discount}
                                  onChange={(e) =>
                                    handleProductDiscountChange(
                                      productId,
                                      e.target.value
                                    )
                                  }
                                  style={{ width: "100px" }}
                                  // disabled={BillSuccess}
                                />
                              </td>
                              <td>{discountedPrice}</td>
                              <td>{totalAmountForProduct}</td>
                              <td>
                                <Button
                                  variant="danger"
                                  onClick={() =>
                                    handleProductRemoval(productId)
                                  }
                                  // disabled={BillSuccess}
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          );
                        })} */}

                        {selectedProducts &&
    selectedProducts.map((selectedProduct) => {
        const productId = selectedProduct.id;
        const discountedPrice =
            selectedProduct.default_price -
            (selectedProduct.default_price *
                selectedProduct.discount) /
            100;
        const totalAmountForProduct =
            discountedPrice * selectedProduct.quantity;

        return (
            <tr key={productId}>
                <td>
                    {selectedProduct.id === 21 ? (
                        <input
                            type="text"
                            value={selectedProduct.title}
                            onChange={(e) =>
                                handleProductTitleChange(
                                    productId,
                                    e.target.value
                                )
                            }
                        />
                    ) : (
                        selectedProduct.title
                    )}
                </td>
                <td>
                    {selectedProduct.id === 21 ? (
                        <input
                            type="number"
                            value={selectedProduct.default_price}
                            onChange={(e) =>
                                handleProductPriceChange(
                                    productId,
                                    e.target.value
                                )
                            }
                        />
                    ) : (
                        selectedProduct.default_price
                    )}
                </td>
                <td>
                    <Button
                        variant="danger"
                        onClick={() =>
                            handleProductQuantityChange(
                                productId,
                                selectedProduct.quantity - 1
                            )
                        }
                    >
                        -
                    </Button>
                    <span style={{ padding: "10px" }}>
                        {selectedProduct.quantity}
                    </span>
                    <Button
                        variant="success"
                        onClick={() =>
                            handleProductQuantityChange(
                                productId,
                                selectedProduct.quantity + 1
                            )
                        }
                    >
                        +
                    </Button>
                </td>
                <td>
                    <input
                        type="number"
                        value={selectedProduct.discount}
                        onChange={(e) =>
                            handleProductDiscountChange(
                                productId,
                                e.target.value
                            )
                        }
                        style={{ width: "100px" }}
                    />
                </td>
                <td>{discountedPrice}</td>
                <td>{totalAmountForProduct}</td>
                <td>
                    <Button
                        variant="danger"
                        onClick={() =>
                            handleProductRemoval(productId)
                        }
                    >
                        Delete
                    </Button>
                </td>
            </tr>
        );
    })}


                    </tbody>
                  </table>
                </div>
              </Col>

              <div style={{textAlign:"right"}} className="mt-3">
                <Button variant="success" onClick={handleBillingSubmit}>Generate Bill</Button>
              </div>
            </div>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default ProductBillingGenerate;
