import React from 'react'
import "./MedSpaAboutUs.css"
import MedSpaStaffDetails from '../../Components/MedSpaStaffDetails/MedSpaStaffDetails'
import ContactUsSecOne from '../MedSpaContactUs/Sections/ContactUsSecOne'

const MedSpaAboutUs = () => {
  return (
    <div>
      <div style={{backgroundColor:"rgb(249, 250, 251)",paddingTop:"50px",paddingBottom:"50px"}}>
        <MedSpaStaffDetails/>
      </div>
      <div style={{paddingTop:"50px",paddingBottom:"50px"}}>
        <ContactUsSecOne/>
      </div>
    </div>
  )
}

export default MedSpaAboutUs