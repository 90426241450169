import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ClinicImg from "../../Assets/IMG_4170.jpg"
import { Link } from 'react-router-dom'

const MedSpaVisitUs = () => {
  return (
    <div>
        <Container>
            
            <Row >
          <Col sm={6} className="order-2 order-sm-2">
            <img
              // src="https://www.viomedspa.com/media/vio_chicago.jpg"
              src={ClinicImg}
              style={{ height: "100%", width: "100%",maxHeight:"400px" }}
              className="section-image-quadra-shape"
              alt=''
            />
          </Col>

          <Col sm={6} className="order-1 order-sm-1" style={{textAlign:"left"}}>
          <div>
                <h1 className='section-one-head-tag'>Visit Us</h1>
                <p className='section-one-para-tag' style={{maxWidth:"400px"}}>Feel confident and beautiful in your skin with exceptional med spa services at the best medical spa near you. Find a COSMOS Med Spa location today!</p>
            </div>
          <Row>
            <Col sm={6}>
                <h1 className='visit-us-p-tag-locations'>Rejuvenation</h1>
                <h1 className='visit-us-p-tag-locations'>Enhanced Beauty</h1>
                <h1 className='visit-us-p-tag-locations'>Personalized Care</h1>
                
            </Col>
            <Col sm={6}>
                <h1 className='visit-us-p-tag-locations'>Expert Professionals</h1>
                <h1 className='visit-us-p-tag-locations'>Skilled Specialists</h1>
                <h1 className='visit-us-p-tag-locations'>Cutting-Edge Treatments</h1>
            </Col>
          </Row>
          <Link to="/signup">
          <button class="med-spa-custom-button">Join the Cosmos Medi Spa</button>
          </Link>
          </Col>
        </Row>
        </Container>
    </div>
  )
}

export default MedSpaVisitUs