import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../../Components/Loader/Loader";
import PatientAccessToken from "../../GlobalFiles/ApiServices/PatientAccessToken";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";

const AppointmentSlot = ({
  selectedDate,
  PatientToBookAppointment,
  onSuccess,
  updateAppointmentData,
}) => {
  const LoginUserType = sessionStorage.getItem("LoginUserType");
  const [appointmentSchedule, setAppointmentSchedule] = useState();
  const [timeSlots, setTimeSlots] = useState([]);
  const [bookedSlots, setBookedSlots] = useState([]);
  const accessToken = sessionStorage.getItem("accessToken");
  // console.log("PatientToBookAppointment :::",PatientToBookAppointment)
  const [SevicesData, setSevicesData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `/apis/default/api/masterServices`;

        if (LoginUserType == "Patient") {
          endpoint = `/apis/default/portal/masterServices`;
        }

        const AppointmentDataResponse = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });
        setSevicesData(AppointmentDataResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const groupedServices = {};
  SevicesData &&
    SevicesData.forEach((service) => {
      if (!groupedServices[service.service]) {
        groupedServices[service.service] = [];
      }
      groupedServices[service.service].push(service);
    });

  useEffect(() => {
    setSelectedService(
      PatientToBookAppointment && PatientToBookAppointment.appointment_type
    );
  }, [updateAppointmentData]);

  const [selectedService, setSelectedService] = useState(null);
  // console.log("selectedService",selectedService)

  // const handleServiceChange = (event) => {
  //   const selectedServiceData = SevicesData.find(
  //     (service) => service.service_no == event.target.value
  //   );

  //   if (selectedServiceData) {
  //     setSelectedService({
  //       service_no: selectedServiceData.service_no,
  //       service: selectedServiceData.service,
  //     });
  //   } else {
  //     // Handle the case when selectedServiceData is not found
  //   }
  // };

  const handleServiceChange = (event) => {
    const selectedServiceData = SevicesData.find(
      (service) => service.service_no == event.target.value
    );
  
    if (selectedServiceData) {
      setSelectedService({
        service_no: selectedServiceData.service_no,
        service: selectedServiceData.service,
      });
      // Reset selected slot if it's booked for the new service
      const selectedSlotBookedForNewService = bookedSlots.some(
        (slot) =>
          slot.pc_startTime.substring(0, 5) === selectedTimeSlot &&
          slot.pc_eventDate === selectedSlotDate &&
          slot.service_id === selectedServiceData.service_no
      );
  
      if (!selectedSlotBookedForNewService) {
        setselectedTimeSlot("");
        setselectedSlotDate("");
      }
    }
  };
  

  useEffect(() => {
    if (PatientToBookAppointment && PatientToBookAppointment?.pc_startTime) {
      setSelectedService({
        service_no: PatientToBookAppointment.service_id,
        service: PatientToBookAppointment.appointment_type,
      });
    } else if (SevicesData && SevicesData.length > 0) {
      const firstService = SevicesData[0];
      setSelectedService({
        service_no: firstService.service_no,
        service: firstService.service,
      });
    }
  }, [SevicesData, PatientToBookAppointment]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `/apis/default/api/appointmentSlot`;

        if (LoginUserType == "Patient") {
          endpoint = `/apis/default/portal/appointmentSlot`;
        }
        const appointmentDataResponse = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });
        const appointmentData = appointmentDataResponse.data["0"];
        setAppointmentSchedule(appointmentData);
        const bookedSlotsData = appointmentDataResponse.data.booked_slots;
        setBookedSlots(bookedSlotsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [selectedDate]);

  useEffect(() => {
    if (appointmentSchedule) {
      generateTimeSlots();
    }
  }, [selectedDate, appointmentSchedule, bookedSlots]);

  const generateTimeSlots = () => {
    const currentDate = new Date(selectedDate);
    const currentTime = new Date();
    const startTime = new Date(currentDate);
    const endTime = new Date(currentDate);

    // Parse hours and minutes from pc_startTime and pc_endTime
    const startHours = parseInt(
      appointmentSchedule.pc_startTime.split(":")[0],
      10
    );
    const startMinutes = parseInt(
      appointmentSchedule.pc_startTime.split(":")[1],
      10
    );

    const endHours = parseInt(appointmentSchedule.pc_endTime.split(":")[0], 10);
    const endMinutes = parseInt(
      appointmentSchedule.pc_endTime.split(":")[1],
      10
    );

    if (LoginUserType === "Patient") {
      startTime.setHours(10, 0, 0, 0);
      endTime.setHours(18, 0, 0, 0);
    } else {
      startTime.setHours(7, 45, 0, 0);
      endTime.setHours(20, 0, 0, 0);
    }

    const slots = [];

    while (startTime <= endTime) {
      const slotTime = startTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });

      const isBooked = isSlotBooked(currentDate, slotTime);
      slots.push({ time: slotTime, isBooked });
      startTime.setMinutes(startTime.getMinutes() + 45);
    }

    setTimeSlots(slots);
  };

  const isSlotBooked = (selectedDate, slotTime) => {
    const year = selectedDate.getFullYear();
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = selectedDate.getDate().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    const bookedSlotsForDate =
      bookedSlots &&
      bookedSlots.filter((slot) => slot.pc_eventDate === formattedDate);

    const bookedSlotsForTime =
      bookedSlotsForDate &&
      bookedSlotsForDate.filter((slot) => {
        const bookedSlotTime = slot.pc_startTime.substring(0, 5);
        return bookedSlotTime === slotTime.substring(0, 5);
      });

      const isBookedForService =
    bookedSlotsForTime &&
    bookedSlotsForTime.some(
      (slot) => slot.service_id === selectedService?.service_no
    );

    // Check if there are two bookings for the same start time
    // const isBooked = bookedSlotsForTime && bookedSlotsForTime.length === 1
    const isBooked = 
    bookedSlotsForTime && (
      (LoginUserType === "Patient" && bookedSlotsForTime.length === 1) ||
      (LoginUserType !== "Patient" && bookedSlotsForTime.length === 2)
    );
    

    const currentTime = new Date();
    const slotDateTime = new Date(`${formattedDate} ${slotTime}`);
    const isPast = slotDateTime < currentTime;

    return isBooked || isPast;
  };

  const [selectedTimeSlot, setselectedTimeSlot] = useState("");
  const [selectedSlotDate, setselectedSlotDate] = useState("");
  // console.log("selectedSlotDate",selectedSlotDate)
  useEffect(() => {
    if (PatientToBookAppointment && PatientToBookAppointment?.pc_startTime) {
      const timeString = PatientToBookAppointment.pc_startTime;
      const [hours, minutes] = timeString.split(":");
      const formattedTime = `${hours}:${minutes}`;
      setselectedTimeSlot(formattedTime);
      setselectedSlotDate(PatientToBookAppointment.pc_eventDate);
    }
  }, [PatientToBookAppointment]);

  const handleSlotSelection = (selectedSlot) => {
    setselectedTimeSlot(selectedSlot.time);
    const year = selectedDate.getFullYear();
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = selectedDate.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setselectedSlotDate(formattedDate);
  };

  const [IsLoading, setIsLoading] = useState(false);

  const BookAppointment = async () => {
    setIsLoading(true);

    let PatientData;
    if (PatientToBookAppointment) {
      PatientData = PatientToBookAppointment;
    } else {
      PatientData = await PatientAccessToken.fetchData();
    }

    const data = {
      pc_catid: "5",
      pc_title: "Office Visit",
      pc_duration: "1800",
      pc_hometext: "Test",
      // pc_apptstatus: PatientToBookAppointment ? "APPR" : "^",
      pc_apptstatus: "^",
      pc_eventDate: selectedSlotDate,
      pc_startTime: selectedTimeSlot,
      pc_facility: "9",
      pc_billing_location: "10",
      pc_aid: "1",
      client_type: "Registered",
      wallet_id: null,
      user_type: null,
      is_billed: 0,
      assigned_by: null,
      generated_bill_provider: 0,
      verified_receptionist: 0,
      appointment_type: selectedService.service || "Consultation",
      service_id: selectedService.service_no || 1,
      appointment_note: "",
    };

    const config = {
      method: "post",
      url: PatientToBookAppointment
        ? `${process.env.REACT_APP_SERVERURL}/apis/default/api/patient/${PatientData.pid}/appointment`
        : // : `${process.env.REACT_APP_SERVERURL}/apis/default/api/patient/${PatientData.pid}/appointment`,
          `${process.env.REACT_APP_SERVERURL}/apis/default/portal/patient/${PatientData.pid}/appointment`,

      // url: `${process.env.REACT_APP_SERVERURL}/apis/default/api/patient/${PatientData.pid}/appointment`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      // console.log(JSON.stringify(response.data));

      if (PatientData.is_portal_sent == 0) {
        try {
          let endpoint = `/apis/default/api/intakeForms`;

          if (LoginUserType == "Patient") {
            endpoint = `/apis/default/portal/intakeForms`;
          }
          await ApiServices.put(
            endpoint,
            {
              pid: PatientToBookAppointment ? PatientData.pid : PatientData.pid,
            },
            {
              Authorization: `Bearer ${accessToken}`,
            }
          );
        } catch (error) {
          console.error("Error during API call:", error);
        }
      }

      setTimeout(() => {
        setIsLoading(false);

        swal({
          title: "Appointment Booked Successfully",
          text: "Your Appointment at Cosmos Medi Spa has been Scheduled.",
          icon: "success",
          button: "OK",
          timer: 2000,
        });
        if (typeof onSuccess === "function") {
          onSuccess();
        }
        // onSuccess()
      }, 2000);
      // {PatientToBookAppointment &&

      //   }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      swal({
        title: "Appointment Booking Failed",
        text: "An error occurred during Appointment Booking, Please Contact to Our Front Desk.",
        icon: "error",
        button: "OK",
        timer: 3000,
      });
      // {PatientToBookAppointment &&

      // handleModalClose()
      // }
    }
  };

  const handleUpdateAppointment = async () => {
    try {
      let endpoint = `/apis/default/api/patient/${PatientToBookAppointment.pc_pid}/appointment/${PatientToBookAppointment.pc_eid}`;

      if (LoginUserType == "Patient") {
        endpoint = `/apis/default/portal/patient/${PatientToBookAppointment.pc_pid}/appointment/${PatientToBookAppointment.pc_eid}`;
      }

      const response = await ApiServices.put(
        endpoint,
        {
          appointment_note: PatientToBookAppointment.appointment_note,
          pc_catid: PatientToBookAppointment.pc_catid,
          pc_title: PatientToBookAppointment.pc_title,
          pc_duration: PatientToBookAppointment.pc_duration,
          pc_hometext: PatientToBookAppointment.pc_hometext,
          pc_apptstatus: PatientToBookAppointment.pc_apptstatus,
          pc_eventDate: selectedSlotDate,
          pc_startTime: selectedTimeSlot,
          pc_facility: PatientToBookAppointment.pc_facility,
          pc_billing_location: PatientToBookAppointment.pc_billing_location,
          pc_aid: PatientToBookAppointment.pc_aid,
          client_type: PatientToBookAppointment.client_type,
          wallet_id: PatientToBookAppointment.wallet_id,
          user_type: PatientToBookAppointment.user_type,
          is_billed: 0,
          assigned_by: PatientToBookAppointment.assigned_by,
          verified_receptionist: 0,
          // appointment_type: selectedService,
          appointment_type: selectedService.service,
          service_id: selectedService.service_no,
        },
        {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        }
      );

      swal({
        title: "Appointment Date and Time Updated Successfully",
        icon: "success",
        button: "OK",
        timer: 2000,
      });

      const postData = {
        pid: PatientToBookAppointment.pc_pid,
        eid: PatientToBookAppointment.pc_eid,
        form_id: selectedService.service_no,
      };
      ApiServices.post("/apis/default/api/sendFormsPatient", postData, {
        Authorization: `Bearer ${accessToken}`,
      });

      if (typeof onSuccess === "function") {
        onSuccess();
      }
    } catch (error) {
      console.error("Error updating appointment note:", error);
      swal({
        title: "Something Went Wrong",
        icon: "error",
        button: "OK",
        timer: 2000,
      });
    }
  };

  return (
    <div>
      {IsLoading && <Loader />}
      <div style={{ paddingTop: "10px" }}>
        <div>
          <label>
          <span style={{fontSize:"18px",fontWeight:"700"}}>
            Choose Your Service Below
          </span>
            <select
              style={{
                width: "100%",
                padding: "5px",
                border: "2px solid black",
                marginBottom: "10px",
              }}
              value={selectedService && selectedService.service_no}
              onChange={handleServiceChange}
            >
              {/* <option value={selectedService&&selectedService.service_no}>{selectedService&&selectedService.service}</option> */}
              {SevicesData &&
                Array.from(
                  new Set(SevicesData.map((service) => service.service))
                ).map((uniqueService, index) => {
                  const uniqueServiceData = SevicesData.find(
                    (service) => service.service == uniqueService
                  );
                  return (
                    <option key={index} value={uniqueServiceData.service_no}>
                      {uniqueService}
                    </option>
                  );
                })}
            </select>
          </label>
        </div>

        <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
          {timeSlots.map((slot, index) => (
            <button
              className="med-spa-custom-button"
              style={{
                // backgroundColor: slot.isBooked ? "#C0C0C0" : "#4851D5",
                backgroundColor:
                  selectedTimeSlot && selectedTimeSlot == slot.time
                    ? "green"
                    : slot.isBooked
                    ? "#C0C0C0"
                    : "#4851D5",
                cursor: slot.isBooked ? "not-allowed" : "pointer",
                minWidth: "73px",
              }}
              disabled={slot.isBooked}
              onClick={() => handleSlotSelection(slot)}
            >
              {slot.time}
            </button>
          ))}
        </div>

        <div style={{ paddingTop: "15px" }}>
          <div>
            {selectedTimeSlot && (
              <div>
                <p className="section-one-para-tag">
                  Do You want to Confirm Your Appointment on{" "}
                  <b>{selectedSlotDate}</b> at <b>{selectedTimeSlot}</b>.
                </p>

                <div style={{ paddingTop: "10px", textAlign: "right" }}>
                  {updateAppointmentData ? (
                    <button
                      className="med-spa-custom-button"
                      onClick={handleUpdateAppointment}
                    >
                      Update Appointment
                    </button>
                  ) : (
                    <button
                      className="med-spa-custom-button"
                      onClick={BookAppointment}
                    >
                      Book Appointment
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentSlot;
