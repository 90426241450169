import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ApiServices from '../../GlobalFiles/ApiServices/ApiServices';
import axios from 'axios';
import { Fade } from 'react-reveal';

const MedSpaStaffDetails = () => {
  const [doctorData, setDoctorData] = useState([]);
  useEffect(() => {
    ApiServices.get('/api/doctors/').then((response) => {
      // setDoctorData(response.data);
      const filteredData = response.data.filter((doctor) => doctor.active_status);
      // setDoctorData(filteredData);
      const sortedData = filteredData.sort((a, b) => a.id - b.id);
      setDoctorData(sortedData);
    });
  }, []);

  return (
    <Container style={{textAlign:"left"}}>
    <h1 class="explore-treartment-tag">Cosmos Medi Spa <br/>Where Beauty Meets Science, and Wellness is a Lifestyle.</h1>
        <p className='wel-come-staff-info' style={{paddingTop:"20px"}}>Welcome to Cosmos MediSpa LLC, where beauty meets science and expertise reigns supreme. Our team is comprised of six dedicated individuals, each bringing their unique set of skills and experience to the forefront of medical spa treatments. <br/><br/> <b>Let us introduce you to our exceptional providers :</b> </p>
    <div>
      {doctorData &&
        doctorData?.map((index, idx) => (
          <Fade bottom>
          <Row
            className={`d-flex align-items-center ${
              idx % 2 === 0 ? 'flex-row-reverse' : '' 
            }`}
            style={{ marginTop: '50px' }}
            key={index.id}
          >
            <Col sm={6} className="order-1">
              <img src={index.image} alt="" className="med-spa-staff-detail-image" />
            </Col>
            <Col sm={6} className="order-2">
              <div className="staf-header-detals-div">
                <h2 className="header-of-staff-name">{index.doctor_name}</h2> -{' '}
                <p className="sub-title-of-staff-name" style={{color:"rgb(28 50 74)",fontWeight:"700"}}>{index.title}</p>
              </div>
              <p className="med-spa-staff-info-p-tag">{index.info}</p>
            </Col>
          </Row>
          </Fade>
        ))}

        <p style={{paddingTop:"50px"}} className='wel-come-staff-info'>At Cosmos MediSpa LLC, we blend medical precision with a passion for enhancing your natural beauty. Our providers are dedicated to delivering personalized treatments that rejuvenate your skin, boost your confidence, and promote your overall well-being. We invite you to experience the Cosmos difference – where expertise meets aesthetics, and beauty becomes a science.</p>
        </div>

    </Container>
  );
};

export default MedSpaStaffDetails;
