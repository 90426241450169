import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FiArrowRight } from "react-icons/fi";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";

const ServicesCards = ({ data, selectedCategory }) => {
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // const filteredData = selectedCategory
  //   ? data.filter(
  //       (service) =>
  //         service.treatment_type === selectedCategory && service.activestatus
  //     )
  //   : data.filter((service) => service.activestatus);

  const filteredData = selectedCategory
    ? data.filter(
        (service) =>
          service.treatment_type === selectedCategory &&
          service.activestatus &&
          !service.is_subtype
      )
    : data.filter((service) => service.activestatus && !service.is_subtype);

  // Sort the filtered data by the sequence field
  const sortedData = [...filteredData].sort((a, b) => a.sequence - b.sequence);


  return (
    <div>
      <Container style={{ textAlign: "left" }}>
        <Row>
          {sortedData &&
            sortedData?.map((index) => (
              <Col md={3} style={{ paddingBottom: "15px" }}>
                <Fade bottom>
                  <Card className="services-card-body">
                    <Card.Img
                      variant="top"
                      src={index.image}
                      style={{ height: "180px" }}
                    />
                    <Card.Body>
                      <Link
                        to={`/services/${index.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Card.Title
                          style={{ borderBottom: "1px solid #1c324a" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h1 className="services-card-title">
                                {index.title}
                              </h1>
                            </div>
                            <div>
                              <FiArrowRight />
                            </div>
                          </div>
                        </Card.Title>
                        <Card.Text className="serv-ces-card-text">
                          {truncateText(index.short_info, 130)}
                        </Card.Text>
                      </Link>
                    </Card.Body>
                    <div style={{ paddingLeft: "20px", paddingBottom: "5px" }}>
                      <Link
                        to="/appointment"
                        style={{ textDecoration: "none" }}
                      >
                        <button className="med-spa-custom-button">
                          Make an Appointment
                        </button>
                      </Link>
                    </div>
                  </Card>
                </Fade>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};

export default ServicesCards;
