import axios from "axios";
import CryptoJS from "crypto-js";

class ApiService {
  get = async (url = {}) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_WEB_BACKEND + url,
        {}
      );
      return { error: null, data: response.data };
    } catch (error) {
      console.log("Error:", error);
      return { error, data: null };
    }
  };

  post = async (url, payload = {}) => {

    try {
      const response = await axios.post(
        process.env.REACT_APP_WEB_BACKEND + url,
        payload
      );
      return { error: null, data: response.data };
    } catch (error) {
      console.log("Post Request Error:", error);
      throw error;
    }
  };

  put = async (url, payload = {}) => {
    try {
      const response = await axios.put(
        process.env.REACT_APP_WEB_BACKEND + url,
        payload,
        
      );
      return { error: null, data: response.data };
    } catch (error) {
      console.log("Error:", error);
      return { error, data: null };
    }
  };

  patch = async (url, payload = {}) => {
    try {
      const response = await axios.patch(
        process.env.REACT_APP_WEB_BACKEND + url,
        payload,
        
      );
      return { error: null, data: response.data };
    } catch (error) {
      console.log("Error:", error);
      return { error, data: null };
    }
  };

  getemr = async (url = {}, headers = {}) => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVERURL + url, 
      {
        headers: headers,
      });
      return { error: null, data: response.data };
    } catch (error) {
      console.log("Error:", error);
      return { error, data: null };
    }
  };


  // getemr = async (url = {}, headers = {}) => {
  //   try {
  //     const response = await axios.get(process.env.REACT_APP_TEST_EMR + url, {
  //       headers: headers,
  //     });

  //     // Assuming the encrypted data is in base64 format
  //     const encryptedData = response.data;
  //     const decryptedData = this.decryptData(encryptedData);

  //     console.log("decryptedData",decryptedData)

  //     return { error: null, data: decryptedData };
  //   } catch (error) {
  //     console.log("Error:", error);
  //     return { error, data: null };
  //   }
  // };

  // decryptData = (encryptedData) => {
  //   const key = CryptoJS.enc.Utf8.parse('006QSunHhdL8NdibKR1AP3q5DNWpPs4VrvbFvPKNTToXg9BmqeELgbXr+7E8KkNnoDvxzjbzn4h/GA6r+6XYB/2DvsOSTSnIKcSB8XUrapu3UbKtAKsM3c37iv1tiGR+P59++1l9tNOMh7JhyFplVzq5A==');
  //   const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
  //     mode: CryptoJS.mode.ECB,
  //     padding: CryptoJS.pad.Pkcs7,
  //   });
  //   return decrypted.toString(CryptoJS.enc.Utf8);
  // };

  postemr = async (url, payload = {}, headers = {}) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVERURL + url,
        payload,
        {
          headers: headers,
        }
      );
      return { error: null, data: response.data };
    } catch (error) {
      console.log("Post Request Error:", error);
      throw error;
    }
  };



}
  export default new ApiService();
