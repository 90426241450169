import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineClockCircle } from 'react-icons/ai';
import { Link } from "react-router-dom";

const ServicesDetailsSectionOne = ({ data }) => {
  const detailsData = data;
    
  return (
    <div>
      <Container style={{textAlign:"left"}}>
      <Row className="align-items-center" style={{paddingTop:"50px"}}>
          <Col sm={6} className="order-2 order-sm-2">
            <img
              src={detailsData&&detailsData?.image}
              style={{ height: "100%", width: "100%" }}
              className="section-image-quadra-shape"
              alt=""
            />
          </Col>

          <Col sm={6} className="order-1 order-sm-1">
            <div style={{display:"flex",alignItems:"center",gap:"5px"}}><AiOutlineClockCircle/> <p className="ser-vice-detail-duration">{detailsData&&detailsData?.duration} {""} minutes</p></div>
            <h1 className="section-one-head-tag" style={{paddingTop:"20px"}}>
            {detailsData&&detailsData?.title}
            </h1>
            <div style={{paddingTop:"20px", paddingBottom:"30px"}}>
            <Link to="/appointment">
            <button className="med-spa-custom-button">Book an Appointment</button>
            </Link>
            </div>
            <p className="section-one-para-tag">
             {detailsData&&detailsData?.short_info}
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServicesDetailsSectionOne;
