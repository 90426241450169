import React from "react";
import ImagetoShow from "../../Assets/construction.jpg";

const PageUnderConstruction = () => {
  return (
    <div>
      <div style={{ height: "100%", overflowX: "hidden" }}>
        <h4 style={{ textAlign: "center" }}>Page Under Construction</h4>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <img
            src={ImagetoShow}
            alt=""
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default PageUnderConstruction;
